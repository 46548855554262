const SvgComponent = (props: { width?: string; height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 63.74 63.74"
    {...props}
  >
    <defs>
      <style>{".cls-1{fill:#174919}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <circle
        cx={31.87}
        cy={31.87}
        r={31.87}
        style={{
          fill: "#3bb64b",
        }}
      />
      <path
        d="M44.71 5.48c.69-.29.65.34.76.66 1.82 5.43 1.37 10.61-1.63 15.56-.95 1.57-2.24 2.78-3.52 4.01-1.37 1.32-1.97 2.96-2.27 4.75-.21 1.23.51 1.99 1.76 1.98 1.23 0 2.43-.43 3.71-.06 2.14.62 3.34 2.09 3.46 4.33.18 3.59-1.03 6.78-2.7 9.85-1.26 2.31-2.92 4.34-4.62 6.34-.13.15-.22.37-.52.29-.32-.55.07-1.03.22-1.51.81-2.45 1.71-4.88 1.61-7.52-.04-1.13-.38-2.13-1.29-2.86-.57-.46-.99-.51-1.24.38-1.68 6-5.53 10.31-10.67 13.6-1.58 1.01-3.19 1.95-4.91 2.7-.36.16-.7.44-1.23.31.18-.57.64-.82 1-1.11 4.86-3.96 7.43-9.15 8.32-15.27.13-.92-.17-1.72-.61-2.49-1.29-2.26-1.34-4.73-1.18-7.23 0-.12.04-.25.05-.37.02-3.09.32-3.03-2.44-4.04-3.64-1.32-6.67-3.57-8.73-6.92-.95-1.55-1.56-3.26-1.18-5.33 1.2.94 2.03 2.04 3.12 2.86 4.81 3.58 10.15 4.41 15.83 2.85 2.52-.69 4.37-2.48 5.66-4.79.85-1.13 1.27-2.46 1.78-3.76.92-2.33 1.08-4.8 1.46-7.23Z"
        className="cls-1"
      />
      <circle cx={34.48} cy={15.13} r={4.91} className="cls-1" />
    </g>
  </svg>
);
export default SvgComponent;
