import { InputFields } from "components/cards/BasicFormCard";
import { Input } from "components/input";
import { useFormContext, Controller } from "react-hook-form";

export default function RHFInput({
  name,
  disabled,
  ...rest
}: Omit<InputFields, "label">) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Input
            {...rest}
            defaultValue={value}
            name={name}
            onChange={onChange}
            error={error?.message}
            disabled={disabled}
          />
        </>
      )}
    />
  );
}
