import { useMemo } from "react";
import { IVmap } from "types/preferences/definition";
import { SortableTree } from "../../components/v-map/Tree/SortableTree";
import { IVMapFilter } from "pages/v-map";

interface Props {
  vmapId: number;
  filter: IVMapFilter;
  filteredVmaps: Pick<IVmap, "values">;
  indexes: number[];
}

const data = ["values", "kpis", "strategy", "project", "critical_activity"];
export const levelMappers = {
  level1: "valueCheck",
  level2: "kActivityCheck",
  level3: "sActivityCheck",
  level4: "pActivityCheck",
  level5: "cActivityCheck",
};
export const filtersName = [
  "includeInReporting",
  "includeInAvatar",
  "none",
  "includeInProfile",
];
export const renamingTitle = [
  "valueTitle",
  "kpiName",
  "strategyName",
  "projectName",
  "criticalActivityName",
];

export const renamingId = [
  "valueId",
  "kpiId",
  "strategyId",
  "projectId",
  "criticalActivityId",
] as const;
//@ts-ignore
function renameKeys(items: any[], filter, depth: number = 1, indexes) {
  if (!items) return [];
  //@ts-ignore will require a lot of type changes therefore we are delaying this for now,
  //TODO make it more typed
  //TODO the same functionality can be achieved the function flattenTree that lies in utilities
  return items?.reduce((newarr: any[], item: any) => {
    if (
      //@ts-ignore
      !filter[levelMappers[`level${depth}`]] &&
      !item[data[depth]]?.length
    ) {
      return [...newarr];
    }
    if (!filter["status"].includes(item.statusId)) {
      return [...newarr];
    }
    if (
      `level${depth}` == "level2" &&
      indexes[2] &&
      item[renamingId[depth - 1]] !== indexes[4]
    ) {
      return [...newarr];
    }

    if (
      //@ts-ignore
      !filter[levelMappers[`level${depth}`]] &&
      item[data[depth]]?.length > 0 &&
      //@ts-ignore
      !filter[levelMappers[`level${depth + 1}`]] &&
      //@ts-ignore
      !item[data[depth + 1]]?.length > 0
    ) {
      return [...newarr];
    }

    const {
      [data[depth]]: children,
      [renamingTitle[depth - 1]]: title,
      [renamingId[depth - 1]]: id,
      statusId: status,
      ...rest
    } = item;

    if (!showElementOrNot(item, filter, depth) && children?.length <= 0) {
      return [...newarr];
    }
    return [
      ...newarr,
      //@ts-ignore
      (!indexes[3] ||
        rest?.delegateTo?.split(",")?.includes(String(indexes[3])) ||
        `level${depth}` === "level1" ||
        children?.length > 0) && {
        ...rest,
        // TODO at present we are creating type in frontend only: but they should be sent from API endpoint
        type: `level${depth}`,
        id,
        status,
        title,
        children: renameKeys(children, filter, depth + 1, indexes),
      },
    ];
  }, []);
}

// @ts-ignore
export const showElementOrNot = (item, filters, depth) => {
  let val = [];

  if (depth === 1) {
    return true;
  }
  for (let key in filters) {
    if (filtersName.includes(key)) {
      if (key in item) {
        if (item[key] == 1 && filters[key] == 1) {
          val.push(true);
        } else {
          val.push(false);
        }
      }
      if (key === "none") {
        if (filters.none === 0) {
          val.push(false);
        } else {
          val.push(true);
        }
      }
    }
  }
  return val.includes(true);
};

export default function VmapDraggableTree({
  filteredVmaps,
  vmapId,
  filter,
  indexes,
}: Props) {
  const renamedKeys = useMemo(
    // @ts-ignore
    () => renameKeys(filteredVmaps, filter, 1, indexes),
    [filteredVmaps, filter, indexes]
  );
  if (renamedKeys) {
    return (
      <>
        <SortableTree
          vmapId={vmapId}
          removable={true}
          // @ts-ignore
          filter={filter}
          // indexes[0]-1 because the first vmap is a select all options
          currentVmapIndex={indexes[0] - 1}
          // @ts-ignore
          defaultItems={renamedKeys}
          collapsible={true}
          isDragOn={
            !Object.values(filter).includes(false) &&
            !Object.values(filter).includes(0) &&
            renamedKeys.length >= 1
          }
        />
      </>
    );
  } else {
    return <></>;
  }
}
