import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { TRunRate } from "types/dashboard";

const Dials = ({ runRate, actual }: TRunRate) => {
  const options: ApexOptions = {
    chart: {
      height: 450,
      width: "100%",
      type: "radialBar",
      zoom: {
        enabled: true,
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: ["#ffb300", "#2589f5"],
    labels: ["Actual", "Win Prob"],
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      floating: true,
      fontSize: "15px",
      position: "left",
      offsetX: 75,
      offsetY: 75,
      labels: {
        useSeriesColors: true,
      },
      formatter: function (seriesName, opts) {
        return (
          seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
        );
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "60%",
        },
      },
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      followCursor: false,
      intersect: false,
      inverseOrder: false,
      custom: undefined,
      fillSeriesColor: false,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        highlightDataSeries: false,
      },
      x: {
        show: true,
        format: "dd MMM",
        formatter: (seriesName) => seriesName + "%",
      },
      y: {
        formatter: (seriesName) => seriesName + "%",
        title: {
          formatter: (seriesName) => seriesName + ":",
        },
      },
      z: {
        formatter: undefined,
        title: "Size: ",
      },
      marker: {
        show: true,
      },
      items: {},
      fixed: {
        enabled: false,
        position: "topRight",
        offsetX: 0,
        offsetY: 0,
      },
    },
  };
  return (
    <div className="chart flex justify-center">
      <Chart
        key={"1"}
        options={options}
        series={[actual, runRate]}
        type="radialBar"
        width="320"
      />
    </div>
  );
};

export default Dials;
