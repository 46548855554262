import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { toastError, toastSuccess } from "components/toasts";
import { useState, useEffect } from "react";
import { IconButton, Popover, Tooltip, useToaster, Whisper } from "rsuite";
import { useUpdateVmapLevelStatusMutation } from "store/v-map/vmapApiSlice";

export const STATUS_LEVEL_VALUES = [
  {
    value: 0,
    label: "Pending",
  },
  {
    value: 1,
    label: "In Progress",
  },
  {
    value: 2,
    label: "Complete",
  },
];

const ALL_STATUSES = [
  {
    text: "Pending",
    color: "red",
    icon: (
      <ExclamationCircleIcon
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        className="w-6 h-6 hover:text-red-500 "
      />
    ),
  },
  {
    text: "In Progress",
    icon: (
      <ClockIcon
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        className="w-6 h-6 hover:text-yellow-500 "
      />
    ),
    color: "yellow",
  },
  {
    text: "Complete",
    color: "green",
    icon: (
      <CheckIcon
        strokeWidth="1.5"
        fill="none"
        stroke="currentColor"
        className="w-6 h-6 hover:text-green-500 "
      />
    ),
  },
] as const;

export interface Props {
  status: number;
  type: string;
  levelId: number;
  overrideClassName?: boolean;
  additionalClassName?: string;
  onSuccessHandler?: (_value: number) => void;
}

const StatusPopOver = ({
  status = 0,
  type,
  levelId,
  overrideClassName = false,
  additionalClassName = "",
  onSuccessHandler,
}: Props) => {
  const [updateVmapLevelStatus] = useUpdateVmapLevelStatusMutation();
  const [showPopOver, setShowPopOver] = useState(false);
  const toast = useToaster();

  const updateVmapStatus = async ({ type, status, levelId }: Props) => {
    try {
      const res = await updateVmapLevelStatus({
        type,
        status,
        levelId,
      }).unwrap();
      if (onSuccessHandler) {
        onSuccessHandler(status);
      }
      toast.push(toastSuccess({ message: res.message }));
    } catch (error) {
      toast.push(toastError({ message: "Something went wrong" }));
    } finally {
      setShowPopOver(false);
    }
  };
  useEffect(() => {
    const handleOutsideClick = (event: any) => {
      // Check if the click event target is not within the component itself
      if (
        showPopOver &&
        !event.target.closest(".popover") &&
        !event.target.closest(".icon-btn-status")
      ) {
        setShowPopOver(false);
      }
    };

    // Add the click event listener when the component mounts
    document.body.addEventListener("click", handleOutsideClick);

    // Remove the click event listener when the component unmounts
    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, [showPopOver]);

  return (
    <div
      className={overrideClassName ? additionalClassName : "absolute right-96"}
    >
      <Whisper
        trigger="click"
        placement={"bottom"}
        open={showPopOver}
        speaker={
          <Popover visible={!showPopOver}>
            <div
              className="popover flex flex-col gap-[5px]"
              data-testid="status-popover"
            >
              {ALL_STATUSES.map((item, index) => (
                <Whisper
                  key={index}
                  placement="right"
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={<Tooltip>{item.text}</Tooltip>}
                >
                  <IconButton
                    appearance="primary"
                    className="popover-icon !px-[10px] !py-[5px] md:!p-1 "
                    data-testid={item.text}
                    key={item.text}
                    circle
                    color={item.color}
                    onClick={() =>
                      updateVmapStatus({ status: index, type, levelId })
                    }
                    icon={item.icon}
                  />
                </Whisper>
              ))}
            </div>
          </Popover>
        }
      >
        <IconButton
          appearance="primary"
          data-testid="status-popover-default"
          className="icon-btn-status  !px-[10px] !py-[5px] md:!p-1 "
          onClick={() => {
            setShowPopOver(true);
          }}
          circle
          color={ALL_STATUSES[status].color}
          icon={ALL_STATUSES[status].icon}
        />
      </Whisper>
    </div>
  );
};

export default StatusPopOver;
