import { Link, useLocation } from "react-router-dom";

export interface ISideBarLink {
  linkLabel: string;
  linkUrl: string;
  img?: string;
  customIcon?: JSX.Element;
}

export interface ISideBarCardProps {
  sideBarLinks: Array<ISideBarLink>;
}

const SideBarCard = ({ sideBarLinks }: ISideBarCardProps) => {
  const location = useLocation();
  const isCurrentRoute = (linkUrl: string) =>
    location.pathname.includes(linkUrl) ||
    decodeURIComponent(location.hash).includes(linkUrl) ||
    location.pathname === linkUrl;

  return (
    <div className="profile-tabs-wrapper bg-white rounded-xl p-3 w-full md:full lg:w-1/3 xl:w-1/4 relative lg:sticky lg:top-40 h-[max-content] ">
      <ul className="profile-tabs px-1 flex justify-between lg:justify-start flex-wrap lg:flex-nowrap lg:flex-col gap-2 transition-all ease-in duration-500">
        {sideBarLinks?.map((sideBarLink) => (
          <li className={`profile-tab-item group`} key={sideBarLink.linkLabel}>
            {" "}
            <Link
              to={sideBarLink?.linkUrl}
              key={sideBarLink?.linkLabel}
              className={`group-hover:text-white group/link w-[330px] lg:w-full profile-tab-item group  group-focus/link:text-white group-focus/link:bg-button-blue group-active/link:text-white group-active/link:bg-button-blue flex justify-start focus:bg-button-blue focus:text-white active:bg-button-blue  hover:bg-button-blue hover:text-white  rounded-2xl px-3 py-2 hover:cursor-pointer transition-{background} duration-300 ease-in-out ${
                isCurrentRoute(sideBarLink?.linkUrl)
                  ? `bg-button-blue !text-white cursor-pointer rounded-2xl visited:text-white`
                  : "text-white"
              }`}
            >
              {sideBarLink?.customIcon}
              {sideBarLink?.img ? (
                <img
                  src={sideBarLink?.img}
                  alt="Profile"
                  className={`mr-2 w-6 group-hover:invert group-active:invert ${
                    isCurrentRoute(sideBarLink?.linkUrl) ? "invert" : ""
                  }`}
                />
              ) : null}
              {sideBarLink.linkLabel}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SideBarCard;
