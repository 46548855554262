import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export const DifinitionInitData = {
  value: '',
  kpi: '',
  strategy:'',
  project: '',
  criticleActivity: '',
  checkbox: {
    0: '',
    1: '',
    2: '',
    3: ''
  }
}
export interface ListState {
  selectedVmapId: number;
  editDelegate: boolean;
  editDelegateUser: number;
  addDelegateModal: boolean;
  assignDelegateModal: boolean,
  isDelete:boolean,
  deleteDelegateUserId: number;
}

const initialState: ListState = {
  selectedVmapId: 0,
  editDelegate: false,
  editDelegateUser: 0,
  addDelegateModal: false,
  assignDelegateModal: false,
  isDelete:false,
  deleteDelegateUserId: 0,
};

export const preferenceSlice = createSlice({
  initialState,
  name: "preferencesSlice",
  reducers: {
    coachCallState: (state, action: PayloadAction<number>) => {
      state.selectedVmapId = action.payload;
    },
    setDelegateEditable: (state, action: PayloadAction<boolean>) => {
      state.editDelegate = action.payload;
    },
    editDelegateUser: (state, action: PayloadAction<number>) => {
      state.editDelegateUser = action.payload;
    },
    deleteDelegate: (state, action: PayloadAction<boolean>) => {
      state.isDelete = action.payload;
    },
    deleteDelegateUser: (state, action: PayloadAction<number>) => {
      state.deleteDelegateUserId = action.payload;
    },
    addDelegateUser: (state, action: PayloadAction<boolean>) => {
      state.addDelegateModal = action.payload;
    },
    assignDelegate: (state, action: PayloadAction<boolean>) => {
      state.assignDelegateModal = action.payload;
    },
  },
});

export const {
  coachCallState,
  setDelegateEditable,
  editDelegateUser,
  deleteDelegate,
  deleteDelegateUser,
  addDelegateUser,
  assignDelegate
} = preferenceSlice.actions;

export default preferenceSlice.reducer;
