import { appApi } from "store/apis/apiSlice";
import {
  TTrackingRequest,
  TUpdateStatusRequest,
  TPostDataRequest,
  TPostDataResponse,
  TPutDataRequest,
  TVmapData,
  TTrackingDataDeletedList,
} from "../../types/tracking-data/index";
import { TTrackingData } from "types/tracking-data";
import { format } from "date-fns";

const callTrackingDataApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["TrackingData", "Dashboard Activity"],
});

export const callTrackingDataApiSlice =
  callTrackingDataApiWithTag.injectEndpoints({
    endpoints: (builder) => ({
      fetchTrackingList: builder.query<TTrackingData, TTrackingRequest>({
        query: ({ params }) => ({
          url: `/tracking-data?userId=${params?.userId}&vMap=${params?.vMap}&value=${params?.value}&kpi=${params?.kpi}&delegate=${params?.delegate}&status=${params?.status}&activity=${params?.activity}&period=${params?.period}&beginingDate=${params?.beginingDate}&endingDate=${params?.endingDate}`,
        }),
        providesTags: ["TrackingData"],
      }),
      fetchVmapList: builder.query<TVmapData, void>({
        query: () => ({
          url: `/vmaps?include=values.kpis.strategy.project.criticalActivity&page=1&pageSize=1000`,
        }),
        providesTags: ["TrackingData", "Dashboard Activity"],
      }),
      updateActivityStatus: builder.mutation<void, TUpdateStatusRequest>({
        query: ({ bodyData, activityId }) => {
          return {
            url: `/vmap-level-status/${activityId}/${bodyData?.type}`,
            method: "PUT",
            body: bodyData,
          };
        },
        invalidatesTags: ["TrackingData", "Dashboard Activity"],
      }),
      insertTrackingData: builder.mutation<TPostDataResponse, TPostDataRequest>(
        {
          query: ({ bodyData, token }) => {
            return {
              url: token
                ? `/add-tracking-data?token=${token}`
                : "/tracking-data",
              method: "POST",
              body: bodyData,
            };
          },
          invalidatesTags: ["TrackingData", "Dashboard Activity"],
        }
      ),
      updateTrackingData: builder.mutation<TPostDataResponse, TPutDataRequest>({
        query: ({ bodyData, trackingId, token }) => {
          return {
            url: token
              ? `/update-tracking-data/${trackingId}?token=${token}`
              : `/tracking-data/${trackingId}`,
            method: "PUT",
            body: bodyData,
          };
        },
        invalidatesTags: ["TrackingData", "Dashboard Activity"],
      }),
      deleteTrackingData: builder.mutation<TPostDataResponse, any>({
        query: ({ trackingID, token }) => {
          return {
            url: !token
              ? `/tracking-data/${trackingID}`
              : `/delete-tracking-data/${trackingID}?token=${token}`,
            method: "DELETE",
          };
        },
        invalidatesTags: ["TrackingData", "Dashboard Activity"],
      }),

      /**
       * get the deleted tracking data list
       */
      fetchUndoTrackingData: builder.query<TTrackingDataDeletedList, void>({
        query: () => ({
          url: "get-deleted-tracking-data",
        }),
        providesTags: ["TrackingData", "Dashboard Activity"],
      }),

      /**
       * revert the deleted tracking data
       */
      revertTrackingDataDelete: builder.mutation({
        query: (revertTrackingData) => {
          return {
            url: "revert-tracking-data-deletion",
            method: "POST",
            body: { id: revertTrackingData },
          };
        },
        invalidatesTags: ["TrackingData", "Dashboard Activity"],
      }),

      /**
       * insert the system generated tracking data
       */
      systemGeneratedTrackingData: builder.mutation({
        query: ({ comment, end_date, data, trackingData }) => {
          return {
            url: "system-generated-tracking-data",
            method: "POST",
            body: {
              comment: comment,
              data: data,
              end_date: format(new Date(end_date), "Y-MM-dd"),
              trackingData: trackingData,
            },
          };
        },
        invalidatesTags: ["TrackingData", "Dashboard Activity"],
      }),
    }),
  });

export const {
  useFetchTrackingListQuery,
  useFetchVmapListQuery,
  useUpdateActivityStatusMutation,
  useInsertTrackingDataMutation,
  useDeleteTrackingDataMutation,
  useUpdateTrackingDataMutation,
  useFetchUndoTrackingDataQuery,
  useRevertTrackingDataDeleteMutation,
  useSystemGeneratedTrackingDataMutation,
} = callTrackingDataApiSlice;
