import { StatusProps } from "../../../types/dashboard";
import { AvatarCalculate } from "sections/dashboard/components/AvatarCalculate";
import { format } from "date-fns";
import { useState } from "react";
import LevelDrawer from "components/v-map/Tree/components/TreeItem/LevelDrawer";
import { IKpis } from "types/preferences/definition";
import { LEVELS } from "./QuickUpdate";
import { useUpdateVmapLevelStatusMutation } from "store/v-map/vmapApiSlice";
import { useGetDelegateListQuery } from "store/preferences/profiles/profileApi";
import { toaster } from "rsuite";
import { toastError, toastSuccess } from "components/toasts";
import { useParams } from "react-router-dom";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import moment from "moment";

const ActivityList = () => {
  const param = useParams();

  const [showLevelDrawer, setShowLevelDrawer] = useState<{
    show: boolean;
    defaultValues: IKpis | null;
  }>({ show: false, defaultValues: null });

  const { data: activityData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
  });

  const { data: delegateList } = useGetDelegateListQuery();

  const [updateVmapLevelStatus] = useUpdateVmapLevelStatusMutation();

  const updateVmapStatus = async ({ type, levelId, status }: StatusProps) => {
    try {
      const response = await updateVmapLevelStatus({
        isDelegateViewing: param?.key,
        type,
        levelId,
        status,
      }).unwrap();

      toaster.push(toastSuccess({ message: response?.message }));
    } catch (error) {
      toaster.push(toastError({ message: "Something went wrong" }));
    }
  };

  return (
    <div className="details w-full overflow-x-auto">
      <table className="w-full mt-5">
        {renderDateList() ?? (
          <tbody>
            <tr>
              <td>
                <div className="w-full h-full flex justify-center items-center">
                  <label className="font-bold">No Activity Found!</label>{" "}
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </table>

      {showLevelDrawer.show && showLevelDrawer.defaultValues && (
        <LevelDrawer
          showDrawer={showLevelDrawer.show}
          setShowDrawer={setShowLevelDrawer}
          isValueDrawer={false}
          type={showLevelDrawer?.defaultValues?.type}
          level={LEVELS[showLevelDrawer?.defaultValues?.type]}
          isLevelUpdate={true}
          vmapId={+showLevelDrawer?.defaultValues?.levelId}
          defaultProps={{
            ...showLevelDrawer.defaultValues,
            //@ts-ignore
            levelId: showLevelDrawer.defaultValues.id,
            statusId: showLevelDrawer?.defaultValues?.status,
            name: showLevelDrawer?.defaultValues?.title,
            type: showLevelDrawer?.defaultValues?.type,
            ...(showLevelDrawer?.defaultValues?.type !== "level1" && {
              goal: showLevelDrawer?.defaultValues?.goal,
              dateAssignedDue: [
                moment(showLevelDrawer?.defaultValues?.startDate)?.toDate(),
                showLevelDrawer?.defaultValues?.dueDate
                  ? moment(showLevelDrawer?.defaultValues?.dueDate)?.toDate()
                  : moment(showLevelDrawer?.defaultValues?.endDate)?.toDate(),
              ],
              delegateTo:
                showLevelDrawer?.defaultValues?.delegates?.length > 0 &&
                showLevelDrawer?.defaultValues?.delegates
                  ?.split(",")
                  .map((item) => +item),
              seasonalGoal:
                showLevelDrawer?.defaultValues?.seasonalGoal?.split(","),
              includeInAvatar: showLevelDrawer?.defaultValues?.inAvatar,
              includeInProfile: showLevelDrawer?.defaultValues?.inProfile,
              includeInReporting: showLevelDrawer?.defaultValues?.inTracking,
              showOnDashboard: showLevelDrawer?.defaultValues?.inDashboard,
            }),
          }}
        />
      )}
    </div>
  );

  function renderDateList() {
    try {
      return activityData?.dataArray?.map((option: any) => {
        return (
          <>
            <thead>
              <tr>
                <td className=" text-black text-base font-bold pt-7">
                  {format(new Date(option[0]?.endDate), "MMMM dd, yyyy")}
                </td>
              </tr>
            </thead>
            {renderActivityList(option)}
          </>
        );
      });
    } catch (error) {
      return true;
    }
  }

  function renderActivityList(option: any) {
    return option?.map((option: any) => {
      return (
        <tbody key={option?.id}>
          <tr className="flex items-center justify-between w-full bg-slate-200 px-5 py-3 rounded-2xl mt-3 shadow-lg ">
            <td className="flex items-center gap-4 justify-center">
              <input
                type="checkbox"
                test-id={option?.id}
                id="v1"
                className="w-9 h-6 min-w-[36px]"
                defaultChecked={option?.status === 2 ? true : false}
                disabled={option?.status === 2 ? true : false}
                onChange={() =>
                  updateVmapStatus({
                    status: 2,
                    type: option?.type,
                    levelId: option?.id,
                  })
                }
              />
              <label
                htmlFor=""
                className="text-secondary-blue text-base cursor-pointer"
                onClick={() =>
                  setShowLevelDrawer({
                    show: true,
                    //@ts-ignore
                    defaultValues: option,
                  })
                }
              >
                {option?.title}
              </label>
            </td>
            <div className="wrap pl-5">
              {delegateList?.map((data) => {
                if (option.delegates.includes(data?.userId)) {
                  return (
                    <td className="text-black text-base">
                      {data?.user?.name + ","}
                    </td>
                  );
                }
              })}
              <td>
                <button
                  className={`border min-w-[120px] rounded-full ${AvatarCalculate(
                    new Date(option?.endDate)
                  )
                    ?.type} px-7 py-2 ml-5 font-bold text-md text-white pointer-events-none`}
                >
                  {AvatarCalculate(new Date(option?.endDate))?.finalPercentage}%
                </button>
              </td>
            </div>
          </tr>
        </tbody>
      );
    });
  }
};

export default ActivityList;
