import { PopOverModal } from "components/modals/PopOverModal";
import Tiptap from "components/v-map/Tree/components/Editor";
import EditIcon from "@rsuite/icons/Edit";
import { Button, IconButton } from "rsuite";
import { useRef } from "react";

export const NotePopup = ({
  setNote,
  note,
  onSave,
  dataTestId,
  isNoteSaving,
  disabled,
}: {
  note?: string;
  dataTestId?: string;
  setNote: (_val: string) => void;
  onSave: () => Promise<boolean>;
  disabled: boolean;
  isNoteSaving: boolean;
  delegateNote: string | undefined;
}) => {
  const ref = useRef<HTMLElement | null>(null);

  const onSaveHandler = async (isClosing?: boolean, isNoteSaving?: boolean) => {
    if (isClosing && isNoteSaving) {
      return;
    }
    const res = await onSave();
    if (res && ref?.current && !isClosing) {
      ref?.current?.click();
    }
  };
  return (
    <div className="ml-2">
      <PopOverModal
        onCloseHandler={() => {
          onSaveHandler(true, isNoteSaving);
        }}
        title={"Add Note"}
        popOverChildren={
          <div>
            <div className="relative flex flex-col mt-6 text-gray-700 bg-white  w-96 h-56 overflow-auto">
              <section>
                <Tiptap
                  withWrapper={true}
                  setValue={setNote}
                  draftJs={note ?? ""}
                  placeholder="Write your notes here"
                />
              </section>
            </div>
            <div className="flex justify-end">
              <Button
                loading={isNoteSaving}
                disabled={disabled}
                type="button"
                appearance="primary"
                onClick={() => {
                  onSaveHandler(false);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        }
      >
        <div data-testId={dataTestId} className="group relative inline-block">
          <IconButton
            ref={ref}
            icon={<EditIcon />}
            color="blue"
            appearance="primary"
            circle
            className="w-[35px] h-[35px]"
          />

          <div className="absolute w-44 top-1/2 mb-2 -left-[190px] bg-white px-4 py-3 shadow-[0_7px_10px_0_rgba(0,0,0,0.2)] -translate-y-1/2 column text-left opacity-0 invisible group-hover:opacity-100 group-hover:visible before:absolute before:w-4 before:h-4 before:bg-white before:rotate-45 before:-right-1 before:top-1/2 before:-translate-y-1/2">
            <div className="horizontal-text">C - Celebrations</div>
            <div className="horizontal-text">O - Opportunities</div>
            <div className="horizontal-text">A - Avatar Health</div>
            <div className="horizontal-text">C - CANI Commitment</div>
            <div className="horizontal-text">H - Help</div>
          </div>
        </div>
      </PopOverModal>
    </div>
  );
};
