import React, { useState } from "react";
import Table from "components/table/Table";
import { ColumnDef, PaginationState } from "@tanstack/react-table";
import { RunRateCalculate } from "./../components/RunRateCalculate";
import { IDashboardAvatarActivity } from "../../../types/dashboard";
import { Modal } from "rsuite";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/index";
import AvatarInfo from "components/avatar/AvatarInfo";
import { useGetUserQuery } from "store/auth/authApi";

const PerformanceAvatarItemDetails = ({
  activityType,
  show,
  close,
  health,
  userDetails
}: any) => {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { filterDates } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const { data: userDetailsssss } = useGetUserQuery();

  const { activityList, dashboardApiData, isPerformanceAvatar } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const details = userDetails ? userDetails : userDetailsssss

  const columns = React.useMemo<ColumnDef<IDashboardAvatarActivity>[]>(
    () => [
      {
        accessorFn: (row) => row?.title,
        id: "title",
        header: () => <span>Dials</span>,
        cell: (info) => info?.getValue(),
      },
      {
        accessorFn: (row) => row?.dueDate,
        id: "dueDate",
        enableSorting: true,
        header: () => <span>Due Date</span>,
        cell: (cell) => (
          <>
            {format(
              new Date(cell?.row?.original?.dueDate ?? ""),
              "MMMM dd, yyyy"
            )}
          </>
        ),
      },
      {
        accessorFn: (row) => row?.endDate,
        id: "endDate",
        sortDescFirst: true,
        header: () => <span>Avatar</span>,
        cell: (cell) => (
          <div className=" w-[100px] h-[80px]">
            <>
              <AvatarInfo
                //   @ts-ignore
                percentage={
                  RunRateCalculate(
                    cell?.row?.original,
                    filterDates,
                    dashboardApiData,
                    details
                  )?.actualRunRate
                }
                //   @ts-ignore
                grade={
                  RunRateCalculate(
                    cell?.row?.original,
                    filterDates,
                    dashboardApiData,
                    details
                  )?.grade
                }
                className={"flex-1"}
                health={health}
              />
            </>
          </div>
        ),
      },
    ],
    [dashboardApiData]
  );

  let scoreData: any = [];
  let activityData: any = [];
  activityList?.map((options, key) => {
    let scores = RunRateCalculate(
      options,
      filterDates,
      dashboardApiData,
      details
    );

    scoreData?.push({
      grade: scores?.grade,
      finalPercentage: scores?.runRate,
      id: options?.id,
      activityList: options,
    });
  });

  scoreData.sort((a: any, b: any) =>
    a.finalPercentage < b.finalPercentage ? 1 : -1
  );
  scoreData?.map((list: any) => {
    activityData?.push(list?.activityList);
  });

  return (
    <Modal size={"lg"} open={show ? true : false}>
      <Modal.Header onClose={() => close(!show)}>
        <Modal.Title className="!text-primary-blue !text-[22px] md:!text-[28px] !font-bold text-left">
          Performance Avatar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-[412px] md:w-full">
          <Table
            tableData={{
              data: activityData,
              columns,
            }}
            isPagination={true}
            isManualPagination={false}
            pageIndex={pageIndex}
            pageSize={pageSize}
            showGlobalFilter={false}
            pageCount={Math.ceil(activityData?.length / pageSize)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          onClick={() => close(!show)}
          className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500 ml-2"
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PerformanceAvatarItemDetails;
