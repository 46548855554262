import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "config";
import { RootState } from "store";


const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { getState }) => {
    headers.set("Accept", "application/json, text/plain, */*");

    if (
      localStorage.getItem("simulateArray") ||
      (getState() as RootState)?.auth?.simulate
    ) {
      const token = (
        (getState() as RootState)?.auth?.simulate ??
        (JSON.parse(localStorage.getItem("simulateArray") as string) as {
          name: string;
          token: string;
          active: boolean;
        }[])
      )?.filter((item) => item?.active)[0]?.token;

      headers.set("Authorization", `Bearer ${token}`);
    } else {
      const token =
        (getState() as RootState)?.auth?.token ??
        window.localStorage.getItem("access_token");
      // If we have a token in localStorage, lets use it:
      headers.set("Authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const appApi = createApi({
  baseQuery,
  endpoints: () => ({}),
});
