import moment from "moment";
import { appApi } from "store/apis/apiSlice";
import { IUserProfile } from "types";
import {
  ICriticalActivity,
  IKpis,
  IProject,
  IStrategy,
  IVampValues,
  IVmap,
} from "types/preferences/definition";
import { IDelegateQueryParams } from "types/preferences/delegate";
import { defaultLevelsOfVmap } from "utils/defaultData";

type TPage = "dashboard" | "vmap" | undefined;
export interface IGetAllProfileResponse {
  filter_id: number;
  filter_parent_id: number;
  user_id: number;
  filter_name: string;
  vMap_id: string;
  value_id: string;
  kpi_id: string;
  strategy_id: string;
  project_id: string;
  critical_activity_id: string;
  delegate_id: string;
  display_order: number;
  begining_date: null;
  ending_date: null;
  tracking_begining_date: null;
  tracking_ending_date: null;
  created_at: string;
}

export interface IDelegateList {
  id: number;
  userId: string;
  parentId: number;
  isDelegate: number;
  status: number;
  user: IUserProfile;
}

export interface IVmapValue {
  valueId: number;
}

export type TVmaps = {
  vmapId: number;
  valueIds: number[];
  kpiIds: number[];
  strategyIds: number[];
  projectIds: number[];
  // criticalActivityIds: number[];
  delegateIds: number[];
  // dialDateRange: null,
  // trackingDateRange: null,
}[];
export interface IEditProfileResponse {
  name: string;
  profile: {
    filter_id: number;
    vmaps: IVmap[];
    values: IVmapValue[];
    kpis: IKpis[];
    strategies: {
      strategyId: number;
      kpiId: number;
      strategyName: string;
    }[];
    projects: {
      projectId: number;
      strategyId: number;
      projectName: string;
    }[];
    delegates: {
      delegateUsersId: number;
      name: string;
    }[];
    criticalActivities: ICriticalActivity[];
    begining_date: Date;
    ending_date: Date;
    tracking_begining_date: Date;
    tracking_ending_date: Date;
    delegate_id: string;
  }[];
  vmaps: TVmaps;
}

export interface IVmapAndDelegateList {
  vmapList: IVmap[];
  manageDelegateList: IDelegateList[];
}

export interface IAddDashboardProfileRequest {
  name: string;
  vmaps: IVmapResponse[];
}

export type IVmapResponse = {
  vmapId: number;
  valueIds: number[];
  kpiIds: number[];
  strategyIds: number[];
  projectIds: number[];
  criticalActivityIds: number[];
  delegateIds: number[];
  dialDateRange: string;
  trackingDateRange: string;
};

export interface IAddDashboardProfileResponse {
  data: {
    name: string;
    vmaps: IVmapResponse[];
  };
}

//the default date here comes from user profile that we take from initial user profile query
export const isValidDate = (date: Date, defaultDate: string) => {
  const isInvalidDate: string = new Date(date).toDateString();
  if (isInvalidDate === "Invalid Date") {
    return new Date(moment(defaultDate).format("MM/DD/YYYY"));
  }
  return new Date(moment(date).format("MM/DD/YYYY"));
};

export const getUrlParams = (key: string, value: number[]) => {
  if (!value) return key;
  if (value && typeof value[0] == "object") {
    //@ts-ignore
    let urlString = `${key}=${value[0].value}`;
    return urlString;
  }
  let urlString: null | string = null;
  for (let id of value) {
    if (urlString) {
      urlString += `&${key}=${id}`;
    } else {
      urlString = `${key}=${id}`;
    }
  }
  return urlString ?? key;
};

const apiWithTag = appApi.enhanceEndpoints({
  addTagTypes: [
    "DashboardProfile",
    "VmapList",
    "IndividualProfile",
    "ValueList",
    "KpiList",
    "StrategyList",
    "ProjectList",
    "CriticalList",
  ],
});

export const dashboardProfileApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllProfile: builder.query<IGetAllProfileResponse[], void>({
      query: () => "dashboard-profile",
      transformResponse: (res: { data: IGetAllProfileResponse[] }) => {
        return res.data;
      },
      providesTags: ["DashboardProfile"],
    }),

    deleteProfile: builder.mutation<[], number>({
      query: (profileId) => ({
        url: `dashboard-profile/${profileId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DashboardProfile"],
    }),

    getVmapList: builder.query<
      IVmap[],
      { vmapPageSize: number[]; include?: string[]; endPoint?: string }
    >({
      query: ({ vmapPageSize, include, endPoint }) => ({
        url: `${endPoint ?? "vmaps"}?include=${
          include ? include.join(".") : ""
        }&page=${vmapPageSize[0]}&pageSize=${vmapPageSize[1]}`,
      }),
      providesTags: ["VmapList"],
      transformResponse: (
        res: { data: IVmap[] } | { data: { data: IVmap[] } },
        meta,
        args
      ) => {
        if (args.endPoint) {
          //@ts-ignore
          return res.data.data;
        }
        return res.data;
      },
    }),
    getDelegateList: builder.query<
      IDelegateList[],
      IDelegateQueryParams | void
    >({
      query: (params) => {
        if (params?.page && params?.pageSize) {
          return {
            url: `delegate?page=${params?.page}&pageSize=${params?.pageSize}`,
          };
        } else {
          return {
            url: `delegate`,
          };
        }
      },
      transformResponse: (res: { data: IDelegateList[] }) => {
        function removeDuplicates(array: IDelegateList[]) {
          array = array.filter((item) => item.user);
          // Create a map to keep track of unique items based on the 'id' property
          let uniqueItems = new Map();

          // Iterate through the array and add each item to the map using 'id' as the key
          array.forEach((item) => {
            uniqueItems.set(item?.user?.id, item);
          });

          // Convert the map values back to an array to get unique items
          let uniqueArray = Array.from(uniqueItems.values());

          return uniqueArray;
        }
        return removeDuplicates(res.data);
      },
    }),
    getVmapValues: builder.query<
      IVampValues[],
      { vmapId: number; page: TPage }
    >({
      query: ({ vmapId }) => {
        if (!vmapId) return "";
        return `vmap-values?vmapId=${vmapId}`;
      },
      transformResponse: (res: { data: IVampValues[] }, meta, args) => {
        if (args.page === "dashboard" && args.vmapId) {
          return [...res.data, defaultLevelsOfVmap.value] as IVampValues[];
        }
        return res.data;
      },
      providesTags: ["ValueList"],
    }),
    getKpis: builder.query<IKpis[], { valueIds: number[]; page: TPage }>({
      query: ({ valueIds }) => {
        if (!valueIds) return "";
        return {
          url: `value-kpis?${getUrlParams("valueId[]", valueIds)}`,
        };
      },
      transformResponse: (res: { data: IKpis[] }, meta, args) => {
        if (args.page === "dashboard" && args.valueIds?.length > 0) {
          return [...res.data, defaultLevelsOfVmap.kpi] as IKpis[];
        }

        return res.data;
      },
      providesTags: ["KpiList"],
    }),
    getStrategies: builder.query<
      IStrategy[],
      { values: number[]; page: TPage }
    >({
      query: ({ values }) =>
        `kpi-strategies?${getUrlParams("kpiId[]", values)}`,
      transformResponse: (res: { data: IStrategy[] }, meta, args) => {
        if (args.page === "dashboard" && args.values?.length > 0) {
          return [...res.data, defaultLevelsOfVmap.strategy] as IStrategy[];
        }

        return res.data;
      },
      providesTags: ["StrategyList"],
    }),
    getProjects: builder.query<IProject[], { values: number[]; page: TPage }>({
      query: ({ values }) =>
        `strategy-projects?${getUrlParams("strategyId[]", values)}`,
      transformResponse: (res: { data: IProject[] }, meta, args) => {
        if (args.page === "dashboard" && args.values?.length > 0) {
          return [...res.data, defaultLevelsOfVmap.project] as IProject[];
        }

        return res.data;
      },
      providesTags: ["ProjectList"],
    }),
    getCriticalActivities: builder.query<
      ICriticalActivity[],
      { values: number[]; page: TPage }
    >({
      query: ({ values }) =>
        `project-critical-activities?${getUrlParams("projectId[]", values)}`,
      transformResponse: (res: { data: ICriticalActivity[] }, meta, args) => {
        if (args.page === "dashboard" && args.values?.length > 0) {
          return [
            ...res.data,
            defaultLevelsOfVmap.criticalActivity,
          ] as ICriticalActivity[];
        }
        return res.data;
      },
    }),

    //-------------DASHBOARD PROFILE CRUD OPERATIONS------------
    getProfileList: builder.query<
      IEditProfileResponse,
      {
        filter_id: number | string;
        rangeStartDate: string;
        rangeEndDate: string;
      }
    >({
      query: ({ filter_id }) => {
        return {
          url: `dashboard-profile/${filter_id}`,
        };
      },
      providesTags: ["IndividualProfile"],
      transformResponse: (res: { data: IEditProfileResponse }, meta, args) => {
        const allVmaps = res.data.profile;
        const vmaps = [];
        // TODO make this more performant
        for (let vmap = 0; vmap < allVmaps.length; vmap++) {
          let vmapObj = {
            filter_id: allVmaps[vmap].filter_id,
            //@ts-ignore
            vmapId: allVmaps[vmap]?.vmaps[0]?.vMapId,
            valueIds: allVmaps[vmap]?.values?.map((value) => value.valueId),
            kpiIds: allVmaps[vmap]?.kpis?.map((kpi) => kpi.kpiId),
            strategyIds: allVmaps[vmap]?.strategies?.map(
              (strategy) => strategy.strategyId
            ),
            projectIds: allVmaps[vmap]?.projects?.map(
              (project) => project.projectId
            ),
            criticalActivityIds: allVmaps[vmap]?.criticalActivities?.map(
              (criticalActivity) => criticalActivity.criticalActivityId
            ),
            delegateIds: allVmaps[vmap]?.delegate_id
              ?.split(",")
              ?.map((delegate) => +delegate),
            dialDateRange: [
              isValidDate(allVmaps[vmap]?.begining_date, args.rangeStartDate),
              isValidDate(allVmaps[vmap]?.ending_date, args.rangeEndDate),
            ],
            trackingDateRange: [
              isValidDate(
                allVmaps[vmap]?.tracking_begining_date,
                args.rangeStartDate
              ),
              isValidDate(
                allVmaps[vmap]?.tracking_ending_date,
                args.rangeEndDate
              ),
            ],
          };
          vmaps.push(vmapObj);
        }
        return { name: res.data.name, profile: res.data.profile, vmaps };
      },
    }),

    //@ TODO: work on individually invalidating cache

    addDashboardProfile: builder.mutation<[], IAddDashboardProfileRequest>({
      query: (body) => ({
        url: "dashboard-profile",
        method: "POST",
        body: {
          ...body,
        },
      }),
      invalidatesTags: ["DashboardProfile"],
    }),
    editDashboardProfile: builder.mutation<
      [],
      { vmapData: IAddDashboardProfileRequest; profileId: number }
    >({
      query: ({ vmapData, profileId }) => ({
        url: `dashboard-profile/${profileId}`,
        method: "PUT",
        body: {
          ...vmapData,
        },
      }),
      invalidatesTags: ["IndividualProfile", "DashboardProfile"],
    }),
    deleteProfileVmap: builder.mutation<[], number>({
      query: (filter_id) => ({
        url: `dashboard-profile-vmap/${filter_id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IndividualProfile"],
    }),
  }),
});

export const {
  useFetchAllProfileQuery,
  useDeleteProfileMutation,
  useGetVmapValuesQuery,
  useGetKpisQuery,
  useGetStrategiesQuery,
  useGetProjectsQuery,
  useGetVmapListQuery,
  useGetCriticalActivitiesQuery,
  useAddDashboardProfileMutation,
  useGetProfileListQuery,
  useGetDelegateListQuery,
  useEditDashboardProfileMutation,
  useDeleteProfileVmapMutation,
} = dashboardProfileApi;
