import { InputFields } from "components/cards/BasicFormCard";
import { ISideBarLink } from "components/cards/SideBarCard";
import {
  MANAGE_QUALIFIER,
  PREFERENCES_DASHBOARD_PROFILES_ROUTE,
  PREFERENCES_DELEGATE_ROUTE,
  PREFERENCES_GENERAL_SETTINGS_ROUTE,
  PREFERENCES_MyAvatar_SETTINGS_ROUTE,
  PREFERENCES_VMAP_DEFINITION_ROUTE,
} from "routes/paths";
import { IGeneralSettingsPageResponse } from "store/tools/general-settings/generalSettingsApi";
import { IUserProfile } from "types";
import { DEFAULT_PAGE, OPEN_CLOSE, WEEK_DAYS, YES_NO } from "./constants";
import moment from "moment";

export const getGeneralSettingsInputFields = (
  data: IGeneralSettingsPageResponse,
  profile: IUserProfile,
  default_profile: number
): InputFields[] => {
  return [
    {
      label: "Weekly Working Days",
      name: "workingDaysWeekly",
      type: "multi-select",
      defaultValue: profile.workingDaysWeekly.split(","),

      data: Object.keys(WEEK_DAYS).map((day) => {
        return { value: day, label: WEEK_DAYS[day] };
      }),
      searchable: false,
    },
    {
      label: "Activity Avatar",
      name: "vmapVisionMission",
      type: "single-select",
      defaultValue: profile?.vmapVisionMission,

      data: Object.keys(OPEN_CLOSE).map((option) => {
        return { value: +option, label: OPEN_CLOSE[option] };
      }),
      searchable: false,
      className: "text-black-500",
    },
    {
      label: "Time Period",
      name: "timePeriod",
      type: "date-range",
      className: "w-full",
      //@ts-ignore
      defaultValue: [
        //@ts-ignore
        new Date(
          moment(profile.rangeStartDate ?? new Date()).format("MM/DD/YYYY")
        ),
        //@ts-ignore
        new Date(
          moment(profile.rangeEndDate ?? new Date()).format("MM/DD/YYYY")
        ),
      ],
      showPredefinedRanges: true,
    },
    {
      label: "Default Page :",
      name: "defaultPage",
      type: "single-select",
      defaultValue: profile.defaultPage,
      data: Object.keys(DEFAULT_PAGE).map((page) => {
        return { value: page, label: DEFAULT_PAGE[page] };
      }),
      searchable: true,
    },
    {
      label: "Default Profile:",
      name: "defaultProfile",
      type: "single-select",
      defaultValue: default_profile,
      data: data?.dashboardProfile?.map((el) => {
        return { value: el.filter_id, label: el.filter_name };
      }),
      searchable: true,
    },
    {
      label: "Default V-MAP:",
      name: "defaultVmap",
      type: "single-select",
      defaultValue: profile.defaultVmap,
      data: data?.vmap?.map((el) => {
        return { value: el.id, label: el.name };
      }),
      searchable: true,
    },
    {
      label: "Completed Items show in default V-MAP :",
      name: "completeIteminVmap",
      type: "single-select",
      defaultValue: profile.completeIteminVmap.toString(),
      data: Object.keys(YES_NO).map((option) => {
        return { value: +option, label: YES_NO[option] };
      }),
      searchable: false,
    },
    {
      label: "Completed Items show in default Tracking Data :",
      name: "completeIteminTracking",
      type: "single-select",
      defaultValue: profile.completeIteminTracking.toString(),
      data: Object.keys(YES_NO).map((option) => {
        return { value: +option, label: YES_NO[option] };
      }),
      searchable: false,
    },
    {
      label: "Max % for Delegate Performance Avatar :",
      name: "maxPerformAv",
      type: "number",
      defaultValue: profile.maxPerformAv,
      min: 0,
      isControlled: true,
    },
    {
      label: "Min % for Delegate Performance Avatar :",
      name: "minPerformAv",
      defaultValue: profile.minPerformAv,
      type: "number",
      min: 0,
      isControlled: true,
    },
  ];
};

/**
 * for the sidebar links
 * @returns
 */
export function getPreferencesSideBarLinks(): ISideBarLink[] {
  return [
    {
      linkLabel: "My Avatar",
      linkUrl: PREFERENCES_MyAvatar_SETTINGS_ROUTE,
      img: "/assets/mail.svg",
    },
    {
      linkLabel: "General Settings",
      linkUrl: PREFERENCES_GENERAL_SETTINGS_ROUTE,
      img: "/assets/mail.svg",
    },
    {
      linkLabel: "V-MAP Definition",
      linkUrl: PREFERENCES_VMAP_DEFINITION_ROUTE,
      img: "/assets/user-circle.svg",
    },
    {
      linkLabel: "Dashboard Profile",
      linkUrl: PREFERENCES_DASHBOARD_PROFILES_ROUTE,
      img: "/assets/user.svg",
    },
    {
      linkLabel: "Manage Delegates",
      linkUrl: PREFERENCES_DELEGATE_ROUTE,
      img: "/assets/briefcase.svg",
    },
    {
      linkLabel: "Manage Qualifiers",
      linkUrl: MANAGE_QUALIFIER,
      img: "/assets/briefcase.svg",
    },
  ];
}
