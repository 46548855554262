import { ErrorMessage } from "@hookform/error-message";
import { format } from "date-fns";
import { useFormContext, Controller } from "react-hook-form";
import { DatePicker, DatePickerProps } from "rsuite";

interface IRHFDatePicker extends DatePickerProps {
  name: string;
  // label: string;
  defaultValue?: Date;
}

//TODO: types checking
//@ts-ignore
export default function RFHDatePicker({
  name,
  defaultValue,
  ...rest
}: IRHFDatePicker) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <>
          <DatePicker
            {...rest}
            defaultValue={defaultValue}
            format="MM/dd/yyyy"
            onChange={(e) => {
              // TODO maybe it can cause some error : because here i am assuming the output will always be the following date format
              let dateVal = new Date();
              try {
                if (e) {
                  //@ts-ignore
                  dateVal = format(e, "yyyy-MM-dd");
                }
              } catch (error) {
                //@ts-ignore
                dateVal = format(new Date(), "yyyy-MM-dd");
              }
              return onChange(dateVal);
            }}
            placeholder={"MM/DD/YYYY"}
            size={"md"}
            block
            disabled={false}
            cleanable={false}
            oneTap
          />
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span className="text-red-500">{message}</span>
            )}
          />
        </>
      )}
    />
  );
}
