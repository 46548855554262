import { useLocation, useParams } from "react-router-dom";
import EditActivity from "./EditActivity";
import ActivityList from "./ActivityList";
import { QuickPrint } from "./QuickPrint";
import { QuickUpdate } from "./QuickUpdate";
import { AddActivity } from "./AddActivity";
import { activityAvatar, showQuickUpdate, showQuickprint } from "store/dashboard";
import { useDispatch } from "react-redux";
import { FolderOpenIcon, PrinterIcon } from "@heroicons/react/20/solid";
import { AvatarCalculate } from "sections/dashboard/components/AvatarCalculate";
import { IDashboardDataList, TActivtityHealth } from "types/dashboard";
import { Panel, PanelGroup, useToaster } from "rsuite";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import { useAddNoteForDelegateMutation, useGetNoteOfDelegateQuery } from "store/dashboard/DashboardSlice";
import { toastError, toastSuccess } from "components/toasts";
import { useEffect, useState } from "react";
import { useGetUserQuery } from "store/auth/authApi";
import { NotePopup } from "components/dashboard";
import AvatarInfo from "components/avatar/AvatarInfo";
import PerformanceAvatarItemDetails from "sections/dashboard/components/PerformanceAvatarItemDetails";
import { useSelector } from "react-redux";
import { RootState } from "store";
import Base64 from "base64";
import { Calculate } from "sections/dashboard/components/Calculate";
import ActivityAvatarItemDetails from "sections/dashboard/components/ActivityAvatarItemDetails";

const Activity = (userDetails: any, list: IDashboardDataList) => {
  let param = useParams();
  const dispatch = useDispatch();
  // const { encryptedId } = useParams<{ encryptedId: string }>();
  // const id = encryptedId ? decryptId(encryptedId) : null;
  const [showActivityAvatar, setShowActivityAvatar] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let idddddddddd = queryParams.get('id') || '';
  let decodedString = Base64.atob(idddddddddd);

  let number = decodedString.match(/\d+/)?.[0]; 
  let result = number ? parseInt(number) / 1000 : 0;


  const { data: activityData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
    id: param?.id ?? "",
  });

  let healthData: TActivtityHealth = getActivityHealthList();

  const { data: user } = useGetUserQuery();

  const [note, setNote] = useState<string | null>(null);
  const toast = useToaster();

  const { filterDates, dashboardApiData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );


  const {
    data: delegateNote,
    isLoading,
    isFetching,
  } = useGetNoteOfDelegateQuery(
    { delegateId: result ? result : '',
      key: param.key,
     },
    {
      skip: !result,
    }
  );

  const id = result;

  const [addNotesPopup, { isLoading: isNoteSaving }] = useAddNoteForDelegateMutation();

  const activityNote = delegateNote?.data?.activity_description;
  const activityDate = delegateNote?.data && delegateNote?.data?.created_at; 

  
  const saveNoteHandler = async () => {
    try {   
      if (id && note) {
        const res = await addNotesPopup({
          activity_description: note,
          performance_description: delegateNote?.data?.performance_description,
          delegateId: id,
          type: "activity",
          key: param?.key,
        }).unwrap();
        if (res?.status) {
          toast.push(toastSuccess({ message: "Notes Updated Successfully" }));
          return true;
        }
      }
    } catch (error) {
      toast.push(
        toastError({ message: "Unable To Add/Update Note Successfully" })
      );
      return false;
    }
    return false;
  };

  
  const getActivitiesData = {avatarActivities: userDetails?.list?.dialsArray}
  
  let results = Calculate(getActivitiesData);

  let activityDataaaa = userDetails?.list?.dialsArray?.filter((list: any) => list?.inProfile);
 
  return (
    <>
      <PanelGroup accordion defaultActiveKey={2} bordered>
        <div className="single-user-wrapper bg-white p-3 rounded-xl ">
        <div style={{top: '3px'}} className="absolute z-[99] right-[28px] px-5 py-[2px] transition-all ease-in-out duration-500">
       
        <div className="gap-2 flex flex-row justify-between border-b">

<div style={{marginTop: '7px'}}>
  <NotePopup
      note={note ? note : activityNote}
      setNote={(htmlString) => {
        setNote(htmlString);
      }}
      onSave={saveNoteHandler}
      disabled={note === activityNote || !note}
      isNoteSaving={isNoteSaving || isFetching || isLoading}
      delegateNote={activityNote}
      dataTestId="activity-note-icon"
      // date={activityDate}
    />
    </div>

    <div
      role="button"
      data-testid="show-performance-table"
      className="gap-1 md:gap-3 flex flex-row justify-between"
      onClick={() => {
        setShowActivityAvatar(true);
        dispatch(activityAvatar(activityDataaaa));
      }}
    >
      <AvatarInfo
        percentage={Math.round(results?.finalPercentage ?? 0)}
        avatarWidth={50}
        grade={results?.grade ?? "F"}
        health={userDetails?.list?.health}
        delegateId={idddddddddd}
      />
      <div className="w-[150px]">
        <p className="font-semibold text-md cursor-pointer">
          {/* {list?.name} */}
        </p>
      </div>
    </div>
    </div>


          </div>
          <Panel
            header={
              <div className="flex flex-row justify-between items-center my-1">
                <h1 className="font-bold text-lg p-7">Activity</h1>
              </div>
            }
            eventKey={2}
            id="panel3"
            className="dashboard-accordion-panel"
          >
            <hr className="mx-auto mt-2 mb-0 bg-blue border-blue-600 border-1 dark:bg-blue-700 "></hr>
            <div className="grid lg:grid-cols-2 md-g my-5">
              <div className="flex flex-wrap">
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-orange-500  border-orange-500 px-7 py-2 mr-2  mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.twenty}
                  </button>
                  <label>20%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-amber-300  border-amber-300 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default ">
                    {healthData?.fourty}
                  </button>
                  <label>40%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-purple-400  border-purple-400 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.sixty}
                  </button>
                  <label>60%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-cyan-400  border-cyan-400 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.eighty}
                  </button>
                  <label>80%</label>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button className="border min-w-[120px] rounded-full bg-lime-400  border-lime-400 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white cursor-default">
                    {healthData?.hundrade}
                  </button>
                  <label>100%</label>
                </div>
              </div>
              <div className="right-col flex md-justify-end flex-wrap h-min ">
                <button
                  onClick={() => dispatch(showQuickprint(true))}
                  className="border min-w-[120px] rounded-full bg-violet-500 border-violet-500 hover:bg-white hover:text-violet-500 px-7 py-2 mr-2 mt-3 md-mt-0 font-bold text-md text-white flex transition-all ease-in-out duration-500"
                >
                  <PrinterIcon className="w-5 h-5 mr-1" />
                  Quick Print
                </button>
              </div>
            </div>

            <ActivityList />
            <EditActivity />
            <QuickPrint />
          </Panel>
        </div>
      </PanelGroup>

      <ActivityAvatarItemDetails
        activityType={"activity"}
        type={"activity"}
        show={showActivityAvatar}
        close={setShowActivityAvatar}
        health={userDetails?.list?.health}
        userDetails={userDetails}
      />

    </>
  );

  function getActivityHealthList() {
    let activityPercentageTwenty = 0;
    let activityPercentageFourty = 0;
    let activityPercentageSixty = 0;
    let activityPercentageEighty = 0;
    let activityPercentageHundrad = 0;

    activityData?.dataArray?.map((options: any) => {
      options?.map((option: any) => {
        let finalPercentage =
          AvatarCalculate(new Date(option?.endDate))?.finalPercentage ?? 0;
        if (finalPercentage == 100) activityPercentageHundrad++;
        if (finalPercentage == 80) activityPercentageEighty++;
        if (finalPercentage == 60) activityPercentageSixty++;
        if (finalPercentage == 40) activityPercentageFourty++;
        if (finalPercentage == 20) activityPercentageTwenty++;
      });
    });
    return {
      hundrade: activityPercentageHundrad,
      eighty: activityPercentageEighty,
      sixty: activityPercentageSixty,
      fourty: activityPercentageFourty,
      twenty: activityPercentageTwenty,
    };
  }

};

export default Activity;
