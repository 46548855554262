const SvgComponent = (props: { width?: string; height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 63.74 63.74"
    {...props}
  >
    <defs>
      <style>{".cls-level4{fill:#043f3f}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <circle
        cx={31.87}
        cy={31.87}
        r={31.87}
        style={{
          fill: "#0097a6",
        }}
      />
      <path
        d="M17.03 29.97c2.17-.52 4.2-2.52 5.31-4.27 1.16-1.83 2.36-3.63 3.45-5.5 1.62-2.76 6.1-3.13 8.41-1.46 1.03.74 2.09 1.43 3.12 2.16 1.97 1.41 4.09 1.23 6.2.48 1.09-.39 2.11-1 3.16-1.51.25.97-1.39 3.43-3.5 4.84-1.77 1.18-3.75 1.81-6.12 1.11.05.57.03 1 .14 1.4 1.56 5.99 2.61 12.06 2.26 18.27-.15 2.52-.35 6.64-1.71 8.42-.24-2.85-.33-5.62-.75-8.33-.43-2.77-1.29-5.44-3.08-8.03-.3.69-.51 1.15-.7 1.62-1.27 3.18-2.35 6.46-3.86 9.53a17.892 17.892 0 0 1-6.86 7.51c.25-.52.45-1.06.76-1.54 2.66-4.11 4.34-8.59 4.89-13.45.26-2.31.47-4.63.46-6.95 0-1.69-.35-3.4-.65-5.08-.23-1.28-.48-1.33-1.61-.64-2.29 1.4-4.82 1.94-7.47 1.99-.35 0-.77.02-1.13-.06-.4-.07-.73-.27-.73-.52Z"
        className="cls-level4"
      />
      <circle cx={30.44} cy={12.25} r={4.73} className="cls-level4" />
    </g>
  </svg>
);
export default SvgComponent;
