import React, { ReactElement} from "react";
import Avatar, { AvatarStyle } from "./avatar";
import AvatarForm from "./AvatarForm";
import { allOptions } from "./options";
import { OptionProvider } from "./options/OptionContext";

export { default as Avatar, AvatarStyle } from "./avatar";

export interface Props {
  avatarStyle: AvatarStyle;
  className?: string;
  style?: React.CSSProperties;
  topType?: string;
  accessoriesType?: string;
  hairColor?: string;
  facialHairType?: string;
  facialHairColor?: string;
  clotheType?: string;
  clotheColor?: string;
  graphicType?: string;
  eyeType?: string;
  eyebrowType?: string;
  mouthType?: string;
  skinColor?: string;
  pieceType?: string;
  pieceSize?: string;
  viewBox?: string;
  isForm?: boolean;
  isDefaultAvatar?: boolean;
  setSelectedAvatarOptions?: () => void;
  defaultAvatarComponent?: ReactElement;
}

function AvatarComponent({
  avatarStyle,
  style,
  isForm,
  className,
  setSelectedAvatarOptions,
  isDefaultAvatar,
  defaultAvatarComponent,
  ...restOfProps
}: Props) {
  return !(isDefaultAvatar&&defaultAvatarComponent)? (
    <OptionProvider options={allOptions} defaultValues={{ ...restOfProps }}>
      <>
        <Avatar avatarStyle={avatarStyle} style={style} className={className} />
        {isForm && setSelectedAvatarOptions ? (
          <AvatarForm setSelectedAvatarOptions={setSelectedAvatarOptions} />
        ) : null}
      </>
    </OptionProvider>
  ) : (
    defaultAvatarComponent
  );
}
export default React.memo(AvatarComponent);
