import moment from "moment";
import { differenceInDays } from "date-fns";
import { TToggleType, TTrackingDrawerField } from "../../types";
import { IDelegateData } from "types/preferences/delegate";
import { InputFields } from "components/cards/BasicFormCard";
import { STATUS_LEVEL_VALUES } from "./StatusPopOver";
import { IKpis } from "types/preferences/definition";
import { array, number, object, string } from "yup";
import { numberToCurrency } from "utils/helperFunctions";

const seasonalGoalValues = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const CREATE_VALUE_SCHEMA_YUP = object({
  name: string().required("title is required"),
});
export const CREATE_LEVEL_SCHEMA_YUP = object({
  name: string().required("title is required"),
  delegateTo: array()
    .min(1, "Delegate is required")
    .of(number())
    .typeError("Delegate is required")
    .required("Delegate are required"),
});

export const checkBoxes = [
  {
    label: "Performance Dial",
    type: "checkbox",
    name: "showOnDashboard",
  },
  {
    label: "Tracking Data",
    type: "checkbox",
    name: "includeInReporting",
  },
  {
    label: "Activity",
    type: "checkbox",
    name: "includeInAvatar",
  },
  {
    label: "Include this level in Avatar",
    type: "checkbox",
    name: "includeInProfile",
  },
] as const;
export const toggleButtons = [
  {
    label: "Number",
    type: "checkbox",
    name: "numberToggle",
  },
  {
    label: "Dollar",
    type: "checkbox",
    name: "dollarToggle",
  },
  {
    label: "Percentage",
    type: "checkbox",
    name: "percentageToggle",
  },
  {
    label: "Success Scale",
    type: "checkbox",
    name: "scaleToggle",
  },
  {
    label: "Seasonal Goal",
    type: "checkbox",
    name: "seasonalToggle",
  },
  {
    label: "Do Not Accumulate",
    type: "checkbox",
    name: "accumulateToggle",
  },
] as const;

export const ADD_ON = {
  Number: "#",
  Dollar: "$",
  Percentage: "%",
  "Success Scale": "...",
  "Seasonal Goal": "@",
};

export const ADD_ON_SYMBOL = {
  "#": "Number",
  $: "Dollar",
  "%": "Percentage",
  "...": "Success Scale",
  "@": "Seasonal Goal",
} as const;

export const checkIncludes = (
  value: string,
  type: string | boolean,
  eventId: string,
  checked: boolean
) => {
  return eventId === value && checked ? value : "";
};

export const checkTracking = (
  value: string,
  type: boolean,
  eventId: string,
  checked: boolean
) => {
  let trackingEvents = trackingEventType();

  if (!trackingEvents?.includes(eventId)) return type;

  if (!checked) return type ? true : false;
  return eventId === value && checked ? true : false;
};

export const trackingTypes = () => {
  return {
    number: "#",
    doller: "$",
    percentage: "%",
    scale: "...",
    seasonal: "@",
  };
};

export const trackingEventType = () => {
  return ["Number", "Dollar", "Percentage", "Scale", "Seasonal"];
};

export const checkTrackingTypes = (type: string) => {
  switch (type) {
    case "Number":
      return "#";
    case "Dollar":
      return "$";
    case "Percentage":
      return "%";
    case "Scale":
      return "...";
    case "Seasonal":
      return "@";
  }
};
export const seasonalGoalData = (seasonalGoal: string) => {
  let seasonalGoalArray = seasonalGoal?.split(",");
  let goals: any = [];

  seasonalGoalValues?.forEach((option, key) => {
    goals[option] = seasonalGoalArray[key];
  });
  return goals;
};

export const updateSeasonalGoalData = (
  seasonalGoal: any,
  id: string,
  value: number
) => {
  if (seasonalGoalValues?.includes(id)) {
    seasonalGoal[id] = value;
  }
  return seasonalGoal;
};
export const goalCalculation = (
  type: string,
  goalValue: number,
  assignDate: Date | string,
  dueDate: Date | string,
  weeklyWorkingDays: number = 7
) => {
  let noOfDaysInYear = daysInYear(assignDate) ? 366 : 365;
  let dateDiff =
    differenceInDays(
      new Date(moment(dueDate).format("MM/DD/YYYY")),
      new Date(moment(assignDate).format("MM/DD/YYYY"))
    ) || 1;

  switch (type) {
    case "daily":
      return Math.round(
        ((noOfDaysInYear / dateDiff) * goalValue) / (52 * weeklyWorkingDays)
      );
    case "weekly":
      return Math.round(((noOfDaysInYear / dateDiff) * goalValue) / 52);
    case "monthly":
      return Math.round(((noOfDaysInYear / dateDiff) * goalValue) / 12);
    case "quarterly":
      return Math.round(((noOfDaysInYear / dateDiff) * goalValue) / 4);
    case "annually":
      return Math.round((noOfDaysInYear / dateDiff) * goalValue);
    default:
      return goalValue;
  }
};

export const daysInYear = (date: Date | string) => {
  var year = new Date(date).getFullYear();
  return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
};

export const getTrackingDrawerFields = (
  currentToggle?: TToggleType | null,
  seasonalSign?: string,
  isDelegateViewing?: boolean
): TTrackingDrawerField[] => {
  switch (currentToggle) {
    case "Number":
    case "Dollar":
    case "Percentage":
      return [
        {
          type: "text",
          label: "Daily",
          disabled: true,
          addOn: ADD_ON[currentToggle],
          placeHolder: "0",
          name: "daily",
          defaultValue: 0,
        },
        {
          type: "text",
          addOn: ADD_ON[currentToggle],
          disabled: true,
          label: "Weekly",
          placeHolder: "0",
          name: "weekly",
          defaultValue: 0,
        },
        {
          type: "text",
          label: "Monthly",
          addOn: ADD_ON[currentToggle],
          disabled: true,
          placeHolder: "0",
          name: "monthly",
          defaultValue: 0,
        },
        {
          type: "text",
          addOn: ADD_ON[currentToggle],
          disabled: true,
          label: "Quarterly",
          placeHolder: "0",
          name: "quarterly",
          defaultValue: 0,
        },
        {
          type: "text",
          label: "Annually",
          addOn: ADD_ON[currentToggle],
          disabled: true,
          placeHolder: "0",
          name: "annually",
        },
        {
          type: "text",
          label: "Goal",
          defaultValue: 0,
          name: "goal",
          addOn: ADD_ON[currentToggle],
          disabled: isDelegateViewing,
        },
      ];
    case "Success Scale":
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => ({
        type: "radio",
        label: `${item}`,
        name: "successScale",
        value: item,
        addOn: null,
      }));

    default:
      return seasonalGoalValues.map((item, index) => {
        return {
          type: "text",
          label: item,
          addOn: seasonalSign,
          name: `seasonalGoal.${index}`,
          defaultValue: 0,
          maxLength: 10,
        };
      });
  }
};

export const getInputFields = (
  delegateList: IDelegateData[],
  level: number,
  isDelegateViewing?: boolean,
  dateAssignDue?: [string, string]
): InputFields[] => {
  return [
    {
      label: "Title",
      isControlled: true,
      required: true,
      type: "text",
      name: "name",
      maxLength: 255,
      placeholder: `New Level ${level + 1} Activity`,
      disabled: isDelegateViewing,
    },
    {
      label: "Status",
      type: "select",
      data: STATUS_LEVEL_VALUES,
      searchable: false,
      name: "statusId",
      "data-testid": "level-status",
      defaultValue: 0,
      placeholder: `New Level ${level + 1} Activity`,
      disabled: isDelegateViewing,
    },
    {
      label: "Link",
      type: "text",
      name: "url",
      placeholder: "Paste link if any",
      maxLength: 255,
      defaultValue: 0,
      disabled: isDelegateViewing,
    },

    {
      label: "Date Assigned / Due",
      type: "date-range",
      required: true,
      name: "dateAssignedDue",
      placeholder: `New Level ${level + 1} Activity`,
      defaultDateRange: dateAssignDue
        ? dateAssignDue.map(
            (item) => new Date(moment(item).format("MM/DD/YYYY"))
          )
        : [new Date(), new Date()],
      showPredefinedRanges: true,
      disabled: isDelegateViewing,
    },

    {
      label: "Delegated To",
      type: "multi-select",
      required: true,
      "data-testid": "delegates-select",
      data: delegateList.map((item) => ({
        label: item?.user?.name,
        value: +item.userId,
      })),
      name: "delegateTo",
      placeholder: `New Level ${level + 1} Activity`,
      disabled: isDelegateViewing,
    },
  ];
};

export const getDefaultValuesForLevelDrawer = (props: IKpis): any => {
  let statusId;
  if (props?.status || props?.status === 0) {
    statusId = props.status;
  }
  if (props?.statusId || props?.statusId === 0) {
    statusId = props.statusId;
  }
  return {
    //Common
    levelId: +props?.levelId || 0,
    type: props?.type,
    // LEVEL 1 and Common
    name: props?.name || props?.title,
    url: props?.type === "level1" ? props?.valueUrl : props?.url,
    statusId: statusId,
    // IF other than Level1
    ...(props?.type !== "level1" && {
      annually: props?.annually,
      quarterly: props.quarterly,
      monthly: props?.monthly,
      weekly: props?.weekly,
      daily: props?.daily,
      goal: props?.goal || props?.Goal,
      description: props?.description || "",
      dateAssignedDue: [props?.assignDate, props?.dueDate],
      seasonalSign: props?.seasonalSign,
      trackSign: props?.trackSign,
      showOnDashboard: props?.showOnDashboard,
      includeInReporting: props?.includeInReporting,
      includeInAvatar: props?.includeInAvatar,
      includeInProfile: props?.includeInProfile,
      delegateTo:
        props?.delegateTo?.length > 0
          ? props?.delegateTo?.split(",")?.map((item: string) => parseInt(item))
          : [0],
      qualifierTo:
        props?.qualifierTo?.length > 0 ? props?.qualifierTo?.split(",") : [0],
      seasonalGoal:
        props?.seasonalGoal?.length > 0
          ? props?.seasonalGoal
              ?.split(",")
              ?.map((item: string) => numberToCurrency(item))
          : [],
      successScale: +props?.successScale,
      isAccumulate: props?.isAccumulate,
    }),
  };
};
