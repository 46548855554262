import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TUserFilterRequestParams, UserStatus, TGroupUserFilter, TFormValue } from '../../../types/userManagement';


export interface ListState {
    filter:TUserFilterRequestParams
    userStatus: UserStatus[],
    isEdit:boolean,
    isAdd:boolean,
    editUserId: number,
    groupFilterUser: TGroupUserFilter[],
    userDetails?:TFormValue
}

const initialState:ListState = {
  filter:{
    status: 1,
    role_id: 0,
    title: "",
    email: "",
    parent: 0,
    group:{ id: 0, name:""}
  },
  userStatus:[
    {
    value: 1,
    label: "Active"
    },
    {
      value: 0,
      label: "In Active"
    }
  ],
  isEdit:false,
  isAdd:false,
  editUserId: 0,
  groupFilterUser:[],
  userDetails:{
    first_name: "",
    last_name:"",
    email:"",
    user_name:"",
    dialNumber:"",
    accessCode:"",
    role_id:0,
    pacUserRole:0,
    mindscanUserRole:0,
    children_count:0,
    meetingLink: "",
    profile: {
        timeZoneId:0
    },
    status:1,
    user_id:0
  }
}

export const userManagementSlice = createSlice({
  initialState,
  name: 'dashboard-slice',
  reducers: {
    userFilter: (state, action: PayloadAction<any>) => {
      state.filter = action.payload;
    },
    setUserAdd: (state, action: PayloadAction<boolean>) => {
      state.isAdd = action.payload;
    },
    setUserEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setEditUserId: (state, action: PayloadAction<number>) => {
      state.editUserId = action.payload;
    },
    setGroupUserFilter: (state, action: PayloadAction<TGroupUserFilter[]>) => {
      state.groupFilterUser = action.payload;
    },
    setUserDetails: (state, action: PayloadAction<TFormValue>) => {
      state.userDetails = action.payload;
    },
  }
})

export const {
  userFilter,
  setUserAdd,
  setUserEdit,
  setEditUserId,
  setGroupUserFilter,
  setUserDetails
} = userManagementSlice.actions;

export default userManagementSlice.reducer