import { useDispatch, useSelector } from "react-redux";

import Dials from "./Dials";
import { RootState } from "../../../store/index";
import { RunRateCalculate } from "./../health/RunRateCalculate";
import { showTrackingData } from "store/dashboard";
import { format } from "date-fns";
import "react-multi-carousel/lib/styles.css";
import { TSliderOption } from "types/dashboard";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import { useParams } from "react-router-dom";

const DialGroup = ({ userDetails, isSlider }: TSliderOption) => {
  const param = useParams()
  const dispatch = useDispatch();

  const { filterDates } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );
  const { data: activityData } = useDashboardFetchDelegateDataQuery({key:param?.key ?? "", type: param?.type ?? ""})

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="mt-5 modal-content">
      {activityData?.dialsArray && isSlider == 0 ? renderDials() : <div className='w-full h-full flex justify-center items-center'><label className='font-bold'>No Data Found!</label> </div>}
    </div>
  );

  function renderDials() {
    return activityData?.dialsArray?.map((activity) => {
        let runRate = RunRateCalculate(
          activity,
          filterDates,
          activityData,
          userDetails
        );

        return (
          <div className="bg-gray-100 p-3 rounded-md shadow-lg h-full flex flex-col justify-between">
            <h3 className="text-base max-w-[320px] overflow-hidden">
              {activity?.title}
            </h3>
            <div className="w-full min-h-[100px]">
              <Dials
                runRate={runRate?.runRate ?? 0}
                actual={runRate?.actualPercentage ?? 0}
              />
            </div>
            <div className="text-center">
              <p className="font-semibold">
                End Goal:{" "}
                <span className="font-normal">{runRate?.goalFormatted}</span>
              </p>
              <p className="font-semibold">
                Win Probability:{" "}
                <span className="font-normal">
                  {runRate?.runRateFormatted}%
                </span>
              </p>
              <p className="pb-3 font-semibold">
                {format(new Date(activity?.startDate ?? ""), "MM/dd/yyyy")}-
                {format(new Date(activity?.endDate ?? ""), "MM/dd/yyyy")}
              </p>
              <button
                onClick={() =>
                  dispatch(
                    showTrackingData({
                      id: activity?.id,
                      level: activity?.type,
                    })
                  )
                }
                className="cursor-pointer w-full rounded-full bg-secondary-blue hover:bg-white hover:text-secondary-blue border border-secondary-blue   p-2  font-bold text-md text-white transition-all ease-in-out duration-500 "
              >
                Last Updated:{" "}
                {runRate?.lastTrackingDate
                  ? runRate?.lastTrackingDateDiff + " days ago"
                  : "No Update"}
              </button>
            </div>
          </div>
        );
    });
  }
};

export default DialGroup;
