import { PlusCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { toastError, toastSuccess } from "components/toasts";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useToaster } from "rsuite";
import { TReflectionQuestions } from ".";
import { TextArea } from "components/input/TextArea";
import { TEXT_AREA_LIMIT } from "utils/constants";

type Props = {
  questions: TReflectionQuestions[];
  deleteActivityHandler: (val: number) => void;
};

const AssignmentSection = ({ deleteActivityHandler }: Props) => {
  const { register, control, getValues } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "Assignments",
  });
  const latestValue = getValues("Assignments");
  const lastCallMaximizerId = latestValue?.[latestValue?.length - 1];
  const toast = useToaster();
  return (
    <>
      <div className="profile-contact-heading flex justify-between gap-5 flex-col lg:flex-row items-center p-4 bg-button-blue rounded-xl text-white">
        <h5>I commit to do the following by the next coaching session:</h5>
        <div className="lastDetails-wrapper flex justify-start align-middle gap-1 text-[14px]">
          <button
            type="button"
            onClick={() => {
              append({
                callMaximizerDataId: null,
                callMaximizerId: lastCallMaximizerId?.callMaximizerId + 1,
                satisfactionLevel: 0,
                motivationLevel: 0,
                updated: 0,
                coachCallId: lastCallMaximizerId?.coachCallId,
              });
            }}
            className=" w-fit px-2 flex gap-2 py-2 text-[16px] bg-primary-blue borderitems-center border-primary-blue rounded-md text-white hover:text-primary-blue hover:bg-white transition-all duration-500 ease-in-out font-semibold"
          >
            <PlusCircleIcon className="w-[25px] h-[25px] ml-2" />
            Add Activity
          </button>
        </div>
      </div>
      <ul className="w-full">
        {/* @ts-ignore */}
        {fields.map((item: any, index) => {
          return (
            <li key={item.id} className="mt-10">
              <div className="w-[80%] mx-auto flex flex-col gap-0">
                <div className="flex flex-wrap gap-5  w-full  justify-between items-center">
                  <div className="flex gap-5 justify-between items-center">
                    <p className="text-sm  px-[10px] py-[4px] font-extrabold bg-green-600   rounded-full text-white ">
                      {index + 1}
                    </p>
                    <div>
                      <input
                        type="radio"
                        // name={`Assignments.${index}.updated`}
                        value={0}
                        defaultChecked={!item?.updated}
                        {...register(`Assignments.${index}.updated`)}
                        id={`Assignments.${index}.updated.incomplete`}
                      />
                      <label
                        htmlFor={`Assignments.${index}.updated.incomplete`}
                        className="ml-[5px] cursor-pointer"
                      >
                        Incomplete
                      </label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        // name={`Assignments.${index}.updated`}
                        value={1}
                        defaultChecked={item.updated === 1}
                        {...register(`Assignments.${index}.updated`)}
                        id={`Assignments.${index}.updated.complete`}
                      />
                      <label
                        htmlFor={`Assignments.${index}.updated.complete`}
                        className="ml-[5px] cursor-pointer"
                      >
                        Complete
                      </label>
                    </div>
                  </div>
                  <button
                    onClick={async () => {
                      const confirm = window.confirm(
                        "Do you want to delete this assignment, Please save changes after deleting"
                      );
                      if (confirm) {
                        try {
                          if (item.callMaximizerDataId) {
                            await deleteActivityHandler(
                              item.callMaximizerDataId
                            );
                            remove(index);
                          } else {
                            remove(index);
                          }
                          toast.push(
                            toastSuccess({
                              message: "Deleted Successfully",
                            })
                          );
                        } catch (error) {
                          toast.push(
                            toastError({
                              message:
                                "Error Deleting Activity Please try again",
                            })
                          );
                        }
                      }
                    }}
                    type="button"
                    className="inline-block relative group px-3 py-2.5  text-white bg-red-600 hover:bg-white border border-red-600 hover:text-red-600 font-medium text-xs leading-tight uppercase rounded-[50%] shadow-md    transition-all duration-500 ease-in-out"
                  >
                    <TrashIcon width={20} height={20} />
                    <div
                      id="tooltip-default"
                      role="tooltip"
                      className="inline-block absolute invisible group-hover:visible z-[500] py-2 px-3 -top-[39px] -left-[12px] text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip dark:bg-gray-700"
                    >
                      Delete
                      <div className="tooltip-arrow" data-popper-arrow></div>
                    </div>
                  </button>
                </div>
                <div>
                  <TextArea
                    //@ts-ignore
                    defaultValue={item.longComment}
                    cols={20}
                    rows={3}
                    maxLength={TEXT_AREA_LIMIT}
                    {...register(`Assignments.${index}.longComment`)}
                    required
                    placeholder="Please Enter Text"
                    className="focus:shadow-primary-outline mt-5 text-sm leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none !resize"
                  />{" "}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default AssignmentSection;
