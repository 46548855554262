import { useDispatch, useSelector } from "react-redux";

import Dials from "./Dials";
import { RootState } from "../../../store/index";
import { RunRateCalculate } from "./../health/RunRateCalculate";
import { showTrackingData } from "store/dashboard";
import { format } from "date-fns";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { TSliderOption } from "types/dashboard";
import { IKpis } from "types/preferences/definition";
import { useState } from "react";
import LevelDrawer from "components/v-map/Tree/components/TreeItem/LevelDrawer";
import { LEVELS } from "../activity/QuickUpdate";
import { Link, useParams } from "react-router-dom";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import moment from "moment";

const DialGroupSlider = ({ userDetails, isSlider }: TSliderOption) => {
  let param = useParams();
  const dispatch = useDispatch();

  const { filterDates } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const [showLevelDrawer, setShowLevelDrawer] = useState<{
    show: boolean;
    defaultValues: IKpis | null;
  }>({ show: false, defaultValues: null });

  const { data: activityData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
  });

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 7,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="mt-5 modal-content">
      {activityData?.dialsArray && activityData?.dialsArray?.length ? (
        <Carousel responsive={responsive}>{renderDials()}</Carousel>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          <label className="font-bold">No Data Found!</label>{" "}
        </div>
      )}
      {showLevelDrawer.show && showLevelDrawer.defaultValues && (
        <LevelDrawer
          showDrawer={showLevelDrawer.show}
          setShowDrawer={setShowLevelDrawer}
          isValueDrawer={false}
          type={showLevelDrawer?.defaultValues?.type}
          level={LEVELS[showLevelDrawer?.defaultValues?.type]}
          isLevelUpdate={true}
          vmapId={+showLevelDrawer?.defaultValues?.levelId}
          defaultProps={{
            ...showLevelDrawer.defaultValues,
            //@ts-ignore
            levelId: showLevelDrawer.defaultValues.id,
            statusId: showLevelDrawer?.defaultValues?.status,
            name: showLevelDrawer?.defaultValues?.title,
            type: showLevelDrawer?.defaultValues?.type,
            ...(showLevelDrawer?.defaultValues?.type !== "level1" && {
              goal: showLevelDrawer?.defaultValues?.goal,
              daily: showLevelDrawer?.defaultValues?.daily,
              monthly: showLevelDrawer?.defaultValues?.monthly,
              quarterly: showLevelDrawer?.defaultValues?.goal,
              dateAssignedDue: [
                moment(showLevelDrawer?.defaultValues?.startDate)?.format(
                  "YYYY-MM-DD"
                ),
                moment(showLevelDrawer?.defaultValues?.endDate)?.format(
                  "YYYY-MM-DD"
                ),
              ],
              delegateTo:
                showLevelDrawer?.defaultValues?.delegates?.length > 0 &&
                showLevelDrawer?.defaultValues?.delegates
                  ?.split(",")
                  .map((item) => +item),
              seasonalGoal:
                showLevelDrawer?.defaultValues?.seasonalGoal?.split(","),
              includeInAvatar: showLevelDrawer?.defaultValues?.inAvatar,
              includeInProfile: showLevelDrawer?.defaultValues?.inProfile,
              includeInReporting: showLevelDrawer?.defaultValues?.inTracking,
              showOnDashboard: showLevelDrawer?.defaultValues?.inDashboard,
              trackSign: showLevelDrawer?.defaultValues?.sign,
            }),
          }}
        />
      )}
    </div>
  );

  function renderDials() {
    return activityData?.dialsArray?.map((activity) => {
      let runRate = RunRateCalculate(
        activity,
        filterDates,
        activityData,
        userDetails
      );

      return (
        <div className="bg-gray-100 p-3 rounded-md shadow-lg h-full flex flex-col justify-between">
          <Link
            to="#"
            onClick={() =>
              setShowLevelDrawer({
                show: true,
                //@ts-ignore
                defaultValues: activity,
              })
            }
          >
            <h3 className="text-base max-w-[320px] overflow-hidden">
              {activity?.title}
            </h3>
          </Link>
          <div className="w-full min-h-[100px]">
            <Dials
              runRate={runRate?.runRate ?? 0}
              actual={runRate?.actualPercentage ?? 0}
            />
          </div>
          <div className="text-center">
            <p className="font-semibold">
              End Goal:{" "}
              <span className="font-normal">{runRate?.goalFormatted}</span>
            </p>
            <p className="font-semibold">
              Win Probabilty:{" "}
              <span className="font-normal">{runRate?.runRateFormatted}%</span>
            </p>
            <p className="pb-3 font-semibold">
              {format(new Date(activity?.startDate ?? ""), "MM/dd/yyyy")}-
              {format(new Date(activity?.endDate ?? ""), "MM/dd/yyyy")}
            </p>
            <button
              onClick={() =>
                dispatch(
                  showTrackingData({
                    id: activity?.id,
                    level: activity?.type,
                  })
                )
              }
              className="cursor-pointer w-full rounded-full bg-secondary-blue    p-2  font-bold text-md text-white "
            >
              Update Tracking:{" "}
              {runRate?.lastTrackingDate
                ? runRate?.lastTrackingDateDiff + " days ago"
                : "No Update"}
            </button>
          </div>
        </div>
      );
    });
  }
};

export default DialGroupSlider;
