import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainDelegateHeader from "./MainDelegateHeader";
export const DelegateHeader = () => {
  const { pathname } = useLocation();

  return (
    <>
      <header className="sticky top-0 z-[100] bg-slate-200">
        <nav className="navbar block md:flex md:flex-col relative">
          <MainDelegateHeader />
        </nav>
      </header>
    </>
  );
};
