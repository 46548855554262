import { TReflectionQuestions } from "sections/call-maximizer";
import { appApi } from "store/apis/apiSlice"


type TtimeZone = {
    timeZoneId: number;
    displayOrder: number;
    offsetUtc: string;
    shortDesc: string;
    longDesc: string;
    transDate: string;
    callTime: string;
}
export interface ICallMaximizerDateListResponse {
    coachCallId: number;
    callDate: string;
    nextCallDate: string;
    timeZoneId: number;
    nextTimeZoneId: number;
    callTimeId: number;
    nextCallTimeId: number;
    timezone: TtimeZone;
    next_timezone: TtimeZone;
    call_time: TtimeZone;
    next_call_time: TtimeZone;
}

type TCoachCall = {
    key: number;
    desc: string;
    subset: boolean;
    subsets: {
        key: number;
        desc: string;
    }[]
}[]

export type TCallMaximizerData = {
    callMaximizerDataId: number;
    coachCallId: number;
    callMaximizerId: number;
    updated: number;
    satisfactionLevel: number;
    motivationLevel: number;
    longComment: string;
    created_at: string;
    updated_at: string
}
export interface ICallMaximizerDataListResponse {
    coachCall: TCoachCall[],
    callMaximizer: {
        callMaximizerId: number;
        callMaximizerAreaId: number;
        controlTypeId: number;
        active: number;
        heading: number;
        description: string;
        displayOrder: number;
        transDate: string;
        call_maximizer_data: TCallMaximizerData[]
    }[]
}

export interface IFetchOverdueListResponse {
    data: {
        coachCallId: number;
        callDate: string;
        overdue_list: TCallMaximizerData[]
    }[]
}



type TCallMaximizerSectionKey = "Reflections" | "Physical & Emotional Health" | "Relationships" | "Spiritual, Purpose and Destiny" | "Financial Health and Prosperity" | "Personal Growth & Development" | "Career & Profession"


type TCallMaximizerSections = {
    [key in TCallMaximizerSectionKey]: TReflectionQuestions[] | TCallMaximizerData[]
}

//TODO this logic can be refined but for now i am not working on optimizing it. 
const generateDataAsPerUiLayer = (unstructuredData: ICallMaximizerDataListResponse): TCallMaximizerSections => {
    const questions = unstructuredData.coachCall

    const answers = unstructuredData.callMaximizer[0].call_maximizer_data

    const getTablularSectionData = (tabularData: TCoachCall) => {
        return tabularData.map(item => {
            const answer = answers.filter(answer =>
                answer.callMaximizerId === item.key
            )[0]
            return {
                ...answer,
                longComment: item.desc,
                motivationLevel: answer.motivationLevel ? answer.motivationLevel : 0,
                satisfactionLevel: answer.satisfactionLevel ? answer.satisfactionLevel : 0

            }
        })

    }


    const subsets: any = []
    let allSections = {
        "Reflections": [...[...questions[6], ...questions[7]].map(item => {
            let questionObj: any = {};
            if (item.subset) {
                questionObj.isSubQuestion = true
                questionObj.subQuestions = item.subsets.map(subSet => {
                    return {
                        subQuestion: subSet.desc,
                        longComment: answers.filter(answer => {
                            if (answer.callMaximizerId === subSet.key) {
                                subsets.push(answer)

                                return answer.callMaximizerId === subSet.key
                            }

                        }
                        )[0].longComment
                    }
                })
                questionObj.mainQuestion = item.desc
            }
            else {
                questionObj.isSubQuestion = false
                questionObj.subQuestions = []
                questionObj.mainQuestion = item.desc
                questionObj.longComment = answers.filter(answer =>
                    answer.callMaximizerId === item.key
                )[0].longComment
            }
            return {
                ...questionObj,
                satisfactionLevel: null,
                motivationLevel: null,
                callMaximizerId: item.key,
                updated: 0,
            }
        }), ...subsets]
        ,
        "Assignments": answers.filter(item=>item.callMaximizerId==46)?.length > 0 ? [...answers.slice(answers?.findIndex(item=>item?.callMaximizerId==46))]?.filter(item=>item?.callMaximizerId>=46) : [{
            coachCallId:answers[0].coachCallId,
            callMaximizerDataId: null,
            longComment: "",
            callMaximizerId: 46,
            updated: 0,
        }],
        "Physical & Emotional Health": getTablularSectionData(questions[0]),
        "Relationships": getTablularSectionData(questions[1]),
        "Spiritual Purpose and Destiny": getTablularSectionData(questions[2]),
        "Financial Health and Prosperity": getTablularSectionData(questions[3]),
        "Personal Growth & Development": getTablularSectionData(questions[4]),
        "Career & Profession": getTablularSectionData(questions[5]),
        "ReflectionsQuestionsLength": questions[6].length + questions[7].length
    }

    return allSections as unknown as TCallMaximizerSections
}


interface IUpdateCallMaximizerRequestBody {
    callMaximizerId: number
    longComment: string
    satisfactionLevel: number
    motivationLevel: number
    updated: number
}

const apiWithTags = appApi.enhanceEndpoints({
    addTagTypes: ["CallMaximizer", "NextCoachingSession"]
})



export const callMaximizerApiSlice = apiWithTags.injectEndpoints({
    endpoints: (builder) => ({
        addNewCallMaximizer: builder.mutation<TCallMaximizerSections, {newCallMax: string, previousCallMaximizerDate: string}>({
            query: ({newCallMax, previousCallMaximizerDate}) => {
                return ({
                url: `/callmaximizer`,
                method: "POST",
                body: {
                    "newCallMax": newCallMax,
                    "previousDate": previousCallMaximizerDate ?? null
                }
            })},
            invalidatesTags: ["CallMaximizer"]
        }),
        callMaximizerDateList: builder.query<ICallMaximizerDateListResponse[], void>({
            query: () => ({
                url: `/callmaximizer-list`,
            }),
            providesTags: ["CallMaximizer"],
            transformResponse: (res: { data: ICallMaximizerDateListResponse[] }) => {
                return res.data
            }
        }),
        callMaximizerData: builder.query<TCallMaximizerSections, number | null | undefined>({
            query: (coachCallId) => ({
                url: `callmaximizer?coachCallId=${coachCallId}&section=7`
            }),
            providesTags: ["CallMaximizer", "NextCoachingSession"],
            transformResponse: (res: ICallMaximizerDataListResponse) => {
                return generateDataAsPerUiLayer(res)
            }
        }),
        updateCallMaximizerData: builder.mutation<[], { coachCallId: number, data: IUpdateCallMaximizerRequestBody[] }>({
            query: ({ coachCallId, data }) => {
                return ({
                    url: `callmaximizer-component/${coachCallId}`,
                    method: "PUT",
                    body: { params: data }
                })
            },
            invalidatesTags: ["CallMaximizer"]
        }),
        deleteCallMaximizer: builder.mutation<[], number>({
            query: (coachCallId: number) => {
                return ({
                    url: `callmaximizer/${coachCallId}`,
                    method: "DELETE"
                })
            },
            invalidatesTags: ["CallMaximizer"]
        }),
        nextCoachingSession: builder.mutation<[], {
            coachCallId: number, scheduleTime: number, nextCallDate: string, timezone: number
        }>({
            query: ({ coachCallId, scheduleTime, nextCallDate, timezone }) => {

                return ({
                    url: `callmaximizer-coaching-session/${coachCallId}`,
                    method: "PUT",
                    body: {
                        scheduleTime,
                        nextCallDate,
                        timezone
                    }
                })
            },
            invalidatesTags: ["CallMaximizer"]
        }),

        // OVERDUES ARE INCOMPLETE ASSIGNMENTS
        fetchOverDueList: builder.query<{
            [key: string]: TCallMaximizerData[]
        }, void>({
            query: () => 'callmaximizer-overdue-activity/list',
            transformResponse: (res: IFetchOverdueListResponse) => {
                // making key value pair of callDate and overdue_list
                const obj: {
                    [key: string]: TCallMaximizerData[]
                } = {}
                for (let i of res.data) {
                    if (obj[i.callDate]) {
                        obj[i.callDate] = [...obj[i.callDate], ...i.overdue_list]
                    }
                    else {
                        obj[i.callDate] = [...i.overdue_list]
                    }
                }
                return obj
            },
            providesTags: ["CallMaximizer"]
        }),
        updateOverDueListItems: builder.mutation<[], number>({
            query: (callMaximizerId) => ({
                url: `callmaximizer-overdue-activity/${callMaximizerId}`,
                method: "PUT"
            }),
            invalidatesTags: ["CallMaximizer"]
        }),
        deleteActivity: builder.mutation<[], number>({
            query: (callMaximizerDataId) => {

                return {

                    url: `delete-callmaximizer-assignment/${callMaximizerDataId}`,
                    method: "DELETE",
            }
        },
            invalidatesTags: ["CallMaximizer"]
        })
    }),
});




export const { useCallMaximizerDateListQuery, useCallMaximizerDataQuery, useUpdateCallMaximizerDataMutation, useDeleteCallMaximizerMutation, useAddNewCallMaximizerMutation, useNextCoachingSessionMutation, useFetchOverDueListQuery, useUpdateOverDueListItemsMutation, useDeleteActivityMutation } = callMaximizerApiSlice;
