import { createColumnHelper, PaginationState } from "@tanstack/react-table";
import Table from "components/table/Table";
import Loader from "pages/frontend-sandbox/loader";
import { useState } from "react";
import {
  IQualifier,
  useFetchQualifierDataQuery,
} from "store/preferences/qualifierApi";

const Qualifiers = () => {
  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageSize: 15,
    pageIndex: 0,
  });

  const { data, isLoading } = useFetchQualifierDataQuery({
    page: pageIndex + 1,
  });

  if (isLoading) {
    return <Loader />;
  }
  const columnHelper = createColumnHelper<IQualifier>();

  if (data) {
    const COLUMNS = [
      columnHelper.accessor("user.name", {
        header: "Name",
      }),
    ];

    return (
      <>
        <Table
          tableData={{ data: data?.data, columns: COLUMNS }}
          tableTitle={"Manage Qualifiers"}
          showGlobalFilter={true}
          isPagination={true}
          isManualPagination={false}
          pageIndex={pageIndex}
          pageSize={pageSize}
          pageCount={data?.last_page}
        />
      </>
    );
  }
  return <><Loader /></>;
};

export default Qualifiers;
