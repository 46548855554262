import { Input, RsuiteInput } from "components/input";
import { FieldValues, UseFormRegister } from "react-hook-form";
import { getClassName, InputProps } from "components/input/Input";
import { TextareaHTMLAttributes } from "react";
import { ListOnItemsRenderedProps } from "react-window";

import {
  RHFSelect,
  RHFMultiSelect,
  RHFDateRangePicker,
  RHFInput,
  RHFDependentMultiSelect,
} from "components/hook-form";
import { DateRangePlacement } from "components/hook-form/RHFDateRangePicker";
import RFHDatePicker from "components/hook-form/RHFDatePicker";
import RHFInfiniteSelect from "components/hook-form/RHFInfiniteSelect";
import { SelectPicker } from "rsuite";
import { TextArea } from "components/input/TextArea";

export interface MultiSelectAttributes {
  options?: Record<string, any>[];
  data?: Record<string, any>[];
  handleChange?: unknown;
  isLoading?: boolean;
  placeHolder?: string;
  additionalClass?: string;
  title?: string;
  isControlled?: boolean;
  datePickerPlacement?: DateRangePlacement;
  showPredefinedRanges?: boolean;
  isRsuiteInput?: boolean;
}
export interface InputFields
  extends MultiSelectAttributes,
    TextareaHTMLAttributes<HTMLElement>,
    InputProps {
  label: string;
  name: string;
  addOn?: string;
  defaultDateRange?: string[] | Date[];
  "data-testid"?: string;
  searchable?: boolean;
  listProps?: (props: ListOnItemsRenderedProps) => void;
}

// REACT HOOK FORM REGISTER
type Register = UseFormRegister<FieldValues>;

export interface IBasicFormCardProps {
  inputFields: Array<InputFields>;
  register: Register;
  additionalClassName?: string;
  overrideClassName?: boolean;
  showSelectionPreview?: boolean;
  overrideBaseElementClassName?: boolean;
  additionalBaseElementClassName?: string;
}

export interface IBaseElementProps {
  inputFieldAttributes: InputFields;
  register?: Register;
  index?: number;
  additionalClassName?: string;
  labelClassName?: string;
  overrideClassName?: boolean;
}

// RETURNS INPUT FIELD CONDITIONALLY
export const getElement = (
  elementType: string,
  inputFields: InputFields,
  register: UseFormRegister<FieldValues>
) => {
  const {
    label,
    name,
    type,
    isRsuiteInput,
    isControlled,
    placeHolder,
    disabled,
    ...rest
  } = inputFields;
  switch (elementType) {
    case "dependent-multi-select":
      if (rest?.options || rest?.data) {
        return (
          //TODO: needs to be refactored
          //Will need to type it more statically
          <RHFDependentMultiSelect
            name={name}
            {...rest}
            //@ts-ignore
            data={rest.data || rest?.options}
            cleanable={false}
            placeholder={placeHolder}
          />
        );
      }
      return <></>;
    case "multi-select":
      if (rest?.options || rest?.data) {
        return (
          //TODO: needs to be refactored
          //Will need to type it more statically
          <RHFMultiSelect
            name={name}
            {...rest}
            //@ts-ignore
            data={rest.data || rest?.options}
            cleanable={false}
            placeholder={placeHolder}
            disabled={disabled}
          />
        );
      }
      return <></>;
    case "infinite-select":
      if (rest?.data || rest?.options) {
        return (
          //TODO: needs to be refactored
          //Will need to type it more statically
          //@ts-ignore
          <RHFInfiniteSelect
            {...rest}
            name={name}
            //@ts-ignore
            data={rest.options || rest.data}
            searchable={rest.searchable}
            cleanable={false}
          />
        );
      }
      return <></>;

    case "infinite-select-simple":
      if (rest?.data || rest?.options) {
        return (
          //TODO: needs to be refactored
          //Will need to type it more statically
          //@ts-ignore
          <SelectPicker
            {...rest}
            //@ts-ignore
            data={rest.options || rest.data}
            searchable={rest.searchable}
            cleanable={false}
          />
        );
      }
      return <></>;

    case "select":
    case "single-select":
      if (rest?.data || rest?.options) {
        return (
          //TODO: needs to be refactored
          //Will need to type it more statically
          //@ts-ignore
          <RHFSelect
            {...rest}
            label={label}
            name={name}
            //@ts-ignore
            data={rest.options || rest.data}
            searchable={rest.searchable}
            cleanable={false}
            disabled={disabled}
          />
        );
      }
      return <></>;

    case "select-picker":
      //@ts-ignore
      return rest.data ? (
        //@ts-ignore
        <SelectPicker className="w-full" disabled={disabled} {...rest} />
      ) : (
        <></>
      );
    case "textArea":
      return (
        <TextArea
          cols={30}
          rows={5}
          className="focus:shadow-primary-outline text-sm leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
          {...rest}
          {...register(name)}
        />
      );

    case "date-range":
      if (rest.defaultValue || rest.defaultDateRange) {
        return (
          <RHFDateRangePicker
            name={name}
            label={label}
            defaultRange={
              (rest.defaultDateRange || rest.defaultValue) as Date[]
            }
            datePickerPlacement={rest.datePickerPlacement}
            cleanable={false}
            showPredefinedRanges={rest.showPredefinedRanges}
            disabled={disabled}
          />
        );
      }
      return (
        <RHFDateRangePicker
          datePickerPlacement={rest.datePickerPlacement}
          showPredefinedRanges={rest.showPredefinedRanges}
          name={name}
          label={label}
        />
      );

    case "date-picker":
      return <RFHDatePicker placeholder={rest?.placeholder} name={name} />;
    default:
      return isRsuiteInput ? (
        <RsuiteInput type={type} {...rest} register={register} name={name} />
      ) : isControlled ? (
        <RHFInput
          type={type}
          additionalClassName="focus:shadow-primary-outline text-sm leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
          overrideClassName={true}
          {...rest}
          name={name}
          disabled={disabled}
        />
      ) : (
        <Input
          type={type}
          overrideClassName={true}
          additionalClassName="focus:shadow-primary-outline text-sm leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
          {...rest}
          {...register(name, {
            value: rest.value,
          })}
          name={name}
          disabled={disabled}
        />
      );
  }
};

//
const defaultClassNameBaseElement =
  "pair-item form-field-label w-full md:w-6/12 max-w-full px-2 py-2 md:px-10 md:py-3 ";
//TODO here i am using the same type as of IBasicFormCardProps look for a typescript but i am not reusing it be cause type of input field is an array
export const BaseElements = ({
  inputFieldAttributes,
  register,
  index,
  overrideClassName,
  additionalClassName = "pair-item form-field-label md:w-6/12 max-w-full px-2 py-2  md:py-3 flex-0",
  labelClassName = "mb-2 ml-1 text-[14px]  md:text-[16px] font-bold text-slate-700 ",
}: IBaseElementProps) => {
  const { name, ...rest } = inputFieldAttributes;
  return (
    <div
      key={index}
      className={
        overrideClassName
          ? additionalClassName
          : `${defaultClassNameBaseElement} ${additionalClassName}`
      }
    >
      <label htmlFor={rest.id ? rest.id : name} className={labelClassName}>
        {rest?.label} {rest.required ? "*" : null}
      </label>
      <div className="mt-1 w-full">
        {/* @ts-ignore */}
        {getElement(rest.type as string, inputFieldAttributes, register)}
      </div>
    </div>
  );
};

const defaultClassName = "pair-one field-set-pair flex pb-4 flex-wrap";

const BasicFormCard = ({
  inputFields,
  register,
  overrideClassName,
  additionalClassName,
  overrideBaseElementClassName,
  additionalBaseElementClassName,
}: IBasicFormCardProps) => {
  return (
    <div id="profile" className="profile-content-tab bg-white rounded-xl ">
      {/* <div className="user-detail-form py-8"> */}
      <div
        className={
          additionalClassName
            ? getClassName(
                overrideClassName as boolean,
                additionalClassName,
                defaultClassName
              )
            : defaultClassName
        }
      >
        {inputFields.map((inputFieldAttributes, index) => {
          return (
            <BaseElements
              overrideClassName={overrideBaseElementClassName}
              additionalClassName={additionalBaseElementClassName}
              // NOTE maybe this can create error in future as key name can be same
              key={inputFieldAttributes.name}
              inputFieldAttributes={inputFieldAttributes}
              register={register}
              index={index}
            />
          );
        })}
      </div>
    </div>
    // </div>
  );
};

export default BasicFormCard;
