import { AvatarType } from "sections/dashboard/components/AvatarHealth";
import MyAvatarSection from "sections/preferences/my-avatar";
import { useGetUserQuery } from "store/auth/authApi";
import { getTheHealthId } from "utils/helperFunctions";

interface IAvatarInfoProps {
  percentage: number;
  avatarWidth: number;
  grow?: string;
  grade?: string;
  gradeType?: string;
  avatarOnly?: boolean;
  health?: { [key: string]: string }[];
  className?: string;
}

const ActivityInfo = (props: IAvatarInfoProps) => {
  const { data: userData } = useGetUserQuery();
  let percentage = props.percentage;
  if (
    userData?.profile?.maxPerformAv &&
    props.percentage > userData?.profile?.maxPerformAv
  ) {
    percentage = userData?.profile?.maxPerformAv;
  } else if (
    userData?.profile?.minPerformAv &&
    props.percentage < userData?.profile?.minPerformAv
  ) {
    percentage = userData?.profile?.minPerformAv;
  }

  return (
    <>
      <div className={props.grow}>
        <div className="flex flex-row items-center justify-start gap-[5px]">
          <div
            className={props?.className}
            style={{ width: props?.avatarWidth }}
          >
            <MyAvatarSection
              getSingleAvatar={true}
              singleHealthId={getTheHealthId(percentage)}
              singleAvatarStyling={{
                width: "100%",
                height: "100%",
                display: "block",
              }}
              defaultAvatarValues={
                props?.health?.filter(
                  (item) => +item.healthId === getTheHealthId(percentage)
                )[0]
              }
            />
          </div>
          {!props.avatarOnly ? (
            <div className="flex flex-col text-center ml-1">
              <div className="my-[5px]">
                <p
                  className={`avatar-grade ${AvatarType(
                    percentage,
                    "bg"
                  )} pointer-events-none`}
                >
                  {props?.grade}
                </p>
              </div>
              <label
                className={`mt-1  text-sm font-extrabold ${AvatarType(
                  percentage,
                  "text"
                )}`}
              >
                {isNaN(percentage) ? 0 : percentage} %
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ActivityInfo;
