import { Link, useParams } from "react-router-dom";
import Health from "./GlobalHealth";
import {
  useFetchGlobalDashboardDataQuery,
  useFetchGraphDataQuery,
} from "store/dashboard/DashboardSlice";
import { useState } from "react";
import { ActivityLineChart } from "sections/charts";
import { DateRangePicker, Modal } from "rsuite";
import { format } from "date-fns";
import { predefinedRanges } from "components/hook-form/RHFDateRangePicker";
import { PresentationChartLineIcon } from "@heroicons/react/20/solid";
import AvatarInfo from "components/avatar/AvatarInfo";
import { AvatarGrade } from "../components/AvatarHealth";

export const GLOBAL_PERFORMANCE_AVATAR = {
  isPerformance: true,
};
export const GLOBAL_ACTIVITY_AVATAR = {
  isPerformance: false,
};

const Global = () => {
  const [showGraph, setShowGraph] = useState<"Performance" | "Activity" | "">(
    ""
  );
  const [dateRange, setDateRange] = useState<[string, string]>([
    format(new Date(), "MM/dd/yyyy"),
    format(new Date(), "MM/dd/yyyy"),
  ]);
  const { data: graphData, isLoading: isGraphLoading } = useFetchGraphDataQuery(
    { type: showGraph.toLowerCase(), dates: dateRange },
    {
      skip: !showGraph,
    }
  );

  const params = useParams();
  const { data: GlobalHealthData } = useFetchGlobalDashboardDataQuery({
    isDelegateView: params?.key,
  });
  if (GlobalHealthData) {
    const { avatarActivities, avatarPerformance } = GlobalHealthData;

    return (
      <>
        {avatarActivities?.count > 0 && (
          <li
            data-testid="global-activity-avatar"
            className="navbar-menu-items group h-full flex justify-center items-center relative"
          >
            <Link
              to="#"
              className="navbar-menu-item-link  px-4 py-2 text-sm font-normal transition-all ease-in-out cursor-pointer select-none text-slate-700 flex justify-center "
            >
              <AvatarInfo
                percentage={avatarActivities?.average}
                avatarWidth={40}
                grow={"flex-grow"}
                gradeType={AvatarGrade(avatarActivities?.average ?? 0)}
                avatarOnly={true}
                isGlobalAvatar={GLOBAL_ACTIVITY_AVATAR}
              />
            </Link>
            <ul className="group-hover:flex flex-row  gap-0 w-[350px] dropdown-menu p-2 bg-white hidden absolute top-[50px] right-1/4 translate-y-3  before:top-[-10px] before:left-[-10px] before:w-full before:h-full before:absolute  rounded  drop-shadow-lg  hover:mouse-pointer before:z-[-1] ">
              <li className="dropdown-menu-item rounded w-[350px] ">
                <div className="w-full flex flex-col">
                  <div className="flex flex-col sm:flex-row xl:flex-col justify-between gap-4 xl:pr-3 my-2">
                    <div className="w-full  bg-gray-100 rounded-md px-2 pt-2 relative">
                      <h5 className="uppercase text-sm">Activity Avatar</h5>
                      <Health
                        {...GlobalHealthData}
                        type="activity"
                        isGlobalAvatar={GLOBAL_ACTIVITY_AVATAR}
                      />
                      <button
                        onClick={() => setShowGraph("Activity")}
                        className="absolute top-[8px] left-[36%] translate-x-0 translate-y-full text-grey-400  hover:text-primary-blue  transition-all ease-in-out duration-500"
                      >
                        <PresentationChartLineIcon className="w-10 h-10  " />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        )}
        {avatarPerformance?.count > 0 && (
          <li
            data-testid="global-performance-avatar"
            className="navbar-menu-items group h-full flex justify-center items-center relative"
          >
            <Link
              to="#"
              className="navbar-menu-item-link  px-4 py-2 text-sm font-normal transition-all ease-in-out cursor-pointer select-none text-slate-700 flex justify-center "
            >
              <AvatarInfo
                percentage={avatarPerformance?.average}
                avatarWidth={40}
                grow={"flex-grow"}
                gradeType={AvatarGrade(avatarPerformance?.average ?? 0)}
                avatarOnly={true}
                isGlobalAvatar={GLOBAL_PERFORMANCE_AVATAR}
              />
            </Link>
            <ul className="group-hover:flex flex-col gap-0 w-[350px] dropdown-menu p-2 bg-white hidden absolute top-[50px] right-1/4 translate-y-3  before:top-[-10px] before:left-[-10px] before:w-full before:h-full before:absolute  rounded  drop-shadow-lg  hover:mouse-pointer before:z-[-1] ">
              <li className="dropdown-menu-item rounded w-[350px]">
                <div className="w-full flex flex-col ">
                  <div className="flex flex-col sm:flex-row xl:flex-col justify-between gap-4 xl:pr-3 my-2">
                    <div className="w-full bg-gray-100 rounded-md px-2 pt-2 relative">
                      <h5 className="uppercase text-sm text-gray-600">
                        Performance Avatar
                      </h5>
                      <Health
                        {...GlobalHealthData}
                        type="performance"
                        isGlobalAvatar={GLOBAL_PERFORMANCE_AVATAR}
                      />
                      <button
                        onClick={() => setShowGraph("Performance")}
                        className="absolute top-[8px] left-[36%] translate-x-0 translate-y-full text-grey-400  hover:text-primary-blue  transition-all ease-in-out duration-500"
                      >
                        <PresentationChartLineIcon className="w-10 h-10" />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        )}
        {showGraph && graphData && (
          <Modal
            className="!w-full xl:!w-[50%]"
            onClose={() => setShowGraph("")}
            onBackdropClick={() => setShowGraph("")}
            open={Boolean(showGraph)}
          >
            <Modal.Header>
              <div className="flex  justify-evenly items-center flex-wrap gap-3">
                <h3 className="pb-5 text-primary-blue">{`User ${showGraph} Graph`}</h3>
                <DateRangePicker
                  format={"MM-dd-yyyy"}
                  // @ts-ignore
                  ranges={predefinedRanges}
                  onChange={(selectedRange) => {
                    if (selectedRange) {
                      setDateRange([
                        format(selectedRange[0], "dd-MM-yyyy"),
                        format(selectedRange[1], "dd-MM-yyyy"),
                      ]);
                    }
                  }}
                  defaultValue={[new Date(), new Date()]}
                  showOneCalendar
                />
                {isGraphLoading && <h5>Loading...</h5>}
              </div>
            </Modal.Header>
            <Modal.Body>
              {isGraphLoading ? (
                <h5>Loading Data Please Wait ....</h5>
              ) : (
                <ActivityLineChart
                  chartData={[
                    {
                      name: showGraph.toLowerCase(),
                      data: graphData?.data?.map((item) => item.health ?? 0),
                    },
                  ]}
                  xaxisData={graphData?.data?.map((item) => item.date)}
                />
              )}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default Global;
