import { useDispatch } from "react-redux";
import {
  ITrackingDataRows,
  TPutDataRequest,
  TTrackingPostValues,
} from "../../types/tracking-data/index";
import { removeTrackingData, removeTrackingDataRow } from "store/tracking-data";
import {
  BookmarkSquareIcon,
  PencilSquareIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/20/solid";
import { useState, useEffect, SyntheticEvent, forwardRef } from "react";
import {
  Form,
  DatePicker,
  useToaster,
  Input,
  Button,
  Tooltip,
  Whisper,
} from "rsuite";
import { TrackingDataSchema } from "./schema";
import beforeUnload from "hooks/beforeUnload";
import { useUpdateTrackingDataMutation } from "store/tracking-data/trackingApi";
import { toastSuccess, toastError } from "components/toasts/index";
import format from "date-fns/format";
import { useParams } from "react-router-dom";
const Textarea = forwardRef((props: any, ref: any) => (
  <Input {...props} as="textarea" ref={ref} />
));
const tooltip = (msg: string) => <Tooltip>{msg}</Tooltip>;

const Row = (tracking: ITrackingDataRows) => {
  const toaster = useToaster();

  const params = useParams();

  const [edit, setEdit] = useState<number>(0);
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState<TTrackingPostValues | any>();
  const [formError, setFormError] = useState<TTrackingPostValues | any>();
  const [_changes, setChanges] = useState<boolean>(false);

  const [updateTrackingData, { isLoading: processing }] =
    useUpdateTrackingDataMutation();
  useEffect(() => {
    if (tracking?.beginningDate) {
      setFormValues({
        beginingDate:
          tracking?.beginningDate && new Date(tracking?.beginningDate),
        endingDate: tracking?.endingDate && new Date(tracking?.endingDate),
        data: tracking?.data,
        comment: tracking?.comment,
      });
    }
  }, [tracking]);

  return (
    <div key={tracking?.trackingId}>
      {edit && formValues?.beginingDate ? (
        <>
          <div className="grid grid-cols-1 gap-1  hover:bg-gray-50 hover:cursor-pointer justify-center items-baseline p-2 border-b-2 border-col border-[#e5e7eb] data-content data-highlight">
            <Form
              formError={formError}
              onCheck={setFormError}
              model={TrackingDataSchema}
              formValue={formValues}
              onChange={(formValues: object) => {
                setFormValues(formValues);
                /** check id changes made by user */
                setChanges(true);
                beforeUnload(true);
              }}
              onSubmit={(el, event) => onSubmitHandler(event)}
              className="edit-trackingData-form grid-cols-1 lg:grid-cols-2 gap-2 inline-flex"
            >
              <Form.Group controlId="beginingDate" className="!mb-0">
                <Form.Control
                  oneTap
                  accepter={DatePicker}
                  name="beginingDate"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  className="edit-trackingData-input"
                />
              </Form.Group>

              <Form.Group controlId="endingDate" className="!mb-0">
                <Form.Control
                  oneTap
                  accepter={DatePicker}
                  name="endingDate"
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  className="edit-trackingData-input"
                />
              </Form.Group>

              <Form.Group controlId="data" className="!mb-0">
                <Form.Control
                  oneTap
                  label="Data"
                  accepter={Input}
                  name="data"
                  placeholder="Data"
                  className="edit-trackingData-input"
                />
              </Form.Group>

              <Form.Group controlId="comment" className="!mb-0">
                <Form.Control
                  oneTap
                  label="Comment"
                  accepter={Textarea}
                  name="comment"
                  placeholder="Comment"
                  className="edit-trackingData-input !h-9"
                />
              </Form.Group>
              <div className="col-span-2  flex  justify-center md:justify-end items-center flex-col md:flex-row !m-0">
                <Button
                  type="submit"
                  loading={processing}
                  className="!inline-block !relative !group !mr-[10px !p-2 !text-white !bg-yellow-500 !hover:bg-white border !border-yellow-500 hover:!text-yellow-500  !font-medium !text-xs !leading-tight  !rounded-full !shadow-md  hover:!shadow-lg focus:!bg-white focus:!text-yellow-500 focus:shadow-lg focus:outline-none focus:ring-0 active:!bg-white active:!text-yellow-500   active:!shadow-lg transition-all duration-500 ease-in-out"
                >
                  <BookmarkSquareIcon className="w-5 h-5" />
                </Button>
                <button
                  onClick={() => {
                    setEdit(0);
                  }}
                  className="inline-block relative group p-2 ml-3 border border-red-600 bg-red-600 hover:bg-white hover:text-red-600 active:text-red-600 active:bg-white focus:bg-white focus:text-red-600  text-white font-medium text-xs leading-tight  rounded-full shadow-md    transition-all duration-500 ease-in-out"
                >
                  <XCircleIcon className="w-5 h-5" />
                  <div
                    id="tooltip-default"
                    role="tooltip"
                    className="inline-block absolute invisible group-hover:visible z-[500] p-2 ml-3 -top-[39px] -left-[22px] text-sm font-medium text-white bg-gray-900 rounded-2xl shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip dark:bg-gray-700"
                  >
                    Cancel
                  </div>
                </button>
              </div>
            </Form>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-5 gap-4  hover:bg-gray-50 hover:cursor-pointer justify-center items-baseline p-2 border-b-2 border-col border-[#e5e7eb] data-content ">
          <div>{tracking?.beginningDate?.toString()}</div>
          <div>{tracking?.endingDate?.toString()}</div>
          <div>{tracking?.data}</div>
          <Whisper
            placement="topStart"
            controlId="control-id-hover"
            classPrefix="!w-9"
            trigger="hover"
            speaker={tooltip(tracking?.comment)}
          >
            <div>
              {tracking?.comment.length > 50
                ? tracking?.comment?.substring(0, 100) + "..."
                : tracking?.comment}
            </div>
          </Whisper>
          <div className="text-right">
            <button
              onClick={() => {
                setEdit(tracking?.trackingId);
              }}
              className="inline-block relative group mr-[10px p-2 text-white bg-yellow-500 hover:bg-white border border-yellow-500 hover:text-yellow-500  font-medium text-xs leading-tight  rounded-full shadow-md  hover:shadow-lg focus:bg-white focus:text-yellow-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-white active:text-yellow-500   active:shadow-lg transition-all duration-500 ease-in-out"
            >
              <PencilSquareIcon width={20} height={20} />
              <div
                id="tooltip-default"
                role="tooltip"
                className="inline-block absolute invisible group-hover:visible z-[500] py-2 px-3 -top-[2px] -left-[53px] text-sm font-medium text-white bg-gray-900 rounded-2xl shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip dark:bg-gray-700"
              >
                Edit
              </div>
            </button>
            <button
              onClick={() => {
                dispatch(removeTrackingData(true));
                dispatch(removeTrackingDataRow(tracking?.trackingId));
              }}
              className="inline-block relative group p-2 ml-3 border border-red-600 bg-red-600 hover:bg-white hover:text-red-600 active:text-red-600 active:bg-white focus:bg-white focus:text-red-600  text-white font-medium text-xs leading-tight  rounded-full shadow-md    transition-all duration-500 ease-in-out"
            >
              <TrashIcon width={20} height={20} />
              <div
                id="tooltip-default"
                role="tooltip"
                className="inline-block absolute invisible group-hover:visible z-[500] p-2 ml-3 -top-[39px] -left-[22px] text-sm font-medium text-white bg-gray-900 rounded-2xl shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip dark:bg-gray-700"
              >
                Delete
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );

  async function onSubmitHandler(event: SyntheticEvent) {
    if (formError && Object.keys(formError)?.length > 0) return false;

    let data: TPutDataRequest = {
      bodyData: {
        activity: tracking?.activity,
        activityId: tracking?.activityId,
        beginningDate: format(new Date(formValues?.beginingDate), "yyyy-MM-dd"),
        endingDate: format(new Date(formValues?.endingDate), "yyyy-MM-dd"),
        comment: formValues?.comment,
        data: formValues?.data,
        trackingId: tracking?.trackingId,
      },
      trackingId: tracking?.trackingId,
      token: params?.key,
    };

    await updateTrackingData(data)
      .unwrap()
      .then((data: any) => {
        if (data.status) {
          toaster.push(toastSuccess({ message: data.message }));
          setFormValues("");
          setFormError("");
          setEdit(0);
          return true;
        }
      })
      .catch((error: any) => {
        setFormError({
          beginingDate: error?.data?.errors?.beginingDate?.values()?.next()
            ?.value,
          endingDate: error?.data?.errors?.endingDate?.values()?.next()?.value,
          comment: error?.data?.errors?.comment?.values()?.next()?.value,
          data: error?.data?.errors?.data?.values()?.next()?.value,
        });
        toaster.push(
          toastError({
            message: (
              <ul>
                {error?.data?.errors?.beginingDate ? (
                  <li>
                    <strong>Beginging Date:</strong>{" "}
                    {error?.data?.errors?.beginingDate}
                  </li>
                ) : (
                  ""
                )}
                {error?.data?.errors?.endingDate ? (
                  <li>
                    <strong>Ending Date:</strong>{" "}
                    {error?.data?.errors?.endingDate}
                  </li>
                ) : (
                  ""
                )}
                {error?.data?.errors?.comment ? (
                  <li>
                    <strong>Comment:</strong> {error?.data?.errors?.comment}
                  </li>
                ) : (
                  ""
                )}
                {error?.data?.errors?.data ? (
                  <li>
                    <strong>Data:</strong> {error?.data?.errors?.data}
                  </li>
                ) : (
                  ""
                )}
              </ul>
            ),
          })
        );
      });
  }
};

export default Row;
