import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TInitialState, TTrackingData } from "types/tracking-data";
import { ITrackingDataList, ITrackingDataRows, IFilterParams } from '../../types/tracking-data/index';

export const initialState: TInitialState = {
    addRow: false,
    isRowEdit:false,
    isInsertRow:false,
    isUndoRow:false,
    editRow:{
      activity: "",
      activityId: 0,
      beginingDate: "",
      beginningDate: "",
      comment: "",
      data: 0,
      endingDate: "",
      trackingId: 0
    },
    isRowDelete: false,
    deleteRowId: 0,
    addActivityData: {
      activityId:0,
      type: "",
      data:[]
    },
    params: {
      vMap: [],
      value: [],
      kpi: [],
      delegate: [],
      status: 3,
      activity: [],
      period:[],
      beginingDate: "",
      endingDate: ""
    },
    dataList:null
};

export const trackingDataSlice = createSlice({
  initialState,
  name: "trackingDataSlice",
  reducers: {
    addNewRowState: (state, action: PayloadAction<boolean>) => {
      state.addRow = action.payload;
    },
    addRowActivityData: (state, action: PayloadAction<ITrackingDataList>) => {
      state.addActivityData = action.payload;
    },
    setEditTrackingData: (state, action: PayloadAction<boolean>) => {
      state.isRowEdit = action.payload;
    },
    editTrackingDataRow: (state, action: PayloadAction<ITrackingDataRows>) => {
      state.editRow = action.payload;
    },
    removeTrackingData: (state, action: PayloadAction<boolean>) => {
      state.isRowDelete = action.payload;
    },
    removeTrackingDataRow: (state, action: PayloadAction<number>) => {
      state.deleteRowId = action.payload;
    },
    updateFilterParams: (state, action: PayloadAction<IFilterParams>) => {
      state.params = action.payload;
    },
    insertRow: (state, action: PayloadAction<boolean>) => {
      state.isInsertRow = action.payload;
    },
    undoInsertedRow: (state, action: PayloadAction<boolean>) => {
      state.isUndoRow = action.payload;
    },
    setDataList: (state, action: PayloadAction<TTrackingData|null>) => {
      state.dataList = action.payload;
    },
  },
});

export const { 
  addNewRowState, 
  addRowActivityData,
  setEditTrackingData,
  editTrackingDataRow,
  removeTrackingData,
  removeTrackingDataRow,
  updateFilterParams,
  insertRow,
  undoInsertedRow,
  setDataList
} = trackingDataSlice.actions;

export default trackingDataSlice.reducer;
