const SvgComponent = (props: { width?: string; height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 63.74 63.74"
    {...props}
  >
    <defs>
      <style>{".cls-level2{fill:#77430e}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <circle
        cx={31.87}
        cy={31.87}
        r={31.87}
        style={{
          fill: "#f8981c",
        }}
      />
      <path
        d="M33.15 24.98c-2.61 4.12-3.56 7.04-.17 10.98 1.75 2.03 2.6 4.47 2.75 7.19.19 3.4-.7 6.56-1.8 9.69-.06.17-.18.33-.39.72-.09-4.57-1.67-8.24-4.64-11.4-.75 5.26-4.42 8.22-8.04 11.21-.09-.06-.18-.11-.28-.17.12-.22.21-.46.35-.66 3.05-4.61 3.58-9.71 2.97-15.04-.32-2.83-.7-5.65-1-8.47-.07-.63.09-1.28-.13-2.2-.92.79-1.86 1.57-2.77 2.38-.9.8-1.78 1.64-2.92 2.4.64-1.59 1.14-3.25 1.94-4.74 2.53-4.72 5.39-8.8 10.51-10.61 1.46-.52 3.68-.67 5.32-.3 3.99.89 6.06 4.19 4.91 8.36-1.15 4.17-.57 7.99 1.43 11.71.39.73.64 1.53.79 2.38-5.26-2.82-7.76-7.48-8.85-13.42ZM42.7 10.2c1.03-.15 1.81.32 2.32 1.3.53 1.01 1.24 1.98 1.53 3.05.21.76.09 1.81-.29 2.5-.95 1.7-2.99 2.52-4.66 1.98-.59-.19-1.19-.69-1.56-1.21-.62-.89-1.09-1.9-1.57-2.89-.9-1.88 1.1-4.54 3.53-4.73.2-.02.41 0 .7 0Z"
        className="cls-level2"
      />
    </g>
  </svg>
);
export default SvgComponent;
