import {
  useEffect,
  forwardRef,
  HTMLAttributes,
  useState,
  CSSProperties,
} from "react";
import {
  ArrowsPointingOutIcon,
  ChevronUpDownIcon,
  LinkIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/20/solid";

import { Button, useToaster } from "rsuite";
import StatusPopOver from "./StatusPopOver";
import LevelDrawer from "./LevelDrawer";
import { useDeleteVmapLevelMutation } from "store/v-map/vmapApiSlice";
import { toastError, toastSuccess } from "components/toasts";
import MoveElement from "./MoveElement";
import { useGetDelegateListQuery } from "store/preferences/profiles/profileApi";
import { addUrlPrefix } from "utils/helperFunctions";
import { useParams } from "react-router-dom";
import { IKpis } from "types/preferences/definition";
import { getDefaultValuesForLevelDrawer } from "./getDrawerFileds";

type TFilterKeys =
  | "kpiTitle"
  | "strategyTitle"
  | "projectTitle"
  | "criticalActivityTitle";

type TFilter = Record<TFilterKeys, string>;

export interface ITreeItemProps
  extends IKpis,
    Omit<HTMLAttributes<HTMLLIElement>, "id" | "title" | "style"> {
  style: { externalStyle: CSSProperties | undefined; style: CSSProperties };
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  levelId: string;
  onCollapse(): void;
  onRemove(): void;
  status: number;
  type: (typeof allLevels)[number];
  name: string;
  vmapId: number;
  isDragging: boolean;
  wrapperRef?(_node: HTMLDivElement): void;
  isDragOn: boolean;
  isSearch: boolean;
  valueUrl?: string;
  filter?: TFilter;
  user_reference?: {
    name: string;
  };
}

export const allLevels = [
  "level1",
  "level2",
  "level3",
  "level4",
  "level5",
] as const;
const levelColors = ["#49BAEA", "#02A574", "#F76232", "#BD7201", "#FFCA43"];
const levels = [
  "kpiTitle",
  "strategyTitle",
  "projectTitle",
  "criticalActivityTitle",
];
const getNextLevel = (currentLevel: (typeof allLevels)[number]) => {
  if (currentLevel === "level5") {
    return "level5";
  }
  return allLevels[allLevels.indexOf(currentLevel) + 1];
};
export const TreeItem = forwardRef<HTMLLIElement, ITreeItemProps>(
  (
    {
      handleProps,
      style,
      onCollapse,
      indentationWidth,
      depth,
      levelId,
      onRemove,
      wrapperRef,
      title,
      collapsed,
      name,
      type,
      ghost,
      status,
      vmapId,
      isDragOn,
      isSearch,
      filter,
      ...props
    },
    ref
  ) => {
    const [showModal, setShowModal] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showValueDrawer, setValueShowDrawer] = useState(false);
    const [deleteLevel] = useDeleteVmapLevelMutation();
    const { data: delegateList } = useGetDelegateListQuery();
    const toast = useToaster();
    const handleDeleteLevel = async () => {
      const confirm = window.confirm(
        "Are you sure you want to delete this level ? "
      );
      try {
        if (type && (name || title) && levelId && confirm) {
          const res = await deleteLevel({
            type,
            title: name || title,
            levelId: +levelId,
          }).unwrap();
          toast.push(toastSuccess({ message: res.message }));
          if (onRemove) {
            onRemove();
          }
        }
      } catch (error) {
        //@ts-ignore
        toast.push(
          toastError({
            //@ts-ignore
            message: error?.data?.message ?? "Something went wrong",
          })
        );
      }
    };
    const params = useParams();
    useEffect(() => {
      if (params && params?.id) {
        if (
          params.level === type &&
          (+params?.id === +levelId || +params?.id === vmapId)
        ) {
          setValueShowDrawer(true);
        }
      }
    }, [params]);

    return (
      <>
        <div
          ref={wrapperRef}
          style={
            {
              paddingLeft: `${indentationWidth * depth}px`,
              maxHeight: "10vh",
              width: `calc(100% - ${indentationWidth * depth}px)`,
              ...style.externalStyle,
            } as React.CSSProperties
          }
          className="colapse-item min-w-[950px] lg:min-w-full"
        >
          <ul className="">
            <li
              className={`vmap-bar relative bg-[${levelColors[depth]}] ${
                isSearch ? "border-[4px] border-blue-600" : ""
              } flex items-center justify-between rounded-xl h-12 mt-5 ml-16 pr-5 relative group`}
              style={{
                ...style.style,
                background: levelColors[depth],
              }}
              aria-selected={isSearch}
              ref={ref}
            >
              <div className="flex items-center relative w-[40%]">
                <div
                  className={`bg-neutral-800 rounded-full p-1 absolute -left-14 before:content-[''] before:border before:border-solid before:border-neutral-800 before:h-0 before:w-10 before:absolute before:top-1/2 before:left-6 after:content-'' after:absolute after:-top-[40px] after:left-[13px] after:w-[2px] after:h-${
                    !ghost ? 10 : 0
                  } after:bg-neutral-800 collapse-btn`}
                  onClick={onCollapse}
                >
                  {collapsed ? (
                    <PlusIcon color="white" width={20} height={20} />
                  ) : (
                    <MinusIcon color="white" width={20} height={20} />
                  )}
                </div>

                <div
                  className="rounded-xl h-12 p-3 mr-3 bg-neutral-800 w-12 flex justify-center items-center"
                  {...(isDragOn && handleProps)}
                >
                  {isDragOn && (
                    <ArrowsPointingOutIcon
                      color="white"
                      width={20}
                      height={20}
                    />
                  )}
                </div>
                <p
                  role={"button"}
                  onClick={() => setValueShowDrawer(true)}
                  className="text-white font-medium truncate"
                >
                  {props?.user_reference?.name} {title || name}
                  <br />
                  {delegateList?.map((item) => {
                    if (
                      props?.delegateTo?.split(",").includes(`${item.userId}`)
                    ) {
                      return item?.user?.name + " , ";
                    }
                    return "";
                  })}
                </p>
                {type && showValueDrawer && props && (
                  <LevelDrawer
                    type={type}
                    level={depth}
                    vmapId={vmapId}
                    isValueDrawer={type === "level1"}
                    isLevelUpdate={true}
                    setShowDrawer={setValueShowDrawer}
                    showDrawer={showValueDrawer}
                    defaultProps={getDefaultValuesForLevelDrawer({
                      ...props,
                      status,
                      name,
                      title,
                      type,
                      levelId,
                    })}
                  />
                )}
              </div>
              {(status || status === 0) && type ? (
                <StatusPopOver levelId={+levelId} status={status} type={type} />
              ) : null}
              <p className="text-white text-xl absolute right-[28rem]">
                {props?.trackSign}
              </p>
              <Button
                onClick={() => setShowModal(true)}
                className="!absolute right-72"
              >
                <ChevronUpDownIcon className="w-6 h-6" />
              </Button>
              {showModal && (
                <MoveElement
                  elementToMove={{
                    Id: +levelId,
                    type,
                    name,
                    title,
                  }}
                  indexesUptoSelectedLevel={props?.indexes}
                  showModal={showModal}
                  setShowModal={setShowModal}
                />
              )}

              <div className="flex items-center">
                {type !== "level5" && filter ? (
                  <button
                    onClick={() => setShowDrawer(true)}
                    className="rounded-full bg-white px-3 py-2 w-[160px] items-center justify-center font-bold text-md text-secondary-blue flex cursor-pointer hover:bg-secondary-blue hover:text-white transition-all ease-in-out duration-500"
                  >
                    <PlusIcon className="w-5 h-5 mr-1" />
                    {filter[levels[depth] as keyof TFilter]}
                  </button>
                ) : null}
                {type && showDrawer && (
                  <LevelDrawer
                    type={getNextLevel(type)}
                    // here the keyname is vmapId but it is the level ID
                    vmapId={parseInt(levelId)}
                    isValueDrawer={false}
                    setShowDrawer={setShowDrawer}
                    showDrawer={showDrawer}
                    level={depth + 1}
                  />
                )}{" "}
                {(props?.url || props?.valueUrl) && (
                  <a
                    className="p-2 text-white absolute right-[30rem]"
                    href={addUrlPrefix(props?.valueUrl ?? props?.url)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LinkIcon width={25} height={25} />
                  </a>
                )}
                <button
                  onClick={handleDeleteLevel}
                  className=" group/delete-btn relative ml-3   border  rounded-full bg-red-600 px-[10px] py-[10px]   md-mt-0 font-bold text-md text-white flex cursor-pointer hover:bg-white hover:text-red-600  border-red-600 transition-all ease-in-out duration-500"
                >
                  <TrashIcon className="w-5 h-5 " />
                  <div
                    id="tooltip-default"
                    role="tooltip"
                    className="inline-block absolute invisible group-hover/delete-btn:visible z-[500] p-2 ml-3 top-[-1px] left-[-73px] text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 group-hover/delete-btn:opacity-100 transition-opacity duration-300 tooltip dark:bg-gray-700"
                  >
                    Delete
                  </div>
                </button>
              </div>
            </li>
          </ul>
        </div>
      </>
    );
  }
);
