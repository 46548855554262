import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Placeholder, toaster } from "rsuite";
import { removeTrackingData } from "store/tracking-data";
import { useDeleteTrackingDataMutation } from "store/tracking-data/trackingApi";

import { RootState } from "../../store/index";
import { Spinner } from "assets";
import { toastSuccess, toastError } from "../../components/toasts/index";
import { useFetchDashboardDataListMutation } from "store/dashboard/DashboardSlice";
import { useParams } from "react-router-dom";

const Delete = () => {
  const params = useParams();

  const dispatch = useDispatch();
  const { filterData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const [refetchDashboardData] = useFetchDashboardDataListMutation({
    fixedCacheKey: "shared-update-post",
  });

  const [deleteTrackingData, { isLoading: processing }] =
    useDeleteTrackingDataMutation();

  const { isRowDelete, deleteRowId } = useSelector(
    (state: RootState) => state?.trackingDataSlice
  );

  const handleClose = () => dispatch(removeTrackingData(false));

  return (
    <>
      <Modal keyboard={false} open={isRowDelete} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title className="!text-primary-blue !text-[20px] !font-bold text-left rs-modal-title">
            Delete Tracking Data Row
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {processing ? (
            <Placeholder.Paragraph />
          ) : (
            <p>Are you sure you want to delete this record?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {processing ? (
            <Button appearance="primary">
              <Spinner /> Delete User
            </Button>
          ) : (
            <Button
              onClick={() => handleDeleteTrackingDataRow()}
              appearance="primary"
            >
              Delete Row
            </Button>
          )}
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

  async function handleDeleteTrackingDataRow() {
    let data = {
      trackingID: deleteRowId,
      token: params?.key,
    };
    let resp = await deleteTrackingData(data).unwrap();

    if (resp?.status) {
      toaster.push(toastSuccess({ message: resp.message }));
      dispatch(removeTrackingData(false));
      refetchDashboardData({ bodyData: filterData });
    } else toaster.push(toastError({ message: resp.message }));
  }
};

export default Delete;
