import { useLocation, useParams } from "react-router-dom";
import Activity from "./activity";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import PerformanceDials from "./performance";
import { useEffect, useState } from "react";
import Loading from "components/loading";
import { useDispatch } from "react-redux";
import moment from "moment";
import { saveFilterDates, setDashboardApiData } from "store/dashboard";

const Notification = () => {
  const param = useParams();
  const dispatch = useDispatch();
  const [type, setType] = useState<number>(0);

  const location = useLocation()

  const queryParams = new URLSearchParams(location.search);
  let delegateIdd = queryParams.get('id') || ''; 

  const { data: notificationData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
    id: delegateIdd ?? "",
  });

  const datass = {
    dataArray: notificationData?.dataArray || [],
    dialsArray: notificationData?.dialsArray || [],
    profile: notificationData?.profile || {},
    settings: notificationData?.settings || {},
    trackingData: notificationData?.trackingData || [],
    health: notificationData?.health || []
  };

  const wrapdata = {data: datass}
  
  const profile = notificationData?.profile;

  function storeFilterDates() {
    const profile = notificationData?.profile;
    let startDate = profile?.rangeStartDate ?? moment().format("Y-m-d");
    let endDate = profile?.rangeEndDate ?? moment().format("Y-m-d");

    let appliedFilterDates = {
      filterStartDate: moment(startDate).format("YYYY-MM-DD"),
      filterEndDate: moment(endDate).format("YYYY-MM-DD"),
      trackingStartDate: moment(startDate).format("YYYY-MM-DD"),
      trackingEndDate: moment(endDate).format("YYYY-MM-DD"),
    };

    dispatch(saveFilterDates(appliedFilterDates));
  }

  useEffect(()=>{
    dispatch(setDashboardApiData(wrapdata))
  },[notificationData])

  useEffect(() => {
    storeFilterDates();
    setType(notificationData?.settings?.type ?? 0);
  }, [notificationData]);
  return <>{renderActivityDials(type)}</>;

  function renderActivityDials(type: number) {
    return type == 2 ? (
      // <PerformanceDials {...profile} />
      // <PerformanceDials userDetails={profile} list={notificationData} />
<PerformanceDials profile={profile} list={notificationData} />

    ) : type == 1 ? (
      <Activity profile={profile} list={notificationData}/>
    ) : (
      <Loading />
    );
  }
};

export default Notification;
