import { Drawer, Loader, toaster } from "rsuite";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/index";
import { useSelector } from "react-redux";
import { showQuickUpdate, updateDashboardActivity } from "store/dashboard";
import { useUpdateActivityDataMutation } from "store/dashboard/DashboardSlice";
import Table from "components/table/Table";
import { createColumnHelper } from "@tanstack/react-table";
import { IDashboardActivities, IDashboardActivityData } from "types/dashboard";
import StatusPopOver from "components/v-map/Tree/components/TreeItem/StatusPopOver";
import RFHDatePicker from "components/hook-form/RHFDatePicker";
import { useForm } from "react-hook-form";
import { FormProvider } from "components/hook-form";
import { useEffect, useState } from "react";
import { toastError, toastSuccess } from "components/toasts";
import { FolderIcon } from "@heroicons/react/20/solid";
import LevelDrawer from "components/v-map/Tree/components/TreeItem/LevelDrawer";
import { IKpis } from "types/preferences/definition";
import { useParams } from "react-router-dom";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import moment from "moment";

export const LEVELS: {
  [key: string]: number;
} = {
  level1: 0,
  level2: 1,
  level3: 2,
  level4: 3,
  level5: 4,
};

export const QuickUpdate = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [close, setClose] = useState(false);
  const { quickUpdate, dashboardActivityList: activityList } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const [showLevelDrawer, setShowLevelDrawer] = useState<{
    show: boolean;
    defaultValues: IKpis | null;
  }>({
    show: false,
    defaultValues: null,
  });

  const { filterData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );
  const { data: activityListData } = useDashboardFetchDelegateDataQuery({
    key: param?.key ?? "",
    type: param?.type ?? "",
  });

  const [updateActivityData, { isLoading: isUpdating }] =
    useUpdateActivityDataMutation();

  const methods = useForm<{ data: IDashboardActivities[] | [] }>({
    defaultValues: {
      data: [],
    },
  });

  const { handleSubmit, register, reset } = methods;

  const saveDashboardActivities = async ({
    data,
  }: {
    data: IDashboardActivities[];
  }) => {
    try {
      const response = await updateActivityData(data).unwrap();
      if (response) {
        dispatch(updateDashboardActivity(data));
        if (close) {
          dispatch(showQuickUpdate(false));
          setClose(false);
        }
        toaster.push(toastSuccess({ message: response?.message }));
      }
    } catch (error) {
      toaster.push(toastError({ message: "Something went wrong" }));
    }
  };
  useEffect(() => {
    if (activityListData) {
      //@ts-ignore
      reset({
        //@ts-ignore
        data:
          activityList ??
          activityListData?.dataArray.reduce(
            // @ts-ignore
            (accumulator, item) => [...accumulator, ...item],
            []
          ),
      });
    }
  }, [activityListData]);

  if (activityListData?.dataArray?.length) {
    //@ts-ignore
    // const itemData = item?.data;
    const data: IDashboardActivityData[] =
      activityList ??
      activityListData?.dataArray.reduce(
        // @ts-ignore
        (accumulator, item) => [...accumulator, ...item],
        []
      );
    //for updating the status of the activity in the dashboard
    const updateActivityStatus = (status: number, id: number) => {
      dispatch(
        updateDashboardActivity(
          data.map((item) => {
            if (id === item.id) {
              return { ...item, status };
            }
            return item;
          })
        )
      );
    };
    const columnHelper = createColumnHelper<IDashboardActivityData>();

    const COLUMNS = [
      columnHelper.display({
        header: " ",
        cell: (info) => {
          return (
            <>
              <button
                type="button"
                onClick={() =>
                  setShowLevelDrawer({
                    show: true,
                    //@ts-ignore
                    //TODO: need to add more typed
                    defaultValues: info?.row?.original,
                  })
                }
              >
                <FolderIcon width={50} height={50} />
              </button>
            </>
          );
        },
      }),
      columnHelper.accessor("title", {
        header: "Name",
      }),
      columnHelper.display({
        header: "Status",
        cell: (info) => {
          return (
            <div className="m-auto">
              <StatusPopOver
                levelId={info?.row?.original?.id}
                status={info?.row?.original?.status}
                type={info?.row?.original?.type}
                overrideClassName={true}
                onSuccessHandler={(status) =>
                  updateActivityStatus(status, info?.row?.original?.id)
                }
              />
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Due Date",
        cell: (info) => {
          return (
            <RFHDatePicker
              name={`data.${info.row.index}.dueDate`}
              defaultValue={
                info?.row?.original?.dueDate
                  ? moment(info?.row?.original?.dueDate)?.toDate()
                  : moment(info?.row?.original?.endDate)?.toDate()
              }
            />
          );
        },
      }),
      columnHelper.display({
        header: "Description",
        cell: (info) => {
          return (
            <textarea
            //@ts-ignore
            // {...register(`data.${info?.row?.index}.description`)}
            ></textarea>
          );
        },
      }),
    ];
    return (
      <>
        <Drawer
          open={quickUpdate}
          size="lg"
          onClose={() => dispatch(showQuickUpdate(false))}
        >
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(saveDashboardActivities)}
          >
            <>
              <Drawer.Header>
                <Drawer.Title className="!text-primary-blue !text-[24px] !font-bold">
                  Quick Update Activity
                </Drawer.Title>
                <Drawer.Actions>
                  <button
                    type="submit"
                    className="text-base font-semibold text-white bg-green-500 py-[6px]  px-7 ml-5 rounded border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => setClose(true)}
                    className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500 ml-2"
                  >
                    Save & Close
                  </button>
                </Drawer.Actions>
              </Drawer.Header>
              <Drawer.Body>
                <Table
                  tableData={{ data: data, columns: COLUMNS }}
                  tableTitle={""}
                  showGlobalFilter={true}
                  isPagination={true}
                  isManualPagination={false}
                />
              </Drawer.Body>
            </>
          </FormProvider>
        </Drawer>

        {showLevelDrawer.show && showLevelDrawer.defaultValues && (
          <LevelDrawer
            showDrawer={showLevelDrawer.show}
            setShowDrawer={setShowLevelDrawer}
            level={LEVELS[showLevelDrawer?.defaultValues?.type]}
            type={showLevelDrawer?.defaultValues?.type}
            vmapId={+showLevelDrawer?.defaultValues?.levelId}
            isLevelUpdate={true}
            isValueDrawer={false}
            defaultProps={{
              ...showLevelDrawer.defaultValues,
              //@ts-ignore
              levelId: showLevelDrawer.defaultValues.id,
              statusId: showLevelDrawer?.defaultValues?.status,
              name: showLevelDrawer?.defaultValues?.title,
              type: showLevelDrawer?.defaultValues?.type,
              ...(showLevelDrawer?.defaultValues?.type !== "level1" && {
                goal: showLevelDrawer?.defaultValues?.goal,
                dateAssignedDue: [
                  moment(showLevelDrawer?.defaultValues?.startDate)?.toDate(),
                  showLevelDrawer?.defaultValues?.dueDate
                    ? moment(showLevelDrawer?.defaultValues?.dueDate)?.toDate()
                    : moment(showLevelDrawer?.defaultValues?.endDate)?.toDate(),
                ],
                delegateTo:
                  showLevelDrawer?.defaultValues?.delegates?.split(","),
                qualifierTo:
                  showLevelDrawer?.defaultValues?.qualifierTo?.split(","),
                seasonalGoal:
                  showLevelDrawer?.defaultValues?.seasonalGoal?.split(","),
              }),
            }}
          />
        )}
      </>
    );
  } else return <Loader />;
};
