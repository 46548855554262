import { SideBarCard } from "components/cards";
import { Outlet } from "react-router-dom";

import { getPreferencesSideBarLinks } from "sections/preferences/settings/props";

const PreferencesLayout = () => {
  return (
    <div className="main-profile-container  bg-slate-200 py-4">
      <div className="profile-wrapper  flex flex-wrap lg:flex-nowrap  justify-between gap-10  p-3 mx-auto rounded-xl h-fit flex-col lg:flex-row ">
        <SideBarCard sideBarLinks={getPreferencesSideBarLinks()} />
        <div className="preference-layout tabs  flex flex-col gap-4 w-full lg:w-9/12">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default PreferencesLayout;
