import { createContext, useState } from "react";

interface IOptionProvider {
  children: JSX.Element;
  options: { key: string; label: string }[];
  defaultValues: { [key: string]: string };
}
interface IOptionContext {
  allValues: { [key: string]: string[] };
  selectedValues: { [key: string]: string };
  setSelectedValues: React.Dispatch<React.SetStateAction<{}>>;
  setAllValues: React.Dispatch<React.SetStateAction<{}>>;
}

export const OptionContext = createContext<IOptionContext>({
  allValues: {},
  selectedValues: {},
  setSelectedValues: () => {},
  setAllValues: () => {},
});

export const OptionProvider = ({
  children,
  defaultValues,
}: IOptionProvider) => {
  const [allValues, setAllValues] = useState({});
  const [selectedValues, setSelectedValues] = useState({ ...defaultValues });
  const contextValues = {
    selectedValues,
    setSelectedValues,
    allValues,
    setAllValues,
  };

  return (
    <OptionContext.Provider value={contextValues}>
      {children}
    </OptionContext.Provider>
  );
};
