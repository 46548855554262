import { appApi } from "store/apis/apiSlice";
import { TDelegateAvatarType } from "types/dashboard";

interface IAvatars {
  //TODO at present i am not performing any actions with id or userId that's why they are made optional in case of MY-AVATAR only.
  id?: number;
  userId?: number;
  healthId: number;
}

interface IAvatarsWithAvatarField extends IAvatars {
  avatar?: { [key: string]: string | boolean };
}

const avatarApiWithTags = appApi.enhanceEndpoints({
  addTagTypes: ["UPDATE_AVATAR"],
});

export const AvatarApiSlice = avatarApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUserAvatars: builder.query<
      IAvatarsWithAvatarField[],
      TDelegateAvatarType | undefined
    >({
      query: (delegateData) =>
        delegateData?.token
          ? `delegate-avatar/${delegateData?.delegateUserId}?token=${delegateData?.token}`
          : `avatar`,
      transformResponse: (res: { data: IAvatars[] }) => {
        const data = [];
        for (let singleAvatar of res.data) {
          const { userId, healthId, id, ...avatar } = singleAvatar;
          data.push({ userId, healthId, id, avatar });
        }
        data.sort((avatar1, avatar2) => avatar1.healthId - avatar2.healthId);
        return data;
      },
      providesTags: ["UPDATE_AVATAR"],
    }),
    updateUserAvatar: builder.mutation<IAvatars[], IAvatars>({
      query: (avatarDetails) => {
        return {
          url: `save-avatar`,
          method: "POST",
          body: avatarDetails,
        };
      },
      invalidatesTags: ["UPDATE_AVATAR"],
    }),
  }),
});

export const { useGetUserAvatarsQuery, useUpdateUserAvatarMutation } =
  AvatarApiSlice;
