import { RHFSelect } from "components/hook-form";
import { Button } from "components/button/Button";
import NextSessionCard from "sections/call-maximizer/NextSessionCard";
import {
  ICallMaximizerDateListResponse,
  useFetchOverDueListQuery,
} from "store/call-maximizer/callMaximizerApiSlice";
import {
  DocumentPlusIcon,
  PlusCircleIcon,
  TrashIcon,
  ClockIcon,
} from "@heroicons/react/20/solid";
import { useGetUserQuery } from "store/auth/authApi";
import moment from "moment";

type Props = {
  callMaximizerDateList: ICallMaximizerDateListResponse[];
  setCoachCallId: (value: ICallMaximizerDateListResponse) => void;
  coachCallId: ICallMaximizerDateListResponse;
  deleteCallMaximizer: () => void;
  showModal: () => void;
  showNextSessionModal: () => void;
  setShowOverDueModal: () => void;
};

const CallMaximizerFilterHeader = ({
  callMaximizerDateList,
  setCoachCallId,
  coachCallId,
  deleteCallMaximizer,
  showModal,
  showNextSessionModal,
  setShowOverDueModal,
}: Props) => {
  const { data: overDueList } = useFetchOverDueListQuery();

  const { data: user } = useGetUserQuery();
  /** get overdue count and filter out with no comment */
  let dueCount = overDueList ? findDueCount() : 0;
  return (
    <>
      <div className="bg-white border-[6px] border-white w-full min-h-[180px] p-5 rounded-lg justify-between mt-[19px] ">
        <div className="flex flex-wrap flex-col md:flex-row sticky top-32 w-full justify-between">
          <div className="flex basis-1/2 w-full py-2 justify-between place-content-center items-center flex-wrap gap-4 ">
            <RHFSelect
              name="callMaximizer"
              block={true}
              className={"z-55 basis-1/2 ml-0 mr-auto"}
              defaultValue={coachCallId.coachCallId}
              value={coachCallId.coachCallId}
              data={callMaximizerDateList.map((item) => {
                return {
                  value: item.coachCallId,
                  label: moment(item.callDate).format("MM/DD/YYYY"),
                };
              })}
              onSelect={(value) => {
                const filtered = callMaximizerDateList.filter(
                  (item) => item.coachCallId === value
                );
                setCoachCallId(filtered[0]);
              }}
              cleanable={false}
              searchable={false}

            />
            <div className="callMax-new-row basis-full flex justify-start gap-2 lg:flex-nowrap flex-wrap ">
              <Button
                additionalClass={
                  "min-w-[100px] bg-green-500 hover:shadow-lg capitalize flex justify-center items-center font-normal text-[14px] leading-[20px hover:bg-white  hover:text-green-500 border-[1px] border-green-500 transition-all duration-500 ease-in-out"
                }
                type="button"
                handleClick={showModal}
                title="New"
                icon={<PlusCircleIcon className="h-5 w-5  inline mr-[10px]" />}
              />
              <Button
                additionalClass={
                  "min-w-[100px]  capitalize bg-yellow-500 hover:shadow-lg flex justify-center items-center  font-semibold  text-[14px] leading-[20px] hover:bg-white  hover:text-yellow-500 border-[1px] border-yellow-500 transition-all duration-500 ease-in-out"
                }
                handleClick={deleteCallMaximizer}
                title="Delete"
                icon={<TrashIcon className="h-5 w-5  inline mr-[10px] " />}
              />
              <Button
                additionalClass={
                  "min-w-[100px]  capitalize bg-button-blue flex justify-center items-center  hover:shadow-lg font-normal  text-[14px] leading-[20px] hover:bg-white  hover:text-button-blue border-[1px] border-button-blue transition-all duration-500 ease-in-out"
                }
                title="Save"
                type="submit"
                icon={
                  <DocumentPlusIcon className="h-5 w-5  inline mr-[10px]" />
                }
              />
              <button
                onClick={setShowOverDueModal}
                className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500"
                type="button"
              >
                <ClockIcon className="h-5 w-5 inline" fontSize={1}></ClockIcon>{" "}
                OverDue <span>{dueCount}</span>
              </button>
            </div>
          </div>
          <NextSessionCard
            coachCallId={coachCallId}
            showNextSessionModal={showNextSessionModal}
          />
        </div>
      </div>
      <h3 className="text-center text-button-blue">
        Call Maximizer For {new Date(moment(coachCallId?.callDate).format("MM/DD/YYYY")).toDateString()}
      </h3>
    </>
  );

  function findDueCount() {
    let count = 0;
    try {
      Object.keys(overDueList ?? {})?.map((item) => {
        overDueList &&
          overDueList[item]?.map((list) => {
            if (list?.longComment) count++;
          });
      });
    } catch (error) {
      count = 0;
    }
    return count;
  }
};

export default CallMaximizerFilterHeader;
