import { useSelector } from "react-redux";
import { matchRoutes, useNavigate } from "react-router-dom";
import { RootState } from "store";
import { Navigate, useLocation } from "react-router-dom";
import { useGetUserQuery } from "store/auth/authApi";
// hooks
import { getAccessibleRoutes } from "layouts/dashboard/header/navLinks";
// pages
import Loader from "pages/frontend-sandbox/loader";
import { useDispatch } from "react-redux";
import { logout } from "store/auth/authStore";
import { ROUTES } from "sections/preferences/settings/constants";

export default function AuthGuard({ children }: { children: JSX.Element }) {
  const { isAuthenticated, simulate } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch();
  const {
    data: userData,
    isLoading,
    error,
  } = useGetUserQuery(simulate as unknown as void, {});

  const { pathname } = useLocation();

  const navigate = useNavigate();

  if (isLoading) {
    return <Loader />;
  }

  if (!isAuthenticated || error) {
    dispatch(logout());
    return <Navigate to={"auth/login"} />;
  }

  if (
    userData &&
    !matchRoutes(
      getAccessibleRoutes(userData?.role.permission).map((item) => ({
        path: item,
      })),
      pathname
    )
  ) {
    return <Navigate to={"/404"} />;
  }
  if (pathname === "/") {
    if (userData?.profile && "/" + userData?.profile.defaultPage === pathname) {
      return <>{children}</>;
    } else if (
      userData?.profile &&
      "/" + userData?.profile?.defaultPage !== pathname
    ) {
      if(ROUTES[userData!.profile!.defaultPage]){
      navigate(ROUTES[userData!.profile!.defaultPage]);
      }
    }
  }

  return <>{userData && children}</>;
}
