import { Input, InputGroup } from "rsuite";

const RsuiteInput = (props: any) => {
  return (
    <InputGroup>
      {props.addOn ? <InputGroup.Addon>{props.addOn}</InputGroup.Addon> : null}
      {props?.register ? (
        <Input {...props} {...props.register(props.name)} />
      ) : (
        <Input {...props} />
      )}
    </InputGroup>
  );
};

export default RsuiteInput;
