const beforeUnload = (option: boolean) => {
  if (option) {
    window.onbeforeunload = function (event: any) {
      if (!option) return false;
      let message = "Leaving the page might result in data loss";
      if (typeof event == "undefined") {
        event = window.event;
      }
      if (event && option) {
        event.returnValue = message;
      }
      return message;
    };
  } else {
    window.onbeforeunload = function () {
      return undefined;
    };
  }
  return undefined;
};
export default beforeUnload;
