const Footer = () => {
  return (
    <footer className="bg-[#111]">
     <div className="text-white pt-[48px] pb-[48px] px-3 md:px-16 flex justify-between flex-col md:flex-row gap-4 container mx-auto">
        <p className="self-center text-[16px]">© Copyright {new Date().getFullYear()} ProAdvisor Drivers</p>
        <a href="https://proadvisorcoach.com/" target="_blank">
          <img src="/assets/powered.svg" alt="ProAdvisorCoach Footer Logo" className="m-auto" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
