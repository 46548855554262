import {
  TDelegateSearchResponse,
  TSearchDelegateRequest,
  IAssignDelegate,
  IDelegateNotificationResponse,
  IDelegateNotification,
  IDelegateQueryParams,
  IDelegateInterfaceResponse,
} from "./../../types/preferences/delegate";
import { appApi } from "store/apis/apiSlice";
import {
  TDelegateUpdateData,
  TDelegateResponse,
} from "../../types/preferences/delegate";
import {
  TDelegateParam,
  IDelegateCommunication,
} from "../../types/preferences/delegate";
import { IDelegateList } from "./profiles/profileApi";
// import { IDelegateList } from './profiles/profileApi';

const callDelegateApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["Delegates"],
});

export const callDelegateApiSlice = callDelegateApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    // fetch delegate list for the user
    fetchDelegateList: builder.query<
      IDelegateInterfaceResponse,
      IDelegateQueryParams
    >({
      query: ({ page, pageSize, isDelegateViewing }) => ({
        url: isDelegateViewing
          ? `/delegate-list?page=${page}&pageSize=1000&token=${isDelegateViewing}`
          : `/delegate?page=${page}&pageSize=1000`,
      }),

      providesTags: ["Delegates"],
    }),
    //fetch delegate communication data
    fetchDelegateCommunication: builder.query<IDelegateCommunication, number>({
      query: (delegateId) => ({
        url: `/communication/${delegateId}`,
      }),
      providesTags: ["Delegates"],
    }),
    // update delegate data
    updateDelegateData: builder.mutation<
      TDelegateResponse,
      TDelegateUpdateData
    >({
      query: ({ bodyData, delegateId }) => {
        return {
          url: `/delegate/${delegateId}`,
          method: "PUT",
          body: bodyData,
        };
      },
      invalidatesTags: ["Delegates"],
    }),
    // update delegate status
    updateDelegateStatus: builder.mutation<void, TDelegateParam>({
      query: ({ bodyData, delegateId }) => {
        return {
          url: `/delegate-status/${delegateId}`,
          method: "PUT",
          body: bodyData,
        };
      },
      invalidatesTags: ["Delegates"],
    }),
    // delete delegate status
    deleteDelegateUser: builder.mutation<void, TDelegateParam>({
      query: ({ delegateId }) => {
        return {
          url: `/delegate/${delegateId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Delegates"],
    }),
    // delete delegate status
    addDelegateStatus: builder.mutation<void, TDelegateParam>({
      query: ({ bodyData, delegateId }) => {
        return {
          url: `/delegate/${delegateId}`,
          method: "POST",
          body: bodyData,
        };
      },
    }),

    //save the delegate
    saveDelegateData: builder.mutation<
      {
        message: string;
      },
      { name: string }
    >({
      query: ({ name }) => {
        return {
          url: "delegate",
          method: "POST",
          body: { name },
        };
      },
      invalidatesTags: ["Delegates"],
    }),

    /**
     * get the suggested delegate list
     */
    getSuggestedDelegateUsers: builder.mutation<TDelegateSearchResponse, TSearchDelegateRequest>({
      query: ({ name }) => {
        return {
          url: "get-suggested-delegate-users",
          params: { name },
        };
      },
    }),

    /**
     * assign the delegate to the user
     */
    assignDelegateUser: builder.mutation<void, IAssignDelegate>({
      query: ({ userId }) => {
        return {
          url: "assign-delegate",
          method: "POST",
          body: { userId },
        };
      },
      invalidatesTags: ["Delegates"],
    }),

    /**
     * for creating the notification link
     */
    createNotificationLink: builder.mutation<
      IDelegateNotificationResponse,
      IDelegateNotification
    >({
      query: ({ email, data }) => {
        return {
          url: "notification",
          method: "POST",
          body: {
            param: [email, data],
          },
        };
      },

      transformErrorResponse: (res: unknown) => {
        return (res as { data: { message: string; status: boolean } }).data;
        // return error.data;
      },
    }),
  }),
});

export const {
  useFetchDelegateListQuery,
  useFetchDelegateCommunicationQuery,
  useUpdateDelegateStatusMutation,
  useUpdateDelegateDataMutation,
  useDeleteDelegateUserMutation,
  useAddDelegateStatusMutation,
  useSaveDelegateDataMutation,
  useGetSuggestedDelegateUsersMutation,
  useAssignDelegateUserMutation,
  useCreateNotificationLinkMutation,
} = callDelegateApiSlice;
