import AvatarInfo from "components/avatar/AvatarInfo";
import { TGlobalHealth } from "types/dashboard";
import { AvatarGrade, AvatarType } from "../components/AvatarHealth";

const GlobalHealth = ({
  avatarActivities,
  avatarPerformance,
  type,
  isGlobalAvatar,
  delegateData,
}: TGlobalHealth) => {
  let healthData = type == "activity" ? avatarActivities : avatarPerformance;
  let healthPercentage = healthData?.average;
  let grade = AvatarGrade(healthPercentage ?? 0);
  let gradeType = AvatarType(healthPercentage ?? 0);

  return (
    <>
      <div className={`flex flex-row items-center justify-between my-2 `}>
        <AvatarInfo
          percentage={healthData?.average}
          avatarWidth={60}
          grow={"flex-grow"}
          gradeType={gradeType}
          grade={grade}
          isGlobalAvatar={isGlobalAvatar}
          delegateData={delegateData}
        />
        <div className="flex-grow">
          <button className="activity-btn">
            {healthData?.count}
            {type == "performance"
              ? ` Dial${healthData?.count > 1 ? "s" : ""}`
              : ` ${healthData?.count > 1 ? "Activities" : "Activity"}`}
          </button>
        </div>
      </div>
    </>
  );
};

export default GlobalHealth;
