import { ReactElement } from "react";
import { Popover, Whisper } from "rsuite";

export const PopOverModal = ({
  title,
  children,
  popOverChildren,
  onCloseHandler,
}: {
  title: string;
  children: ReactElement;
  popOverChildren: ReactElement;
  onCloseHandler: (_e: HTMLElement) => void;
}) => {
  return (
    <Whisper
      onExiting={onCloseHandler}
      defaultOpen={false}
      preventOverflow={true}
      trigger="click"
      placement={"left"}
      controlId={`control-id-left`}
      speaker={<Popover title={title}>{popOverChildren}</Popover>}
      children={children}
    />
  );
};
