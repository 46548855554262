import {
  Drawer,
  Button,
  Placeholder,
  Form,
  Toggle,
  RadioGroup,
  Input,
  Checkbox,
  CheckboxGroup,
  Radio,
  InputPicker,
  TagPicker,
  InputGroup,
} from "rsuite";
import React from "react";

import { useParams, useNavigate } from "react-router-dom";
import { ACIVITYSTATUSES } from "sections/dashboard/constants";
import {
  useDashboardFetchDelegateListQuery,
  useFetchActivityDetailsQuery,
} from "store/dashboard/DashboardSlice";
import { PredefinedRanges } from "sections/dashboard/constants";
import DateRangePicker from "rsuite/DateRangePicker";
import { useState, useEffect } from "react";
import {
  ActivityFormatData,
  UpdateActivityData,
  ScaleSign,
  SeasonalGoalSign,
} from "sections/dashboard/components/activity/ActivityData";

const Textarea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" />
));

const EditActivity = () => {
  const navigate = useNavigate();
  let { level, id } = useParams();

  const { data: delegatesData, isLoading: isDelegateLoading } =
    useDashboardFetchDelegateListQuery();
  const { data: activityData, isLoading: isActivityDetailsLoading } =
    useFetchActivityDetailsQuery({ level, id });

  const [formValue, setFormValue] = useState(ActivityFormatData(activityData));
  useEffect(() => {
    setFormValue(ActivityFormatData(activityData));
  }, [activityData]);

  return (
    <>
      <Drawer
        open={id ? true : false}
        size="lg"
        onClose={() => navigate("/dashboard")}
      >
        <Drawer.Header>
          <Drawer.Title>
            Edit <span className="bold">{formValue?.title}</span> Activity
          </Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => navigate("/dashboard")}>Cancel</Button>
            <Button appearance="primary">Save</Button>
            <Button appearance="primary">Save & Close</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {isActivityDetailsLoading && <Placeholder.Paragraph />}
          <Form
            formValue={formValue}
            onChange={(formValue, event) => {
              event?.target &&
                setFormValue(UpdateActivityData(formValue, event));
            }}
          >
            <div className="grid grid-cols-2 gap-6">
              <Form.Group controlId="name-1">
                <Form.ControlLabel>Title</Form.ControlLabel>
                <Form.Control name="title" placeholder="Level 2" />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="inputPicker">
                <Form.ControlLabel>Status:</Form.ControlLabel>
                <Form.Control
                  name="status"
                  accepter={InputPicker}
                  data={ACIVITYSTATUSES}
                />
              </Form.Group>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Group controlId="delegates">
                <Form.ControlLabel>Delegate To:</Form.ControlLabel>
                <Form.Control
                  name="delegates"
                  accepter={TagPicker}
                  data={delegateData() ?? []}
                  isLoading={isDelegateLoading}
                />
              </Form.Group>
              <Form.Group controlId="email-1">
                <Form.ControlLabel>Date Assign / Due *</Form.ControlLabel>
                <Form.Control
                  name="assignDueDate"
                  accepter={DateRangePicker}
                  data={PredefinedRanges}
                  placement="auto"
                />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
            </div>
            <div className="grid grid-cols-2 gap-6">
              <Form.Group controlId="name-1">
                <Form.ControlLabel>Link</Form.ControlLabel>
                <Form.Control name="name" />
                <Form.HelpText tooltip>Required</Form.HelpText>
              </Form.Group>
              <Form.Group controlId="tagPicker">
                <Form.ControlLabel>Qualifier:</Form.ControlLabel>
                <Form.Control
                  name="tagPicker"
                  accepter={TagPicker}
                  data={delegateData() ?? []}
                  isLoading={isDelegateLoading}
                />
              </Form.Group>
            </div>
            <Form.Group controlId="checkbox">
              <div className="grid grid-cols-1 gap-6   w-[12 justify-center flex-wrap gap-5 bg-slate-200 p-5 mx-auto rounded-xl">
                <Form.Control name="checkbox" accepter={CheckboxGroup} inline>
                  <Checkbox value="showOnDashboard">Performance Dial</Checkbox>
                  <Checkbox value="includeInReporting">Tracking Data</Checkbox>
                  <Checkbox value="includeInAvatar">Activity</Checkbox>
                  <Checkbox value="includeInProfile">
                    Include this level in Avatar
                  </Checkbox>
                </Form.Control>
              </div>
            </Form.Group>
            <div className="grid grid-cols-7 gap-6">
              <Form.Group controlId="Tracking">
                <Form.ControlLabel>Tracking:</Form.ControlLabel>
              </Form.Group>
              <Form.Group controlId="Number">
                <Form.Control
                  name="number"
                  accepter={Toggle}
                  checked={formValue?.number}
                ></Form.Control>
                <Form.ControlLabel>Number</Form.ControlLabel>
              </Form.Group>
              <Form.Group controlId="Doller">
                <Form.Control
                  name="doller"
                  accepter={Toggle}
                  checked={formValue?.doller}
                ></Form.Control>
                <Form.ControlLabel>Doller</Form.ControlLabel>
              </Form.Group>
              <Form.Group controlId="Percentage">
                <Form.Control
                  name="percentage"
                  accepter={Toggle}
                  checked={formValue?.percentage}
                ></Form.Control>
                <Form.ControlLabel>Percentage</Form.ControlLabel>
              </Form.Group>
              <Form.Group controlId="Scale">
                <Form.Control
                  name="scale"
                  accepter={Toggle}
                  checked={formValue?.scale}
                ></Form.Control>
                <Form.ControlLabel>Success Scale</Form.ControlLabel>
              </Form.Group>
              <Form.Group controlId="Seasonal">
                <Form.Control
                  name="seasonal"
                  accepter={Toggle}
                  checked={formValue?.seasonal}
                ></Form.Control>
                <Form.ControlLabel>Seasonal Goal</Form.ControlLabel>
              </Form.Group>
              <Form.Group controlId="Accumulate">
                <Form.Control
                  name="accumulate"
                  accepter={Toggle}
                  checked={formValue?.accumulate}
                ></Form.Control>
                <Form.ControlLabel>Do Not Accumulate</Form.ControlLabel>
              </Form.Group>
            </div>

            {formValue?.trackSign &&
              formValue?.trackSign != ScaleSign &&
              formValue?.trackSign != SeasonalGoalSign && (
                <div className="grid-cols-6 gap-1 flex w-full justify-between bg-slate-200 p-5 mx-auto rounded-xl  flex-wrap">
                  <Form.Group controlId="Daily">
                    <Form.ControlLabel>Daily</Form.ControlLabel>
                    <Form.Control name="daily" accepter={InputGroup} disabled>
                      <InputGroup.Addon>
                        {formValue?.trackSign}
                      </InputGroup.Addon>
                      <Form.Control
                        name="daily"
                        accepter={Input}
                      ></Form.Control>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="weekly">
                    <Form.ControlLabel>Weekly</Form.ControlLabel>
                    <Form.Control name="weekly" accepter={InputGroup} disabled>
                      <InputGroup.Addon>
                        {formValue?.trackSign}
                      </InputGroup.Addon>
                      <Form.Control
                        name="weekly"
                        accepter={Input}
                      ></Form.Control>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="Monthly">
                    <Form.ControlLabel>Monthly</Form.ControlLabel>
                    <Form.Control name="" accepter={InputGroup} disabled>
                      <InputGroup.Addon>
                        {" "}
                        {formValue?.trackSign}
                      </InputGroup.Addon>
                      <Form.Control
                        name="monthly"
                        accepter={Input}
                      ></Form.Control>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="Quarterly">
                    <Form.ControlLabel>Quarterly</Form.ControlLabel>
                    <Form.Control
                      name="quarterly"
                      accepter={InputGroup}
                      disabled
                    >
                      <InputGroup.Addon>
                        {formValue?.trackSign}
                      </InputGroup.Addon>
                      <Form.Control
                        name="quarterly"
                        accepter={Input}
                      ></Form.Control>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="Annually">
                    <Form.ControlLabel>Annually</Form.ControlLabel>
                    <Form.Control
                      name="annually"
                      accepter={InputGroup}
                      disabled
                    >
                      <InputGroup.Addon>
                        {formValue?.trackSign}
                      </InputGroup.Addon>
                      <Form.Control
                        name="annually"
                        accepter={Input}
                      ></Form.Control>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="Goal">
                    <Form.ControlLabel>Goal</Form.ControlLabel>
                    <Form.Control name="goalValue" accepter={InputGroup}>
                      <InputGroup.Addon>
                        {formValue?.trackSign}
                      </InputGroup.Addon>
                      <Form.Control name="goal" accepter={Input}></Form.Control>
                    </Form.Control>
                  </Form.Group>
                </div>
              )}
            {formValue?.trackSign == ScaleSign && (
              <div className="grid grid-cols-6 gap-1">
                <RadioGroup
                  inline
                  name="successScale"
                  value={formValue?.successScale}
                >
                  {renderSuccessScale()}
                </RadioGroup>
              </div>
            )}
            {formValue?.trackSign == SeasonalGoalSign && (
              <div className="grid grid-cols-6 gap-1">
                {renderSeasonalGoal()}
              </div>
            )}
          </Form>
        </Drawer.Body>
      </Drawer>
    </>
  );

  function renderSeasonalGoal() {
    let seadsonalGoal = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return seadsonalGoal?.map((option) => {
      return (
        <Form.Group controlId="SasonalSign">
          <Form.ControlLabel>{option}</Form.ControlLabel>
          <Form.Control name={option} accepter={InputGroup}>
            <InputGroup.Addon>{formValue?.seasonalSign}</InputGroup.Addon>
            <Form.Control
              name={option}
              accepter={Input}
              value={formValue?.seasonalGoal[option]}
              id={option}
            ></Form.Control>
          </Form.Control>
        </Form.Group>
      );
    });
  }

  function renderSuccessScale() {
    let successScale = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return successScale?.map((option) => {
      return (
        <Form.Group controlId="successScale">
          <Form.Control name="successScale" accepter={RadioGroup}>
            <Form.ControlLabel>{option}</Form.ControlLabel>
            <Form.Control
              name="successScale"
              accepter={Radio}
              value={option}
            ></Form.Control>
          </Form.Control>
        </Form.Group>
      );
    });
  }

  /**
   * render delegates data
   *
   * @returns object
   */
  function delegateData() {
    return delegatesData?.data?.map((option) => {
      return { value: option?.user?.id, label: option?.user?.name };
    });
  }

  function findActivityData() {
    // activityList?.data?.activityArray?.filter( list => list?.data?.filter( list => list?.id == id && list?.type == level) )
  }
};

export default EditActivity;
