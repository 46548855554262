export const TrackingDataStatus = [
    {
        value: 0,
        label: "Pending" 
    },
    {
        value: 1,
        label: "Progress" 
    },
    {
        value: 2,
        label: "Completed" 
    }
]

export const TrackingDataLevel  = [{
    'Kpi' : "level1"
}]

export const DEFAULTFILTER = {
    vMap:[],
    value:[],
    kpi:[],
    activity:[],
    delegate:[],
    period:"",
    status: 1
}
