type prop = {additionalClassName ?: string}
const Loader = ({additionalClassName}:prop) => {
  return (
    <div
      role="alert"
      data-tesid="loader"
      aria-busy="true"
      className={"loader w-full h-full flex justify-center flex-col items-center absolute top-0 left-0 bg-white z-[300] "+additionalClassName}
    >
      <div className="loader-logo">
        <img src="/assets/logo-main.png" alt="loader-logo-img" />
      </div>
      <div className="dots flex justify-center items mt-4 gap-6">
        <div className="dot w-4 h-4 bg-button-blue  rounded-full animate-ping delay-200"></div>
        <div className="dot w-4 h-4 bg-button-blue  rounded-full  animate-ping delay-150"></div>
        <div className="dot w-4 h-4 bg-button-blue  rounded-full animate-ping delay-100"></div>
        <div className="dot w-4 h-4 bg-button-blue  rounded-full  animate-ping delay-75"></div>
      </div>
    </div>
  );
};

export default Loader;
