import { Dispatch, SetStateAction, useRef } from "react";
import { debounce } from "lodash";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Input, InputGroup } from "rsuite";
import { FlattenedItem } from "../../types";

type Props = {
  setSearch: Dispatch<SetStateAction<string>>;
  flattenedItems: FlattenedItem[];
  setSelectedIndex: Dispatch<SetStateAction<number>>;
  selectedIndex: number;
};

const SearchField = ({
  setSearch,
  flattenedItems,
  setSelectedIndex,
  selectedIndex,
}: Props) => {
  const searchCount = useRef(0);
  const searchResultsIndexes: { current: number[] } = useRef([]);
  const moveToSearchResult = (currentIndex: number, prev: boolean) => {
    let selectedResultIndex =
      searchResultsIndexes.current.indexOf(currentIndex);
    if (
      !isNaN(selectedResultIndex) &&
      searchResultsIndexes.current[selectedResultIndex + 1] &&
      !prev
    ) {
      setSelectedIndex(searchResultsIndexes.current[selectedResultIndex + 1]);
      searchCount.current += 1;
    }
    if (
      !isNaN(selectedResultIndex) &&
      searchResultsIndexes.current[selectedResultIndex - 1] &&
      prev
    ) {
      setSelectedIndex(searchResultsIndexes.current[selectedResultIndex - 1]);
      searchCount.current -= 1;
    }
  };
  return (
    <div className="flex items-center">
      <label className="pr-5 font-semibold">Search</label>
      <InputGroup inside className="lg:min-w-[400px]">
        <Input
          placeholder="Search Levels"
          name="vmap-search-field"
          data-testid="vmap-search-field"
          onChange={debounce((value: string) => {
            setSearch(value);
            if (value.length === 0 || !value) {
              searchCount.current = 0;
              return;
            }

            let filteredItems = flattenedItems
              .filter((item, index) => {
                if (item?.title?.toLowerCase()?.match(value?.toLowerCase())) {
                  /**this is just for searching the element */
                  item.searchIndex = index;
                  return true;
                }
                return false;
              })
              .map((item) => item.searchIndex);
            if (filteredItems.includes(undefined)) return;
            searchResultsIndexes.current = filteredItems.filter(
              (item): item is number => typeof item === "number"
            );
            if (filteredItems[0]) {
              setSelectedIndex(filteredItems[0]);
              searchCount.current = 1;
            }
          }, 100)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              moveToSearchResult(selectedIndex, false);
            }
          }}
        />
      </InputGroup>
      <button
        className="border border-solid border-gray-200 rounded mr-1 ml-5 p-2"
        onClick={() => moveToSearchResult(selectedIndex, true)}
        data-testid="search-prev"
      >
        <ChevronLeftIcon width={15} height={15} className="text-gray-400" />
      </button>
      <button
        onClick={() => moveToSearchResult(selectedIndex, false)}
        className="border border-solid border-gray-200 rounded ml-1 mr-5 p-2"
        data-testid="search-next"
      >
        <ChevronRightIcon width={15} height={15} className="text-gray-400" />
      </button>
      <span>
        {searchCount.current}/
        {searchCount.current
          ? searchResultsIndexes?.current?.length
          : flattenedItems.length}
      </span>
    </div>
  );
};

export default SearchField;
