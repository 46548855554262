import { useEffect, useRef, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { SelectPicker, SelectPickerProps } from "rsuite";
import { ErrorMessage } from "@hookform/error-message";
export interface IRHFSelect extends SelectPickerProps<any> {
  name: string;
  block: boolean;
}

export default function RHFSelect({
  name,
  data,
  defaultValue,
  ...rest
}: IRHFSelect) {
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref.current]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <SelectPicker
            data={data}
            {...rest}
            placeholder={rest.placeholder}
            label={rest.label}
            searchable={rest.searchable}
            block
            value={value ?? defaultValue}
            onChange={onChange}
            className={rest.className}
            name={name}
            // defaultValue={defaultValue ? defaultValue : getValues(name)}
            placement={"autoVerticalStart"}
          />

          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span ref={ref} className="text-red-500">
                {message}
              </span>
            )}
          />
        </>
      )}
    />
  );
}
