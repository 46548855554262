const NotFound = ({message}:{message?:string}) => {
    return (
      <div className="section-pageNotFound flex relative h-screen w-full flex-col md:flex-row ">
      <main className="pageNotFound-main min-h-screen bg-no-repeat w-full bg-cover	flex items-center justify-center pt-5">
        <section className=" w-full max-w-[430px] self-center flex justify-center items-center">
            <div className=" flex justify-between items-center py-4 flex-col gap-6 h-1/2">
              <img
                src="/assets/logo-main.png"
                alt="Pro-Advisor-logo"
              />
              {message?null:<h2 className="pageNotFoundHeading font-bold text-[180px] pt-10 pb-10">404
              </h2>}
              <p className="pageNotFound text-primary-blue font-bold text-center">
                  {message??'Sorry, the page you are looking for could not be found.'}
              </p>
              <p className="text-center text-slate-700">
                  <a href="/auth/login" className="border min-w-[100px] rounded-full bg-secondary-blue px-5 py-2  md-mt-0 font-bold text-md text-white flex cursor-pointer hover:bg-white hover:text-secondary-blue  border-secondary-blue transition-all ease-in-out duration-500 mx-auto"> Back to Home</a>
              </p>
            </div>
          
        </section>
      </main>
      <div className="forgotpass-image bg-login-background min-h-screen bg-no-repeat w-full bg-cover bg-center hidden	md:flex items-center justify-end ">
        <div className="overlay-timer min-h-screen bg-no-repeat w-full text-center  justify-center items-center cursor-pointer transition-all delay-100  ease-out  bg-[#007dc596]  text-white py-[30px] flex flex-col gap-[10px] text-[22px]">
          <p>KNOW IF YOU'RE WINNING IN 10 SECONDS OR LESS</p>
          <p>Turning Potential into Maximized Results</p>
        </div>
      </div>
    </div>
    );
  };
  
  export default NotFound;