import { useFormContext, Controller } from "react-hook-form";
import { DateRangePicker, DateRangePickerProps } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth, startOfQuarter } from "date-fns";
import { endOfQuarter } from "date-fns";
import { useGetUserQuery } from "store/auth/authApi";
import moment from "moment";

export type DateRangePlacement =
  | "bottomStart"
  | "bottomEnd"
  | "topStart"
  | "topEnd"
  | "leftStart"
  | "leftEnd"
  | "rightStart"
  | "rightEnd"
  | "auto"
  | "autoVerticalStart"
  | "autoVerticalEnd"
  | "autoHorizontalStart"
  | "autoHorizontalEnd";

interface IRHFDateRangePicker extends DateRangePickerProps {
  name: string;
  label: string;
  defaultRange?: Date[] | string[];
  showPredefinedRanges?: boolean;
  datePickerPlacement?: DateRangePlacement;
  disabled?: boolean;
}

// TODO repeating dates you can

export const predefinedRangesGraph = [
  {
    label: "Since 30 Days",
    value: [subDays(new Date(), 30), new Date()],
    placement: "left",
  },
  {
    label: "Since 90 Days",
    value: [subDays(new Date(), 90), new Date()],
    placement: "left",
  },
];

export const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: "left",
  },
  {
    label: "This year",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    placement: "left",
  },
  {
    label: "3 year",
    value: [
      new Date(new Date().getFullYear() - 3, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "This Quarter",
    value: [startOfQuarter(new Date()), endOfQuarter(new Date())],
    placement: "left",
  },
  {
    label: "Since 30 Days",
    value: [subDays(new Date(), 30), new Date()],
    placement: "left",
  },
  {
    label: "Since 60 Days",
    value: [subDays(new Date(), 60), new Date()],
    placement: "left",
  },
  {
    label: "Since 90 Days",
    value: [subDays(new Date(), 90), new Date()],
    placement: "left",
  },
];

//TODO: types checking
//@ts-ignore
export default function RFHDateRangePicker({
  name,
  defaultRange,
  showPredefinedRanges,
  datePickerPlacement,
  disabled,
}: IRHFDateRangePicker) {
  const { data: user } = useGetUserQuery();
  const { control, getValues } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <DateRangePicker
          format={"MM/dd/yyyy"}
          onChange={onChange}
          size={"md"}
          showOneCalendar
          placement={datePickerPlacement}
          //TODO: needs to be worked on
          //@ts-ignore
          ranges={[]}
          {...(showPredefinedRanges && {
            ranges: showPredefinedRanges
              ? predefinedRanges
              : user
              ? [
                  {
                    label: "Default range",
                    value: [
                      new Date(
                        moment(
                          user?.profile?.rangeStartDate ?? new Date()
                        ).format("MM-dd-yyyy")
                      ),
                      new Date(
                        moment(
                          user?.profile?.rangeEndDate ?? new Date()
                        ).format("MM-dd-yyyy")
                      ),
                    ],
                    placement: "down",
                  },
                ]
              : [],
          })}
          block
          disabled={disabled}
          defaultValue={
            defaultRange
              ? (defaultRange as unknown as [Date, Date])
              : getValues(name)
          }
          cleanable={false}
        />
      )}
    />
  );
}
