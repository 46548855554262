import { TextArea } from "components/input/TextArea";
import { useFormContext } from "react-hook-form";
import { TEXT_AREA_LIMIT_CALL_MAXIMIZER } from "utils/constants";

export type TReflectionQuestions = {
  callMaximizerId: number;
  isSubQuestion: boolean;
  subQuestions: {
    subQuestion: string;
    longComment: string;
  }[];
  mainQuestion: string;
  longComment: string;
  updated: number;
};

type Props = {
  reflectionQuestions: TReflectionQuestions[];
};

const ReflectionSection = ({ reflectionQuestions }: Props) => {
  const { register, getValues } = useFormContext();
  const totalQuestions = reflectionQuestions.slice(
    0,
    getValues("ReflectionsQuestionsLength")
  );

  return (
    <>
      {totalQuestions.map((question, index) => (
        <div
          key={index}
          className="pair-item form-field-label w-full lg:w-1/2 max-w-full px-2 py-2 md:px-10 md:py-3 flex-0"
        >
          <label
            htmlFor={question.mainQuestion}
            className="mb-2 ml-1 text-sm font-bold text-slate-700 "
          >
            {question.mainQuestion}
          </label>
          {question.isSubQuestion ? (
            question.subQuestions.map((subQuestion, subIndex) => {
              return (
                <div className="px-2 py-2 md:px-10 md:py-3" key={subIndex}>
                  <label
                    htmlFor={subQuestion.subQuestion}
                    className="mb-2 ml-1 text-sm font-bold text-slate-700 "
                  >
                    {subQuestion.subQuestion}
                  </label>
                  <TextArea
                    defaultValue={subQuestion.longComment}
                    cols={20}
                    maxLength={TEXT_AREA_LIMIT_CALL_MAXIMIZER}
                    rows={3}
                    {...register(
                      `Reflections.${
                        totalQuestions.length + subIndex
                      }.longComment`
                    )}
                    placeholder="Please Enter Text"
                    className="focus:shadow-primary-outline mt-5 text-sm leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none !resize"
                  />
                </div>
              );
            })
          ) : (
            <TextArea
              defaultValue={question.longComment}
              {...register(`Reflections.${index}.longComment`)}
              cols={20}
              rows={3}
              maxLength={TEXT_AREA_LIMIT_CALL_MAXIMIZER}
              placeholder="Please Enter Text"
              className="focus:shadow-primary-outline text-sm mt-5 leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none !resize"
            />
          )}{" "}
        </div>
      ))}
    </>
  );
};

export default ReflectionSection;
