import { useEffect, useState } from "react";
import { SideBarCard } from "components/cards";
import { FormProvider, RHFSelect } from "components/hook-form";
import Loader from "pages/frontend-sandbox/loader";
import { useForm } from "react-hook-form";

import {
  COACHING_TIMES,
  getCallMaximizerSideBardLinks,
} from "sections/call-maximizer/callMaximizerFields";
import CallMaximizerFilterHeader from "./CallMaximizerFilterHeader";
import {
  ICallMaximizerDateListResponse,
  useAddNewCallMaximizerMutation,
  useCallMaximizerDataQuery,
  useCallMaximizerDateListQuery,
  useDeleteActivityMutation,
  useDeleteCallMaximizerMutation,
  useFetchOverDueListQuery,
  useNextCoachingSessionMutation,
  useUpdateCallMaximizerDataMutation,
  useUpdateOverDueListItemsMutation,
} from "store/call-maximizer/callMaximizerApiSlice";
import ReflectionSection from "sections/call-maximizer";
import TabularSectionCallMaximizer from "sections/call-maximizer/TabularSectionCallMaximizer";
import AssignmentSection from "sections/call-maximizer/AssignmentSection";
import { useLocation } from "react-router-dom";
import { Button } from "components/button/Button";
import RFHDatePicker from "components/hook-form/RHFDatePicker";
import { format } from "date-fns";
import CustomModal from "components/modals/CustomModal";
import { TIMEZONES } from "sections/tools/my-profile/constants";
import { toastError, toastSuccess } from "components/toasts";
import { Timeline, useToaster } from "rsuite";
import moment from "moment";

// import { DevTool } from "@hookform/devtools";

const CallMaximizerPage = () => {
  const [coachCallId, setCoachCallId] =
    useState<ICallMaximizerDateListResponse | null>(null);

  //TODO try to make a single state to open multiple modals, either use useReducer or an object
  const [showModal, setShowModal] = useState(false);
  const [showNextSessionModal, setNextSessionModal] = useState(false);
  const [showOverDueModal, setShowOverDueModal] = useState(false);

  //-------------ALL QUERIES AND MUTATIONS---------------------------
  const [addNewCallMaximizer] = useAddNewCallMaximizerMutation();

  const { data: callMaximizerDateList, isLoading } =
    useCallMaximizerDateListQuery();

  const { data: callMaximizerData } = useCallMaximizerDataQuery(
    coachCallId?.coachCallId,
    {
      skip: !coachCallId,
    }
  );

  const [deleteCallMaximizerQuery] = useDeleteCallMaximizerMutation();

  const [updateCallMaximizer, { isLoading: isUpdateLoading }] =
    useUpdateCallMaximizerDataMutation();

  const [updateNextCallSession] = useNextCoachingSessionMutation();
  const { data: overDueList } = useFetchOverDueListQuery();
  const [updateOverDueListItem] = useUpdateOverDueListItemsMutation();
  const [deleteActivity] = useDeleteActivityMutation();
  //----------------3rd party functions-----------------------
  const toast = useToaster();
  const methods = useForm();

  const {
    handleSubmit,
    getValues,
    // formState: { errors, dirtyFields },
    reset,
  } = methods;
  const { hash } = useLocation();
  let currentSection = decodeURI(hash.split("#")[1]);

  //------EFFECTS-----
  useEffect(() => {
    reset({
      ...callMaximizerData,
      nextCallDate: coachCallId?.nextCallDate,
      scheduleTime: coachCallId?.nextCallTimeId,
      timezone: coachCallId?.nextTimeZoneId,
    });
  }, [callMaximizerData]);
  useEffect(() => {
    if (callMaximizerDateList) {
      setCoachCallId(callMaximizerDateList[0]);
    }
  }, [callMaximizerDateList]);

  if (isLoading) {
    return <Loader />;
  }

  /**
   * Next coaching session Update
   */

  /**
   * Add CallMaximizer
   * @param data
   */
  const handleAddNewCallMaximizer = async () => {
    try {
      const newCallMax = getValues("newDate");
      const previousCallMaximizerDate = callMaximizerDateList
        ? callMaximizerDateList[0]?.callDate
        : "";

      if (newCallMax) {
        await addNewCallMaximizer({
          newCallMax,
          previousCallMaximizerDate,
        }).then((res) => {});

        toast.push(
          toastSuccess({ message: "Added Call Maximizer Successfully" })
        );
        setShowModal(false);
      }
    } catch (error) {
      toast.push(
        toastError({
          message: "Error while adding Call Maximizer Please try again",
        })
      );
    }
  };

  /**
   *
   * @param data
   */
  const handleNextCoachingSession = async () => {
    const values = getValues(["timezone", "nextCallDate", "scheduleTime"]);
    try {
      if (coachCallId) {
        await updateNextCallSession({
          timezone: values[0],
          nextCallDate: moment(values[1]).format("YYYY-MM-DD"),
          scheduleTime: values[2],
          coachCallId: coachCallId?.coachCallId,
        }).unwrap;

        toast.push(toastSuccess({ message: "Next coaching Updated session" }));
        setNextSessionModal(false);
      }
    } catch (error) {
      toast.push(
        toastError({ message: "Error Updating Next coaching session" })
      );
    }
  };

  //-------------UPDATE CALLMAXIMIZER-------------------
  //@ts-ignore
  const submitCallMaximizer = async (data) => {
    //@ts-ignore
    let newData = [];
    //TODO try to send updated fields only
    for (let i of Object.keys(data)) {
      if (data[i] && Array.isArray(data[i])) {
        const dataWithCommentAttribute = data[i]?.filter((item: any) => {
          if (item?.longComment && item.callMaximizerId) {
            if (item.motivationLevel || item.satisfactionLevel) {
              item.longComment = null;
            }
            return true;
          }
          return false;
        });
        //@ts-ignore
        newData = [...newData, ...dataWithCommentAttribute];
      }
    }

    try {
      if (coachCallId) {
        //@ts-ignore
        let res = await updateCallMaximizer({
          coachCallId: coachCallId.coachCallId,
          //@ts-ignore
          data: newData,
        }).unwrap();
        if (res) {
          toast.push(
            toastSuccess({ message: "Call Maximizer Updated Successfully" })
          );
        }
      }
    } catch (error) {
      toast.push(toastError({ message: "Error updating callmaximizer" }));
    }
  };

  // ----------DELETE CALLMAXIMIZER-----------------------
  const deleteCallMaximizerHandler = async () => {
    try {
      const confirmDelete = window.confirm(
        "Do you really want to delete this Call Maximizer ?"
      );
      if (confirmDelete && coachCallId) {
        await deleteCallMaximizerQuery(coachCallId.coachCallId);
        toast.push(
          toastSuccess({ message: "Call Maximizer Deleted successfully" })
        );
      }
    } catch (error) {
      toast.push(toastError({ message: "Error Deleting Call Maximizer" }));
    }
  };

  //--------------UPDATE OVERDUE LIST ITEM-----------
  const handleUpdateOverdueListItem = async (callMaximizerDataId: number) => {
    try {
      await updateOverDueListItem(callMaximizerDataId);
      await toast.push(
        toastSuccess({ message: "Assignment updated successfully" })
      );
    } catch (error) {
      toast.push(
        toastError({
          message: "Error while adding Call Maximizer Please try again",
        })
      );
    }
  };

  /**
   * this function is called in JSX section
   * @returns Sections as per current #section in the url
   */
  const getCurrentSection = () => {
    let index = 0;
    //TODO make it more predictable rather than comparing it to undefined
    if (currentSection === "undefined") {
      currentSection = "Reflections";
    }
    if (callMaximizerData) {
      index = Object.keys(callMaximizerData).findIndex(
        (item) => item === currentSection
      );
    }
    switch (currentSection) {
      case "Reflections":
        return (
          <ReflectionSection
            //@ts-ignore
            //TODO
            reflectionQuestions={callMaximizerData["Reflections"]}
            sectionName={currentSection}
          />
        );
      case "Assignments":
        return (
          <AssignmentSection
            //@ts-ignore
            //TODO
            questions={callMaximizerData["Assignments"]}
            deleteActivityHandler={deleteActivity}
          />
        );
      default:
        return (
          <TabularSectionCallMaximizer
            //@ts-ignore
            data={callMaximizerData[currentSection]}
            sectionName={currentSection}
            index={index}
          />
        );
    }
  };

  //--------------- JSX SECTION RETURN -----------------------------

  if (callMaximizerDateList && callMaximizerDateList.length >= 0) {
    return (
      <FormProvider
        onSubmit={handleSubmit(submitCallMaximizer)}
        methods={methods}
      >
        <div className="main-content-container main-profile-container  bg-slate-200 ">
          {coachCallId ? (
            <div className="profile-wrapper  flex flex-wrap lg:flex-nowrap  justify-between xl:gap-10 gap-5  p-3 mx-auto rounded-xl h-fit flex-col md:flex-row ">
              <SideBarCard sideBarLinks={getCallMaximizerSideBardLinks()} />
              <div className="tabs  w-full  xl:w-3/4  flex flex-col gap-4 ">
                {coachCallId && (
                  <CallMaximizerFilterHeader
                    callMaximizerDateList={callMaximizerDateList}
                    setCoachCallId={setCoachCallId}
                    coachCallId={coachCallId}
                    deleteCallMaximizer={deleteCallMaximizerHandler}
                    showModal={() => setShowModal(true)}
                    showNextSessionModal={() => setNextSessionModal(true)}
                    setShowOverDueModal={() => setShowOverDueModal(true)}
                  />
                )}

                <div className="profile-content-submit call-maximizer-content bg-white p-3  bottom-0 rounded-xl   top-24 ">
                  {callMaximizerData && getCurrentSection()}
                  <div className="single-user-detail flex justify-center items-center text-white   z-50  rounded-xl p-0 h-fit  mt-4">
                    <Button
                      additionalClass=" submit-primary m-1  w-fit bg-primary-blue hover:bg-white hover:text-primary-blue border-[1px] border-primary-blue text-center text-white active:bg-blueGray-600  round-xl hover:cursor-pointer text-[14px] font-bold uppercase px-6 py-4 rounded shadow hover:shadow-lg outline-none focus:outline-none  ease-linear transition-all duration-500 ease-in-out"
                      type="submit"
                      title="Submit"
                      isLoading={isUpdateLoading}
                      loadingText="updating profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="profile-content-submit w-[50%] mx-auto bg-white p-5  rounded-xl flex flex-col gap-5">
                <h5>
                  System does not find any previous Call Maximizer. Please
                  create new Call Maximizer for details.
                </h5>
                <div>
                  <Button
                    title="New Call Maximizer"
                    additionalClass={"bg-primary-blue"}
                    handleClick={() => setShowModal(true)}
                  />
                </div>
              </div>
            </>
          )}
          {/* All the Modals Are Here */}
          {showModal && (
            <CustomModal
              title={"Create New Call Maximizer"}
              successBtnText={"Create New Call Maximizer"}
              successBtnHandler={handleAddNewCallMaximizer}
              cancelBtnHandler={() => setShowModal(false)}
              cancelBtnText={"Close"}
            >
              <RFHDatePicker name={"newDate"} format={"dd/MM/yyyy"} />
            </CustomModal>
          )}
          {showNextSessionModal && coachCallId && (
            <CustomModal
              title={"Next Coaching Schedule New Call Maximizer"}
              successBtnText={"Schedule Session"}
              successBtnHandler={handleNextCoachingSession}
              cancelBtnHandler={() => setNextSessionModal(false)}
              cancelBtnText={"Close"}
            >
              Next Coaching Date
              <RFHDatePicker
                name={"nextCallDate"}
                defaultValue={
                  new Date(
                    moment(coachCallId.nextCallDate).format("MM/DD/YYYY")
                  )
                }
              />
              Next Coaching Time
              <RHFSelect
                defaultValue={coachCallId.nextCallTimeId}
                data={COACHING_TIMES}
                block={true}
                name={"scheduleTime"}
              />
              Next Coaching TimeZone
              <RHFSelect
                defaultValue={coachCallId.nextTimeZoneId}
                data={Object.keys(TIMEZONES).map((item, index) => {
                  return {
                    value: item,
                    label: TIMEZONES[item],
                  };
                })}
                block
                name={"timezone"}
              />
            </CustomModal>
          )}
          {/* OVERDUELIST MODAL */}
          {showOverDueModal && coachCallId && overDueList && (
            <CustomModal
              title={"Overdue Assignments"}
              successBtnText={false}
              successBtnHandler={handleAddNewCallMaximizer}
              cancelBtnHandler={() => setShowOverDueModal(false)}
              cancelBtnText={"Close"}
            >
              <Timeline align={"left"}>
                {Object.keys(overDueList).map((item, itemIndex) => (
                  <Timeline.Item key={itemIndex}>
                    <p className="overdue-date">{item}</p>
                    {overDueList[item].map((overDue, index) => {
                      return (
                        <div key={index} className="overdue-checkbox-wrapper">
                          <input
                            type="checkbox"
                            className="overdue-checkboxes"
                            onChange={(e) => {
                              handleUpdateOverdueListItem(
                                overDue?.callMaximizerDataId
                              );
                              setShowOverDueModal(false);
                            }}
                          />
                          {overDue?.longComment}
                        </div>
                      );
                    })}
                  </Timeline.Item>
                ))}
              </Timeline>
            </CustomModal>
          )}
        </div>
      </FormProvider>
    );
  }
  return (
    <>
      <Loader />
    </>
  );
};

export default CallMaximizerPage;
