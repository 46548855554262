import { Drawer, Placeholder } from "rsuite";
import { RootState } from "../../../store/index";
import { useSelector, useDispatch } from "react-redux";
import { showTrackingData } from "store/dashboard";
import TrackingDataLink from "sections/tracking-data/Header";
import { useGetTrackingDataListQuery } from "store/notification/NotificationSlice";
import { useParams } from "react-router-dom";

export const TrackingData = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { trackingData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  let dataParam = {
    level: trackingData?.level,
    id: trackingData?.id,
    token: params?.key,
  };

  const { data: list, isFetching } = useGetTrackingDataListQuery(dataParam, {
    refetchOnMountOrArgChange: false,
    skip: !trackingData?.id,
  });

  const tracking = {
    id: 0,
    level: "",
  };

  return (
    <Drawer
      open={trackingData?.id ? true : false}
      size="lg"
      onClose={() => dispatch(showTrackingData(tracking))}
    >
      <Drawer.Header>
        <Drawer.Title className="!text-primary-blue !text-[24px] !font-bold">
          Tracking Data
        </Drawer.Title>
        <Drawer.Actions>
          <button
            onClick={() => dispatch(showTrackingData(tracking))}
            className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500 ml-2"
          >
            Close
          </button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body className="!pt-0">
        {isFetching ? <Placeholder.Paragraph /> : renderTrackingData()}
      </Drawer.Body>
    </Drawer>
  );

  function renderTrackingData() {
    return list?.data?.data?.map((tracking, index) => {
      return (
        <>
          <TrackingDataLink key={index} {...tracking} isFetching={isFetching} />
        </>
      );
    });
  }
};
