import { Message } from "rsuite";

type props = {
  message: string | JSX.Element;
};

export const toastError = ({ message }: props) => (
  <Message showIcon type={"error"}>
    {message}
  </Message>
);

export const toastSuccess = ({ message }: props) => (
  <Message showIcon type={"success"}>
    {message}
  </Message>
);
