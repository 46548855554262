import Chart from "react-apexcharts";

const BasicChartComponent = ({
  options,
  series,
  type,
  additionalClassName,
}: Chart["props"]) => {
  return (
    <div
      className={
        "profile-content-tab bg-white rounded-xl sm:py-3 h-fit mt-5 " +
        additionalClassName
      }
    >
      <Chart
        width={"100%"}
        height={"100%"}
        options={options}
        series={series}
        type={type}
      />
    </div>
  );
};

export default BasicChartComponent;
