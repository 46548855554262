import { SyntheticEvent, useState, forwardRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, DatePicker, Input, useToaster, Button } from "rsuite";

import { toastSuccess, toastError } from "components/toasts/index";
import { RootState } from "../../store/index";
import { addNewRowState } from "store/tracking-data";
import { TrackingDataSchema } from "./schema";
import {
  TTrackingPostValues,
  TPostDataRequest,
  ITrackingDataRows,
  ITrackingDataList,
} from "../../types/tracking-data/index";
import { format } from "date-fns";
import { useInsertTrackingDataMutation } from "store/tracking-data/trackingApi";
import {
  useFetchDashboardDataListMutation,
  useFetchTrackingDataListQuery,
} from "store/dashboard/DashboardSlice";
import moment from "moment";
import beforeUnload from "hooks/beforeUnload";
import { BookmarkSquareIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { useParams } from "react-router-dom";

const Textarea = forwardRef((props, ref) => (
  <Input {...props} as="textarea" rows={1} />
));

const AddRow = (props: any) => {
  const toaster = useToaster();
  const dispatch = useDispatch();

  const { filterData, trackingData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const [refetchDashboardData] = useFetchDashboardDataListMutation({
    fixedCacheKey: "shared-update-post",
  });

  const { addRow, addActivityData } = useSelector(
    (state: RootState) => state?.trackingDataSlice
  );
  const params = useParams();
  const { data: list } = useFetchTrackingDataListQuery(trackingData, {
    refetchOnMountOrArgChange: false,
    skip: params?.type == "masterId" || !trackingData?.id,
  });
  let filterTracking: ITrackingDataList = list?.data?.data?.values()?.next()
    ?.value;
  let trackingDataFirstRow: ITrackingDataRows = filterTracking?.data
    ?.values()
    ?.next()?.value;

  const [formValues, setFormValues] = useState<TTrackingPostValues | any>({
    beginningEndingDate: [
      new Date(moment(trackingDataFirstRow?.endingDate).format("MM/DD/YYYY")),
      new Date(),
    ],
  });
  const [formError, setFormError] = useState<TTrackingPostValues | any>({
    data: "",
  });
  const [changes, setChanges] = useState<boolean>(false);

  const [insertTrackingData, { isLoading }] = useInsertTrackingDataMutation();
  const handleClose = (confirm: boolean) => {
    if (changes && confirm) {
      if (window.confirm("Changes you made may not be saved")) {
        closeModalBox();
      }
    } else {
      closeModalBox();
    }
  };

  useEffect(() => {
    let filterTracking: ITrackingDataList = list?.data?.data?.values()?.next()
      ?.value;
    let trackingDataFirstRow: ITrackingDataRows = filterTracking?.data
      ?.values()
      ?.next()?.value;

    let filterTrackingFirstRow: any = addActivityData?.data
      ? addActivityData?.data?.values()?.next()?.value
      : trackingDataFirstRow;
    let currentDate = moment();
    let beginningDate = moment(filterTrackingFirstRow?.endingDate)?.add(
      1,
      "days"
    );
    let endDate = moment(filterTrackingFirstRow?.endingDate);
    let endingDate = currentDate < beginningDate ? beginningDate : currentDate;
    setFormValues({
      beginingDate: new Date(beginningDate?.format("MM/DD/YYYY")),
      endingDate: new Date(endingDate?.format("MM/DD/YYYY")),
      beginningEndingDate: [
        new Date(beginningDate?.format("MM/DD/YYYY")),
        new Date(endingDate?.format("MM/DD/YYYY")),
      ],
    });
    setChanges(false);
    beforeUnload(false);
  }, [list, addActivityData, filterTracking, addRow]);

  function closeModalBox() {
    setFormValues({
      data: "",
      comment: "",
    });
    setChanges(false);
    beforeUnload(false);
    dispatch(addNewRowState(false));
  }
  return (
    <div
      className={`grid grid-cols-1 gap-1 mb-2 hover:bg-gray-50 hover:cursor-pointer justify-center items-baseline p-2 border-b-2 border-col border-[#e5e7eb] data-content data-highlight ${
        addRow ? "" : "hidden"
      }`}
    >
      <Form
        formError={formError}
        onCheck={setFormError}
        className="edit-trackingData-form grid-cols-1 lg:grid-cols-1 gap-2 inline-flex w-full"
        model={TrackingDataSchema}
        formValue={formValues}
        onChange={(formValues: object) => {
          setFormValues(formValues);
          /** check if changes made by user */
          setChanges(true);
          beforeUnload(true);
        }}
        onSubmit={(el, event) => onSubmitHandler(event)}
        autoComplete="off"
      >
        <Form.Group controlId="beginingDate" className="!mb-0">
          <Form.Control
            oneTap
            accepter={DatePicker}
            name="beginingDate"
            format="MM/dd/yyyy"
            placeholder="MM/DD/YYY"
            className="edit-trackingData-input"
          />
        </Form.Group>

        <Form.Group controlId="endingDate" className="!mb-0">
          <Form.Control
            oneTap
            accepter={DatePicker}
            name="endingDate"
            format="MM/dd/yyyy"
            placeholder="MM/DD/YYY"
            className="edit-trackingData-input"
          />
        </Form.Group>

        <Form.Group controlId="data" className="!mb-0">
          <Form.Control
            checkAsync
            oneTap
            accepter={Input}
            name="data"
            placeholder="Data*"
            className="edit-trackingData-input w-full"
          />
        </Form.Group>

        <Form.Group controlId="comment" className="!mb-0">
          <Form.Control
            accepter={Textarea}
            name="comment"
            //@ts-ignore
            placeholder="Comment"
            className="edit-trackingData-input"
          />
        </Form.Group>
        <div className="col-span-2 flex justify-center md:justify-end items-center flex-col md:flex-row !m-0">
          <Button
            type="submit"
            loading={isLoading}
            className="text-base font-semibold text-white bg-green-500   !p-2  !rounded-full border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500 "
          >
            <BookmarkSquareIcon className="w-5 h-5" />
          </Button>
          <button
            type="button"
            onClick={() => {
              props?.handleClose();
            }}
            className="inline-block relative group p-2 ml-3 border border-red-600 bg-red-600 hover:bg-white hover:text-red-600 active:text-red-600 active:bg-white focus:bg-white focus:text-red-600  text-white font-medium text-xs leading-tight  rounded-full shadow-md    transition-all duration-500 ease-in-out"
          >
            <XCircleIcon className="w-5 h-5" />
            <div
              id="tooltip-default"
              role="tooltip"
              className="inline-block absolute invisible group-hover:visible z-[500] p-2 ml-3 -top-[39px] -left-[22px] text-sm font-medium text-white bg-gray-900 rounded-2xl shadow-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300 tooltip dark:bg-gray-700"
            >
              Cancel
            </div>
          </button>
        </div>
      </Form>
    </div>
  );

  async function onSubmitHandler(event: SyntheticEvent) {
    if (Object.keys(formError)?.length !== 0) {
      toaster.push(
        toastError({
          message: "Please check the required fields.",
        })
      );
      return false;
    }
    let data: TPostDataRequest = {
      bodyData: {
        activity: addActivityData?.type,
        beginingDate: format(new Date(formValues?.beginingDate), "yyyy-MM-dd"),
        endingDate: format(new Date(formValues?.endingDate), "yyyy-MM-dd"),
        comment: formValues?.comment,
        data: formValues?.data,
        activityId: addActivityData?.activityId,
      },
      token: params?.key,
    };

    await insertTrackingData(data)
      .unwrap()
      .then(async (data) => {
        if (data.status) {
          toaster.push(toastSuccess({ message: data.message }));
          setChanges(false);
          setFormValues("");
          handleClose(false);
          props?.handleClose();
          await refetchDashboardData({ bodyData: filterData });
        }
      })
      .catch((error) => {
        setFormError({
          beginingDate: error?.data?.errors?.beginingDate?.values()?.next()
            ?.value,
          comment: error?.data?.errors?.comment?.values()?.next()?.value,
          data: error?.data?.errors?.data?.values()?.next()?.value,
        });
        toaster.push(
          toastError({
            message: error?.data?.message,
          })
        );
      });
  }
};

export default AddRow;
