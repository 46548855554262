import { Button } from "components/button/Button";
import { Drawer } from "rsuite";

type DefaultHeaderProps = {
  dontShowControlButtons?: boolean;
  title?: string;
  closeBtnText?: string;
  closeBtnFunc?: () => void;
  successBtnText?: string;
  successBtnFunc?: () => void;
  saveAndClose?: string;
  saveAndCloseFunc?: () => void;
  isSuccessBtnLoading?: boolean;
};

interface Props extends DefaultHeaderProps {
  openDrawer: boolean;
  toggleDrawer: () => void;
  openWithHeader: boolean;
  body: JSX.Element;
  dontShowControlButtons?: boolean;
  customHeader?: JSX.Element;
  additionalClassName?: string;
}

const DrawerHeader = ({
  title,
  closeBtnText,
  closeBtnFunc,
  successBtnFunc,
  successBtnText,
  saveAndClose,
  saveAndCloseFunc,
  isSuccessBtnLoading,
  dontShowControlButtons,
}: DefaultHeaderProps) => {
  return (
    <Drawer.Header className="custom-header !p-5">
      <Drawer.Title className="custom-title">{title}</Drawer.Title>
      <Drawer.Actions className="custom-action-btn">
        <>
          <Button
            title={successBtnText ?? ""}
            handleClick={successBtnFunc}
            isLoading={isSuccessBtnLoading}
            additionalClass="text-base font-semibold text-white bg-green-500 py-[6px]  px-7 rounded border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500 "
          />
          <Button
            title={saveAndClose ?? ""}
            handleClick={saveAndCloseFunc}
            isLoading={isSuccessBtnLoading}
            additionalClass="text-base font-semibold text-white bg-green-500 py-[6px]  px-7  rounded border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500 "
          />
        </>
        <button
          onClick={closeBtnFunc}
          className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500 "
        >
          {dontShowControlButtons ? "Close" : closeBtnText}
        </button>
      </Drawer.Actions>
    </Drawer.Header>
  );
};

const CustomDrawer = ({
  openDrawer,
  customHeader,
  successBtnFunc,
  closeBtnFunc,
  closeBtnText,
  successBtnText,
  dontShowControlButtons,
  body,
  additionalClassName,
  isSuccessBtnLoading,
  saveAndClose,
  saveAndCloseFunc,
}: Props) => {
  return (
    <div>
      <Drawer
        open={openDrawer}
        onClose={closeBtnFunc}
        containerClassName={"overflow-scroll"}
        style={{ width: "50%", padding: "0px" }}
      >
        {/* To open with header you can use this */}
        {customHeader ? (
          customHeader
        ) : (
          <DrawerHeader
            successBtnFunc={successBtnFunc}
            closeBtnFunc={closeBtnFunc}
            closeBtnText={closeBtnText}
            successBtnText={successBtnText}
            dontShowControlButtons={dontShowControlButtons}
            saveAndClose={saveAndClose}
            isSuccessBtnLoading={isSuccessBtnLoading}
            saveAndCloseFunc={saveAndCloseFunc}
          />
        )}
        <Drawer.Body className="!py-5 !px-10">{body}</Drawer.Body>
      </Drawer>
    </div>
  );
};

export default CustomDrawer;
