import { createSlice } from "@reduxjs/toolkit";
import { IUserFullDetails } from "types";

interface initialState {
  user: IUserFullDetails | null;
  isAuthenticated: boolean;
  wow_token?:string,
  token: null | string;
  simulate: null | { name: string, token: string, active: boolean, defaultPage: string, user_id?:string }[];
}


const simulateArray = localStorage.getItem("simulateArray")
const initialState: initialState = {
  isAuthenticated: localStorage.getItem("access_token") ? true : false,
  user: null,
  token: null,
  wow_token: localStorage.getItem("wow_token") ?? '',
  simulate: simulateArray ? JSON.parse(simulateArray) : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
    },
    logout: (state) => {
      localStorage.clear();
      state.isAuthenticated = false;
      state.token = null;
      state.simulate = null;
    },
    setSimulate: (state, action) => {
      state.simulate = action.payload
    },

    removeSimulate: (state, action) => {
      if (action.payload) {
        state.simulate = action.payload
        return
      }
      state.simulate = null
      state.token = localStorage.getItem("access_token")
    },
    setAuthenticated:(state, action) => {
        state.isAuthenticated = action.payload
        return
    },
    setAuthenticatedToken:(state, action) => {
      state.token = action.payload
      return
    },
    setWowAuthenticatedToken:(state, action) => {
      state.wow_token = action.payload
      return
    },
  },
});

export const { loginSuccess, logout, setSimulate, removeSimulate, setAuthenticated, setAuthenticatedToken, setWowAuthenticatedToken } = authSlice.actions;

export default authSlice.reducer;
