//TODO need to be typed more statically
import { InputFields } from "components/cards/BasicFormCard";
import CustomModal from "components/modals/CustomModal";
import { toastError, toastSuccess } from "components/toasts";
import useInfiniteVmapList from "hooks/useInfiniteVmapList";
import { useState } from "react";
import { SelectPicker, useToaster } from "rsuite";
import { useMoveVmapMutationMutation } from "store/v-map/vmapApiSlice";
import { IKpis, IVmap } from "types/preferences/definition";
import { arraysEqual } from "utils/helperFunctions";

// @ts-ignore
// TODO make this function more reusable and less redundant
const getElements = (
  combinedVmapList: IVmap[],
  initialIndexes: number[],
  selectedIndexes: number[]
) => {
  //@ts-ignore
  const vmaps = combinedVmapList.map((item, index) => ({
    label: item.formTitle,
    value: item.vMapId,
    index,
  }));
  return [
    [
      {
        label: "Current V-MAP",
        data: vmaps,
        defaultValue: vmaps[initialIndexes[0]].value,
        disabled: true,
      },
      {
        label: "New V-MAP",
        data: vmaps,
        defaultValue: vmaps[selectedIndexes[0]].value,
        disabled: false,
      },
    ],
    [
      {
        label: "Current Value",
        data: combinedVmapList[initialIndexes[0]]?.values?.map(
          (item, index) => ({
            label: item.valueTitle,
            value: item.valueId,
            index,
          })
        ),
        defaultValue:
          combinedVmapList[initialIndexes[0]]?.values[initialIndexes[1]]
            ?.valueId,
        disabled: true,
      },
      {
        label: "New Value",
        data: combinedVmapList[selectedIndexes[0]]?.values?.map(
          (item, index) => ({
            label: item.valueTitle,
            value: item.valueId,
            index,
          })
        ),
        defaultValue:
          combinedVmapList[selectedIndexes[0]]?.values[selectedIndexes[1]]
            ?.valueId,
        disabled: false,
      },
    ],
    initialIndexes.length > 2 && [
      {
        label: "Current KPI",
        data: combinedVmapList[initialIndexes[0]].values[
          initialIndexes[1]
        ]?.kpis?.map((item, index) => ({
          label: item.kpiName,
          value: item?.kpiId,
          index,
        })),
        defaultValue:
          combinedVmapList[initialIndexes[0]]?.values[initialIndexes[1]]?.kpis[
            initialIndexes[2]
          ]?.kpiId,
        disabled: true,
      },
      {
        label: "New KPI",
        data: combinedVmapList[selectedIndexes[0]].values[
          selectedIndexes[1]
        ]?.kpis?.map((item, index) => ({
          label: item?.kpiName,
          value: item?.kpiId,
          index,
        })),
        defaultValue:
          combinedVmapList[selectedIndexes[0]]?.values[selectedIndexes[1]]
            ?.kpis[selectedIndexes[2]]?.kpiId,
        disabled: false,
      },
    ],
    initialIndexes.length > 3 && [
      {
        label: "Current Strategy",
        data: combinedVmapList[initialIndexes[0]].values[
          initialIndexes[1]
        ]?.kpis[initialIndexes[2]]?.strategy?.map((item, index) => ({
          label: item?.strategyName,
          value: item?.strategyId,
          index,
        })),
        defaultValue:
          combinedVmapList[initialIndexes[0]]?.values[initialIndexes[1]]?.kpis[
            initialIndexes[2]
          ]?.strategy[initialIndexes[3]]?.strategyId,
        disabled: true,
      },
      {
        label: "New Strategy",
        data: combinedVmapList[selectedIndexes[0]].values[
          selectedIndexes[1]
        ]?.kpis[selectedIndexes[2]]?.strategy?.map((item, index) => ({
          label: item?.strategyName,
          value: item ? item?.strategyId : "Select Option",
          index,
        })),
        defaultValue:
          combinedVmapList[selectedIndexes[0]]?.values[selectedIndexes[1]]
            ?.kpis[selectedIndexes[2]]?.strategy[selectedIndexes[3]]
            ?.strategyId,
        disabled: false,
      },
    ],

    initialIndexes.length > 4 && [
      {
        label: "Current Project",
        data: combinedVmapList[initialIndexes[0]].values[
          initialIndexes[1]
        ]?.kpis[initialIndexes[2]]?.strategy[initialIndexes[3]]?.project?.map(
          (item, index) => ({
            label: item?.projectName,
            value: item?.projectId,
            index,
          })
        ),
        defaultValue:
          combinedVmapList[initialIndexes[0]].values[initialIndexes[1]]?.kpis[
            initialIndexes[2]
          ]?.strategy[initialIndexes[3]]?.project[initialIndexes[4]]?.projectId,
        disabled: true,
      },
      {
        label: "New Project",
        data: combinedVmapList[selectedIndexes[0]].values[
          selectedIndexes[1]
        ]?.kpis[selectedIndexes[2]]?.strategy[selectedIndexes[3]]?.project?.map(
          (item, index) => ({
            label: item?.projectName,
            value: item?.projectId,
            index,
          })
        ),
        defaultValue:
          combinedVmapList[selectedIndexes[0]].values[selectedIndexes[1]]?.kpis[
            selectedIndexes[2]
          ]?.strategy[selectedIndexes[3]]?.project[selectedIndexes[4]]
            ?.projectId,
        disabled: false,
      },
    ],

    initialIndexes.length > 5 && [
      {
        label: "Current Critical Activity",
        data: combinedVmapList[initialIndexes[0]].values[
          initialIndexes[1]
        ]?.kpis[initialIndexes[2]]?.strategy[initialIndexes[3]]?.project[
          initialIndexes[4]
        ]?.critical_activity?.map((item, index) => ({
          label: item?.criticalActivityName,
          value: item?.criticalActivityId,
          index,
        })),
        defaultValue:
          combinedVmapList[initialIndexes[0]].values[initialIndexes[1]]?.kpis[
            initialIndexes[2]
          ]?.strategy[initialIndexes[3]]?.project[initialIndexes[4]]
            ?.critical_activity[initialIndexes[5]]?.criticalActivityId,
        disabled: true,
      },
      {
        label: "New Critical Activity",
        data: combinedVmapList[selectedIndexes[0]].values[
          selectedIndexes[1]
        ]?.kpis[selectedIndexes[2]]?.strategy[selectedIndexes[3]]?.project[
          selectedIndexes[4]
        ]?.critical_activity?.map((item, index) => ({
          label: item?.criticalActivityName,
          value: item?.criticalActivityId,
          index,
        })),
        defaultValue:
          combinedVmapList[selectedIndexes[0]].values[selectedIndexes[1]]?.kpis[
            selectedIndexes[2]
          ]?.strategy[selectedIndexes[3]]?.project[selectedIndexes[4]]
            ?.critical_activity[selectedIndexes[5]]?.criticalActivityId,
        disabled: false,
      },
    ],
  ];
};
const properties = [
  "values",
  "kpis",
  "strategy",
  "project",
  "critical_activity",
];
export const levelIds = [
  "valueId",
  "kpiId",
  "strategyId",
  "projectId",
  "criticalActivityId",
] as const;
export const ids = ["vMapId", ...levelIds];
const MoveElement = ({
  indexesUptoSelectedLevel,
  setShowModal,
  elementToMove,
}: {
  indexesUptoSelectedLevel: number[];
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  elementToMove: Pick<IKpis, "Id" | "type" | "name" | "title">;
}) => {
  const [finalIndexes, setFinalIndexes] = useState(
    Array.from(indexesUptoSelectedLevel)
  );
  // moveDirection 1 means down and 0 means up
  const [moveDirection, setMoveDirection] = useState(0);
  const { combinedVmapList } = useInfiniteVmapList({
    initialPageRange: [1, 100],
    include: ["values", "kpis", "strategy", "project", "criticalActivity"],
    endPoint: "all-vmaps",
  });
  const [moveVmap] = useMoveVmapMutationMutation();
  const toast = useToaster();
  const ELEMENTS = getElements(
    combinedVmapList,
    indexesUptoSelectedLevel,
    finalIndexes
  ).slice(0, indexesUptoSelectedLevel.length);

  // @ts-ignore
  function isPropertyUndefined(parentData: any, currentIndex: number) {
    const hasReachedSelectedLevel =
      currentIndex === indexesUptoSelectedLevel.length - 1;
    const property =
      parentData[finalIndexes[currentIndex]]?.[properties[currentIndex]];

    if (hasReachedSelectedLevel) {
      return false;
    }

    if (typeof property === "undefined") {
      return true;
    }

    return isPropertyUndefined(property, currentIndex + 1);
  }
  // @ts-ignore
  function getProperty(
    parentData: any[],
    currentIndex: number,
    lastLevel: any,
    oldParentData: any,
    oldLastLevel: any,
    vmapId: number
  ) {
    const hasReachedSelectedLevel =
      currentIndex === indexesUptoSelectedLevel.length - 1;
    let property = hasReachedSelectedLevel
      ? parentData[finalIndexes[currentIndex]]
      : parentData[finalIndexes[currentIndex]]?.[properties[currentIndex]];
    let oldProperty = hasReachedSelectedLevel
      ? oldParentData[indexesUptoSelectedLevel[currentIndex]]
      : oldParentData[indexesUptoSelectedLevel[currentIndex]]?.[
          properties[currentIndex]
        ];

    if (hasReachedSelectedLevel) {
      const moveElementsOfArr = () => {
        //removing the element that we have to move from the current element if it exists
        let filteredArr = [...parentData].filter(
          (item) => item[ids[currentIndex - 1]] !== elementToMove.Id
        );
        //getting the array that contains only ids
        let iDsFromFilteredArr = filteredArr.map(
          (item) => item[ids[currentIndex - 1]]
        );
        // inserting element at the level
        iDsFromFilteredArr.splice(
          finalIndexes[currentIndex] + moveDirection,
          0,
          elementToMove.Id
        );
        return iDsFromFilteredArr;
      };
      return {
        index: property ? moveElementsOfArr() : [elementToMove.Id],
        node: {
          ...elementToMove,
          parent: oldLastLevel[ids[currentIndex - 2]],
          vMapId: vmapId,
        },
        parent: {
          Id: lastLevel[ids[currentIndex - 2]],
        },
      };
    }

    if (typeof property === "undefined") {
      return undefined;
    }

    return getProperty(
      property,
      currentIndex + 1,
      parentData[finalIndexes[currentIndex]],
      oldProperty,
      oldParentData[indexesUptoSelectedLevel[currentIndex]],
      vmapId
    );
  }
  const moveVmapHandler = async () => {
    let data = getProperty(
      combinedVmapList,
      0,
      combinedVmapList[finalIndexes[0]],
      combinedVmapList,
      combinedVmapList[indexesUptoSelectedLevel[0]],
      combinedVmapList[finalIndexes[0]].vMapId
    );
    try {
      await moveVmap(data).unwrap();
      toast.push(toastSuccess({ message: "Element Moved successfully" }));
      setShowModal(false);
    } catch (error) {
      toast.push(toastError({ message: "Unable To Move " }));
      setShowModal(false);
    }
  };

  return (
    <CustomModal
      title={`Move ${elementToMove.name || elementToMove.title}`}
      successBtnText={"move"}
      successBtnDisabled={
        arraysEqual(indexesUptoSelectedLevel, finalIndexes) ||
        isPropertyUndefined(combinedVmapList, 0)
      }
      successBtnHandler={moveVmapHandler}
      cancelBtnHandler={() => setShowModal(false)}
      cancelBtnText={"Close"}
      children={
        // @ts-ignore
        <>
          {indexesUptoSelectedLevel.map((item, index) => (
            <div className=" p-5" key={index}>
              {// @ts-ignore
              ELEMENTS[index]?.map((subItem: InputFields, subIndex: number) => (
                <div
                  className="flex justify-around p-2 align-center"
                  key={subIndex}
                >
                  {/* @ts-ignore */}
                  <SelectPicker
                    searchable={false}
                    cleanable={false}
                    className={"w-full"}
                    {...subItem}
                    onSelect={(e, item) => {
                      setFinalIndexes((prev) => {
                        let newArr = prev.map((prevItem, i) => {
                          if (i > index) {
                            return 0;
                          }
                          if (i == index) {
                            return item.index;
                          }
                          return prevItem;
                        });
                        return [...newArr];
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          ))}{" "}
          <div className="pl-[30px] py-5">
            <input
              type="radio"
              id="up"
              name="place"
              value={moveDirection}
              onChange={() => setMoveDirection(0)}
              defaultChecked={true}
            />
            <label htmlFor="up" className="pl-1 pr-5 cursor-pointer">
              Up
            </label>
            {"  "}
            <input
              onChange={() => setMoveDirection(1)}
              type="radio"
              id="down"
              name="place"
              value={moveDirection}
            />
            <label htmlFor="down" className="pl-1 cursor-pointer">
              Down
            </label>
          </div>
        </>
      }
    />
  );
};

export default MoveElement;
