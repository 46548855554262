// form
import { FormProvider as Form } from "react-hook-form";

type PropTypes = {
  children: JSX.Element;
  methods: any;
  onSubmit?: () => void;
  overrideClassName?: boolean;
};

export default function FormProvider({
  children,
  onSubmit,
  methods,
  overrideClassName = false,
}: PropTypes) {
  return (
    <Form {...methods}>
      <form
        onSubmit={onSubmit}
        className={!overrideClassName ? "tabs flex flex-col gap-4 w-full " : ""}
      >
        {children}
      </form>
    </Form>
  );
}
