export const DASHBOARD_ROUTE = "/dashboard";

// ------------ALL AUTH ROUTES--------------
export const LOGIN_ROUTE = "/auth/login";

//-------------TOOLS ROUTES---------------
export const getToolsRoute = (subRoute: string) => `/tools/${subRoute}`;

export const MANAGE_ROLE_ROUTE = getToolsRoute("manage-roles");
export const MY_PROFILE_ROUTE = getToolsRoute("my-profile");
export const MANAGE_ACCESS_ROUTE = getToolsRoute("manage-access");
export const MANAGE_USERS_ROUTE = getToolsRoute("manage-users");

//----------------USER PREFERENCES--------
const getPreferencesRoute = (subRoute: string) => `/preferences/${subRoute}`;

export const PREFERENCES_MyAvatar_SETTINGS_ROUTE =
  getPreferencesRoute("my-avatar");
export const PREFERENCES_GENERAL_SETTINGS_ROUTE =
  getPreferencesRoute("general-settings");

export const PREFERENCES_VMAP_DEFINITION_ROUTE =
  getPreferencesRoute("vmap-definition");

export const PREFERENCES_DASHBOARD_PROFILES_ROUTE =
  getPreferencesRoute("dashboard-profiles");

export const PREFERENCES__ADD_DASHBOARD_PROFILES_ROUTE = PREFERENCES_DASHBOARD_PROFILES_ROUTE + "/add-profile"
export const PREFERENCES__EDIT_DASHBOARD_PROFILES_ROUTE = PREFERENCES_DASHBOARD_PROFILES_ROUTE + "/edit-profile/:id"

export const PREFERENCES_DELEGATE_ROUTE = getPreferencesRoute("delegate");

export const MANAGE_QUALIFIER = getPreferencesRoute("vmap-qualifiers");

// ---------- ACTIVITY ROUTES-------------------
export const USER_ACTIVITY_ROUTE = "/activity/user";
export const COACH_ACTIVITY_ROUTE = "/activity/coach";

//----------CALL MAXIMIZER--------------------
export const CALL_MAXIMIZER_ROUTE = "/call-maximizer";

//----------My Clients--------------------
export const MY_CLIENTS_ROUTE = "/client-list";

//---------------V-MAP -----
export const VMAP_ROUTE = "/v-map";

//--------TRACKING DATA ROUTE

export const TRACKING_DATA_ROUTE = "/tracking-data"

//--------Coach Path Routes------------
export const COACHPATH_AWARENESS_ROUTE = "coach-path/awareness";
export const COACHPATH_ACTION_ROUTE = "coach-path/action";
export const COACHPATH_ACHIEVEMENT_ROUTE = "coach-path/achievement";
export const COACHPATH_ACTION_ITEMS_ROUTE = "/coach-path/action-items";
export const COACHING_READINESS = "/coaching-readiness"
export const SWEET_SPOT_ANALYSIS = "/sweet-spot-analysis"
export const CORE_DISCIPLINES = "/core-discipline"
export const EMAIL_NOTIFICATION_ROUTE = "/group-update/master-id/:key"
export const VMAP_LEVEL_ROUTE = "v-map/:vmapId/:level/:id"
export const DASHBOARD_LEVEL_ROUTE = "dashboard/:level/:id"
//ALL-ROUTES-FOR MANAGE-ROLES
export const ALL_ROUTES: { [key: string]: string } = {
  coachingReadiness: COACHING_READINESS,
  vmaproute: VMAP_ROUTE,
  callMaximizer: CALL_MAXIMIZER_ROUTE,
  manageRoles: MANAGE_ROLE_ROUTE,
  "user-management": MANAGE_USERS_ROUTE,
  myClient: MY_CLIENTS_ROUTE,
  profile: MY_PROFILE_ROUTE,
  resources: "",
  trackSystemData: TRACKING_DATA_ROUTE,
  useractivity: USER_ACTIVITY_ROUTE,
  dashboard: "/dashboard",
  COACH_ACTIVITY_ROUTE,
  COACHPATH_ACTION_ROUTE,
  COACHPATH_AWARENESS_ROUTE,
  COACHPATH_ACHIEVEMENT_ROUTE,
  COACHPATH_ACTION_ITEMS_ROUTE,
  COACHING_READINESS,
  PREFERENCES_MyAvatar_SETTINGS_ROUTE,
  // COACHING_READINESS,
  sweetSpotAnalysis: SWEET_SPOT_ANALYSIS,
  coreDiscipline: CORE_DISCIPLINES,
  manageAccess: MANAGE_ACCESS_ROUTE
};

export const ALL_ROUTES_TOOLS: { [key: string]: string } = {
  coachingReadiness: "coaching-readiness",
  vmaproute: "v-map",
  callMaximizer: "call-maximizer",
  awareness: "awareness",
  manageRoles: "manage-roles",
  "user-management": MANAGE_USERS_ROUTE,
  myClient: "client-list",
  profile: "my-profile",
  resources: "",
  trackSystemData: "tracking-data",
  useractivity: "user",
  dashboard: "dashboard",
  action: "action",
  sweetSpotAnalysis: "sweet-spot-analysis",
  coreDiscipine: "core-discipline",
  manageAccess: "manage-access"
};
