import React, { CSSProperties } from "react";
import type { UniqueIdentifier } from "@dnd-kit/core";
import { AnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { TreeItem, ITreeItemProps } from "./TreeItem";
import { iOS } from "../../utilities";

interface Props extends Omit<ITreeItemProps, "id"> {
  externalStyle?: CSSProperties;
  id: UniqueIdentifier;
  isDragOn: boolean;
}

const animateLayoutChanges: AnimateLayoutChanges = ({
  isSorting,
  wasDragging,
}) => (isSorting || wasDragging ? false : true);

export function SortableTreeItem({
  id,
  depth,
  vmapId,
  externalStyle,
  isDragOn,
  ...props
}: Props) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const style: CSSProperties = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      vmapId={vmapId}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      isDragOn={isDragOn}
      {...props}
      style={{ externalStyle, style }}
    />
  );
}
