const SvgComponent = (props: { width?: string; height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 63.74 63.74"
    {...props}
  >
    <defs>
      <style>{".cls-level1{fill:#7a111b}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <circle
        cx={31.87}
        cy={31.87}
        r={31.87}
        style={{
          fill: "#ed1f23",
        }}
      />
      <path
        d="M43.17 41.44c.05 2.18-.39 4.1.57 5.91.18.34.68.58 1.08.71 1.79.6 4.01 1.41 5.81 1.97.72.33.77.51-.54.44l-5.69-.35c.85 1.55 2.49 2.78 4.28 3.59-.24.09-.66.2-1.08.16-3.26-.26-6.53-.63-8.73-3.61-.44-.59-1.14-1.02-1.8-1.39-1.82-1.03-2.47-2.57-1.79-4.67.29-.88.31-1.39-1.07-1.49-.57-.04-.99.38-.91.95.15 1.11.35 2.2.54 3.3.42 2.43-.7 4.04-3.12 4.46-.6.11-1.24.07-1.8.28-.9.33-1.83.7-2.62 1.23-2.06 1.38-4.21 2.47-6.73 2.68-2.36.2-4.73.39-7.09.58-.93.08-.85-.06-.09-.39 2.29-.96 4.59-1.91 6.87-2.89.3-.13.6-.35.86-.57.19-.21-.03-.3-.19-.33-.73-.07-1.46-.15-2.2-.22-.78-.1-.6-.22-.04-.37.95-.24 1.88-.55 2.84-.71 2.06-.34 2.93-1.8 3.38-3.58.49-1.97.94-3.96 1.23-5.96.73-4.96 2.8-5.97 7.08-6.42 2.1-.22 4.33.12 6.42.57 1.9.4 3.42 1.62 4.19 3.5.07.16.2.39.31 1.34"
        className="cls-level1"
      />
      <circle cx={47.22} cy={38.45} r={4.73} className="cls-level1" />
    </g>
  </svg>
);
export default SvgComponent;
