import {
  ALL_ROUTES,
  ALL_ROUTES_TOOLS,
  CALL_MAXIMIZER_ROUTE,
  COACH_ACTIVITY_ROUTE,
  COACHPATH_ACTION_ROUTE,
  COACHPATH_AWARENESS_ROUTE,
  DASHBOARD_ROUTE,
  MANAGE_ACCESS_ROUTE,
  MANAGE_QUALIFIER,
  MANAGE_ROLE_ROUTE,
  MANAGE_USERS_ROUTE,
  MY_CLIENTS_ROUTE,
  MY_PROFILE_ROUTE,
  PREFERENCES_DASHBOARD_PROFILES_ROUTE,
  PREFERENCES_DELEGATE_ROUTE,
  PREFERENCES_GENERAL_SETTINGS_ROUTE,
  PREFERENCES_VMAP_DEFINITION_ROUTE,
  TRACKING_DATA_ROUTE,
  USER_ACTIVITY_ROUTE,
  VMAP_ROUTE,
  COACHPATH_ACHIEVEMENT_ROUTE,
  COACHPATH_ACTION_ITEMS_ROUTE,
  PREFERENCES_MyAvatar_SETTINGS_ROUTE,
  SWEET_SPOT_ANALYSIS,
  CORE_DISCIPLINES,
  EMAIL_NOTIFICATION_ROUTE,
  PREFERENCES__ADD_DASHBOARD_PROFILES_ROUTE,
  PREFERENCES__EDIT_DASHBOARD_PROFILES_ROUTE,
  VMAP_LEVEL_ROUTE,
  DASHBOARD_LEVEL_ROUTE

} from "routes/paths";
import { IPermission } from "types";

interface INavMenuOptions {
  link: string;
  nested: boolean;
  nestedMenu?: INavMenu;
}
export interface INavMenu {
  [P: string]: INavMenuOptions;
}

export const NavMenu: Record<string, INavMenu> = {
  left: {
    Dashboard: {
      link: DASHBOARD_ROUTE,
      nested: false,
    },
    "V-MAP": {
      link: VMAP_ROUTE,
      nested: false,
    },
    "Tracking Data": {
      link: TRACKING_DATA_ROUTE,
      nested: false,
    },
  },

  right: {
    WOW: {
      link: `https://wow.proadvisordrivers.com/authentication/`,
      nested: false
    },
    Activity: {
      link: "/activity",
      nested: true,
      nestedMenu: {
        User: {
          link: USER_ACTIVITY_ROUTE,
          nested: false,
        },
        Coach: {
          link: COACH_ACTIVITY_ROUTE,
          nested: false,
        },
      },
    },
    //----------------TOOLS AND SUBLINKS----------------------
    Tools: {
      link: "/tools",
      nested: true,
      nestedMenu: {
        "Manage Roles": {
          link: MANAGE_ROLE_ROUTE,
          nested: false,
        },
        "Manage Wow Access": {
          link: MANAGE_ACCESS_ROUTE,
          nested: false,
        },
        "Manage Users": {
          link: MANAGE_USERS_ROUTE,
          nested: false,
        },
        "My Profile": {
          link: MY_PROFILE_ROUTE,
          nested: false,
        },
        Preferences: {
          link: PREFERENCES_GENERAL_SETTINGS_ROUTE,
          nested: false,
        },
      },
    },

    "My Client": {
      link: MY_CLIENTS_ROUTE,
      nested: false,
    },
    "Call Maximizer": {
      link: CALL_MAXIMIZER_ROUTE,
      nested: false,
    },

    "Coach Path": {
      link: "coach-path",
      nested: true,
      nestedMenu: {
        "Awareness": {
          link: COACHPATH_AWARENESS_ROUTE,
          nested: false,
        },
        "Action": {
          link: COACHPATH_ACTION_ROUTE,
          nested: false,
        },
        "Achievement": {
          link: COACHPATH_ACHIEVEMENT_ROUTE,
          nested: false,
        },
        "Action Item Checklist": {
          link: COACHPATH_ACTION_ITEMS_ROUTE,
          nested: false,
        },
      }
    },

  },
};

export const getAccessibleRoutes = (
  acccessibleRoutes: IPermission[],
  isRoutes?: boolean
) => {
  if (!acccessibleRoutes) return [];


  //TODO try to do it with some other methods for now i am using verbose method

  const dataWithRoutes = acccessibleRoutes.filter((item) => item.pages);
  const pagesName = dataWithRoutes.map(
    (item) => (isRoutes ? ALL_ROUTES_TOOLS : ALL_ROUTES)?.[item.pages.route]
  );

  return [
    ...pagesName,

    // NOTE: THE FOLLOWING ROUTES ARE NOT IN manage-roles that's why we have added them by default, so they are accessible to every user

    PREFERENCES_GENERAL_SETTINGS_ROUTE,
    // MANAGE_ACCESS_ROUTE,
    "/",
    PREFERENCES_GENERAL_SETTINGS_ROUTE,
    PREFERENCES_VMAP_DEFINITION_ROUTE,
    PREFERENCES_DASHBOARD_PROFILES_ROUTE,
    PREFERENCES_DELEGATE_ROUTE,
    MANAGE_QUALIFIER,
    PREFERENCES__EDIT_DASHBOARD_PROFILES_ROUTE,
    PREFERENCES__ADD_DASHBOARD_PROFILES_ROUTE,
    // MANAGE_USERS_ROUTE,
    COACHPATH_AWARENESS_ROUTE,
    COACHPATH_ACTION_ROUTE,
    COACHPATH_ACHIEVEMENT_ROUTE,
    COACHPATH_ACTION_ITEMS_ROUTE,
    PREFERENCES_MyAvatar_SETTINGS_ROUTE,
    SWEET_SPOT_ANALYSIS,
    CORE_DISCIPLINES,
    "dashboard/:level/:id",
    "v-map/:vmapId/:level/:id",
    EMAIL_NOTIFICATION_ROUTE,
    DASHBOARD_LEVEL_ROUTE,
    VMAP_LEVEL_ROUTE,
    // TODO Remove this with env variable
    `https://wow.proadvisordrivers.com/authentication/`
  ];
};