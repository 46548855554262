import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useRef, useState } from "react";
// form
import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, CheckPicker } from "rsuite";
import { MultipleSelectProps } from "rsuite/esm/SelectPicker";

interface IDelegate {
  label: string;
  value: number;
}
interface IRHFSelect extends MultipleSelectProps<any> {
  name: string;
  data: IDelegate[];
  defaultValue: number[];
  disabled?: boolean;
}

//TODO: types checking
//@ts-ignore
export default function RHFMultiSelect({ name, data, ...rest }: IRHFSelect) {
  const {
    control,
    setValue: setFormValues,
    getValues,
    formState: { errors },
  } = useFormContext();
  const [value, setValue] = useState<any[]>([]);
  useEffect(() => {
    if (getValues(name)) {
      setValue(getValues(name));
    }
  }, [getValues(name), value, rest.defaultValue]);

  const handleChange = (value: any) => {
    setValue(value);
    setFormValues(name, value);
  };

  const handleCheckAll = (checked: boolean) => {
    const values = checked ? data.map((item: any) => item.value) : [];
    setValue(values);
    setFormValues(name, values);
  };

  const ref = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref.current]);
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) => (
          <>
            <CheckPicker
              data={data}
              searchable={true}
              {...rest}
              block
              className="w-[50]"
              value={value}
              defaultValue={rest.defaultValue ?? getValues(name)}
              placement={"autoVertical"}
              onChange={handleChange}
              disabled={rest.disabled}
              renderExtraFooter={() => (
                <div className="order-2">
                  <Checkbox
                    indeterminate={
                      value.length > 0 && value.length < data.length
                    }
                    checked={value.length === data.length}
                    onChange={(values, checked) => {
                      handleCheckAll(checked);
                    }}
                  >
                    Select all
                  </Checkbox>
                </div>
              )}
            />
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <span ref={ref} className="text-red-500">
                  {message}
                </span>
              )}
            />
          </>
        )}
      />
    </>
  );
}
