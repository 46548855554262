import { useAppDispatch } from "hooks";
import { useEffect, useState } from "react";
import { TagDescription } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
// form
import { useFormContext, Controller } from "react-hook-form";
import { Checkbox, CheckPicker, SelectPicker } from "rsuite";
import { MultipleSelectProps } from "rsuite/esm/SelectPicker";
import { dashboardProfileApi } from "store/preferences/profiles/profileApi";
import { useLocation } from "react-router-dom";

interface IRHFSelect extends MultipleSelectProps<any> {
  name: string;
  defaultValue: number[];
}

//----------------------------------------- NOTE THIS COMPONENT IS ONLY FOR elements of route "preferences/dashboard-profiles"---------------------
// TODO Rewrite this component for two specific use cases 1. dashboard profile 2. dashboard add activity

//TODO: types checking
//@ts-ignore
export default function RHFMultiSelect({ name, data, defaultValue, ...rest }: IRHFSelect) {
  const { control, setValue: setFormValues, getValues } = useFormContext();
  const [value, setValue] = useState([]);

  const location = useLocation();


  useEffect(() => {
    setValue(getValues(name));
  }, [getValues(name)]);

  const dispatch = useAppDispatch();
  const index = name.split(".")[1];
  const resetFieldsOrder = [
    `vmaps.${index}.valueIds`,
    `vmaps.${index}.kpiIds`,
    `vmaps.${index}.strategyIds`,
    `vmaps.${index}.projectIds`,
    `vmaps.${index}.criticalActivityIds`,
  ];
  const tags = [
    "ValueList",
    "KpiList",
    "StrategyList",
    "ProjectList",
    "CriticalList",
  ];


  const handleChange = (value: any, data:any) => {
    if(!Array.isArray(value)) {
      //@ts-ignore
      setValue([data]);
      setFormValues(name, [data]);    }
    else{
      //@ts-ignore
      setValue(value);
      setFormValues(name, value);

    }
    const indexes = resetFieldsOrder.indexOf(name);
    for (let i of resetFieldsOrder.slice(indexes + 1)) {
      setFormValues(i, []);
    }
    for (let i of tags[indexes]) {
      dispatch(
        dashboardProfileApi.util.invalidateTags([
          i as TagDescription<
            | "ValueList"
            | "KpiList"
            | "StrategyList"
            | "ProjectList"
            | "CriticalList"
            | "DashboardProfile"
            | "VmapList"
            | "IndividualProfile"
          >,
        ])
      );
    }
  };

  const handleCheckAll = (checked: boolean) => {
    const values = checked ? data.map((item: any) => item.value) : [];
    setValue(values);
    setFormValues(name, values);
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange }, fieldState: { error } }) =>
          location?.pathname?.includes("dashboard-profile") ? (
            <CheckPicker
              data={data}
              searchable={true}
              {...rest}
              block
              className="w-[50]"
              value={value}
              placement={"autoVertical"}
              onChange={handleChange}
              renderExtraFooter={() => (
                <div className="order-2">
                  <Checkbox
                    indeterminate={
                      value?.length > 0 && value?.length < data?.length
                    }
                    checked={value?.length === data?.length}
                    onChange={(values, checked) => {
                      handleCheckAll(checked);
                    }}
                  >
                    Select all
                  </Checkbox>
                </div>
              )}
            />
          ) : (
            // @ts-ignore
            <SelectPicker
              data={data}
              {...rest}
              searchable={true}
              block
              // @ts-ignore
              value={value?.[0]?.value}
              onSelect={handleChange}
              className={""}
              placement={"autoVerticalStart"}
            />
          )
        }
      />
    </>
  );
}
