import { BasicFormCard } from "components/cards";
import { CustomDrawer } from "components/drawers";
import { FormProvider } from "components/hook-form";
import { toastError, toastSuccess } from "components/toasts";
import { useState, memo, useEffect, useRef } from "react";
import { useForm, useWatch } from "react-hook-form";
import { Toggle, useToaster } from "rsuite";
import { useSelector } from "react-redux";
import moment from "moment";
import { PaginationState } from "@tanstack/react-table";
import {
  useCreateLevelsMutation,
  useCreateValuesMutation,
  useUpdateVmapLevelMutation,
} from "store/v-map/vmapApiSlice";
import { IKpis } from "types/preferences/definition";
import {
  ADD_ON,
  ADD_ON_SYMBOL,
  getInputFields,
  getTrackingDrawerFields,
  checkBoxes,
  toggleButtons,
  goalCalculation,
  CREATE_VALUE_SCHEMA_YUP,
  CREATE_LEVEL_SCHEMA_YUP,
  getDefaultValuesForLevelDrawer,
} from "./getDrawerFileds";
import Editor from "../Editor";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetUserQuery } from "store/auth/authApi";
import { useFetchDelegateListQuery } from "store/preferences/delegate";
import { vmapTranslations } from "utils/vmap/translation";
import { renamingId, renamingTitle } from "sections/v-map/VMapDraggableTree";
import { RootState } from "store";
import { useFetchDashboardDataListMutation } from "store/dashboard/DashboardSlice";
import { ILevelDrawerProps } from "../../types";
import { numberToCurrency } from "utils/helperFunctions";
import beforeUnload from "hooks/beforeUnload";
import { useParams } from "react-router-dom";
import { useGetVmapListQuery } from "store/preferences/profiles/profileApi";

let propertyToConvert = [
  "goal",
  "daily",
  "weekly",
  "quarterly",
  "annually",
  "monthly",
] as const;

export interface ILevelDrawerDefaultValueProps
  extends Omit<
    IKpis,
    "qualifierTo" | "Goal" | "daily" | "seasonalGoal" | "delegateTo" | "kpiId"
  > {
  daily: number | string;
  delegateTo: number[] | string;
  seasonalGoal: number[] | string[];
  qualifierTo: number[];
}

const LevelDrawer = ({
  showDrawer,
  setShowDrawer,
  isValueDrawer,
  vmapId,
  type,
  level,
  defaultProps,
  isLevelUpdate = false,
}: ILevelDrawerProps) => {
  const [saveAndCloseState, setSaveAndClose] = useState(false);
  const [currentToggle, setCurrentToggle] = useState<
    keyof typeof ADD_ON | null
  >(defaultProps ? ADD_ON_SYMBOL[defaultProps?.trackSign] : null);
  const [checked, setChecked] = useState<number>(
    defaultProps?.successScale || 1
  );

  const currentResolver = isValueDrawer
    ? yupResolver(CREATE_VALUE_SCHEMA_YUP)
    : yupResolver(CREATE_LEVEL_SCHEMA_YUP);
  const [{ pageIndex, pageSize }] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  });
  const [accumulate, setAccumulate] = useState<boolean>(
    Boolean(defaultProps?.isAccumulate)
  );
  const { isFetching } = useGetVmapListQuery({
    vmapPageSize: [1, 100],
    include: ["values", "kpis", "strategy", "project", "criticalActivity"],
    endPoint: "all-vmaps",
  });
  const [createValues] = useCreateValuesMutation();
  const [createLevel, { isLoading: createLevelLoading }] =
    useCreateLevelsMutation();
  const [updateLevel, { isLoading: updateLevelLoading }] =
    useUpdateVmapLevelMutation();
  const { data: userData } = useGetUserQuery();
  // this is for when delegate access this drawer it should be disabled in that case
  const params = useParams();
  const isDelegateViewing =
    params?.type === "masterId" || params?.type === "key";

  const { data: delegateList } = useFetchDelegateListQuery({
    page: pageIndex + 1,
    pageSize,
    isDelegateViewing: params?.key,
  });

  const { filterData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );
  const [refetchDashboardData] = useFetchDashboardDataListMutation({
    fixedCacheKey: "shared-update-post",
  });

  if (defaultProps) {
    for (let item of propertyToConvert) {
      // @ts-ignore
      defaultProps[item] = numberToCurrency(defaultProps[item]);
    }
  }

  const { register, handleSubmit, ...methods } =
    useForm<ILevelDrawerDefaultValueProps>({
      defaultValues: defaultProps ?? {
        name: "",
        goal: "0",
        seasonalGoal: [0],
        dateAssignedDue: [new Date().toString(), new Date().toString()],
        delegateTo: [],
        qualifierTo: [],
        includeInProfile: 0,
        includeInAvatar: 0,
        includeInReporting: 0,
        url: "",
        showOnDashboard: 0,
        successScale: 1,
        trackSign: "#",
        seasonalSign: "#",
        daily: 0,
        monthly: 0,
        quarterly: 0,
        description: "",
        weekly: 0,
        statusId: 0,
        annually: 0,
        isAccumulate: 0,
      },
      resolver: currentResolver,
    });

  const seasonalSignWatcher = useWatch({
    name: "seasonalSign",
    control: methods.control,
  });
  useEffect(() => {
    if (defaultProps) {
      methods.reset((prev) => ({
        ...defaultProps,
        ...(isFetching ? prev : {}),
        description: watchDescription ?? defaultProps.description,
      }));
    }
    if (saveAndCloseState && !isFetching) {
      setShowDrawer(false);
    }
    return () => {
      beforeUnload(false);
    };
  }, [defaultProps, saveAndCloseState, isFetching]);

  const currentLevelId = useRef(null);
  const toaster = useToaster();
  const trackingDrawerFields = getTrackingDrawerFields(
    currentToggle,
    seasonalSignWatcher,
    isDelegateViewing
  );

  const handleSetValuesToggle = (fieldName: string) => {
    const dateRange = methods.watch("dateAssignedDue");
    return (
      goalCalculation(
        fieldName.toLowerCase(),
        +methods.watch("goal").replace(/,/g, ""),
        dateRange ? dateRange[0] : new Date().toDateString(),
        dateRange ? dateRange[1] : new Date().toDateString(),
        userData?.profile.workingDaysWeekly?.split(",").length
      ) || 0
    );
  };

  const createValueHandler = async (data: any, saveAndClose: boolean) => {
    try {
      const res = await createValues({
        statusId: data.statusId,
        url: data.url,
        name: data.name,
        vmapId,
      }).unwrap();
      if (res.status) {
        // @ts-ignore
        currentLevelId.current = res.data[renamingId[level]];
        toaster.push(toastSuccess({ message: "Value Created SuccessFully" }));
      }
      if (saveAndClose) {
        setSaveAndClose(true);
      }
    } catch (error) {
      toaster.push(toastError({ message: "Unable To Create Value" }));
    }
  };

  const createLevelHandler = async (data: any, saveAndClose: boolean) => {
    const { dateAssignedDue, ...rest } = data;
    propertyToConvert.forEach(
      (property) => (rest[property] = `${rest[property]}`.split(",").join(""))
    );
    try {
      const res = await createLevel({
        vmapId,
        level: type,
        dueDate: moment(dateAssignedDue[1]).format("YYYY-MM-DD"),
        assignDate: moment(dateAssignedDue[0]).format("YYYY-MM-DD"),
        ...rest,
        trackSign: currentToggle ? ADD_ON[currentToggle] : null,
        isAccumulate: accumulate ? 1 : 0,
        successScale: checked,
        seasonalGoal: data?.seasonalGoal.map(
          (item: number) => `${item}`?.split(",")?.join("")
        ),
      }).unwrap();
      if (res) {
        // @ts-ignore
        currentLevelId.current = res[renamingId[level]];
        // @ts-ignore
        methods.reset(
          // @ts-ignore
          getDefaultValuesForLevelDrawer({
            ...res,
            // @ts-ignore
            title: res[renamingTitle[level]],
          })
        );
        toaster.push(toastSuccess({ message: "Level Created Successfully" }));
        refetchDashboardData({ bodyData: filterData });
        if (saveAndClose) {
          setSaveAndClose(true);
        }
      }
    } catch (error) {
      toaster.push(toastError({ message: "Unable To Create Level" }));
    }
  };
  const updateVmapHandler = async (data: any, saveAndClose: boolean) => {
    try {
      if (currentLevelId?.current && !isLevelUpdate) {
        data.levelId = currentLevelId?.current;
      }
      const res = await updateLevel({
        isDelegateViewing: params?.key,
        vmapId,
        level: type,
        ...data,
        type,
        ...(!isValueDrawer && {
          dueDate: moment(data.dateAssignedDue[1]).format("YYYY-MM-DD"),
          assignDate: moment(data.dateAssignedDue[0]).format("YYYY-MM-DD"),
          quarterly: data?.quarterly
            ? data?.quarterly?.split(",")?.join("")
            : 0,
          delegateTo: data.delegateTo,
          trackSign:
            currentToggle === "Seasonal Goal"
              ? "@"
              : currentToggle
              ? ADD_ON[currentToggle]
              : data?.trackSign,
          isAccumulate: accumulate ? 1 : 0,
          goal: data?.goal?.split(",")?.join(""),
          daily: data?.daily?.split(",")?.join(""),
          monthly: data?.monthly?.split(",")?.join(""),
          annually: data?.annually?.split(",")?.join(""),
          weekly: data?.weekly?.split(",")?.join(""),
          successScale: checked,
          seasonalGoal: data?.seasonalGoal
            ? data?.seasonalGoal?.map(
                (item: number) => `${item}`?.split(",")?.join("")
              )
            : "",
        }),
      }).unwrap();
      if (res?.status) {
        toaster.push(toastSuccess({ message: "Level updated successfully" }));
        refetchDashboardData({ bodyData: filterData });
        methods.reset((prev) => {
          return { ...prev, includeInAvatar: data.includeInAvatar };
        });
        if (saveAndClose) {
          setSaveAndClose(true);
        }
      }
    } catch (error) {
      toaster.push(toastError({ message: "Unable to Update Level" }));
    }
  };

  const levelSubmit = (saveAndClose: boolean) => (data: any) => {
    if (isLevelUpdate || currentLevelId.current) {
      return updateVmapHandler(data, saveAndClose);
    } else {
      return isValueDrawer
        ? createValueHandler(data, saveAndClose)
        : createLevelHandler(data, saveAndClose);
    }
  };
  const isDirty = methods.formState.isDirty;
  const watchDescription = useWatch({
    name: "description",
    control: methods.control,
  });
  if (
    isDirty ||
    (currentToggle && ADD_ON[currentToggle] !== defaultProps?.trackSign) ||
    watchDescription !== defaultProps?.description
  ) {
    beforeUnload(true);
  }
  return (
    <FormProvider methods={methods}>
      <CustomDrawer
        dontShowControlButtons={isDelegateViewing}
        openDrawer={showDrawer}
        toggleDrawer={() => setShowDrawer(false)}
        openWithHeader={false}
        closeBtnFunc={() => {
          if (
            isDirty ||
            methods.watch("description") !== defaultProps?.description
          ) {
            const close = window.confirm("Changes you made may not be saved!");
            if (!close) return;
            else {
              setShowDrawer(false);
            }
          } else {
            setShowDrawer(false);
          }
        }}
        successBtnText={vmapTranslations["levelDrawer.saveBtn"]}
        closeBtnText={vmapTranslations["levelDrawer.cancelBtn"]}
        successBtnFunc={handleSubmit(levelSubmit(false))}
        saveAndCloseFunc={handleSubmit(levelSubmit(true))}
        saveAndClose={vmapTranslations["levelDrawer.saveAndCloseBtn"]}
        isSuccessBtnLoading={
          updateLevelLoading || isFetching || createLevelLoading
        }
        additionalClassName={"!p-0"}
        body={
          <div>
            <BasicFormCard
              inputFields={
                isValueDrawer
                  ? getInputFields([], level, isDelegateViewing).slice(0, 3)
                  : getInputFields(
                      delegateList ? delegateList?.data : [],
                      level,
                      isDelegateViewing,
                      defaultProps?.dateAssignedDue
                    )
              }
              register={register}
              additionalClassName={" grid sm:grid-cols-3 xl:grid-cols-2  gap-4"}
              additionalBaseElementClassName={"p-0"}
              overrideBaseElementClassName={true}
            />

            {!isValueDrawer && (
              <>
                <div className="flex justify-between flex-wrap gap-5 bg-slate-200 px-5 pb-5 pt-4 rounded-xl">
                  {checkBoxes.map((item) => (
                    <div
                      className="flex mt-2 items-center gap-2"
                      key={item.name}
                    >
                      <input
                        type="checkbox"
                        {...register(item.name, {
                          onChange: (e) => {
                            if (
                              item.name === "showOnDashboard" &&
                              e.target.checked
                            ) {
                              methods.setValue("includeInReporting", 1);
                              methods.setValue("includeInProfile", 1);
                              return;
                            }
                            return e.target.checked ? 1 : 0;
                          },
                        })}
                        className=" w-6 h-6 text-blue-600 bg-gray-100 rounded-md border-gray-300 cursor-pointer"
                        id={item.name}
                        disabled={isDelegateViewing}
                      />
                      <label htmlFor={item.name} className="cursor-pointer">
                        {item.label}
                      </label>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-6 gap-3 py-5 rounded-xl">
                  {toggleButtons.map((item) => (
                    <div
                      className="flex  mt-2 items-start flex-col gap-5"
                      key={item.name}
                    >
                      <Toggle
                        size="lg"
                        className="!mb-0"
                        data-testid={item.label}
                        arial-label={item.label}
                        checked={
                          item.label !== "Do Not Accumulate"
                            ? currentToggle === item.label
                            : accumulate
                        }
                        onClick={() => {
                          return item.label !== "Do Not Accumulate"
                            ? setCurrentToggle((prev) => {
                                return prev === item?.label ? null : item.label;
                              })
                            : setAccumulate(!accumulate);
                        }}
                        disabled={isDelegateViewing}
                      />
                      <label htmlFor={"lead"}>{item.label}</label>
                    </div>
                  ))}
                </div>
                {currentToggle && (
                  <div className="flex justify-between flex-wrap bg-slate-200 p-5 gap-3 rounded-xl">
                    {trackingDrawerFields.map(({ addOn, ...item }) => {
                      return (
                        <div className="tracking-number" key={item.label}>
                          <label
                            htmlFor={item.name}
                            className="mb-1 inline-block"
                          >
                            {item.label}
                          </label>
                          <div className="rs-input-group">
                            {addOn ? (
                              <span className="rs-input-group-addon">
                                {" "}
                                {addOn}
                              </span>
                            ) : null}
                            {currentToggle === "Success Scale" &&
                            "value" in item ? (
                              <input
                                id={item.name}
                                {...item}
                                onChange={() => {
                                  if (item.value) {
                                    if (typeof item.value == "string") {
                                      setChecked(parseInt(item?.value));
                                    } else {
                                      setChecked(item?.value);
                                    }
                                  }
                                }}
                                defaultChecked={item?.value === checked}
                                checked={item?.value === checked}
                                disabled={isDelegateViewing}
                              />
                            ) : (
                              <input
                                id={item.name}
                                className="rs-input max-w-[90px]"
                                {...item}
                                maxLength={20}
                                {...register(item.name, {
                                  ...(currentToggle === "Seasonal Goal" && {
                                    onChange: (e) => {
                                      methods.setValue(
                                        item?.name,
                                        numberToCurrency(e.target.value)
                                      );
                                    },
                                  }),
                                  ...(item.name === "goal" && {
                                    onChange: (e) => {
                                      for (let item of [
                                        "weekly",
                                        "daily",
                                        "monthly",
                                        "annually",
                                        "quarterly",
                                      ] as const) {
                                        methods.setValue(
                                          item,
                                          numberToCurrency(
                                            handleSetValuesToggle(item)
                                          )
                                        );
                                      }
                                      methods.setValue(
                                        "goal",
                                        numberToCurrency(e.target.value)
                                      );
                                    },
                                  }),
                                })}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}
                    {currentToggle === "Seasonal Goal" ? (
                      <div className="flex w-full justify-between mt-3">
                        <div className="flex gap-2">
                          <input
                            type="radio"
                            {...register("seasonalSign")}
                            id="seasonalSign"
                            value="#"
                            checked={seasonalSignWatcher === "#"}
                            className="w-5 h-5"
                            disabled={isDelegateViewing}
                          />
                          <label htmlFor="seasonalSign">Number</label>
                        </div>
                        <div className="flex gap-2">
                          <input
                            type="radio"
                            {...register("seasonalSign")}
                            id="dollar"
                            checked={seasonalSignWatcher === "$"}
                            value="$"
                            className="w-5 h-5"
                            disabled={isDelegateViewing}
                          />
                          <label htmlFor="dollar">Dollar</label>
                        </div>
                        <span className="text-bold">
                          {seasonalSignWatcher}
                          {(methods.watch("seasonalGoal") as number[])
                            .reduce(
                              (accumulator: number, currentValue: number) => {
                                if (!accumulator && accumulator !== 0) return 0;
                                if (
                                  !currentValue ||
                                  !parseInt(
                                    `${currentValue}`.split(",").join("")
                                  )
                                ) {
                                  currentValue = 0;
                                }
                                return (
                                  accumulator +
                                  parseInt(
                                    `${currentValue}`.split(",").join("")
                                  )
                                );
                              },
                              0
                            )
                            .toLocaleString()}
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
                <Editor
                  withWrapper={true}
                  draftJs={watchDescription ?? defaultProps?.description ?? ""}
                  setValue={(val) => {
                    methods.setValue("description", val);
                  }}
                />
              </>
            )}
          </div>
        }
      />
    </FormProvider>
  );
};

export default memo(LevelDrawer);
