import { Outlet } from "react-router-dom";
import { DelegateHeader } from "./header/DelegateHeader";
import NotifyBeforeClose from "components/toasts/NotifyBeforeClose";
import Footer from "layouts/auth/Footer";

const DelegateLayout = () => {
  return (
    <>
      {NotifyBeforeClose()}
      <DelegateHeader />
      <div className="main-content-container bg-[#e0e6ee]">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default DelegateLayout;
