import { Outlet } from "react-router-dom";
import { DashboardHeader } from "./header";
import NotifyBeforeClose from "components/toasts/NotifyBeforeClose";
import Footer from "layouts/auth/Footer";

const DashboardLayout = () => {
  return (
    <>
    {
      NotifyBeforeClose()
    }
      <DashboardHeader />
      <div className="main-content-container bg-[#e0e6ee]">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default DashboardLayout;
