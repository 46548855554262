import merge from 'lodash/merge'
import ReactApexChart from 'react-apexcharts'
const useCharts = (options: ReactApexChart["props"]["options"]) => {
    const BASE_OPTIONS = {
        legend: {
            show: true,
            showForSingleSeries: true,
            showForNullSeries: true,
        },
        chart: {
            id: "activity-graph",
        },
    }
    return merge(BASE_OPTIONS, options)


}
export default useCharts