import { useDispatch } from "react-redux";
import {  InputPicker } from "rsuite";
import { ITrackingDataList } from "../../types/tracking-data/index";
import { Link } from "react-router-dom";
import { TrackingDataStatus } from "./constants";
import { SyntheticEvent, useState } from "react";
import { useUpdateActivityStatusMutation } from "store/tracking-data/trackingApi";
import { addNewRowState, addRowActivityData } from "store/tracking-data";
import Row from './Row';
import { PlusCircleIcon } from "@heroicons/react/20/solid";
import Add from './Add'

const TrackingDataLink = (tracking: ITrackingDataList, ) => {
  const dispatch = useDispatch();

  const [newRow, setNewRow] = useState<ITrackingDataList>()

  const [updateActivityStatus, { isLoading: processing }] =
    useUpdateActivityStatusMutation();

  /**
   * for calculating the tracking data sum
   */
  function calculateSum() {
    let sum = 0;
    
    tracking?.data?.map( list => {
      sum += list?.data ?? 0
    })
    

    return sum.toLocaleString("en-us");
  }

  return (
    <>
      {/* {tracking?.isFetching ? (
        <div className="h-[100px]">
          <Loader center size="md" content="Loading..." />
        </div>
      ) : ( */}
        <div
          className="accordion container-accordion bg-white rounded-xl p-3 w-full h-fit  mt-4 mb-4 "
          id={`trackingDataHeader-${tracking?.activityId}`}
          key={tracking?.activityId}
        >
          <div className="accordion-item ">
            <div
              className="accordion-header flex flex-wrap gap-5 justify-between items-start p-4 border-b-2 border-button-blue  text-button-blue mb-4"
              id={`heading-${tracking?.activityId}`}
              key={`heading-${tracking?.activityId}`}
            >
              <div className="button-link-container relative flex items-start text-base text-gray-800 text-left bg-white border-0 rounded-none transition focus:outline-none">
                {/* <img src="/accordion-down-arrow.svg" alt="drop-arrow" className='accordion-drop-arrow cursor-pointer invert' /> */}
                <button
                  className="accordion-button flex gap-1 flex-wrap justify-center lg:justify-start"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  {renderLink()}
                </button>
              </div>
              <div className="flex flex-wrap gap-5 justify-center lg:justify-start">
                <button
                  onClick={() => {
                    dispatch(addNewRowState(true));
                    dispatch(addRowActivityData(tracking));
                    setNewRow(tracking)
                  }} 
                 className=" w-fit px-4 flex gap-2 py-2 bg-primary-blue border border-primary-blue rounded-md text-white hover:text-primary-blue hover:bg-white transition-all duration-500 ease-in-out">
                  <PlusCircleIcon className="w-[20px] h-[20px] ml-2"/>
                  Add New Row</button>
                <InputPicker
                  data={TrackingDataStatus}
                  className="addNewRow-button w-[217px] mr-[20px] h-[37px]"
                  value={tracking?.status}
                  loading={processing}
                  onChange={(value: string, event: SyntheticEvent) =>
                    updateStatus(tracking, value)
                  }
                />
              </div>
            </div>
            <div
              id="collapseOne"
              className="accordion-collapse in show  transition-all ease-out duration-300 rounded-xl overflow-hidden border-2 bg-white p-4 relative overflow-x-scroll md:overflow-x-hidden"
              aria-labelledby={`heading-${tracking?.activityId}`}
              data-bs-parent="#accordionExample"
              key={`accordion-${tracking?.activityId}`}
            >
              <div className="accordion-popup grid grid-cols-5 gap-4 mb-2 data-content">
                <div>
                  <strong>Beginning Date</strong>
                </div>
                <div>
                  <strong>Ending Date</strong>
                </div>
                <div>
                  <strong>Data </strong>
                  <span className="text-blue-600 font-bold">
                    {calculateSum()}
                  </span>{" "}
                </div>
                <div>
                  <strong>Comment</strong>
                </div>
                <div></div>
              </div>
              <div className="accordion-body py-4">
                {renderTrackingDataRows()}
              </div>
            </div>
          </div>
        </div>
      {/* )} */}
    </>
  );

  function renderLink() {
    return tracking?.vmapLink?.map((links, index) => {
    
      return (
        <Link
          key={index}
          to={`/v-map/${links?.path}`}
          target="_blank"
          className={`link-tabs px-5 py-1 rounded-2xl transition-{background} duration-300 ease-in-out text-white hover:bg-white   border activity-${links?.level} bg-primary-blue border border-primary-blue rounded-md text-white hover:text-primary-blue hover:bg-white transition-all duration-500 ease-in-out active:text-white`}
        >
          <span className={` type-${links?.level}`}> {links?.title} </span>
        </Link>
      );
    });
  }

  function renderTrackingDataRows() 
  {
    
    return (
      <>
        {
          newRow &&
          <Add handleClose={() => setNewRow(undefined)}/>
        }
        {tracking?.data?.map((data) => {
          
          if( data?.trackingId )
          {
            return (
              <>
                <Row {...data} />
              </>
            );
          }
        })}
      </>
    );
  }

  async function updateStatus(tracking: ITrackingDataList, value: string) 
  {  
    let activityLevel:string | number  = tracking?.level ?? tracking?.vmapLink?.length
    activityLevel  = Number.isInteger(activityLevel) ? `level${activityLevel}` : activityLevel
    let data = {
      bodyData: {
        type: `${activityLevel}`,
        status: parseInt(value),
      },
      activityId: tracking?.activityId,
    };

    await updateActivityStatus(data);
  }
};

export default TrackingDataLink;
