const SvgComponent = (props:{ width?: string; height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 63.74 63.74"
    {...props}
  >
    <defs>
      <style>{".cls-level3{fill:#462f4f}"}</style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <circle
        cx={31.87}
        cy={31.87}
        r={31.87}
        style={{
          fill: "#a270b1",
        }}
      />
      <path
        d="M32.54 57.82c1.75-4.52.91-8.36-2.33-12.14-.61 5.57-4.46 8.39-8.12 11.62.08-.38.07-.64.18-.81 3.74-6.12 3.86-12.72 2.24-19.42-.93-3.84-.43-7.41.8-11.01.13-.37.2-.75.29-1.13l-.36-.27c-.55.54-1.2 1.01-1.63 1.64-1.81 2.6-2.57 5.65-3.36 8.66-.12.47-.25.94-.37 1.4-.12 0-.25.02-.37.03-.16-.8-.42-1.6-.45-2.4-.12-3.37-.26-6.72.89-10 1.43-4.06 4.18-6.52 8.29-7.32 1.62-.32 3.33-.4 4.97-.28 1.86.14 3 1.4 3.46 3.24.4 1.61.79 3.23 1.2 4.84.72 2.87 2.48 4.72 5.29 5.54.39.12.8.22 1.17.39.62.29.59.71.02.97-2.65 1.22-6.26 1.44-8.8-.84-.67-.6-1.22-1.34-1.85-1.99-.21-.22-.54-.52-.76-.49-.21.03-.5.45-.52.72-.09 1.27-.38 2.62-.07 3.8.67 2.48 1.64 4.87 2.54 7.28 1.23 3.32 2.41 6.66 2.09 10.3-.27 3-1.73 5.41-3.74 7.53-.07.07-.22.05-.67.14Z"
        className="cls-level3"
      />
      <circle cx={35.49} cy={10.65} r={4.73} className="cls-level3" />
    </g>
  </svg>
);
export default SvgComponent;
