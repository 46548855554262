export const MAX_NOTES_LIMIT = 100000;
export const TEXT_INPUT_LIMIT = 100;
export const TEXT_AREA_LIMIT_CALL_MAXIMIZER = 1000;
export const TEXT_AREA_LIMIT = 500;
export const TEXT_ZIP_LIMIT = 10;
export const VMAP_DEFINITION_LIMIT = 50;
export const DIAL_NUMBER_LIMIT = 16;

export const roles: { [key: string]: number } = {
  "Coach Client User": 1,
  Coach: 2,
  "SAS Client": 7,
  "SAS Client Manager": 6,
  "Coach Client Manager": 3,
  "Super Admin": 4,
};
