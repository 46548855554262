import { Drawer, Button, Placeholder } from "rsuite";
import { RootState } from "../../../store/index";
import { useSelector, useDispatch } from "react-redux";
import { showAllDials } from "store/dashboard";
import DialGroup from "./DialGroup";

export const AllDials = (userDetails: any) => {
  const dispatch = useDispatch();
  const { allDials } = useSelector((state: RootState) => state?.dashboardSlice);

  return (
    <Drawer
      open={allDials}
      size="lg"
      onClose={() => dispatch(showAllDials(false))}
      className="dashboard-drawer-modal-content"
    >
      <Drawer.Header>
        <Drawer.Title className="!text-primary-blue !text-[24px] !font-bold rs-drawer-title">All Dials</Drawer.Title>
        <Drawer.Actions>
          <button onClick={() => dispatch(showAllDials(false))} className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500 ml-2">Close</button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <DialGroup {...userDetails} isSlider="0" className="modal-content" />
      </Drawer.Body>
    </Drawer>
  );
};
