import draftToHtml from "draftjs-to-html";
import BulletList from "@tiptap/extension-bullet-list";
import { useEditor, EditorContent, Editor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { Color } from "@tiptap/extension-color";
import ListItem from "@tiptap/extension-list-item";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";
import CharacterCount from "@tiptap/extension-character-count";
import Placeholder from "@tiptap/extension-placeholder";
import { MAX_NOTES_LIMIT } from "utils/constants";
function isHTML(str: string): boolean {
  const htmlRegex = /<.*?>/g;
  return htmlRegex.test(str);
}

const convertToHtml = (draftJsData: string) => {
  try {
    return draftToHtml(JSON.parse(draftJsData));
  } catch (error) {
    if (isHTML(draftJsData)) {
      return `<div>${draftJsData}<div>`;
    }
    try {
      return draftToHtml(
        JSON.parse(
          draftJsData +
            ',"type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"link":""}'
        )
      );
    } catch (error) {
      return `<div></div>`;
    }
  }
};

const EditorContentComponent = ({ editor }: { editor: Editor | null }) => (
  <EditorContent
    editor={editor}
    placeholder="Add Description"
    className={
      editor?.storage?.characterCount?.characters() >= MAX_NOTES_LIMIT
        ? "border-2 border-red-700 rounded-md"
        : "border-gray-400"
    }
  />
);
const Tiptap = ({
  draftJs,
  setValue,
  placeholder,
  withWrapper,
  disabled,
}: {
  draftJs: string;
  setValue: (_val: string) => void;
  placeholder?: string;
  withWrapper?: boolean;
  disabled?: boolean;
}) => {
  BulletList.config = { name: "bulletList" };
  const editor = useEditor({
    editable: !disabled,
    onUpdate: ({ editor }) => {
      setValue(editor.getHTML());
    },
    extensions: [
      Link.configure({
        protocols: ["ftp", "mailto", "https"],
      }),
      CharacterCount.configure({
        limit: MAX_NOTES_LIMIT,
      }),
      Color.configure({ types: [TextStyle.name, ListItem.name] }),
      //   @ts-ignore
      TextStyle.configure({ types: [ListItem.name] }),
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
        },
      }),
      BulletList,
      Placeholder.configure({
        placeholder: placeholder ?? "Add Description",
        // emptyNodeClass: 'my-custom-is-empty-class',
      }),
    ],
    editorProps: {
      attributes: {
        class: "p-0 rounded-md",
      },
    },
    content: convertToHtml(draftJs),
  });
  // With Wrapper it is only used in V-MAP
  return withWrapper ? (
    <div className="flex flex-col">
      <div className="flex w-full justify-between py-5">
        <div className="column text-left">
          <div className="horizontal-text">C - Celebrations</div>
          <div className="horizontal-text">O - Opportunities</div>
          <div className="horizontal-text">A - Avatar Health</div>
          <div className="horizontal-text">C - CANI Commitment</div>
          <div className="horizontal-text">H - Help</div>
        </div>
        <div className="flex">
          {editor?.storage.characterCount.characters()}/{MAX_NOTES_LIMIT}{" "}
          characters
        </div>
      </div>{" "}
      <EditorContentComponent editor={editor} />
    </div>
  ) : (
    <EditorContentComponent editor={editor} />
  );
};

export default Tiptap;
