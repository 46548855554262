import { useLocation } from "react-router-dom";
import { AvatarType } from "sections/dashboard/components/AvatarHealth";
import MyAvatarSection from "sections/preferences/my-avatar";
import { TDelegateAvatarType } from "types/dashboard";
import { getTheHealthId } from "utils/helperFunctions";

interface IAvatarInfoProps {
  percentage: number;
  avatarWidth?: number;
  grow?: string;
  grade?: string;
  gradeType?: string;
  avatarOnly?: boolean;
  health?: { [key: string]: string }[];
  isGlobalAvatar?: {
    isPerformance: boolean;
  };
  isLoggedInUser?: boolean;
  className?: string;
  delegateData?: TDelegateAvatarType;
  delegateId?: string
}

const AvatarInfo = (props: IAvatarInfoProps) => {
  let percentage = props.percentage;



  return (
    <>
      <div className={props.grow}>
        <div className="flex flex-row items-center justify-start gap-[5px]">
          <div
            className={props.className}
            style={{ width: props?.avatarWidth }}
          >
            <MyAvatarSection
              getSingleAvatar={true}
              singleHealthId={getTheHealthId(percentage)}
              singleAvatarStyling={{
                width: "100%",
                height: "100%",
                display: "block",
              }}
              isGlobalAvatar={props.isGlobalAvatar}
              defaultAvatarValues={
                props?.health?.filter(
                  (item) => +item.healthId === getTheHealthId(percentage)
                )[0]
              }
              isLoggedInUser={props?.isLoggedInUser}
              delegateData={props?.delegateData}
              getIdFromUrl={props?.delegateId ? props?.delegateId : ''}
            />
          </div>
          {!props.avatarOnly ? (
            <div className="flex flex-col text-center ml-1">
              <div className="my-[5px]">
                <label
                  className={`avatar-grade ${AvatarType(
                    percentage,
                    "bg"
                  )} pointer-events-none`}
                >
                  {props?.grade}
                </label>
              </div>
              <label
                className={`mt-1  text-sm font-extrabold ${AvatarType(
                  percentage,
                  "text"
                )}`}
              >
                {isNaN(percentage) ? 0 : percentage} %
              </label>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default AvatarInfo;
