import EmailNotification from "../../sections/notification";

const Notification = () => {
    return (
        <div className="main-profile-container  bg-slate-200 py-4">
            <div className="profile-wrapper flex flex-wrap lg:flex-nowrap justify-between gap-10  p-3 mx-auto rounded-xl h-fit  ">
                <div className="tabs w-full flex flex-col gap-4 ">
                    <EmailNotification />
                </div>
            </div>
        </div>
    );
};

export default Notification;
