import { ReactNode, useState, useEffect } from "react";

import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  FilterFn,
  getFilteredRowModel,
  PaginationState,
  OnChangeFn,
  SortingState,
  getPaginationRowModel,
} from "@tanstack/react-table";

//@Replace Can be replaced by custom functions
import { RankingInfo, rankItem } from "@tanstack/match-sorter-utils";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

// TODO MAKE IT MORE STATICALLY TYPE
interface TableProps {
  data: any[];
  columns: any[];
}

type Props = {
  tableData: TableProps | any;
  tableTitle?: string;
  showGlobalFilter?: boolean;
  isPagination?: boolean;
  pageSize?: number;
  pageIndex?: number;
  pageCount?: number;
  isManualPagination?: boolean;
  setPagination?: OnChangeFn<PaginationState>;
  customHeader?: ReactNode;
  tableHeadRowClass?: string;
  tableRowClass?: string;
  tableDataClass?: string;
  tableHeadClass?: string;
  tableFooter?: boolean;
  isFooter?: boolean;
  globalFilter?: string;
  setGlobalFilter?: any;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// ---------DEFAULT CLASSNAME-------------
const defaultTableHeadRowClass = "mainTable-head";
const defaultTableHeadClass =
  "default-table-head px-3 py-3 font-bold text-left capitalize align-middle bg-transparent border-b border-collapse shadow-none text-xxs border-b-solid tracking-none whitespace-nowrap text-black opacity-70";
const defaultTableRowClass =
  "default-table-row hover:bg-gray-50 hover:cursor-pointer";
const defaultTableDataClass =
  "default-table-data p-2 text-left align-middle bg-transparent border-b whitespace-nowrap shadow-transparent";

//------------------------------COMPONENT--------------------------------------

const Table = ({
  tableData: { data, columns },
  tableTitle,
  showGlobalFilter,
  isPagination,
  pageCount,
  customHeader,
  tableHeadRowClass,
  tableHeadClass,
  tableRowClass,
  tableDataClass,
  isFooter,
}: Props) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    pageCount: pageCount ?? -1,
    state: {
      globalFilter,
      pagination: {
        pageSize: pageSize || 10,
        pageIndex: pageIndex || 0,
      },
      sorting,
    },
    autoResetPageIndex: false,
    globalFilterFn: fuzzyFilter,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: false,
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div>
      <div className="table-container flex flex-wrap sm:p-4  w-10/12 mx-auto justify-center items-center h-full ">
        <div className="flex-none w-full max-w-full sm:px-3">
          <div className="relative flex flex-col min-w-0 mb-6 break-words bg-clip-border ">
            {tableTitle ? (
              <div className="table-title  md:mb-8 border-b-2 border-b-solid border-b-button-blue p-4  text-button-blue mt-0 mb-[20px] ml-[20px] mr-[20px] md:m-0 md:mr-0  ">
                <h6 className=" text-lg uppercase">{tableTitle}</h6>
              </div>
            ) : null}
            <div className="search-table-wrapper rounded-xl border-2 bg-white p-4 relative overflow-x-auto ml-[10px] mr-[10px] md:ml-0 md:mr-0">
              <div className="wrapper-delegate flex justify-start gap-5 flex-wrap md:flex-nowrap md:justify-end">
                {customHeader ? customHeader : null}
                {showGlobalFilter ? (
                  <input
                    value={globalFilter ?? ""}
                    onChange={(value) =>
                      setGlobalFilter(String(value.target.value))
                    }
                    className="delegate-search-box block md:p-2 md:w-[200px] pl-2 h-[50px] mt-3 md:mt-0 ml-2 md:ml-0 md:h-auto md:pl-3  text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Search all columns..."
                  />
                ) : null}
              </div>
              <div className="flex-auto px-0 pt-0 pb-2  mt-3">
                <div className="p-0  ">
                  <table className="items-center w-full mb-0 align-top border-collapse text-bl-500">
                    <thead className="align-bottom bg-gray-50">
                      {table.getHeaderGroups().map((headerGroup) => (
                        <tr
                          key={headerGroup.id}
                          className={
                            tableHeadRowClass
                              ? tableHeadRowClass
                              : defaultTableHeadRowClass
                          }
                        >
                          {headerGroup.headers.map((header) => (
                            <th
                              key={header.id}
                              className={
                                tableHeadClass
                                  ? defaultTableHeadClass + " " + tableHeadClass
                                  : defaultTableHeadClass
                              }
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => (
                        <tr
                          className={
                            tableRowClass
                              ? defaultTableRowClass + " " + tableRowClass
                              : defaultTableRowClass
                          }
                          key={row.id}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <td
                              className={
                                tableDataClass
                                  ? defaultTableDataClass + tableDataClass
                                  : defaultTableDataClass
                              }
                              key={cell.id}
                            >
                              <p
                                className={
                                  "table-data-para pl-2 whitespace-break-spaces"
                                }
                              >
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </p>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                    {isFooter
                      ? table.getFooterGroups().map((footerGroup) => (
                          <tr key={footerGroup.id}>
                            {footerGroup.headers.map((header) => (
                              <th key={header.id}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.footer,
                                      header.getContext()
                                    )}
                              </th>
                            ))}
                          </tr>
                        ))
                      : null}
                  </table>
                </div>
              </div>
              <div className="table-pagination bg-white py-3">
                {isPagination ? (
                  <div className="flex items-center gap-1 md:gap-5 justify-center mt-2 mb-2 ">
                    <button
                      type="button"
                      className="border rounded-lg p-2"
                      onClick={() => table.setPageIndex(0)}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <img
                        src="/assets/chevron-double-left.svg"
                        alt="double left arrow "
                      />
                    </button>
                    <button
                      type="button"
                      className="border rounded-lg p-2"
                      onClick={() => table.previousPage()}
                      disabled={!table.getCanPreviousPage()}
                    >
                      <img src="/assets/chevron-left.svg" alt="left arrow" />
                    </button>
                    <button
                      type="button"
                      className="border rounded-lg p-2"
                      onClick={() => table.nextPage()}
                      disabled={!table.getCanNextPage()}
                    >
                      <img src="/assets/chevron-right.svg" alt="right arrow" />
                    </button>
                    <button
                      type="button"
                      className="border rounded-lg p-2"
                      onClick={() =>
                        table.setPageIndex(table.getPageCount() - 1)
                      }
                      disabled={!table.getCanNextPage()}
                    >
                      <img
                        src="/assets/chevron-double-right.svg"
                        alt="double right arrow"
                      />
                    </button>
                    <span className="flex items-center gap-1">
                      <div>Page</div>
                      <strong>
                        {table.getState().pagination.pageIndex + 1} of{" "}
                        {table.getPageCount()}
                      </strong>
                    </span>
                    {/* <span className="flex items-center gap-1">
                      | Go to page:
                      <input
                        type="number"
                        min={0}
                        defaultValue={table.getState().pagination.pageIndex + 1}
                        onChange={(e) => {
                          const page = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          table.setPageIndex(page);
                        }}
                        className="border p-1 rounded w-16"
                      />
                    </span> */}
                    {/* <select
                      value={table.getState().pagination.pageSize}
                      onChange={(e) => {
                        table.setPageSize(Number(e.target.value));
                      }}
                    >
                      {[10, 20, 30, 40, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
