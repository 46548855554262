import { combineReducers } from "redux";
import { appApi } from "./apis/apiSlice";
import preferenceSliceReducer from './preferences'
import trackingDataSlice from './tracking-data'
import dashboardSlice from './dashboard'

import authReducer from './auth/authStore'
import userManagementSlice  from './tools/manage-users';
// ----------------------------------------------------------------------

const rootReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  preferenceSlice:preferenceSliceReducer,
  trackingDataSlice:trackingDataSlice,
  dashboardSlice: dashboardSlice,
  userManagementSlice:userManagementSlice,
  auth: authReducer
});

export { rootReducer };
