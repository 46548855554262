import * as React from "react";

import BlazerShirt from "./BlazerShirt";
import BlazerSweater from "./BlazerSweater";
import CollarSweater from "./CollarSweater";
import GraphicShirt from "./GraphicShirt";
import Hoodie from "./Hoodie";
import Overall from "./Overall";
import ShirtCrewNeck from "./ShirtCrewNeck";
import ShirtScoopNeck from "./ShirtScoopNeck";
import ShirtVNeck from "./ShirtVNeck";
import { ClotheOption, Selector } from "../../options";

export default function Clothes() {
  return (
    <Selector option={ClotheOption} defaultOption={BlazerShirt}>
      <BlazerShirt />
      <BlazerSweater />
      <CollarSweater />
      {/* NOTE: THIS WAS REMOVED BECAUSE THERE WERE NO FIELDS FOR THIS IN BACKEND */}
      {/* <GraphicShirt /> */}
      <Hoodie />
      <Overall />
      <ShirtCrewNeck />
      <ShirtScoopNeck />
      <ShirtVNeck />
    </Selector>
  );
}
