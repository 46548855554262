export const AvatarHealth = (dateDiff: number) => {
  if (dateDiff >= 0) {
    return 100;
  }
  if (dateDiff < 0 && dateDiff > -10) {
    return 80;
  }
  if (dateDiff < -9 && dateDiff > -16) {
    return 60;
  }
  if (dateDiff < -15 && dateDiff > -21) {
    return 40;
  }
  if (dateDiff < -20 && dateDiff > -26) {
    return 20;
  }

  return 0;
};

export const AvatarGrade = (percent: number) => {
  if (percent >= 90) {
    return "A";
  }
  if (percent < 90 && percent >= 80) {
    return "B";
  }
  if (percent < 80 && percent >= 60) {
    return "C";
  }
  if (percent < 60 && percent >= 40) {
    return "D";
  }
  return "F";
};

export const AvatarType = (percent: number, property: string = "bg") => {
  if (percent >= 90) {
    return property === "bg"
      ? "bg-lime-400  border-lime-400 hover:text-lime-600 hover:bg-white transition-all ease-in-out duration-500"
      : "text-lime-400";
  }
  if (percent < 90 && percent >= 80) {
    return property === "bg"
      ? "bg-cyan-400 border-cyan-400  hover:text-cyan-600 hover:bg-white transition-all ease-in-out duration-500"
      : "text-cyan-400";
  }
  if (percent < 80 && percent >= 60) {
    return property === "bg"
      ? "bg-purple-400 border-purple-400  hover:text-purple-600 hover:bg-white  transition-all ease-in-out duration-500"
      : "text-purple-400";
  }
  if (percent < 60 && percent >= 40) {
    return property === "bg"
      ? "bg-amber-400 border-amber-300 hover:text-amber-400 hover:bg-white transition-all ease-in-out duration-500"
      : "text-amber-300";
  }
  return property === "bg"
    ? "bg-orange-500 border-orange-500 hover:text-orange-600 hover:bg-white transition-all ease-in-out duration-500"
    : "text-orange-500";
};
