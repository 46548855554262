import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  IDashboardAvatarActivity,
  TActivityDetailsParam,
  TDashboardDataResponse,
  TFilterDates,
  TsetFilter,
} from "../../types/dashboard";
import { boolean } from "yup";

export interface ListState {
  activityList: IDashboardAvatarActivity[];
  isActivityAvatar: boolean;
  isGroupAvatar: boolean;
  groupAvatarType: string;
  isPerformanceAvatar: boolean | number;
  quickUpdate: boolean;
  quickPrint: boolean;
  addActivity: boolean;
  allDials: boolean;
  filterDates: TFilterDates;
  dashboardActivityList: IDashboardAvatarActivity[] | null;
  trackingData: TActivityDetailsParam;
  filterData: TsetFilter;
  showDelegateGraph: boolean | number;
  delegateName: string;
  delegateType: string;
  dashboardData?: any;
  performPercentage: any;
  performanceGrade: string;
  dashboardApiData: TDashboardDataResponse | null;
}

const initialState: ListState = {
  activityList: [
    {
      target: true,
      id: 0,
      title: "",
      type: "",
      actual: 0,
      delegates: 0,
      dueDate: "",
      endDate: new Date(),
      goal: 0,
      inAvatar: false,
      inDashboard: false,
      inProfile: false,
      inTracking: false,
      isAccumulate: 0,
      runRate: 0,
      seasonalGoal: "",
      sign: "",
      startDate: new Date(),
      status: 0,
      vMapId: 0,
      delegateTo: "",
      description: "",
      daily: 0,
      weekly: 0,
      monthly: 0,
      quarterly: 0,
      annually: 0,
      trackSign: "",
      showOnDashboard: false,
      includeInReporting: false,
      includeInAvatar: false,
      includeInProfile: false,
      seasonalSign: "",
      successScale: 0,
    },
  ],
  isActivityAvatar: false,
  isGroupAvatar: false,
  groupAvatarType: "",
  isPerformanceAvatar: false,
  quickUpdate: false,
  quickPrint: false,
  addActivity: false,
  allDials: false,
  filterDates: {
    filterStartDate: new Date(),
    filterEndDate: new Date(),
    trackingStartDate: new Date(),
    trackingEndDate: new Date(),
  },
  dashboardActivityList: null,
  trackingData: { level: "", id: 0 },
  filterData: {
    activeVMap: [],
    activeValue: [],
    activeKpi: [],
    activeStrategy: "",
    activeProject: "",
    activeCriticalActivity: "",
    activeDelegate: [],
    startDate: new Date(),
    activeStatus: 1,
    endDate: new Date(),
    activeProfile: 0,
  },
  showDelegateGraph: false,
  delegateName: "",
  delegateType: "",
  dashboardData: false,
  performPercentage: 0,
  performanceGrade: "",
  dashboardApiData: null,
};

export const dashboardSlice = createSlice({
  initialState,
  name: "dashboard-slice",
  reducers: {
    activityAvatar: (state, action: PayloadAction<any>) => {
      state.activityList = action.payload;
    },
    showActivityAvatar: (state, action: PayloadAction<any>) => {
      state.isActivityAvatar = action.payload;
    },
    showPerformanceAvatar: (state, action: PayloadAction<any>) => {
      state.isPerformanceAvatar = action.payload;
    },
    showQuickprint: (state, action: PayloadAction<boolean>) => {
      state.quickPrint = action.payload;
    },
    showQuickUpdate: (state, action: PayloadAction<boolean>) => {
      state.quickUpdate = action.payload;
    },
    showAddActivity: (state, action: PayloadAction<boolean>) => {
      state.addActivity = action.payload;
    },
    showAllDials: (state, action: PayloadAction<boolean>) => {
      state.allDials = action.payload;
    },
    saveFilterDates: (state, action: PayloadAction<TFilterDates>) => {
      state.filterDates = action.payload;
    },
    updateDashboardActivity: (state, action: PayloadAction<any>) => {
      state.dashboardActivityList = action.payload;
    },
    showTrackingData: (state, action: PayloadAction<TActivityDetailsParam>) => {
      state.trackingData = action.payload;
    },
    showGroupAvatar: (state, action: PayloadAction<boolean>) => {
      state.isGroupAvatar = action.payload;
    },
    setGroupAvatarType: (state, action: PayloadAction<string>) => {
      state.groupAvatarType = action.payload;
    },
    setFilterData: (state, action: PayloadAction<TsetFilter>) => {
      state.filterData = action.payload;
    },
    setShowDelegateGraph: (state, action: PayloadAction<boolean | number>) => {
      state.showDelegateGraph = action.payload;
    },
    setDelegateName: (state, action: PayloadAction<string>) => {
      state.delegateName = action.payload;
    },
    setDelegateType: (state, action: PayloadAction<string>) => {
      state.delegateType = action.payload;
    },
    setDashboardData: (state, action: PayloadAction<any>) => {
      state.dashboardData = action.payload;
    },
    setDashboardPerformance: (state, action: PayloadAction<any>) => {
      state.performPercentage = action.payload;
    },
    setDashboardApiData: (state, action: PayloadAction<any>) => {
      state.dashboardApiData = action.payload;
    },
    setDashboardGrad: (state, action: PayloadAction<any>) => {
      state.performanceGrade = action.payload;
    },
  },
});

export const dashboardSliceInitialState = initialState;
export const {
  activityAvatar,
  showActivityAvatar,
  showPerformanceAvatar,
  showQuickprint,
  showQuickUpdate,
  showAddActivity,
  showAllDials,
  saveFilterDates,
  updateDashboardActivity,
  showTrackingData,
  showGroupAvatar,
  setGroupAvatarType,
  setFilterData,
  setShowDelegateGraph,
  setDelegateType,
  setDelegateName,
  setDashboardData,
  setDashboardPerformance,
  setDashboardGrad,
  setDashboardApiData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
