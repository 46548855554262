import Loading from "components/loading";
import AuthGuard from "guards/AuthGuard";
import GuestGuard from "guards/GuestGuard";
import AuthLayout from "layouts/auth";
import DashboardLayout from "layouts/dashboard";
import CallMaximizer from "pages/call-maximizer";
import { lazy, LazyExoticComponent, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import PreferencesLayout from "layouts/preferences-layout";
import ManageQualifiers from "pages/preferences/qualifier";
import NotFound from "pages/frontend-sandbox/404screen";
import EmailNotification from "./../pages/notification";
import DelegateLayout from "layouts/dashboard/Delegate";

const Loadable =
  (Component: LazyExoticComponent<() => JSX.Element>) => (props: any) => {
    return (
      <Suspense fallback={<Loading />}>
        <Component {...props} />
      </Suspense>
    );
  };

//-------------------------AUTH PAGES----------------------------
const Login = Loadable(lazy(() => import("../pages/auth/login")));
const ForgotPassword = Loadable(
  lazy(() => import("../pages/auth/forgot-password"))
);
const ChangePassword = Loadable(
  lazy(() => import("../pages/auth/change-password"))
);

//--------------------------TOOLS------------------------------------
const ManageRoles = Loadable(lazy(() => import("../pages/tools/manage-roles")));
const ManageUsers = Loadable(lazy(() => import("../pages/tools/manage-users")));
const MyProfile = Loadable(lazy(() => import("../pages/tools/my-profile")));
const ManageAccess = Loadable(
  lazy(() => import("../pages/tools/manage-access"))
);

//--------------------------PREFERENCES------------------------------------
const Settings = Loadable(lazy(() => import("../pages/preferences/settings")));
const PreferenceActivity = Loadable(
  lazy(() => import("../pages/preferences/activity"))
);
const DashboardProfiles = Loadable(
  lazy(() => import("../pages/preferences/profiles"))
);
const AddDashboardProfile = Loadable(
  lazy(() => import("../pages/preferences/profiles/add-profile"))
);
const EditDashboardProfile = Loadable(
  lazy(() => import("../pages/preferences/profiles/edit-profile"))
);
const PreferenceDelegate = Loadable(
  lazy(() => import("../pages/preferences/delegate"))
);
const MyAvatar = Loadable(lazy(() => import("../pages/preferences/my-avatar")));
//----------------
const UserActivity = Loadable(
  lazy(() => import("../pages/activity/user-activity"))
);

const TrackingData = Loadable(lazy(() => import("../pages/tracking-data")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));

const MyClients = Loadable(lazy(() => import("../pages/my-clients")));

const VMap = Loadable(lazy(() => import("../pages/v-map")));

const CoachPathAwareness = Loadable(
  lazy(() => import("../pages/coach-path/awareness"))
);

const CoachPathAction = Loadable(
  lazy(() => import("../pages/coach-path/action"))
);
const CoachPathAchievement = Loadable(
  lazy(() => import("../pages/coach-path/achievement"))
);
const CoachPathActionItems = Loadable(
  lazy(() => import("../pages/coach-path/action-item-checklist"))
);
const CoachingReadiness = Loadable(
  lazy(() => import("../pages/coach-path/coaching-readiness"))
);
const SweetSpotAnalysis = Loadable(
  lazy(() => import("../pages/coach-path/sweet-spot-analysis"))
);
const CoreDiscipline = Loadable(
  lazy(() => import("../pages/coach-path/core-discipline"))
);

export const router = createBrowserRouter([
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "change-password/:token",
        element: <ChangePassword />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    errorElement: (
      <NotFound
        message={
          "Unfortunately, there was a problem. Please try again. If the problem continues, get in touch with your Admin."
        }
      />
    ),
    children: [
      {
        index: true,
        element: <MyProfile />,
      },
      {
        path: "call-maximizer",
        element: <CallMaximizer />,
      },
      {
        path: "activity",
        children: [
          {
            path: "user",
            element: <UserActivity />,
          },
        ],
      },
      // ---------------USER TOOLS------------------
      {
        path: "tools",
        children: [
          {
            path: "manage-roles",
            element: <ManageRoles />,
          },
          {
            path: "manage-users",
            element: <ManageUsers />,
          },
          {
            path: "manage-access",
            element: <ManageAccess />,
          },
          {
            path: "my-profile",
            element: <MyProfile />,
          },
        ],
      },
      // ---------------USER PREFERENCES------------------
      {
        path: "preferences",
        element: <PreferencesLayout />,
        children: [
          {
            path: "vmap-definition",
            element: <PreferenceActivity />,
          },
          {
            path: "dashboard-profiles",
            element: <DashboardProfiles />,
          },

          {
            path: "dashboard-profiles/add-profile",
            element: <AddDashboardProfile />,
          },
          {
            path: "dashboard-profiles/edit-profile/:id",
            element: <EditDashboardProfile />,
          },

          {
            path: "my-avatar",
            element: <MyAvatar />,
          },
          {
            index: true,
            path: "general-settings",
            element: <Settings />,
          },
          {
            path: "delegate",
            element: <PreferenceDelegate />,
          },
          {
            path: "vmap-qualifiers",
            element: <ManageQualifiers />,
          },
        ],
      },
      {
        path: "tracking-data",
        element: <TrackingData />,
      },

      //-------------MY-CLIENTS--------------------------
      {
        path: "client-list",
        element: <MyClients />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "dashboard/:level/:id",
        element: <Dashboard />,
      },
      {
        path: "v-map",
        element: <VMap />,
      },

      {
        path: "v-map/:vmapId/:level/:id",
        element: <VMap />,
      },

      //-----------------COACH PATH ROUTES-------------------------
      {
        path: "coach-path",
        children: [
          {
            path: "awareness",
            element: <CoachPathAwareness />,
          },
          {
            path: "action",
            element: <CoachPathAction />,
          },
          {
            path: "achievement",
            element: <CoachPathAchievement />,
          },
          {
            path: "action-items",
            element: <CoachPathActionItems />,
          },
        ],
      },

      {
        path: "/coaching-readiness",
        element: <CoachingReadiness />,
      },
      {
        path: "/sweet-spot-analysis",
        element: <SweetSpotAnalysis />,
      },
      {
        path: "/core-discipline",
        element: <CoreDiscipline />,
      },
    ],
  },
  {
    path: "/*",
    element: <NotFound />,
  },
  {
    path: "/group-update/:type/:key",
    element: <DelegateLayout />,
    children: [
      {
        index: true,
        element: <EmailNotification />,
      },
    ],
  },
]);
