import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";

// ----------------------------------user------------------------------------

export default function GuestGuard({ children }: { children: JSX.Element }) {
  // TRY TO CLEAR THE useGetUserQuery CACHE

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  return <>{children}</>;
}
