import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { Dispatch, SetStateAction } from "react";

interface IPasswordEye {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

const PasswordEye = ({ show, setShow }: IPasswordEye) => {
  return (
    <div className="absolute h-[44px] w-[40px] inset-y-0 right-0 pr-3 flex items-center  text-sm leading-5">
      {show ? (
        <EyeIcon onClick={() => setShow(!show)} width={"100%"} height={"100%"}/>
      ) : (
        <EyeSlashIcon onClick={() => setShow(!show)} />
      )}
    </div>
  );
};

export default PasswordEye;
