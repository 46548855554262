import differenceInDays from "date-fns/differenceInDays";
import { AvatarGrade } from "./../../dashboard/components/AvatarHealth";
import moment from "moment";
import {
  TDashboardDataResponse,
  IDashboardAvatarActivity,
  TFilterDates,
  TTRackingData,
} from "../../../types/dashboard";

export const RunRateCalculate = (
  list: IDashboardAvatarActivity,
  filterDates: TFilterDates,
  dashboardData: any,
  userDetails: any
) => {
  // const toast = useToaster();
  return runRate(list, filterDates, dashboardData, userDetails);

  function runRate(
    list: IDashboardAvatarActivity,
    filterDates: TFilterDates,
    dashboardData: any,
    userDetails: any
  ) {
    if (!list) return;
    try {
      let finalDueDate = getActivityDueDate(list, filterDates, dashboardData);
      const goalValues = calculateGoal(list, filterDates, dashboardData);

      let actualGoal = list?.goal ?? 0;
      let goal = goalValues?.calGoal;
      let annualSeasonalGoal = goalValues.annualSeasonalGoal;
      let sign = goalValues?.sign ?? "";

      let trackingData: TTRackingData = findTrackingData(dashboardData, list);
      let lastTrackingDate = new Date(trackingData?.endDate);

      if (list?.isAccumulate == 1) {
        return accumulate(
          trackingData?.total,
          goal,
          finalDueDate?.endingDate,
          annualSeasonalGoal,
          lastTrackingDate
        );
      }

      return runRatecalculator(
        trackingData?.total,
        goal,
        annualSeasonalGoal,
        lastTrackingDate,
        sign,
        actualGoal
      );
    } catch (error) {
      return {
        runRate: 0,
        html: "",
        isAccumulate: false,
        goal: 0,
        actual: 0,
        annualSeasonalGoal: 0,
        runRateFormatted: numberFormat(0, 2),
        goalFormatted: numberFormat(0, 2),
        actualFormatted: numberFormat(0, 2),
        lastTrackingDateDiff: 0,
        lastTrackingDate: false,
        actualPercentage: 0,
        grade: getGrade(0),
      };
    }
  }

  function findTrackingData(
    dashboardData: any,
    list: IDashboardAvatarActivity
  ) {
    switch (list?.type) {
      case "level2":
        return dashboardData?.trackingData?.filter(
          (data: any) => data?.kpiId == list?.id
        )
          ?.values()
          ?.next()?.value;
        break;

      case "level3":
        return dashboardData?.trackingData?.filter(
          (data: any) => data?.strategyId == list?.id
        )
          ?.values()
          ?.next()?.value;
        break;

      case "level4":
        return dashboardData?.trackingData?.filter(
          (data: any) => data?.projectId == list?.id
        )
          ?.values()
          ?.next()?.value;
        break;

      case "level5":
        return dashboardData?.trackingData?.filter(
          (data: any) => data?.criticalActivityId == list?.id
        )
          ?.values()
          ?.next()?.value;
        break;
    }
  }

  function accumulate(
    actual: any,
    goal: any,
    lastdate: any,
    annualSeasonalGoal: any,
    trackingData: Date
  ) {
    let $html = "";
    let momentum = 0,
      runrate = 0,
      _actual = 0;
    if (actual.trackingValue) {
      let actualTracking = actual.trackingValue.split(",");
      let actuallen = actualTracking.length;
      if (actuallen) {
        _actual = actual.total;
        if (goal != 0) {
          try {
            momentum = _actual / goal;
            runrate = Math.round(momentum * 100);
            runrate = parseFloat(runrate?.toFixed(2));
            _actual = parseFloat(_actual?.toFixed(2));
            goal = goal?.toFixed(2);
            momentum = parseFloat(momentum?.toFixed(2));
          } catch (e) {
            console.log(e);
          }
          $html = `Last Tracking Data based on tracking date (${lastdate}) = ${_actual}, and Goal = ${goal}
                  Run Rate = ${_actual}/${goal} = ${momentum},
                  Run Rate (%) = ${runrate}%`;
        }
      }
    }

    return {
      runRate: runrate,
      html: $html,
      isAccumulate: true,
      goal: goal,
      actual: _actual,
      actualPercentage: Math.round((actual / goal) * 100),
      annualSeasonalGoal: annualSeasonalGoal,
      runRateFormatted: numberFormat(runrate, 2),
      goalFormatted: numberFormat(goal, 2),
      actualFormatted: numberFormat(actual, 2),
      lastTrackingDateDiff: trackingDataLastUpdated(trackingData),
      lastTrackingDate: moment(trackingData)?.isValid() ?? false,
      grade: getGrade(runrate),
    };
  }

  function runRatecalculator(
    actual: number,
    goal: any,
    annualSeasonalGoal: any,
    trackingData: Date,
    sign: string,
    actualGoal: number
  ) {
    let runRate: number = 0,
      runRatePer = 0;
    actual = actual ? actual : 0;

    try {
      actualGoal = sign == "@" ? annualSeasonalGoal : actualGoal;

      runRate = goal && actual ? actual / goal : 0;
      runRatePer = runRate * 100;
      runRatePer = Math.round(runRatePer);
      runRate = runRate;
      actual = actual;
      runRatePer = runRatePer;
    } catch (error) {
      runRatePer = parseFloat(runRatePer?.toFixed(2));
    }

    let $html = `Actual = ${numberFormat(actual, 2)}
    Goal = ${annualSeasonalGoal}, Run Rate = ${actual}/ ${annualSeasonalGoal} = ${runRate},
    RunRate(%) = ${runRate}*100 = ${runRatePer}%`;

    return {
      runRate: runRatePer,
      runRateFormatted: numberFormat(runRatePer, 2),
      goalFormatted: numberFormat(actualGoal, 2),
      actualFormatted: numberFormat(actual, 2),
      html: $html,
      isAccumulate: false,
      goal: goal,
      actual: actual,
      actualPercentage: actual ? Math.round((actual / actualGoal) * 100) : 0,
      annualSeasonalGoal: annualSeasonalGoal,
      lastTrackingDateDiff: trackingDataLastUpdated(trackingData),
      lastTrackingDate: moment(trackingData)?.isValid() ?? false,
      grade: getGrade(runRatePer),
    };
  }

  function calculateGoal(
    list: IDashboardAvatarActivity,
    filterDates: TFilterDates,
    dashboardData: TDashboardDataResponse
  ) {
    let seasonalGoalData = 0;
    let annualSeasonalGoal = 0;
    let calGoal: number | string = 0;
    let dateDiff = 0;
    let $html: string = "";

    try {
      let finalDueDate = getActivityDueDate(list, filterDates, dashboardData);
      dateDiff = finalDueDate ? finalDueDate?.startEndDaysDiff : 1;

      if (list?.sign == "@") {
        if (list?.seasonalGoal) {
          const seasonalArray = list?.seasonalGoal?.split(",");
          let start = parseInt(moment(finalDueDate.beginingDate).format("M"));
          let end = parseInt(moment(finalDueDate.endDate).format("M"));
          let activityEnd = moment(list?.endDate).format("M");
          let goalIndexes = getGoalIndexes(
            start,
            end,
            finalDueDate?.finalDatedaysDiff
          );

          seasonalArray.map(function (val, index) {
            let istrue = goalIndexes.findIndex((niddle) => niddle === index);
            if (istrue >= 0) {
              if (list?.isAccumulate) {
                if (end - 1 == index) seasonalGoalData = parseFloat(val);
              } else seasonalGoalData += parseFloat(val);
            }

            let fixedStart = finalDueDate?.startDate?.format("M");
            let fixedEnd = finalDueDate?.endingDate?.format("M");

            if (
              index >= parseInt(fixedStart) - 1 &&
              index <= parseInt(fixedEnd) - 1
            ) {
              if (list?.isAccumulate) {
                annualSeasonalGoal = parseFloat(val);
              } else annualSeasonalGoal += parseFloat(val);
            }
          });
          calGoal = seasonalGoalData;
        }
      } else {
        const goal = list?.goal ?? 0;

        if (list?.isAccumulate) {
          calGoal = finalDueDate.finalDateMonthsDiff >= 0 ? goal : 0;
        } else if (goal > 0) {
          let daysEndFrom = moment(finalDueDate.endDate).daysInMonth();
          let currentDay = parseInt(finalDueDate.startDate.format("D"));
          if (
            daysEndFrom ==
              parseInt(moment(finalDueDate.endDate).format("DD")) &&
            finalDueDate.finalDateMonthsDiff >= 0 &&
            currentDay == 1
          ) {
            calGoal =
              goal /
              (finalDueDate.startEndMonthsDiff /
                finalDueDate.finalDateMonthsDiff);
          } else if (finalDueDate.finalDatedaysDiff >= 0) {
            try {
              let finalEndDate = finalDueDate.startEndDaysDiff
                ? finalDueDate.startEndDaysDiff
                : 1;
              dateDiff = finalDueDate.finalDatedaysDiff;
              dateDiff = dateDiff ? dateDiff : 1;
              $html += ` Date difference between Assign and Due date = ${dateDiff}, `;
              let runGoal: number = goal / finalEndDate;
              $html += ` ${goal}/${finalEndDate} = ${runGoal}, `;
              calGoal = (runGoal * dateDiff)?.toFixed(2);
              $html += ` ${runGoal} * ${dateDiff} = ${calGoal} `;
            } catch (e) {
              console.log(e);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    return {
      calGoal: calGoal,
      html: $html,
      annualSeasonalGoal: annualSeasonalGoal,
      sign: list?.sign,
    };
  }

  function getGoalIndexes(
    start: number,
    end: number | string,
    finalDatedaysDiff: number
  ) {
    let indexes = [],
      goalIndex = start;

    if (finalDatedaysDiff >= 0) {
      let final = start + 12;
      for (let count = start; count <= final; count++) {
        if (count >= goalIndex) {
          indexes.push(goalIndex - 1);
          if (end == goalIndex) return indexes;
          goalIndex++;
        }
        if (goalIndex == 13) goalIndex = 1;
      }
    }
    return indexes;
  }

  function getActivityDueDate(
    list: IDashboardAvatarActivity,
    filterDates: TFilterDates,
    dashboardData: TDashboardDataResponse
  ) {
    const trackingValues = getTrackingValues(list, dashboardData);

    let date = {
      filterStart: moment().subtract(12, "months"),
      filterEnd: moment(),
      startDate: moment(list?.startDate),
      endDate: moment(list?.endDate),
      lastTracking: moment(trackingValues?.endDate),
      beginingDate: filterDates?.filterStartDate
        ? moment(filterDates?.filterStartDate)
        : moment().subtract(12, "months"),
      endingDate: filterDates
        ? moment(new Date(filterDates?.filterEndDate), "yyyy-MM-dd")
        : moment(),
    };
    let startDate = date?.beginingDate?.isSameOrAfter(date.startDate)
      ? date.beginingDate
      : date.startDate;
    let endDate = date.endingDate.isSameOrBefore(date.endDate)
      ? date.endingDate
      : date.endDate;
    endDate =
      date.lastTracking.isSameOrBefore(endDate) &&
      date.lastTracking.isSameOrAfter(startDate)
        ? date.lastTracking
        : endDate;

    let finalDates = {
      startDate: startDate,
      endDate: endDate,
      beginingDate: date?.beginingDate,
      endingDate: date?.endingDate,
      startEndDaysDiff: date.endDate.diff(date.startDate, "days", true),
      startEndMonthsDiff: Math.round(
        date.endDate.diff(date.startDate, "months", true)
      ),
      finalDatedaysDiff: endDate.diff(startDate, "days", true),
      finalDateMonthsDiff: Math.round(endDate.diff(startDate, "months", true)),
    };

    return finalDates;
  }

  // RETURN THE GRADES BASED ON THE PERCENTAGE
  function getGrade(percent: number) {
    return AvatarGrade(percent);
  }

  function getTrackingValues(
    list: IDashboardAvatarActivity,
    dashboardData: any
  ) {
    switch (list?.type) {
      case "level2":
        return dashboardData?.trackingData
          ?.filter((data: any) => data?.kpiId == list?.id)
          ?.values()
          ?.next()?.value;

      case "level3":
        return dashboardData?.trackingData
          ?.filter((data: any) => data?.strategyId == list?.id)
          ?.values()
          ?.next()?.value;
        break;

      case "level4":
        return dashboardData?.trackingData
          ?.filter((data: any) => data?.projectId == list?.id)
          ?.values()
          ?.next()?.value;
        break;

      case "level5":
        return dashboardData?.trackingData
          ?.filter((data: any) => data?.criticalActivityId == list?.id)
          ?.values()
          ?.next()?.value;
        break;

      default:
        break;
    }
  }

  function numberFormat(value: number, digit: number) {
    try {
      return new Intl.NumberFormat("en-NZ", {
        minimumFractionDigits: 2,
      }).format(value);
    } catch (error) {
      return value;
    }
  }

  function trackingDataLastUpdated(date: Date) {
    let currentDate = moment();
    let trackingLastDate = moment(date);

    let diff = currentDate.diff(trackingLastDate, "days");

    return isNaN(diff) ? 0 : diff;
  }
};
