import { Drawer, Button } from "rsuite";
import { RootState } from "../../../store/index";
import { useSelector, useDispatch } from "react-redux";
import { showQuickprint } from "store/dashboard";
import format from "date-fns/format";
import { PrinterIcon } from "@heroicons/react/20/solid";
import { ReactInstance, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";

export const QuickPrint = () => {
  const dispatch = useDispatch();
  const { quickPrint } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const param  = useParams()
 
  const { data: activityData } = useDashboardFetchDelegateDataQuery({key:param?.key ?? "", type: param?.type ?? ""})

  const printRef = useRef<ReactInstance | HTMLDivElement | null>(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  
  return (
    <Drawer
      open={quickPrint}
      size="lg"
      onClose={() => dispatch(showQuickprint(false))}
    >
      <Drawer.Header>
        <Drawer.Title className="!text-primary-blue !text-[24px] !font-bold">Quick Print</Drawer.Title>
        <Drawer.Actions>
          <Button appearance="primary" onClick={handlePrint}>
            <PrinterIcon width={20} height={20} />
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body
        //@ts-ignore
        ref={printRef}
      >
        {activityData?.dataArray?.map((item:any, key:number) => { 
        
          return (
            <>
              <h4 className="text-lg mt-5">
                {format(new Date(item[0]?.endDate), "MMMM dd, yyyy")}
              </h4>
              {renderActivityList(item)}
            </>
          );
        })}
      </Drawer.Body>
    </Drawer>
  );

  function renderActivityList(items: any) {
    return items?.map((item:any) => {
      return (
        <>
        <div className="flex gap-1">
             <p className="pl-5 flex items-center justify-between  w-full bg-slate-200 px-5 py-3 rounded-2xl mt-3 hover:shadow-lg hover:bg-slate-400 cursor-pointer  hover:text-white transition-all ease-in-out duration-500">{item?.title}</p>
          </div>
        </>
      );
    });
  }
};
