export function passwordChanged(pwd: string) {
  let strongRegex = new RegExp(
    "^(?=.{8,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$",
    "g"
  );
  let mediumRegex = new RegExp(
    "^(?=.{4,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
    "g"
  );
  let enoughRegex = new RegExp("(?=.{8,}).*", "g");

  if (!pwd) {
    return { text: "", color: "bg-gray-200" };
  }
  if (!enoughRegex.test(pwd)) {
    return { text: "Too short", color: "bg-gray-200" };
  } else if (strongRegex.test(pwd)) {
    return { text: "Strong!", color: "bg-green-500" };
  } else if (mediumRegex.test(pwd)) {
    return { text: "Medium!", color: "bg-yellow-400" };
  } else {
    return { text: "weak!", color: "bg-red-200" };
  }
}
export function isObjectEmpty() {}

//@params : string[] that contains '=' e.g  ["accessoriesType=Round","clotheType=Overall"]
//@returns {accessoriesType:Round,clotheType:Overall}
export function avatarDefaultValuesObject(defaultAvatarValue: string[]) {
  let obj: { [key: string]: string } = {};
  for (let value of defaultAvatarValue) {
    let tempArr = value.split("=");
    obj[tempArr[0]] = tempArr[1];
  }
  return obj;
}

//to capitalize the first character
export function capitalize(word: string) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

/**
 *
 * @param arr1 number[]
 * @param arr2 number[]
 * @returns boolean
 */
export function arraysEqual(arr1: number[], arr2: number[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

/**
 *@param
 *
 *
 */

export function getLink(str: string) {
  try {
    const val = JSON.parse(str);
    if (val) {
      return val.link;
    }
  } catch (error) {
    return "";
  }
}

export function addUrlPrefix(url: string) {
  // check if "https://" or "www." is already present
  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    url = "https://" + url;
  }
  if (!url.startsWith("www.")) {
    url = url.replace("https://", "https://www.");
  }
  return url;
}

export function getTheHealthId(percentage: number) {
  if (percentage >= 0 && percentage <= 39.99) {
    return 1;
  }
  if (percentage >= 40 && percentage <= 59.99) {
    return 2;
  }

  if (percentage >= 60 && percentage <= 79.99) {
    return 3;
  }
  if (percentage >= 80 && percentage <= 89.99) {
    return 4;
  }

  if (percentage >= 90 && percentage <= 100) {
    return 5;
  }
}

/**
 * @params pathname, link
 * @return true | false on basis of similarity between links
 */

export function isCurrentLink(pathname: string, link: string) {
  return pathname.includes(link);
}

// Number to Currency Function
export function numberToCurrency(num: number | string) {
  if (typeof num === "string") {
    num = parseFloat(num.replace(/[^\d.-]/g, ""));
  }
  if (isNaN(num)) {
    num = 0;
  }
  const formatter = new Intl.NumberFormat("en-US");
  const formatToDollar = formatter.format(num);
  return formatToDollar;
}
export function addCharLimitFunc(className: string) {
  const parentElement = document.getElementsByClassName(className)[0];

  if (parentElement) {
    const allElements = parentElement.querySelectorAll(".rs-input");

    allElements.forEach((element) => {
      const errorElement = document.createElement("div");
      errorElement.setAttribute(
        "class",
        "justify-end font-medium tracking-wide text-red-500 text-xs mt-1 ml-1"
      );
      element.parentElement?.appendChild(errorElement);

      // Add event listener for 'input' event
      element.addEventListener("input", () => {
        // @ts-ignore
        const maxLength = element?.maxLength;
        // @ts-ignore
        const remainingChars = maxLength - element?.value.length;
        errorElement.textContent = `Remaining characters: ${remainingChars}`;
      });

      // Add event listeners for 'focus' and 'blur' events
      element.addEventListener("focus", () => {
        errorElement.style.display = "block";
      });

      element.addEventListener("blur", () => {
        errorElement.style.display = "none";
      });
    });
  }
}
