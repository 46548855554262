import * as React from "react";
import { uniqueId } from "lodash";

import FacialHair from "./facialHair";
import HairColor from "./HairColor";
type Props = {
  children: JSX.Element;
};
export default function ShortHairShortWaved({ children }: Props) {
  const filter1 = uniqueId("react-filter-");
  const mask1 = uniqueId("react-mask-");
  const mask2 = uniqueId("react-mask-");
  const path1 = uniqueId("react-path-");
  const path2 = uniqueId("react-path-");

  return (
    <g id="Top" strokeWidth="1" fillRule="evenodd">
      <defs>
        <rect id={path2} x="0" y="0" width="264" height="280" />
        <path
          d="M183.679824,38.9488198 C189.086072,33.9985622 190.387393,23.9615454 187.317704,17.4493246 C183.549263,9.45385312 175.901319,8.45217737 168.572342,11.9686703 C161.664469,15.2835661 155.515175,16.3878671 147.950196,14.7817319 C140.691624,13.2406923 133.805566,10.5226172 126.303388,10.0762471 C113.978028,9.34292483 102.003367,13.914565 93.6031232,23.1292512 C92.0003538,24.8871655 90.7089493,26.8971594 89.4882796,28.9343872 C88.5115454,30.5644351 87.4105298,32.3133822 86.9950459,34.1921885 C86.7973853,35.0855929 87.165272,37.2898774 86.7203704,38.0218712 C86.2391099,38.8123183 84.4244668,39.5373375 83.6510124,40.1238625 C82.0842713,41.3125222 80.7267597,42.6539573 79.4713836,44.1710842 C76.8052796,47.3926541 75.3376994,50.7577001 74.1034777,54.7428152 C70.0005333,67.9877849 69.6528094,83.7412616 74.9569218,96.7467724 C75.6639385,98.4811062 77.8550622,102.098564 79.1431613,98.3847912 C79.3976741,97.6508047 78.8086588,95.1907873 78.8099809,94.4501584 C78.8146084,91.7300906 80.3160587,73.7213568 86.857084,63.6330196 C88.9862338,60.3491948 98.8298903,48.0522456 100.840541,47.9536058 C101.9058,49.6464245 112.720532,60.4624529 140.783385,59.1948919 C153.445253,58.6229725 163.18265,52.9341181 165.520833,50.4680909 C166.549375,56.0008881 178.51323,64.2839965 180.33625,67.6921976 C185.602529,77.5376948 186.770677,97.9957204 188.780988,97.9573368 C190.791299,97.9189532 192.234429,92.7197798 192.647929,91.7270713 C195.719601,84.351669 196.242509,75.0948338 195.914948,67.1684434 C195.487565,56.9663626 191.276535,45.9419513 183.679824,38.9488198 Z"
          id={path1}
        />
        <filter
          x="-0.8%"
          y="-2.0%"
          width="101.5%"
          height="108.0%"
          filterUnits="objectBoundingBox"
          id={filter1}
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.16 0"
            type="matrix"
            in="shadowOffsetOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <mask id={mask2} fill="white">
        <use xlinkHref={"#" + path2} />
      </mask>
      <g id="Mask" />
      <g id="Top/Short-Hair/Short-Waved" mask={`url(#${mask2})`}>
        <g transform="translate(-1.000000, 0.000000)">
          <FacialHair />
          <mask id={mask1} fill="white">
            <use xlinkHref={"#" + path1} />
          </mask>
          <use
            id="Short-Hair"
            stroke="none"
            fill="#28354B"
            fillRule="evenodd"
            xlinkHref={"#" + path1}
          />
          <HairColor maskID={mask1} />
          {children}
        </g>
      </g>
    </g>
  );
}

ShortHairShortWaved.displayName = "ShortHairShortWaved";
