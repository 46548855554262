import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { router } from "./routes";
import "./styles/index.css";
import "rsuite/dist/rsuite.min.css";
import { store } from "store";
import ReactGA from "react-ga4";
import reportWebVitals from "reportWebVitals";

const root = createRoot(document.getElementById("root") as HTMLElement);

ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <RouterProvider router={router} />
    </ReduxProvider>
  </StrictMode>
);

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

reportWebVitals(SendAnalytics);
