export const vmapTranslations = {
  // ADD VMAP MODAL-FORM
  "addVmap.success": "Added A New V-MAP",
  "addVmap.error": "Unable To Add New V-MAP",
  "addVmap.title.label": "V-MAP Title :",
  "addVmap.title.placeholder": "Please Enter V-MAP",
  "addVmap.date.label": "V-MAP Date :",
  "addVmap.date.placeholder": "Date for V-MAP",
  "addVmap.title.error": "Title is required",
  "addVmap.date.error": "Date is required",
  "addVmap.btn.testId": "add-new-vmap",
  "addVmap.modal.title": "Create New V-MAP",
  "addVmap.btn.title": "Create V-MAP",
  "addVmap.close.btn.text": "Close",
  "addVmap.modal.successBtn.text": "Create V-MAP",
  "addVmap.modal.testId": "create-new-vmap-modal",

  // EDIT VMAP MODAL-FORM
  "edit.success": "Updated Selected V-MAP",
  "edit.error": "Unable To Update V-MAP",
  "edit.modal.title": "Edit V-MAP",
  "edit.modal.success.btn": "Save and Close",
  "edit.modal.close.btn": "Cancel",
  "edit.btn.testId": "edit-vmap-btn",
  "edit.btn.text": "Edit",
  // COPY VMAP MODAL-FORM
  "copy.success": "V-MAP Has Been SuccessFully Copied",
  "copy.error": "Unable To Copy V-MAP",
  "copy.delegate.error": "Please Select Delegate",
  "copy.title.label": "V-MAP title :",
  "copy.title.placeholder": "Please Enter V-MAP Title",
  "copy.date.label": "V-MAP Date :",
  "copy.date.placeholder": "Please Select date",
  "copy.delegate.label": "Select User",
  "copy.delegate.placeHolder": "Select a delegate for the vmap",
  "copy.modal.title": "Copy V-MAP",
  "copy.modal.successBtn.text": "Copy Current V-MAP",
  "copy.modal.closeBtn.text": "Close",
  "copy.btn.testId": "copy-vmap-btn",
  "copy.btn.text": "Copy",

  // DELETE VMAP MODAL
  "delete.success": "V-MAP Has Been SuccessFully Deleted",
  "delete.error": "Unable To Delete V-MAP",
  "delete.modal.title": "Delete V-MAP",
  "delete.modal.successBtn.text": "Delete",
  "delete.modal.closeBtn.text": "Close",
  "delete.btn.testId": "delete-vmap-btn",
  "delete.btn.text": "Delete",
  // VISION MISSION
  "vision.modal.title": "Vision Mission",
  "vision.label": "Vision Statement :",
  "vision.placeholder": "Vision Statement",
  "mission.label": "Mission Statement :",
  "mission.placeholder": "Mission Statement",
  //Export PDF
  "exportPdf.success": "V-MAP Has Been SuccessFully Exported",
  "exportPdf.error": "Unable To Export V-MAP",
  "exportPdf.modal.title": "Export V-MAP",
  "exportPdf.modal.successBtn.text": "Export",
  "exportPdf.modal.closeBtn.text": "Close",

  // LEVEL DRAWER
  "levelDrawer.saveBtn": "Save",
  "levelDrawer.cancelBtn": "Cancel",
  "levelDrawer.saveAndCloseBtn": "Save and Close",

  // RESTORE VMAP
  "restoreVmap.error.msg": "Unable To Restore V-Map",
};
