import {
  Bars3Icon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useGetUserQuery, useLazySwitchToMindscanQuery, useLazySwitchToPACDASHQuery } from "store/auth/authApi";
import { logout } from "store/auth/authStore";
import MainHeader from "./MainHeader";
import { getAccessibleRoutes, NavMenu } from "./navLinks";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { appApi } from "store/apis/apiSlice";
import SimulateUsersList from "pages/simulate";
import { RootState } from "store";
import { useSelector } from "react-redux";
import { useToaster } from "rsuite";
import { toastError } from "components/toasts";
export const DashboardHeader = () => {
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentNav, setCurrentNav] = useState<string[]>([] as string[]);
  const [showHamburger, setShowHamburger] = useState(false);

  const { isAuthenticated, wow_token, token } = useSelector(
    (state: RootState) => state?.auth
  );

  const toast = useToaster();

  const [MindscanData] = useLazySwitchToMindscanQuery();
  const [PACDASHData] = useLazySwitchToPACDASHQuery();


  const switchToPACDASH = async () => {
    try {
          
      // const token = localStorage.getItem("access_token");
      const pacdashResult = await PACDASHData(token as string).unwrap();
        if (pacdashResult && pacdashResult.url) {
            window.location.href = pacdashResult.url;
          }

    } catch (error) {
      toast.push(toastError({ message: "Failed to get PACDASH URL" }));
    }
  };


  const switchToMindscan = async () => {
    try {
      // const token = localStorage.getItem("access_token");
          const mindscanResult = await MindscanData(token as string).unwrap();
          if (mindscanResult && mindscanResult.url) {
            window.location.href = mindscanResult.url;
          }

    } catch (error) {
      toast.push(toastError({ message: "Failed to get Mindscan URL" }));
    }
  };



  const { data: user } = useGetUserQuery();
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname) {
      setShowHamburger(false);
    }
  }, [pathname]);

  const getActiveLinkClassName = ({
    isActive,
    ...rest
  }: {
    isActive: boolean;
  }) => {
    let defaultClass = "menu-sub-menu-items ";
    return isActive
      ? defaultClass + "!bg-primary-blue  !text-white !hover:text-white"
      : defaultClass;
  };

  if (user && isAuthenticated) {
    const accessibleRoutes = getAccessibleRoutes(user?.role?.permission);

    const wowRoute = user?.modules?.filter(item => item.moduleName === 'WoW')[0]


    return (
      <header className="sticky top-0 z-[100] bg-slate-200">
        <nav className="navbar block md:flex md:flex-col relative">
          <MainHeader />
          <div
            className="hamburger-icon-container w-1/4 inline-block h-[80px]   bg-white  md:hidden pr-5   bg-white/95 transition-all ease-in-out duration-300 "
            onClick={() => setShowHamburger(!showHamburger)}
          >
            {!showHamburger ? (
              <Bars3Icon className="w-12 h-full  p-1 ml-auto cursor-pointer transition-all ease-in-out duration-300" />
            ) : (
              <XMarkIcon className="w-12 h-full  p-1 ml-auto cursor-pointer transition-all ease-in-out duration-300" />
            )}
          </div>
          <div
            className={`sub-navbar-container  scroll-none w-screen h-fit  md:w-auto md:h-fit md:flex justify-between pt-[15px] md:pt-0 px-4 md:px-0 md:mt-[35px] border-b-[2px] border-primary-blue md:mx-3 flex-col md:flex-row pb-[10px] md:pb-0 gap-[20px] md:gap-0 transition-all ease-in-out duration-700 delay-100 ${
              !showHamburger ? "sub-navbar-small-screen !p-0 " : ""
            } `}
          >
            {Object.keys(NavMenu).map((rightOrLeft) => {
              return (
                <ul
                  key={rightOrLeft}
                  className={`sub-nav-menu-${rightOrLeft} flex justify-start flex-col md:flex-row  md:justify-start   gap-[5px] md:gap-[2px] 2xl:gap-[6px] flex-wrap !mb-[5px] md:!mb-0 transition-all ease-in-out duration-500 `}
                >
                  {Object.keys(NavMenu?.[`${rightOrLeft}`]).map((navLink) => {
                    let navLinkData = NavMenu?.[`${rightOrLeft}`]?.[navLink];
                    return navLinkData?.nested ? (
                      <li
                        key={navLink}
                        className={`sub-nav-item ${rightOrLeft}-sub-nav-item group relative md:static transition-all ease-in-out duration-500`}
                      >
                        <NavLink
                          to={navLinkData?.link}
                          onClick={(e) => {
                            e.preventDefault()
                            return width >= 720
                              ? null
                              : setCurrentNav((prev) =>
                              {
                                  return prev.includes(navLink)
                                    ? currentNav.filter(
                                        (item) => item !== navLink
                                      )
                                    : [...prev, navLink]}
                                );
                          }}
                          className={getActiveLinkClassName}
                        >
                          {navLink}
                          <ChevronDownIcon className="w-[20px] h-[20px]" />
                        </NavLink>

                        {(currentNav.includes(navLink) || width >= 720) && (
                          <ul
                            className={`sub-menu-list md:hidden group-hover:flex flex-col gap-0  dropdown-menu p-2  bg-white  absolute top-[79%] md:top-[127px] lg:top-[120px] translate-y-3  before:top-[79%] before:md:top-[-10px] left-[-100px] md:left-auto before:left-[-100px] md:before:left-[-10px]  md:before:w-full md:before:h-full before:absolute  rounded  drop-shadow-lg w-48  hover:mouse-pointer before:z-[-1] transition-all ease-in-out duration-500 `}
                          >
                            {navLinkData?.nestedMenu &&
                              Object.keys(navLinkData?.nestedMenu).map(
                                (subLink) => {
                                  let subLinkData =
                                    navLinkData?.nestedMenu?.[subLink];
                                  if (
                                    !accessibleRoutes.includes(
                                      subLinkData?.link as string
                                    )
                                  ) {
                                    return;
                                  }
                                  return (
                                    <li
                                      key={subLink}
                                      className="dropdown-menu-item hover:bg-slate-100 rounded  py-1 "
                                    >
                                      <NavLink
                                        className={getActiveLinkClassName}
                                        to={subLinkData?.link as string}
                                      >
                                        {subLink}
                                      </NavLink>
                                    </li>
                                  );
                                }
                              )}
                          </ul>
                        )}
                      </li>
                    ) : (
                      accessibleRoutes.includes(navLinkData.link) && (
                        <li
                          key={navLink}
                          className={`sub-nav-item ${rightOrLeft}-sub-nav-item group`}
                        >
                          {navLink !== "WOW" ? (
                            <NavLink
                              to={navLinkData.link}
                              className={getActiveLinkClassName}
                            >
                              {navLink}
                            </NavLink>
                          ) : wowRoute ? (

                            <a
                              onClick={() => {
                                window.location.replace(navLinkData?.link+wow_token);
                              }}
                              href={navLinkData?.link+wow_token}
                              className="menu-sub-menu-items group-hover:text-white"
                            >
                              {navLink}
                            </a>
                          ) : null}
                        </li>
                      )
                    );
                  })}
                </ul>
              );
            })}
            {width <= 720 && <SimulateUsersList />}
            <ul className=" flex flex-col gap-0 text-center dropdown-menu p-2 md:hidden   rounded  drop-shadow-lg overflow-hidden w-3/5 mx-auto mt-5 ">
            
              {user?.pac_role !== "0" && user?.pac_role !== null && user?.pac_token !== null && user?.pac_token !== " " && (
          <li className="dropdown-menu-item rounded  mt-2 cursor-pointer px-1 py-2">
                  <button
                    onClick={switchToPACDASH}
                    className="px-1 py-2  text-white text-l font-bold"
                    >
                    Switch to PACDash
                  </button>
                </li>
              )}

              {user?.mindscan_role !== "0" && user?.mindscan_role !== null && user?.mindscan_token !== null && user?.mindscan_token !== " " && (
                <li className="dropdown-menu-item rounded  mt-2 cursor-pointer px-1 py-2">
                  <button
                    onClick={switchToMindscan}
                    className="px-1 py-2  text-white text-l font-bold"
                  >
                    Switch to Mindscan
                  </button>
                </li>
              )}

          <li className="dropdown-menu-item rounded  mt-2 cursor-pointer px-1 py-2">
            <button
                  //TODO
                  onClick={() => {
                    dispatch(appApi.util.resetApiState());
                    dispatch(logout());
                    localStorage.removeItem("access_token");
                    navigate("/auth/login");
                  }}
                  className="px-1 py-2  text-white text-l font-bold"
                >
                  Logout
                </button>
              </li>

            </ul>
          </div>
        </nav>
      </header>
    );
  }
  return (
    <>
      <header className="sticky top-0 z-[100] bg-slate-200">
        <nav className="navbar block md:flex md:flex-col relative">
          <MainHeader />
        </nav>
      </header>
    </>
  );
};
