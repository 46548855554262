import {
  ArrowTrendingUpIcon,
  ArrowUturnRightIcon,
  BanknotesIcon,
  HeartIcon,
  IdentificationIcon,
  LifebuoyIcon,
  ShoppingBagIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { ISideBarLink } from "components/cards/SideBarCard";

export function getCallMaximizerSideBardLinks(): ISideBarLink[] {
  return [
    {
      linkLabel: "Reflections",
      linkUrl: "#Reflections",
      // img: "/mail.svg"
      customIcon: (
        <IdentificationIcon
          width={22}
          height={22}
          className="mr-3"
        />
      ),
    },
    {
      linkLabel: "Assignments",
      linkUrl: "#Assignments",
      // img: "/user-circle.svg",
      customIcon: (
        <ArrowUturnRightIcon
          width={22}
          height={22}
          className=" mr-3"
        />
      ),
    },
    {
      linkLabel: "Physical & Emotional Health",
      linkUrl: "#Physical & Emotional Health",
      // img: "/user.svg",
      customIcon: (
        <HeartIcon
          width={22}
          height={22}
          className="mr-3"
        />
      ),
    },
    {
      linkLabel: "Relationships",
      linkUrl: "#Relationships",
      // img: "/briefcase.svg",
      customIcon: (
        <UserGroupIcon
          width={22}
          height={22}
          className=" mr-3"
        />
      ),
    },
    {
      linkLabel: "Spiritual, Purpose and Destiny",
      linkUrl: "#Spiritual Purpose and Destiny",
      // img: "/briefcase.svg",
      customIcon: (
        <LifebuoyIcon
          width={22}
          height={22}
          className=" mr-3"
        />
      ),
    },
    {
      linkLabel: "Financial Health and Prosperity",
      linkUrl: "#Financial Health and Prosperity",
      // img: "/briefcase.svg",
      customIcon: (
        <BanknotesIcon
          width={22}
          height={22}
          className=" mr-3"
        />
      ),
    },
    {
      linkLabel: "Personal Growth and Development",
      linkUrl: "#Personal Growth & Development",
      // img: "/briefcase.svg",
      customIcon: (
        <ArrowTrendingUpIcon
          width={22}
          height={22}
          className=" mr-3"
        />
      ),
    },
    {
      linkLabel: "Career & Profession",
      linkUrl: "#Career & Profession",
      // img: "/briefcase.svg",
      customIcon: (
        <ShoppingBagIcon
          width={22}
          height={22}
          className=" mr-3"
        />
      ),
    },
  ];
}

export const COACHING_TIMES = [
  {
    label: "7:30 AM",
    value: 0,
  },
  {
    label: "8:00 AM",
    value: 1,
  },
  {
    label: "8:30 AM",
    value: 2,
  },
  {
    label: "9:00 AM",
    value: 3,
  },
  {
    label: "9:30 AM",
    value: 4,
  },
  {
    label: "10:00 AM",
    value: 5,
  },
  {
    label: "10:30 AM",
    value: 6,
  },
  {
    label: "11:00 AM",
    value: 7,
  },
  {
    label: "11:30 AM",
    value: 8,
  },
  {
    label: "12:00 PM",
    value: 9,
  },
  {
    label: "12:30 PM",
    value: 10,
  },
  {
    label: "1:00 PM",
    value: 11,
  },
  {
    label: "1:30 PM",
    value: 12,
  },
  {
    label: "2:00 PM",
    value: 13,
  },
  {
    label: "2:30 PM",
    value: 14,
  },
  {
    label: "3:00 PM",
    value: 15,
  },
  {
    label: "3:30 PM",
    value: 16,
  },
  {
    label: "4:00 PM",
    value: 17,
  },
  {
    label: "4:30 PM",
    value: 18,
  },
  {
    label: "5:00 PM",
    value: 19,
  },
  {
    label: "5:30 PM",
    value: 20,
  },
  {
    label: "6:00 PM",
    value: 21,
  },
  {
    label: "6:30 PM",
    value: 22,
  },
];
