import { activityAvatar, showAllDials } from "store/dashboard";
import { useDispatch } from "react-redux";
import { AllDials } from "./AllDials";
import { TrackingData } from "./TrackingData";
import AddRow from "sections/tracking-data/Add";
import EditDelegate from "sections/tracking-data/Edit";
import { Panel, PanelGroup, useToaster } from "rsuite";
import DeleteTrackingDataRow from "sections/tracking-data/Delete";
import DialGroupSlider from "./DialGroupSlider";
import { NotePopup } from "components/dashboard";
import { useState } from "react";
import { useAddNoteForDelegateMutation, useGetNoteOfDelegateQuery } from "store/dashboard/DashboardSlice";
import { toastError, toastSuccess } from "components/toasts";
import { IDashboardDataList, IDashboardDelegateList } from "types/dashboard";
import { useGetUserQuery } from "store/auth/authApi";
import { useParams } from "react-router-dom";
// import { decryptId } from "decrypt_data";
import AvatarInfo from "components/avatar/AvatarInfo";
import { Calculate } from "sections/dashboard/components/Calculate";
// import { RunRateCalculate } from "./../components/RunRateCalculate";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { AvatarGrade } from "sections/dashboard/components/AvatarHealth";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";
import PerformanceAvatarItemDetails from "sections/dashboard/components/PerformanceAvatarItemDetails";

import { useLocation } from 'react-router-dom';
import Base64 from "base64";
import { RunRateCalculate } from "sections/dashboard/components/RunRateCalculate";

const Performance = (userDetails: any, list: IDashboardDataList) => {
  const dispatch = useDispatch();
  const { data: user } = useGetUserQuery();

  const location = useLocation();

  let params = useParams();
  // let activityData = userDetails?.list?.dialsArray;
  let activityDataaaa = userDetails?.list?.dialsArray?.filter((list: any) => list?.inProfile);

    // Decode the URL-encoded string and remove '==' at the end if it exists
    const queryParams = new URLSearchParams(location.search);
    let idddddddddd = queryParams.get('id') || ''; // Get the 'id' parameter or default to an empty string
  
    // Clean the ID: remove trailing spaces or unwanted characters like '='
    let decodedString = Base64.atob(idddddddddd);

    let number = decodedString.match(/\d+/)?.[0]; // This matches the first sequence of digits

// Convert the number from string to a number and divide by 1000
    let result = number ? parseInt(number) / 1000 : 0;

  const [showPerformanceAvatar, setShowPerformanceAvatar] = useState(false);

  const [note, setNote] = useState<string | null>(null);
  const toast = useToaster();
  const { filterDates, dashboardApiData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const {
    data: delegateNote,
    isLoading,
    isFetching,
  } = useGetNoteOfDelegateQuery(
    { delegateId: result ? result : '',
      key: params.key,
     },
    {
      skip: !result,
    }
  );

  const id = result;

  const [addNotesPopup, { isLoading: isNoteSaving }] =
  useAddNoteForDelegateMutation();

  const performanceNote = delegateNote?.data?.performance_description;
  const performanceNoteDate = delegateNote?.data && delegateNote?.data?.created_at; 

  const results = getActivityHealth(userDetails?.list)

  const saveNoteHandler = async () => {
    try {

      if (id && note) {
        const res = await addNotesPopup({
          activity_description: delegateNote?.data?.activity_description,
          performance_description: note,
          delegateId: id,
          type: "performance",
          key: params?.key
        }).unwrap();
        if (res?.status) {
          toast.push(toastSuccess({ message: "Notes Updated Successfully" }));
          return true;
        }
      }
    } catch (error) {
      toast.push(
        toastError({ message: "Unable To Add/Update Note Successfully" })
      );
      return false;
    }
    return false;
  };  
  
  return (
    <>
      <PanelGroup accordion defaultActiveKey={3} bordered>
        <div className="single-user-wrapper bg-white p-3 rounded-xl ">
        <div style={{top: '3px'}} className="absolute z-[99] right-[100px] transition-all ease-in-out duration-500">
        <div className="gap-2 flex flex-row justify-between border-b">

      <div style={{marginTop: '7px'}}>
        <NotePopup
            note={note ? note : performanceNote}
            setNote={(htmlString) => {
              setNote(htmlString);
            }}
            onSave={saveNoteHandler}
            disabled={note === performanceNote || !note}
            isNoteSaving={isNoteSaving || isFetching || isLoading}
            delegateNote={performanceNote}
            dataTestId="performance-note-icon"
            // date={performanceNoteDate}
          />
          </div>

          <div
            role="button"
            data-testid="show-performance-table"
            className="gap-1 md:gap-3 flex flex-row justify-between"
            onClick={() => {
              setShowPerformanceAvatar(!showPerformanceAvatar);
              dispatch(activityAvatar(activityDataaaa));
            }}
          >
            <AvatarInfo
              percentage={Math.round(results?.finalPercentage ?? 0)}
              avatarWidth={50}
              grade={results?.grade ?? "F"}
              health={userDetails?.list?.health}
              delegateId={idddddddddd}
            />
            <div className="w-[150px]">
              <p className="font-semibold text-md cursor-pointer">
                {/* {list?.name} */}
              </p>
            </div>
          </div>
          </div>

          </div>
          
        <button
            className="bg-gray-200 absolute z-[99] right-[28px] font-bold px-5 py-[10px] hover:bg-gray-400 hover:text-white transition-all ease-in-out duration-500"
            onClick={() => dispatch(showAllDials(true))}
          >
            See All Dials
          </button>
          <Panel
            header={
              <div className="flex flex-row justify-between items-center my-1">
                <h2 className="font-bold text-lg pl-7">Performance</h2>
              </div>
            }
            eventKey={3}
            id="panel3"
            className="dashboard-accordion-panel"
          >
            
            <hr className="mx-auto mt-2 mb-0 bg-blue border-blue-600 border-1 dark:bg-blue-700 "></hr>
            <DialGroupSlider {...userDetails?.profile} />
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4 mt-5"></div>
          </Panel>
        </div>
        <AllDials {...userDetails?.profile} />
        <TrackingData />
        <AddRow />
        <DeleteTrackingDataRow />
        <EditDelegate />
      </PanelGroup>

      <PerformanceAvatarItemDetails
       activityType={"performance"}
       type={"performance"}
        show={showPerformanceAvatar}
        close={setShowPerformanceAvatar}
        userDetails={userDetails}
        health={userDetails?.list?.health}
      />

   </>
  );
  function getActivityHealth(list: IDashboardDataList) {
    let runRateGoal = 0;
    list?.dialsArray?.forEach((option) => {
      let result = RunRateCalculate(
        option,
        filterDates,
        dashboardApiData,
        userDetails
      );
      if (
        result &&
        userDetails &&
        result?.runRate < userDetails?.profile?.minPerformAv
      ){
        runRateGoal += userDetails?.profile?.minPerformAv;
      }
      else if (
        result &&
        userDetails &&
        result?.runRate > userDetails?.profile?.maxPerformAv
      ){
        runRateGoal += userDetails?.profile?.maxPerformAv;
      }
      else if (result){
         runRateGoal += result?.runRate;
      }
    });

    let percentage =
    list?.dialsArray?.length && runRateGoal > 0
        ? Math.round(runRateGoal / list?.dialsArray?.length)
        : 0;

    return { grade: AvatarGrade(percentage), finalPercentage: percentage };
  }
};

export default Performance;
