import { forwardRef, InputHTMLAttributes, useRef, useState } from "react";
import PasswordEye from "./PasswordEye";

export interface InputProps extends InputHTMLAttributes<HTMLElement> {
  type: string;
  error?: string;
  additionalClassName?: string;
  overrideClassName?: boolean;
  prefixElement?: JSX.Element;
}

const defaultClassName =
  "input-field-ui focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none ";

export const getClassName = (
  override: boolean,
  additionalClassName: string,
  defaultClassName?: string
) => {
  if (override) return additionalClassName;
  if (additionalClassName) {
    return defaultClassName + additionalClassName;
  }
  return defaultClassName;
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    name,
    onChange,
    onBlur,
    placeholder,
    error,
    id,
    type,
    defaultValue,
    maxLength,
    additionalClassName = "",
    overrideClassName = false,
    value,
    required,
    disabled,
    min,
  } = props;
  const [show, setShow] = useState(false);
  const refLim = useRef<null | HTMLElement>(null);
  let passwordType = type;

  if (type === "password" && show) {
    passwordType = "text";
  }
  return (
    <>
      <div className="relative w-full ">
        <input
          type={type === "password" ? passwordType : type}
          name={name}
          placeholder={placeholder}
          onChange={(e) => {
            if (refLim.current && maxLength) {
              refLim.current.textContent = `char left ${
                maxLength - e?.target?.value?.length
              }`;
            }
            if (onChange) {
              if (e.target.value.trim().length === 0) {
                e.target.value = e?.target?.value?.trim();
              }
              onChange(e);
            }
          }}
          onBlur={onBlur}
          ref={ref}
          value={value}
          min={min}
          maxLength={maxLength}
          defaultValue={defaultValue}
          required={required}
          onFocus={() => {
            if (refLim.current) {
              refLim.current.style.display = "flex";
            }
          }}
          onBlurCapture={() => {
            if (refLim.current) {
              refLim.current.style.display = "none";
            }
          }}
          className={
            type === "radio"
              ? ""
              : getClassName(
                  overrideClassName,
                  additionalClassName,
                  defaultClassName + (error ? " border-red-500" : "")
                )
          }
          id={id}
          disabled={disabled}
          {...(type === "password" && { autoComplete: "password" })}
        />
        {type === "password" ? (
          <PasswordEye show={show} setShow={setShow} />
        ) : null}

        {maxLength && (
          <span
            ref={refLim}
            className="hidden justify-end font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 "
          ></span>
        )}
        {error && (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 capitalize">
            {error}
          </span>
        )}
      </div>
    </>
  );
});
