import {
  Schema
} from 'rsuite';

import * as Yup from "yup";

const { StringType, NumberType, DateType } = Schema.Types;

export const TrackingDataSchema = Schema.Model({
  beginingDate:DateType()?.isRequired('Beginning Date is required'),
  endingDate:DateType()?.isRequired('Ending Date is required'),
  data:NumberType().isRequired('Data is Required field.'),
  comment:StringType(),
});

/**
 * for reverting the deleted tracking data
 */
export const RevertDeletedTrackingDataSchema = Yup.object().shape({
  id: Yup.number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .nullable(false)
    .required("The Tracking data date is required"),
});

/**
 * validation for system generated tracking data
 */
export const SystemGeneratedTrackingDataSchema = Yup.object().shape({
  end_date: Yup.date().required('The end date is required'),
});

