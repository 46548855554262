import { useContext, useEffect, ReactElement } from "react";
import { OptionContext } from "./OptionContext";
interface ISelectorProps {
  children: JSX.Element[];
  option: { key: string; label: string };
  // TODO accept a generic component value
  defaultOption: ReactElement | string | any;
}

export default function Selector({
  children,
  option,
  defaultOption,
}: ISelectorProps) {
  const { selectedValues, setAllValues, setSelectedValues } =
    useContext(OptionContext);

  const setAllValuesFromChildren = () => {
    const values = children.map((item) => item.type.displayName);

    setSelectedValues((prev) => ({
      // @ts-ignore //TODO - we intentionally add a displayName Property to each svg component that is the name of the component
      [option.key]: defaultOption.displayName,
      //TODO document this better: NOTE Here we are destructuring prev after all the keyname has been entered because we want the default values to be what user provides
      ...prev,
    }));
    setAllValues((prev) => ({
      ...prev,
      [option.key]: values,
    }));
  };

  useEffect(() => {
    setAllValuesFromChildren();
  }, [children]);

  return children.filter(
    (item) =>
      item.type.displayName ===
      // @ts-ignore
      (selectedValues ? selectedValues[option.key] : defaultOption.displayName)
  )[0];
}
