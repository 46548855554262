import * as React from "react";

export default function Serious() {
  return (
    <g
      id="Mouth/Serious"
      transform="translate(2.000000, 52.000000)"
      fill="#000000"
      fillOpacity="0.699999988"
    >
      <rect id="Why-so-serious?" x="42" y="18" width="24" height="6" rx="3" />
    </g>
  );
}
Serious.displayName = "Serious";
