import differenceInDays from 'date-fns/differenceInDays'
import { AvatarHealth, AvatarGrade} from './AvatarHealth'
import { IDashboardDataList } from '../../../types/dashboard';

export const Calculate = (list:IDashboardDataList) => {

    let activityData  = (list?.activityType == "performance") ? list?.avatarPerformance : list?.avatarActivities

    return calculateHealth(activityData)

    function calculateHealth(list:any)
    {
        if (!list) return;

        let allValues = [];
        // loop through tasks and get percentage
        for (let task of list) 
        {
            if(task?.inProfile){
                const dateDiff = getDateDifference(task.dueDate);

                // check the due date and assign percentage
                allValues?.push(AvatarHealth(dateDiff))
            }
        }

        // add all the percentages
        let finalPercentage:number = allValues?.length > 0 ? allValues.reduce((a, b) => (a + b), 0) : 0;

        // average the sum of percentages
        finalPercentage = (!isNaN(finalPercentage) && finalPercentage > 0) ? finalPercentage/allValues.length : 0;

        // return the final percentage
        return {finalPercentage : finalPercentage ?? 0, grade: getGrade(finalPercentage) ?? "F"};
    }

    function getDateDifference(dueDate: Date)
    {
        return differenceInDays(
            new Date(dueDate),
            new Date()
        )
    }

    // RETURN THE GRADES BASED ON THE PERCENTAGE
    function getGrade (percent:number) 
    {
        return AvatarGrade(percent)
    }
}