import { dashboardProfileApi } from "store/preferences/profiles/profileApi";
import { IKpis, IVmap } from "types/preferences/definition";

export interface ICreateVmap {
  formTitle: string;
  formDate: string;
}
export interface IEditCreateVmap extends ICreateVmap {
  vmapId: number;
  vissionStatement?: string;
  missionStatement?: string;
}

interface IDeletedVmapResponse {
  itemId: number;
  userId: number;
  tableName: string;
  name: string;
  tableId: number;
  status: number;
  created_at: string;
  updated_at: string;
}

export interface IVMapPdfExport {
  vmapId?: number;
  type?: number;
  exportType?: string;
  name?: string;
}

interface IVmapLevelUpdateRequest {
  levelId: number;
  name: string;
  strategyId: number;
  assignDate: string;
  dueDate: string;
  delegateTo: number[];
  qualifierTo: string[];
  showOnDashboard: number;
  includeInReporting: number;
  includeInAvatar: number;
  includeInProfile: number;
  trackSign: string;
  seasonalSign: string;
  successScale: number;
  statusId: number;
  description: string;
  isAccumulate: number;
  daily: number;
  weekly: number;
  monthly: number;
  quarterly: number;
  annually: number;
  goal: number;
  type: string;
  seasonalGoal: string[];
  valueUrl?: string;
  isDelegateViewing?: string;
}

export const vmapApiSlice = dashboardProfileApi
  .enhanceEndpoints({
    addTagTypes: [
      "VMAP",
      "DELETED_VMAPS",
      "DELETED_VALUES",
      "Dashboard Activity",
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      createVmap: builder.mutation<
        {
          message: string;
          status: true;
        },
        ICreateVmap
      >({
        query: (data) => ({
          url: `vmap`,
          method: "POST",
          body: { ...data },
        }),
        invalidatesTags: ["VmapList"],
      }),

      copyVmap: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        IEditCreateVmap
      >({
        query: ({ vmapId, ...rest }) => ({
          url: `vmaps/${vmapId}/copy`,
          method: "POST",
          body: { ...rest },
        }),
        invalidatesTags: ["VmapList"],
      }),

      deleteVmap: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        number
      >({
        query: (vmapId) => ({
          url: `vmap/${vmapId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["VmapList", "DELETED_VMAPS"],
      }),
      editVmap: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        IEditCreateVmap
      >({
        query: (data) => ({
          url: `vmap/${data.vmapId}`,
          method: "PUT",
          body: { ...data },
        }),
        invalidatesTags: ["VmapList"],
      }),
      getDeletedVmap: builder.query<IDeletedVmapResponse[], void>({
        query: () => `vmaps-deleted-items`,
        transformResponse: (res: { data: IDeletedVmapResponse[] }) => {
          return res.data;
        },
        providesTags: ["DELETED_VMAPS", "DELETED_VALUES"],
      }),
      restoreDeletedVmaps: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        number
      >({
        query: (vmapId) => ({
          url: `vmap/${vmapId}/restore`,
          method: "PUT",
        }),
        invalidatesTags: ["VmapList", "DELETED_VMAPS"],
      }),

      addVmapToDashboard: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        {
          vmapId: number;
          value: number;
        }
      >({
        query: ({ vmapId, value }) => ({
          url: `vmap-dashboard/${vmapId}`,
          method: "POST",
          body: { value },
        }),
        invalidatesTags: ["VmapList"],
      }),
      updateVmapLevelStatus: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        {
          type: string;
          status: number;
          levelId: number;
          isDelegateViewing?: string;
        }
      >({
        query: ({ type, status, levelId, isDelegateViewing }) => ({
          url: isDelegateViewing
            ? `vmap-level-status-delegate/${levelId}/${type}?token=${isDelegateViewing}`
            : `vmap-level-status/${levelId}/${type}`,
          method: "PUT",
          body: {
            status,
          },
        }),
        invalidatesTags: ["VmapList", "Dashboard Activity"],
      }),
      updateVmapLevel: builder.mutation<
        { status: boolean; message: string; data: IKpis },
        IVmapLevelUpdateRequest
      >({
        query: ({ levelId, type, isDelegateViewing, ...rest }) => {
          return {
            url: isDelegateViewing
              ? `vmap-levels-update/${levelId}/${type}?token=${isDelegateViewing}`
              : `vmap-levels/${levelId}/${type}`,
            method: "PUT",
            body: {
              ...rest,
            },
          };
        },
        invalidatesTags: ["VmapList", "Dashboard Activity"],
      }),
      deleteVmapLevel: builder.mutation<
        { status: boolean; message: string },
        {
          levelId: number;
          title: string;
          type: string;
        }
      >({
        query: ({ levelId, type, title }) => ({
          url: `vmap-levels/${levelId}/${type}`,
          method: "DELETE",
          body: {
            title,
          },
        }),
        invalidatesTags: ["VmapList", "DELETED_VALUES", "Dashboard Activity"],
      }),
      createValues: builder.mutation<
        {
          message: string;
          status: number;
        },
        {
          vmapId: number;
          name: string;
          url: string;
          statusId: number;
        }
      >({
        query: ({ vmapId, name, url, statusId }) => ({
          url: `vmap/${vmapId}/values`,
          method: "POST",
          body: {
            name,
            url,
            status: statusId,
          },
        }),
        invalidatesTags: ["VmapList"],
      }),
      createLevels: builder.mutation<
        {
          message: string;
          status: number;
          data: IKpis;
        },
        {
          vmapId: number;
          level: string;
        }
      >({
        query: ({ vmapId, level, ...bodyData }) => ({
          url: `create-vmap-levels/${vmapId}/${level}`,
          method: "POST",
          body: {
            ...bodyData,
          },
        }),
        invalidatesTags: ["VmapList"],
      }),

      moveVmapMutation: builder.mutation<
        { message: string },
        {
          index: number[];
          node: IVmap;
          parent: {
            id: string;
          };
        }
      >({
        query: (movedChildren) => {
          return {
            url: "vmap-order",
            method: "PUT",
            body: { ...movedChildren },
          };
        },
        invalidatesTags: ["VmapList"],
      }),
      //vmap pdf export query
      exportVmapPdf: builder.query<{ data: string }, IVMapPdfExport>({
        query: ({ vmapId, type, exportType, name }) => {
          return {
            url: `export-${exportType}?vmapId=${vmapId}&type=${type}`,
            method: "GET",
            responseHandler: async (response) => {
              let element = document.createElement("a");
              element?.setAttribute(
                "href",
                `${window.URL.createObjectURL(await response.blob())}`
              );

              if (exportType === "pdf") {
                element.setAttribute("download", name + ".pdf");
              } else {
                element.setAttribute("download", name + ".csv");
              }
              element.click();
            },
          };
        },
      }),

      /**
       * for importing the vmap csv
       */
      importVmapCSV: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        { formData: FormData; vmapId: number }
      >({
        query: ({ formData, vmapId }) => {
          return {
            url: `import-csv/${vmapId}`,
            method: "POST",
            body: formData,
          };
        },

        invalidatesTags: ["VmapList"],
      }),

      /**
       * for copying the vmap to another user
       */
      copyVMapProfile: builder.mutation<
        {
          status: boolean;
          message: string;
        },
        {
          vmap_id: number;
          parent_vmap_id: number;
        }
      >({
        query: ({ vmap_id, parent_vmap_id }) => {
          return {
            url: "vmap-profile",
            method: "POST",
            body: {
              vmap_id: vmap_id,
              parent_vmap_id: parent_vmap_id,
            },
          };
        },
        invalidatesTags: ["VMAP", "VmapList"],
      }),
    }),
  });

export const {
  useExportVmapPdfQuery,
  useCreateVmapMutation,
  useEditVmapMutation,
  useCopyVmapMutation,
  useDeleteVmapMutation,
  useGetDeletedVmapQuery,
  useRestoreDeletedVmapsMutation,
  useAddVmapToDashboardMutation,
  useUpdateVmapLevelStatusMutation,
  useDeleteVmapLevelMutation,
  useCreateValuesMutation,
  useCreateLevelsMutation,
  useMoveVmapMutationMutation,
  useUpdateVmapLevelMutation,
  useImportVmapCSVMutation,
  useCopyVMapProfileMutation,
} = vmapApiSlice;
