import { Link, useParams } from "react-router-dom";
import { logout } from "store/auth/authStore";
import { PAD_API_MEDIA_URL } from "config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserQuery, useLogoutUserMutation } from "store/auth/authApi";
import { appApi } from "store/apis/apiSlice";
import Health from "sections/dashboard/health/Global";
import SimulateUsersList from "pages/simulate";
import { useToaster } from "rsuite";
import { toastError } from "components/toasts";
import { useSelector } from "react-redux";
import { RootState } from "store";
import moment from "moment";
import DelegateGlobalHealth from "sections/dashboard/health/DelegateGlobalHealth";
import { useDashboardFetchDelegateDataQuery } from "store/notification/NotificationSlice";

const MainDelegateHeader = () => {
  const { data: user } = useGetUserQuery();

  const { isAuthenticated, token } = useSelector(
    (state: RootState) => state?.auth
  );

  let params = useParams();

  const { data: activityData } = useDashboardFetchDelegateDataQuery({
    key: params?.key ?? "",
    type: params?.type ?? "",
  });

  const [logoutUser] = useLogoutUserMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toast = useToaster();

  const userLogout = async () => {
    try {
      await logoutUser();
      dispatch(appApi.util.resetApiState());
      dispatch(logout());
      localStorage.removeItem("access_token");
      navigate("/auth/login");
    } catch (error) {
      toast.push(toastError({ message: "Something went wrong" }));
    }
  };

  return (
    <div className="navbar-container w-3/4 inline-block  md:w-[100%] lg:w-full h-[80px] md:h-max lg:h-[57px] px-5 md:rounded-l-lg md:rounded-b-lg  md:flex  md: gap-3 md:gap-[8px] justify-center md:justify-between items-center flex-col sm:flex-row lg:flex-row md:shadow-lg shadow-slate-400 md:backdrop-saturate-200 md:backdrop-blur-2xl bg-white/95 ">
      <div className="logo-wrapper w-fit mt-[9px] sm:mt-0  md:w-1/3">
        <a href="/">
          <img
            src="/assets/logo-main.png"
            alt="logo-img"
            className="md:w-auto h-full md:h-[47px] p-1 mr-auto  md:mx-auto"
          />
        </a>
      </div>
      <div className="navbar-items hidden md:flex justify-evenly w-[100%] h-min lg:h-full align-center flex-row md:gap-[8px] gap-3">
        <div className="top-meetinglink-wrapper flex justify-center items-center gap-[2px] lg:gap-[6px]  text-sm leading-[17px] flex-col lg:flex-row ">
          {!activityData?.settings?.is_delegate_user && user && (
            <>
              <p className="meetinglink-title text-primary-blue font-semibold">
                <a href={user?.meetingLink} target="_blank">
                  {" "}
                  Meeting Link
                </a>
              </p>
              <p className="dial-number text-slate-400 flex flex-wrap justify-center">
                <span>Dial-in Number:</span> {user?.dialNumber}
              </p>
              <p className="access-code text-slate-400 flex flex-wrap justify-center">
                <span>Access Code:</span> {user?.accessCode}
              </p>
            </>
          )}
        </div>

        <ul className="avatar-circles flex  justify-center lg:justify-end items-center lg:w-1/3  flex-row flex-nowrap">
          <DelegateGlobalHealth />
          {!activityData?.settings?.is_delegate_user && (
            <li className="navbar-menu-items group h-full flex justify-center items-center relative">
              <Link
                to="#"
                className="navbar-menu-item-link  px-4 py-2 text-sm font-normal transition-all ease-in-out cursor-pointer select-none text-slate-700 flex justify-center "
              >
                <img
                  src={
                    user?.user_image
                      ? PAD_API_MEDIA_URL + user?.user_image
                      : "/assets/default-profile-icon.jpg"
                  }
                  alt="profile"
                  className="navbar-profilePic  w-[50px] h-[50px] object-cover rounded-full"
                />
              </Link>
              <ul className="group-hover:flex flex-col gap-0  dropdown-menu p-2 w-60 bg-white hidden absolute top-[50px] right-0 translate-y-3  before:top-[-10px] before:left-[-10px] before:w-full before:h-full before:absolute  rounded  drop-shadow-lg  hover:mouse-pointer before:z-[-1] ">
                <li className="dropdown-menu-item hover:bg-slate-100 rounded text-center flex justify-evenly items-center gap-2 bg-gray-100">
                  <img
                    src={
                      user?.user_image
                        ? PAD_API_MEDIA_URL + user?.user_image
                        : "/assets/default-profile-icon.jpg"
                    }
                    alt="profile"
                    className="navbar-profilePic  w-[100px] h-[100px] object-cover rounded-full p-2"
                  />
                  <p className="flex flex-col cursor-pointer font-bold text-sm justify-start items-start">
                    <span className="text-primary-blue text-base">
                      Welcome !!
                    </span>
                    {user?.name}{" "}
                    <span className="text-xs font-normal">
                      {user?.role?.description}
                    </span>
                  </p>
                </li>
                <li className="dropdown-menu-item hover:bg-slate-100 rounded  mt-2 cursor-pointer px-1 py-2">
                  <span>Member Since :</span>{" "}
                  {user?.created_at
                    ? moment(user?.created_at).format("MM/DD/YYYY")
                    : new Date().toLocaleDateString()}
                </li>
                <li className="dropdown-menu-item  rounded  mt-2  text-center">
                  <button
                    onClick={userLogout}
                    className="border min-w-[100px] rounded-full bg-secondary-blue px-5 py-2  md-mt-0 font-bold text-md text-white flex cursor-pointer hover:bg-white hover:text-secondary-blue  border-secondary-blue transition-all ease-in-out duration-500 mx-auto"
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default MainDelegateHeader;
