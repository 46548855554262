import { Spinner } from "assets";
import React from "react";
import { uniqueId } from "lodash";
interface ButtonProps {
  title: string;
  type?: "submit" | "reset" | "button";
  isLoading?: boolean;
  disabled?: boolean | false;
  children?: React.ReactNode;
  loadingText?: string;
  additionalClass?: string | null;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: JSX.Element;
}

export const Button = (props: ButtonProps) => {
  const {
    type = "button",
    title,
    additionalClass,
    disabled,
    isLoading,
    loadingText,
    handleClick,
    icon,
  } = props;

  return (
    <button
      type={type}
      title={title}
      disabled={isLoading||disabled}
      className={`bg-blueGray-700 active:bg-blueGray-600 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 ${additionalClass}`}
      onClick={!isLoading?handleClick:()=>{}}
    >
      {icon ? icon : null}
      {isLoading ? <Spinner /> : null}
      {isLoading ? loadingText : title}
    </button>
  );
};
