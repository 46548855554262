import { CalendarDaysIcon } from "@heroicons/react/20/solid";
import { ICallMaximizerDateListResponse } from "store/call-maximizer/callMaximizerApiSlice";
import { COACHING_TIMES } from "./callMaximizerFields";
import moment from "moment";

type Props = {
  showNextSessionModal: () => void;
  coachCallId: ICallMaximizerDateListResponse;
};

const NextSessionCard = ({ showNextSessionModal, coachCallId }: Props) => {
  return (
    <div className="h-[115px] lg:w-[400px] max-w-[400px] basis-1/2 rounded-lg bg-slate-400 flex relative flex-col gap-2 p-5 text-white font-bold shadow-lg ">
      <p>Next Coaching Session</p>
      <p>
        {new Date(
          moment(coachCallId?.nextCallDate).format("MM/DD/YYYY")
        ).toDateString()}
      </p>
      <p>
        {
          COACHING_TIMES.filter(
            (item) => item.value === coachCallId?.nextCallTimeId
          )[0].label
        }
      </p>
      <button
        type="button"
        className="calendar-yellow-button"
        onClick={showNextSessionModal}
      >
        <CalendarDaysIcon className="w-8 h-8 m-auto " />
      </button>
    </div>
  );
};

export default NextSessionCard;
