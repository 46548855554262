import React, { useContext } from "react";
import { SelectPicker } from "rsuite";
import { allOptions } from "./options";
import { OptionContext } from "./options/OptionContext";

type Props = {
  setSelectedAvatarOptions: (val: { [key: string]: string }) => void;
};

const AvatarForm = ({ setSelectedAvatarOptions }: Props) => {
  const { allValues, setSelectedValues, selectedValues } =
    useContext(OptionContext);

  return allValues ? (
    <div className="flex flex-col gap-3 justify-between w-full mt-2">
      {Object.keys(allValues)?.map((value: string) => (
        <SelectPicker
          searchable={false}
          preventOverflow
          placement="auto"
          cleanable={false}
          defaultValue={selectedValues[value]}
          label={allOptions.filter((item) => item.key === value)[0].label}
          data={allValues[value].map((item) => ({ value: item, label: item }))}
          onChange={(e) => {
            setSelectedValues((prev) => ({
              ...prev,
              [value]: e,
            }));
            //@ts-ignore
            setSelectedAvatarOptions({ ...selectedValues, [value]: e });
          }}
        />
      ))}
    </div>
  ) : (
    <></>
  );
};

export default AvatarForm;
