import { createColumnHelper } from "@tanstack/react-table";
import Table from "components/table/Table";
import { useFormContext } from "react-hook-form";
import { Button } from "rsuite";

type TData = {
  longComment: string;
  satisfactionLevel: number;
  motivationLevel: number;
  updated: number;
};

const TabularSectionCallMaximizer = ({
  data,
  sectionName,
}: {
  data: TData[];
  sectionName: string;
  index: number;
}) => {
  const { register, watch, getValues, setValue } = useFormContext();
  const columnHelper = createColumnHelper<TData>();
  let sum = 0;
  const values: TData[] = watch(sectionName);
  const satisfactionLevelMean =
    (values || data).reduce(
      (accumulator: number, currentValue) =>
        accumulator + Number(currentValue.satisfactionLevel),
      sum
    ) / (values || data).length;

  const motivationLevelMean =
    (values || data).reduce(
      (accumulator: number, currentValue) =>
        accumulator + Number(currentValue.motivationLevel),
      sum
    ) / (values || data).length;

  const resetValuesFunc = () => {
    const sectionValues = getValues(`${sectionName}`);
    const newSectionValues = sectionValues.map((item: any) => ({
      ...item,
      motivationLevel: 0,
      satisfactionLevel: 0,
      updated: 0,
    }));
    setValue(`${sectionName}`, newSectionValues);
  };

  const COLUMNS = [
    columnHelper.accessor("longComment", {
      header: "Task",
    }),
    columnHelper.display({
      header: "Satisfaction",
      cell: (props) => (
        <select
          id=""
          {...register(`${sectionName}.${props.row.index}.satisfactionLevel`, {
            onChange(e) {
              setValue(`${sectionName}.${props.row.index}.updated`, true);
              return e;
            },
          })}
          defaultValue={
            props.row.original.satisfactionLevel
              ? props.row.original.satisfactionLevel
              : 0
          }
        >
          {[...new Array(11)].map((item, index) => (
            <option selected={true} key={index}>
              {index}
            </option>
          ))}
        </select>
      ),
      footer: () => {
        return (
          <h4 className="text-left pl-[57px] pr-[24px] py-[12px]">
            {!isNaN(satisfactionLevelMean) && satisfactionLevelMean !== 0
              ? satisfactionLevelMean.toFixed(2)
              : 0}
          </h4>
        );
      },
    }),

    columnHelper.display({
      header: "Motivation",
      cell: (props) => {
        return (
          <select
            defaultValue={
              props.row.original.motivationLevel
                ? props.row.original.motivationLevel
                : 0
            }
            {...register(`${sectionName}.${props.row.index}.motivationLevel`, {
              onChange: (e) => {
                setValue(`${sectionName}.${props.row.index}.updated`, true);
                return e;
              },
            })}
          >
            {[...new Array(11)].map((item, index) => (
              <option key={index}>{index}</option>
            ))}
          </select>
        );
      },
      footer: () => {
        return (
          <>
            <h4 className="text-left pl-[57px] pr-[24px] py-[12px]">
              {!isNaN(motivationLevelMean) && motivationLevelMean !== 0
                ? motivationLevelMean.toFixed(2)
                : 0}
            </h4>
          </>
        );
      },
    }),
    columnHelper.display({
      header: "Action",
      cell: (props) => {
        return (
          <input
            type={"checkbox"}
            value={1}
            {...register(`${sectionName}.${props.row.index}.updated`, {
              onChange(event) {
                return event.target.checked ? 1 : 0;
              },
            })}
            checked={getValues(`${sectionName}.${props.row.index}.updated`)}
          />
        );
      },

      footer: (_props) => {
        return (
          <>
            <Button appearance="primary" onClick={resetValuesFunc}>
              Reset
            </Button>
          </>
        );
      },
    }),
  ];

  return (
    <div>
      <Table
        tableData={{ data, columns: COLUMNS }}
        showGlobalFilter={false}
        isPagination={false}
        isFooter={true}
        //TODO: remove important from class name
        tableHeadRowClass={"callMaximizer__thead-class"}
        tableHeadClass={" !text-left !px-2"}
        tableRowClass={"callMaximizer__row-class even:bg-gray-100 "}
        tableDataClass={" !text-left call-maximizer-class"}
      />
    </div>
  );
};

export default TabularSectionCallMaximizer;
