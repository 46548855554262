import { ReactNode } from "react";
import { Modal } from "rsuite";

type Props = {
  children: ReactNode;
  title: string;
  successBtnText: string | boolean;
  successBtnHandler: false | (() => void);
  cancelBtnText: string;
  cancelBtnHandler: () => void;
  customSuccessBtn?: JSX.Element | boolean;
  link?: string | boolean;
  successBtnDisabled?: boolean;
  cancelBtnTestId?: string;
};

const CustomModal = ({
  children,
  title,
  successBtnText,
  successBtnHandler,
  cancelBtnText,
  cancelBtnHandler,
  successBtnDisabled,
  cancelBtnTestId,
}: Props) => {
  return (
    <Modal
      backdrop={true}
      keyboard={false}
      open={true}
      onClose={cancelBtnHandler}
    >
      <Modal.Header>
        <Modal.Title className="!text-primary-blue !text-[20px] !font-bold text-left">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end items-center gap-2 md:gap-5 flex-col md:flex-row">
          {successBtnText ? (
            successBtnHandler ? (
              <button
                disabled={successBtnDisabled}
                onClick={successBtnHandler}
                className="text-base font-semibold text-white bg-green-500 py-[6px]  px-7 ml-5 rounded border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500"
              >
                {successBtnText}
              </button>
            ) : (
              <button
                type="submit"
                className="text-base font-semibold text-white bg-green-500 py-[6px]  px-7 rounded border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500"
              >
                {successBtnText}
              </button>
            )
          ) : null}
          {cancelBtnText ? (
            <button
              data-testid={cancelBtnTestId ?? "cancel-btn"}
              onClick={cancelBtnHandler}
              className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 ml-5 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500"
            >
              {cancelBtnText}
            </button>
          ) : null}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomModal;
