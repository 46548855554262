import BasicChartComponent from "components/charts";
import useCharts from "hooks/useCharts";

const LineChart = ({
  chartData,
  xaxisData,
}: {
  chartData: {
    name: string;
    data: number[];
  }[];
  xaxisData: string[];
}) => {
  const chartOptions = useCharts({
    xaxis: {
      categories: xaxisData,
    },
  });
  if (!chartData) return <></>;
  return (
    <BasicChartComponent
      additionalClassName={"!h-[500px] w-full overflow-x-scoll"}
      series={chartData}
      options={chartOptions}
      type="line"
    />
  );
};

export default LineChart;
