import { useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { useToaster } from "rsuite";
import VirtualList from "react-tiny-virtual-list";
//@ts-ignore
import AutoSizer from "react-virtualized-auto-sizer";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  removeChildrenOf,
  setProperty,
} from "./utilities";
import type { FlattenedItem, SensorContext, TreeItems } from "./types";
import { SortableTreeItem } from "./components";
import { CSS } from "@dnd-kit/utilities";
import LevelDrawer from "./components/TreeItem/LevelDrawer";
import { IDefinitionFormPostData } from "types/preferences/definition";
import { useMoveVmapMutationMutation } from "store/v-map/vmapApiSlice";
import { toastError, toastSuccess } from "components/toasts";
import SearchField from "./components/SearchField";

const initialItems: TreeItems = [];

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

interface Props {
  collapsible?: boolean;
  defaultItems?: TreeItems;
  indentationWidth?: number;
  indicator?: boolean;
  removable?: boolean;
  vmapId: number;
  filter: IDefinitionFormPostData;
  currentVmapIndex: number;
  isDragOn: boolean;
}

export function SortableTree({
  collapsible,
  defaultItems = initialItems,
  indicator = false,
  indentationWidth = 50,
  removable,
  vmapId,
  filter,
  currentVmapIndex,
  isDragOn,
}: Props) {
  const [items, setItems] = useState(defaultItems);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);
  const [search, setSearch] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null;
    overId: UniqueIdentifier;
  } | null>(null);
  const [moveVmap] = useMoveVmapMutationMutation();
  const toast = useToaster();
  const moveVmapHandler = async (data: any) => {
    try {
      await moveVmap(data).unwrap();
      toast.push(toastSuccess({ message: "Element Moved successfully" }));
    } catch (error) {
      toast.push(
        toastError({ message: "Unable to move element please try again" })
      );
    }
  };

  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items, currentVmapIndex);
    const collapsedItems = flattenedTree.reduce<string[]>(
      (acc, { children, collapsed, id }) =>
        //@ts-ignore
        collapsed && children?.length ? [...acc, id] : acc,
      []
    );

    return removeChildrenOf(
      flattenedTree,
      // @ts-ignore
      collapsedItems
    );
  }, [currentVmapIndex, items]);
  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const sensors = useSensors(useSensor(TouchSensor), useSensor(PointerSensor));

  const sortedIds = useMemo(
    () => flattenedItems.map(({ id }) => id),
    [flattenedItems]
  );
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  return (
    <div
      data-testid="sortable-tree-vmap"
      className="table-sec bg-white px-3 py-8 rounded-xl mt-8 "
    >
      <div className="flex flex-wrap gap-2 justify-between pb-3">
        <div className="flex gap-2 ">
          <button
            onClick={() => handleCollapse(false, false)}
            className={`border min-w-[120px] rounded-full ${
              isExpanded
                ? "bg-gray-400 border-gray-400 "
                : "bg-cyan-400 border-cyan-400 "
            }   px-7 py-2   md-mt-0 font-bold text-md text-white flex cursor-pointer hover:bg-white hover:text-cyan-400  transition-all ease-in-out duration-500`}
            disabled={isExpanded}
          >
            <ArrowsPointingInIcon className="w-5 h-5 mr-1" />
            Expand All
          </button>

          <button
            disabled={!isExpanded}
            onClick={() => handleCollapse(false, true)}
            className={`border min-w-[120px] rounded-full ${
              !isExpanded
                ? "bg-gray-400 border-gray-400 "
                : "bg-cyan-400 border-cyan-400 "
            } px-7 py-2   md-mt-0 font-bold text-md text-white flex cursor-pointer hover:bg-white hover:text-cyan-400  transition-all ease-in-out duration-500`}
          >
            <ArrowsPointingOutIcon className="w-5 h-5 mr-1" />
            Collapse All
          </button>
        </div>
        <SearchField
          setSearch={setSearch}
          flattenedItems={flattenedItems}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
        />
        <button
          disabled={!vmapId}
          onClick={() => setShowDrawer(true)}
          className="border min-w-[120px] rounded-full bg-secondary-blue px-7 py-2   md-mt-0 font-bold text-md text-white flex cursor-pointer hover:bg-white hover:text-secondary-blue  border-secondary-blue transition-all ease-in-out duration-500"
        >
          <PlusIcon className="w-5 h-5 mr-1" />
          {filter["valueTitle"]}
        </button>
        {showDrawer && vmapId && (
          <LevelDrawer
            level={0}
            type="level1"
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            isValueDrawer={true}
            vmapId={vmapId}
          />
        )}
      </div>
      <div className="h-[100vh]">
        <DndContext
          sensors={sensors}
          measuring={measuring}
          onDragStart={handleDragStart}
          onDragMove={handleDragMove}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <>
            <AutoSizer>
              {/* @ts-ignore */}
              {({ height, width }) => (
                <SortableContext
                  items={sortedIds}
                  strategy={verticalListSortingStrategy}
                >
                  <VirtualList
                    height={height || 100}
                    width={width}
                    className={"overflow-hidden"}
                    itemCount={flattenedItems?.length}
                    {...(flattenedItems?.length > 0
                      ? { scrollToIndex: selectedIndex }
                      : {})}
                    itemSize={64}
                    overscanCount={40}
                    stickyIndices={
                      activeId ? [sortedIds.indexOf(activeId)] : undefined
                    }
                    renderItem={({ index, style }) => {
                      const { id, children, collapsed, depth, ...rest } =
                        flattenedItems[index];

                      return (
                        <SortableTreeItem
                          key={id}
                          id={id}
                          // @ts-ignore
                          levelId={id}
                          depth={
                            id === activeId && projected
                              ? projected.depth
                              : depth
                          }
                          indentationWidth={indentationWidth}
                          indicator={indicator}
                          collapsed={Boolean(collapsed && children?.length)}
                          onCollapse={
                            collapsible && children?.length
                              ? () => handleCollapse(id)
                              : () => {}
                          }
                          // @ts-ignore
                          vmapId={rest.vMapId}
                          externalStyle={{
                            ...style,
                          }}
                          // if the following is the search result
                          isSearch={
                            search &&
                            rest?.title
                              ?.toLowerCase()
                              ?.match(new RegExp(search?.toLowerCase()))
                              ? true
                              : false
                          }
                          onRemove={
                            removable ? () => handleRemove(id) : () => {}
                          }
                          isDragOn={isDragOn}
                          filter={filter}
                          {...rest}
                        />
                      );
                    }}
                  />
                </SortableContext>
              )}
            </AutoSizer>
            {createPortal(
              <DragOverlay
                dropAnimation={dropAnimationConfig}
                modifiers={indicator ? [adjustTranslate] : undefined}
              >
                {activeId && activeItem ? (
                  //@ts-ignore
                  <SortableTreeItem
                    clone
                    {...activeItem}
                    id={activeId}
                    ghost={true}
                    vmapId={vmapId}
                    depth={activeItem.depth}
                    className={"border-1 border-red-500 "}
                    childCount={getChildCount(items, activeId) + 1}
                    levelId={activeId.toString()}
                    indentationWidth={indentationWidth}
                  />
                ) : null}
              </DragOverlay>,
              document.body
            )}
          </>
        </DndContext>
      </div>
    </div>
  );

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty("cursor", "grabbing");
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over, ...rest }: DragEndEvent) {
    resetState();
    if (projected && over) {
      const { depth, parentId } = projected;
      // if (!depth || !parentId) return;
      const clonedItems: FlattenedItem[] = JSON.parse(
        // @ts-ignore
        JSON.stringify(flattenTree(items, currentVmapIndex))
      );
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);
      const newTree = flattenTree(newItems, currentVmapIndex);
      const itemMoved = newTree.filter(({ id }) => id === active.id)[0];
      if (activeTreeItem.depth !== itemMoved.depth) {
        toast.push(toastError({ message: "Cannot Drag To Different Level" }));
        return;
      }
      if (activeTreeItem.parentId && itemMoved.parentId) {
        const newParent = newTree.filter(
          ({ id }) => id === itemMoved.parentId
        )[0];
        const oldParent = newTree.filter(
          ({ id }) => id === activeTreeItem.parentId
        )[0];
        if (newParent.type !== oldParent.type) {
          toast.push(toastError({ message: "Cannot Drag To Different Level" }));
          return;
        }
        const indexArr = newParent.children.map((item) => item.id);
        moveVmapHandler({
          index: indexArr,
          node: {
            ...itemMoved,
            Id: itemMoved.id,
            name: itemMoved?.title,
            vMapId: vmapId,
            parent: activeTreeItem.parentId,
          },

          parent: { Id: itemMoved.parentId },
        });
      }

      if (!activeTreeItem.parentId) {
        moveVmapHandler({
          index: newTree
            .filter((item) => !item.parentId)
            .map((item) => item.id),
          node: {
            ...itemMoved,
            Id: itemMoved.id,
            name: itemMoved?.title,
            vMapId: vmapId, //newParentId
          },

          parent: { Id: activeTreeItem.parentId }, //old parent id
        });
      }

      setItems(newItems);
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty("cursor", "");
  }

  function handleRemove(id: UniqueIdentifier) {
    setItems((items) => removeItem(items, id));
  }
  function handleCollapse(id: UniqueIdentifier | boolean, expandAll?: boolean) {
    setItems((items) =>
      setProperty(
        items,
        id,
        "collapsed",
        (value) => {
          return !value;
        },
        expandAll
      )
    );
    setIsExpanded(!expandAll);
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};
