import { DateRangePicker, DateRangePickerProps, Stack } from "rsuite";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import { endOfMonth, startOfQuarter } from "date-fns";
import { endOfQuarter } from "date-fns";

export type DateRangePlacement =
  | "bottomStart"
  | "bottomEnd"
  | "topStart"
  | "topEnd"
  | "leftStart"
  | "leftEnd"
  | "rightStart"
  | "rightEnd"
  | "auto"
  | "autoVerticalStart"
  | "autoVerticalEnd"
  | "autoHorizontalStart"
  | "autoHorizontalEnd";

interface IRHFDateRangePicker extends DateRangePickerProps {
  name: string;
  label: string;
  defaultRange?: string[];
  showPredefinedRanges?: boolean;
  datePickerPlacement?: DateRangePlacement;
  placeholder: string;
}

const predefinedRanges = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "This week",
    value: [startOfWeek(new Date()), endOfWeek(new Date())],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), endOfMonth(new Date())],
    placement: "left",
  },
  {
    label: "This year",
    value: [
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
    placement: "left",
  },
  {
    label: "Last year",
    value: [
      new Date(new Date().getFullYear() - 1, 0, 1),
      new Date(new Date().getFullYear(), 0, 0),
    ],
    placement: "left",
  },
  {
    label: "This Quarter",
    value: [startOfQuarter(new Date()), endOfQuarter(new Date())],
    placement: "left",
  },
  {
    label: "Since 30 Days",
    value: [subDays(new Date(), 30), new Date()],
    placement: "left",
  },
  {
    label: "Since 60 Days",
    value: [subDays(new Date(), 60), new Date()],
    placement: "left",
  },
  {
    label: "Since 90 Days",
    value: [subDays(new Date(), 90), new Date()],
    placement: "left",
  },
];

//TODO: types checking
//@ts-ignore
export default function DateRangePickerWithOptions({
  name,
  defaultRange,
  showPredefinedRanges,
  datePickerPlacement,
  placeholder,
  value,
  onChange,
}: IRHFDateRangePicker) {
  return (
    <Stack
      direction="column"
      spacing={8}
      alignItems="flex-start"
      className="xl:w-1/4 lg:w-1/3 sm:w-1/2 px-2 mb-2"
    >
      <DateRangePicker
        onChange={onChange}
        size={"md"}
        value={value}
        placement={datePickerPlacement}
        placeholder={placeholder}
        //TODO: needs to be worked on
        //@ts-ignore
        ranges={predefinedRanges}
        block
        disabled={false}
        cleanable={false}
        format="yyyy-MM-dd"
        showOneCalendar
      />
    </Stack>
  );
}
