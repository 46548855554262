import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useWindowSize from "hooks/useWindowSize";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "store";
import { useUserSimulationMutation } from "store/my-clients/myClientsApi";

const SimulateUsersList = () => {
  const [userSimulation] = useUserSimulationMutation();

  const { width } = useWindowSize();
  const { simulate } = useSelector((state: RootState) => state.auth);
  const simulateBack = async (index: number) => {
    if (!simulate) return;
    if (index === 0) {
      localStorage.removeItem("simulateArray");
      await userSimulateBack(simulate[0]?.user_id);
      window.location.href = "/";
      return;
    }
    let simulateArray = simulate;

    let value = simulateArray?.slice(0, index + 1);

    if (value) {
      value[index] = { ...value[index], active: true };
      localStorage.setItem("simulateArray", JSON.stringify(value));
      await userSimulateBack(value[index]?.user_id);
      window.location.href = "/";
    }
  };

  async function userSimulateBack(user_id: any) {
    const response = await userSimulation({ user_id })?.unwrap();
    if (response) {
      localStorage.setItem("wow_token", response?.data?.secret_token);
    }
  }

  if (simulate) {
    return width <= 720 ? (
      <>
        <ul
          className={`sub-nav-item right-sub-nav-item transition-all ease-in-out duration-500 `}
        >
          <li className={`mb-4`}>
            <div className="px-[8px]  py-2 text-primary-grey   transition-all duration-300 ease-in-out flex justify-between gap-1">
              <span className="text-primary-grey">
                Back to
                <Link to="#" className="">
                  {simulate?.map((item, index) => {
                    return (
                      !item.active && (
                        <button
                          onClick={() => simulateBack(index)}
                          className="dropdown-menu-item text-center menu-sub-menu-items "
                        >
                          {item.name}
                        </button>
                      )
                    );
                  })}
                </Link>
              </span>
              <ChevronDownIcon className="w-[20px] h-[20px]" />
            </div>
            <ul
              className={`sub-menu-list mb-4 md:hidden group-hover:flex flex-col gap-0  dropdown-menu p-2  bg-white  absolute top-[79%] md:top-[127px] lg:top-[120px] translate-y-3  before:top-[79%] before:md:top-[-10px] left-[-100px] md:left-auto before:left-[-100px] md:before:left-[-10px]  md:before:w-full md:before:h-full before:absolute  rounded  drop-shadow-lg w-48  hover:mouse-pointer before:z-[-1] transition-all ease-in-out duration-500 `}
            >
              <li className=" hover:bg-slate-100 rounded  py-1 ">
                <Link to="#" className="flex !justify-center !flex-col ">
                  {simulate?.map((item, index) => {
                    return (
                      !item.active && (
                        <button
                          onClick={() => simulateBack(index)}
                          className="dropdown-menu-item text-center menu-sub-menu-items "
                        >
                          {item.name}
                        </button>
                      )
                    );
                  })}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </>
    ) : (
      <ul className="avatar-circles flex  justify-center lg:justify-end items-center lg:w-[184px]  flex-row flex-nowrap">
        <li className="navbar-menu-items group h-full flex justify-center items-center relative">
          <div
            onClick={() => simulateBack(0)}
            className="navbar-menu-item-link  px-2 py-2 text-sm font-normal transition-all ease-in-out cursor-pointer select-none text-primary-blue flex justify-center flex-wrap gap-1 hover:bg-slate-100 border-grey-400 border-transparent rounded-xl duration-500 "
          >
            <span>Back to: {simulate[0]?.name}</span>
          </div>
          {simulate?.length > 2 && (
            <ul className="group-hover:flex flex-col gap-0  dropdown-menu p-2 w-52 bg-white hidden absolute top-[65px] lg:top-[50px] right-0 translate-y-3  before:top-[-10px] before:left-[-10px] before:w-full before:h-full before:absolute  rounded  drop-shadow-lg  hover:mouse-pointer before:z-[-1] cursor-pointer">
              {simulate?.map((item, index) => {
                return (
                  !item.active && (
                    <li className="dropdown-menu-item hover:bg-slate-100 rounded  ">
                      <button
                        onClick={() => simulateBack(index)}
                        className="px-1 py-2 hover:text-primary-blue "
                      >
                        {item.name}
                      </button>
                    </li>
                  )
                );
              })}
            </ul>
          )}
        </li>
      </ul>
    );
  } else {
    return <></>;
  }
};

export default SimulateUsersList;
