import { differenceInDays } from 'date-fns'
import { TActivityDetailsResponse } from '../../../../types/dashboard';

export const ScaleSign = '...';
export const SeasonalGoalSign = '@';

export const ActivityFormatData = (activityData:TActivityDetailsResponse | undefined) => {

    let description = activityData?.data?.description ? JSON.parse(activityData?.data?.description) : '';
    let types       = trackingTypes()

    return {
        title: activityData?.data?.title,
        status: activityData?.data?.status,
        delegates: activityData?.data?.delegateTo?.split(',').map( Number ),
        links: description?.link,
        daily:activityData?.data?.daily ?? 0,
        weekly:activityData?.data?.weekly,
        monthly:activityData?.data?.monthly,
        quarterly:activityData?.data?.quarterly,
        annually:activityData?.data?.annually,
        goal:activityData?.data?.goal,
        trackSign:activityData?.data?.trackSign,
        seasonalSign:activityData?.data?.seasonalSign,
        checkbox:[
            activityData?.data?.showOnDashboard ? "showOnDashboard" : "",
            activityData?.data?.showOnDashboard ? "showOnDashboard" : "",
            activityData?.data?.includeInReporting ? "includeInReporting" : "",
            activityData?.data?.includeInAvatar ? "includeInAvatar" : "",
            activityData?.data?.includeInProfile ? "includeInProfile" : "",
        ],
        description:description?.blocks,
        accumulate:activityData?.data?.isAccumulate ? true : false,
        
        number:(activityData?.data?.trackSign == types?.number) ? true : false,
        doller:(activityData?.data?.trackSign == types?.doller) ? true : false,
        percentage:(activityData?.data?.trackSign == types?.percentage) ? true : false,
        scale:(activityData?.data?.trackSign == types?.scale) ? true : false,
        seasonal:(activityData?.data?.trackSign == types?.seasonal) ? true : false,
        successScale: activityData?.data?.successScale,
        assignDueDate: [
            activityData?.data?.assignDate ? new Date(activityData?.data?.assignDate) : new Date(),
            activityData?.data?.dueDate ? new Date(activityData?.data?.dueDate) : new Date()
        ],
        seasonalGoal:seasonalGoalData(activityData?.data?.seasonalGoal ?? "")
    }
}

export const UpdateActivityData = (formData:any, event:any) => {

    let groupTracking  = trackingEventType()
    let goal       = (event?.target?.id == "Goal") ? event?.target?.value : formData?.goal
    
    return {
        title: formData?.title,
        status: formData?.status,
        delegates: formData?.delegates,
        links: formData?.link,
        daily:goalCalculation('daily', goal, formData?.assignDueDate[0], formData?.assignDueDate[1], formData?.goal) ?? 0,
        weekly:goalCalculation('weekly', goal, formData?.assignDueDate[0], formData?.assignDueDate[1], formData?.goal),
        monthly:goalCalculation('monthly', goal, formData?.assignDueDate[0], formData?.assignDueDate[1], formData?.goal),
        quarterly:goalCalculation('quarterly', goal, formData?.assignDueDate[0], formData?.assignDueDate[1], formData?.goal),
        annually:goalCalculation('annually', goal, formData?.assignDueDate[0], formData?.assignDueDate[1], formData?.goal),
        goal:formData?.goal,
        trackSign:groupTracking?.includes(event?.target?.id) ? checkTrackingTypes(event?.target?.id) : formData?.trackSign,
        performanceDial:formData?.performanceDial,
        checkbox:formData?.checkbox,
        seasonalSign:formData?.seasonalSign,

        description:formData?.description,
        accumulate:formData?.accumulate,
        successScale:formData?.successScale,

        number:checkTracking("Number", formData?.number, event?.target?.id, event?.target?.checked),
        doller:checkTracking("Doller", formData?.doller, event?.target?.id, event?.target?.checked),
        percentage:checkTracking("Percentage", formData?.percentage, event?.target?.id, event?.target?.checked),
        scale:checkTracking("Scale", formData?.scale, event?.target?.id, event?.target?.checked),
        seasonal:checkTracking("Seasonal", formData?.seasonal, event?.target?.id, event?.target?.checked),

        assignDueDate: formData?.assignDueDate,
        seasonalGoal:updateSeasonalGoalData(formData?.seasonalGoal, event?.target?.id, event?.target?.value)
    }
}

export const checkIncludes = (value:string, type:string|boolean, eventId:string, checked:boolean) => {
    
    return (eventId == value && checked) ? value : ""
}

export const checkTracking = (value:string, type:boolean, eventId:string, checked:boolean) => {

    let trackingEvents  = trackingEventType()

    if(!trackingEvents?.includes(eventId)) return type

    if(!checked)
        return type ? true : false
    return (eventId === value && checked) ? true : false
}

export const trackingTypes = () => {
    return {
       number: "#",
       doller: "$",
       percentage: "%",
       scale: "...",
       seasonal: "@"
    }
}

export const trackingEventType = () => {
    return ['Number', 'Doller', 'Percentage', 'Scale', 'Seasonal']
}

export const checkTrackingTypes = (type:string) => {
    switch(type){
        case "Number":
            return "#"
        case "Doller": 
            return "$";
        case "Percentage": 
            return "%";
        case "Scale": 
            return "...";
        case "Seasonal": 
            return "@";
    }
}

export const seasonalGoalData = (seasonalGoal:string) => {

    let seasonalGoalArray = seasonalGoal?.split(',');
    let goals:any = [];
    let seadsonalGoalValues = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', "Sep", 'Oct', 'Nov', 'Dec'];
    seadsonalGoalValues?.map((option, key) => {
        goals[option] = seasonalGoalArray[key]
    })
    return goals
}

export const updateSeasonalGoalData = (seasonalGoal:any, id:string, value:number) => {

    let seadsonalGoalValues = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', "Sep", 'Oct', 'Nov', 'Dec'];
    if( seadsonalGoalValues?.includes(id) ){
        seasonalGoal[id] = value
    }
    return seasonalGoal
}

export const goalCalculation = (type:string, goalValue:number, assignDate:Date, dueDate:Date, goal:number) => {

    let noOfDaysInYear = daysInYear(assignDate)?366:365;
    let dateDiff  = differenceInDays( new Date(dueDate), new Date(assignDate))

    dateDiff  = dateDiff ? dateDiff : 1

    
    switch(type){
        case "daily":
            return Math.round(((noOfDaysInYear/dateDiff)*goalValue)/(52*7)) ?? 0
        case "weekly":
            return Math.round(((noOfDaysInYear/dateDiff)*goalValue)/52)
        case "monthly":
            return Math.round(((noOfDaysInYear/dateDiff)*goalValue)/(12))
        case "quarterly":
            return Math.round(((noOfDaysInYear/dateDiff)*goalValue)/(4))
        case "annually":
            return Math.round(((noOfDaysInYear/dateDiff)*goalValue))
        default :
            return goalValue;
    }
}

export const daysInYear = (date:Date) => {
    
    var year = new Date(date).getFullYear();
    return year % 400 === 0 || (year % 4 === 0 && year % 100 !== 0);
}