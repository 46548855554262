import { appApi } from "store/apis/apiSlice";
import {
  TDelegate,
  IProfileResponseData,
  TVMapResponseData,
  SelectValueType,
  IDashboardActivities,
  TProfileResponseData,
  TGlobalHealth,
  TDelegateGraphRequestParams,
} from "types/dashboard";
import {
  TDashboardDataParams,
  TDashboardDataResponse,
  TValuesResponse,
  TKpisResponse,
  TActivityDetailsParam,
  TActivityDetailsResponse,
} from "../../types/dashboard";
import { TTrackingData } from "types/tracking-data";

export interface IGraphData {
  data: { date: string; health: number }[];
}

const apiWithTags = appApi.enhanceEndpoints({
  addTagTypes: [
    "Dashboard Activity",
    "Delegates",
    "Notification Activity",
    "DelegateNote",
  ],
});

export const dashboardSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    dashboardFetchDelegateList: builder.query<TDelegate, void>({
      query: () => ({
        url: `/delegate?pageSize=200`,
      }),
      providesTags: ["Delegates"],
    }),

    fetchProfileList: builder.query<IProfileResponseData, void>({
      query: () => ({
        url: `/dashboard-profile`,
      }),
    }),
    /** ftech all user dashboard profile list */
    fetchUserProfileList: builder.query<IProfileResponseData, void>({
      query: () => ({
        url: `/user-dashboard-profile`,
      }),
    }),

    fetchDashboardProfileList: builder.mutation<TProfileResponseData, Number>({
      query: (value) => ({
        url: `/dashboard-profile/${value}`,
      }),
    }),

    fetchAllVMapList: builder.mutation<TVMapResponseData, void>({
      query: () => ({
        url: `/all-vmaps?include=values.kpis.strategy.project.criticalActivity&page=1&pageSize=100`,
      }),
    }),
    fetchValuesList: builder.mutation<TValuesResponse, number[]>({
      query: (bodyData) => {
        return {
          url: `/values`,
          method: "POST",
          body: { data: bodyData },
        };
      },
    }),

    fetchKpisList: builder.mutation<TKpisResponse, number[]>({
      query: (bodyData) => {
        return {
          url: `/kpis`,
          method: "POST",
          body: { data: bodyData },
        };
      },
    }),
    fetchDashboardDataList: builder.mutation<
      TDashboardDataResponse,
      TDashboardDataParams
    >({
      query: ({ bodyData }) => {
        return {
          url: `/get-dashboard-filter-data`,
          method: "POST",
          body: bodyData,
        };
      },
      invalidatesTags: ["Dashboard Activity"],
    }),

    fetchActivityDetails: builder.query<
      TActivityDetailsResponse,
      TActivityDetailsParam
    >({
      query: ({ level, id }) => {
        return {
          url: `/activity-details/${level}/${id}`,
        };
      },
    }),

    updateActivityData: builder.mutation<
      {
        message: string;
      },
      IDashboardActivities[]
    >({
      query: (data) => {
        return {
          url: "/dashboard-quick-update",
          method: "POST",
          body: {
            data,
          },
        };
      },
      invalidatesTags: ["Dashboard Activity"],
    }),

    fetchTrackingDataList: builder.query<TTrackingData, TActivityDetailsParam>({
      query: (bodyData) => {
        return {
          url: `/getSpecificActivity`,
          method: "POST",
          body: bodyData,
        };
      },
      providesTags: ["Dashboard Activity"],
    }),

    fetchGlobalDashboardData: builder.query<
      TGlobalHealth,
      { isDelegateView?: string }
    >({
      query: ({ isDelegateView }) => {
        return {
          url: isDelegateView
            ? `/health-data?token=${isDelegateView}`
            : `/user-global-avatar-health`,
        };
      },
    }),
    fetchGraphData: builder.query<
      IGraphData,
      { type: string; dates: [string, string] }
    >({
      query: ({ type, dates }) => {
        return {
          url: `/graph/${type}?startDate=${dates[0]}&endDate=${dates[1]}&vMap&kpi&delegate&profile`,
        };
      },
    }),
    fetchDashboardData: builder.query<
      TDashboardDataResponse,
      TDashboardDataParams
    >({
      query: (bodyData) => {
        return {
          url: `/get-dashboard-filter-datas`,
          method: "POST",
          body: bodyData?.bodyData,
        };
      },
      providesTags: ["Dashboard Activity"],
    }),
    fetchDelegateGraphData: builder.mutation<any, TDelegateGraphRequestParams>({
      query: ({ filterData, delegateId, dateRange, avatarType }) => {
        return {
          url: `/graph/${avatarType}/${delegateId}`,
          method: "POST",
          body: {
            vMap: filterData?.activeVMap,
            value: filterData?.activeValue,
            kpi: filterData?.activeKpi,
            startDate: dateRange[0],
            endDate: dateRange[1],
            profile: filterData?.activeProfile,
            delegate: filterData?.activeDelegate,
          },
        };
      },
    }),
    fetchNotificationTrackingDataList: builder.query<
      TTrackingData,
      TActivityDetailsParam
    >({
      query: (bodyData) => {
        return {
          url: `/getTrackingActivity`,
          method: "POST",
          body: bodyData,
        };
      },
      providesTags: ["Notification Activity"],
    }),
    getNoteOfDelegate: builder.query<
    {
      data: {
        activity_description: string;
        performance_description: string;
        updated_at: string;
        created_at: string;
      };
    },
    { delegateId: number | undefined | string,
      key?: string | undefined
    }
  >({
    query: ({ delegateId, key }) => {

      const url = key ?
      `delegate-descriptions/${delegateId}?token=${key}`
      : `delegate-description/${delegateId}`
      return url;
    },
    providesTags: (res, _error, args) => [
      { type: "DelegateNote", id: args.delegateId },
    ],
  }),
  
    addNoteForDelegate: builder.mutation<
      { status: number; data: { user_id: number } },
      {
        type: "performance" | "activity";
        activity_description?: string;
        performance_description?: string;
        delegateId: number | string;
        key?: string
      }
    >({
      query: ({
        activity_description,
        performance_description,
        type,
        delegateId,
        key
      }) => {
      
          const url = key ?
          `delegate-descriptions/${type}/${delegateId}?token=${key}`
          : `delegate-description/${type}/${delegateId}`;
          return {

          url: url,
          method: "POST",
          body: {
            activity_description,
            performance_description,
          },
        };
      },
      invalidatesTags: (res) => [
        { type: "DelegateNote", id: res?.data?.user_id },
      ],
    }),
  }),
});

export const {
  useDashboardFetchDelegateListQuery,
  useFetchProfileListQuery,
  useFetchActivityDetailsQuery,
  useFetchDashboardDataListMutation,
  useFetchValuesListMutation,
  useFetchKpisListMutation,
  useUpdateActivityDataMutation,
  useFetchTrackingDataListQuery,
  useFetchGlobalDashboardDataQuery,
  useFetchGraphDataQuery,
  useFetchDashboardDataQuery,
  useFetchDelegateGraphDataMutation,
  useFetchAllVMapListMutation,
  useFetchNotificationTrackingDataListQuery,
  useFetchDashboardProfileListMutation,
  useFetchUserProfileListQuery,
  useGetNoteOfDelegateQuery,
  useAddNoteForDelegateMutation,
} = dashboardSlice;
