import { Level1, Level2, Level3, Level4, Level5 } from "./icons";

export const DEFAULT_AVATAR = ({
  avatarLevel,
  style,
}: {
  avatarLevel: number;
  style?: {
    width?: string;
    height?: string;
  };
}) => {
  switch (avatarLevel) {
    case 1:
      return <Level1 width={style?.width} height={style?.height}/>;
    case 2:
      return <Level2 width={style?.width} height={style?.height} />;
    case 3:
      return <Level3 width={style?.width}  height={style?.height}/>;
    case 4:
      return <Level4 width={style?.width}  height={style?.height} />;
    default:
      return <Level5  width={style?.width}  height={style?.height} />;
  }
};
