import differenceInDays from "date-fns/differenceInDays";
import { AvatarHealth, AvatarGrade, AvatarType } from "./AvatarHealth";
// write test for the following funciton

export const AvatarCalculate = (dueDate: Date) => {
  return calculateHealth(dueDate);

  function calculateHealth(activity: any) {
    if (!dueDate) return;

    // loop through tasks and get percentage
    const dateDiff = getDateDifference(dueDate);

    // add all the percentages
    let finalPercentage = AvatarHealth(dateDiff);

    // return the final percentage
    return {
      finalPercentage: finalPercentage.toFixed(0) ?? 0,
      grade: getGrade(finalPercentage),
      type: AvatarType(finalPercentage),
    };
  }

  function getDateDifference(dueDate: Date) {
    return differenceInDays(new Date(dueDate), new Date());
  }

  // RETURN THE GRADES BASED ON THE PERCENTAGE
  function getGrade(percent: number) {
    return AvatarGrade(percent) ?? "F";
  }
};
