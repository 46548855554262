export const defaultLevelsOfVmap = {
    value: {
        valueId: null,
        valueTitle: "New Activity",
        type: "level1"
    },
    kpi: {
        kpiId: null,
        kpiName: "New Activity",
        type: "level2"
    },
    strategy: {
        strategyId: null,
        strategyName: "New Activity",
        type: 'level3'
    },
    project: {
        projectId: null,
        projectName: "New Activity",
        type: 'level4'
    }, criticalActivity: {
        criticalActivityId: null,
        criticalActivityName: "New Activity",
        type: "level5"
    }


}