import { ErrorMessage } from "@hookform/error-message";
import { useEffect, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { SelectPicker, SelectPickerProps } from "rsuite";

export interface IRHFSelect extends SelectPickerProps<any> {
  name: string;
  block: boolean;
}

export default function RHFInfiniteSelect({ name, data, ...rest }: IRHFSelect) {
  const {
    control,
    getValues,
    setValue,
    formState: { defaultValues, errors },
  } = useFormContext();
  const ref = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ref.current]);

  const index = name.split(".")[1];
  const resetFieldsOrder = [
    `vmaps.${index}.valueIds`,
    `vmaps.${index}.kpiIds`,
    `vmaps.${index}.strategyIds`,
    `vmaps.${index}.projectIds`,
    `vmaps.${index}.criticalActivityIds`,
  ];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <SelectPicker
            data={data}
            {...rest}
            defaultValue={getValues(name)}
            searchable={rest.searchable}
            block
            onChange={(e) => {
              // so whenever the vmap is changed all the select fields should be emptied.
              resetFieldsOrder.forEach((name) => setValue(name, []));
              onChange(e);
            }}
            className={rest.className}
            // virtualized
            // listProps={{
            //   //@ts-ignore
            //   onItemsRendered: rest.listProps,
            // }}
          />

          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <span ref={ref} className="text-red-500">
                {message}
              </span>
            )}
          />
        </>
      )}
    />
  );
}
