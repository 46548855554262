import { appApi } from "store/apis/apiSlice";
import { TActivityDetailsParam, TGroupActivity } from "types/dashboard";
import { TNotificationRequestParam } from "types/notification";
import { TTrackingData } from "types/tracking-data";

const apiWithTags = appApi.enhanceEndpoints({
  addTagTypes: ["Dashboard Activity", "Delegates"],
});

export const notificationSlice = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    dashboardFetchDelegateData: builder.query<
      TGroupActivity,
      TNotificationRequestParam
    >({
      query: ({ key, type, id }) => {
        // Check if the `id` should be added (e.g., based on `type`)
        let url = `/group-update/${type}/${key}`;
        if (id) { // Replace 'specificType' with your actual condition
          url += `?id=${id}`;
        }
        return {
          url,
        };
      },
      providesTags: ["Dashboard Activity"],
    }),
    getTrackingDataList: builder.query<TTrackingData, TActivityDetailsParam>({
      query: (bodyData) => {
        return {
          url: `/get-specific-activity?token=${bodyData?.token}`,
          method: "POST",
          body: bodyData,
        };
      },
      providesTags: ["Dashboard Activity"],
    }),
  }),
});

export const {
  useDashboardFetchDelegateDataQuery,
  useGetTrackingDataListQuery,
} = notificationSlice;
