import { appApi } from "store/apis/apiSlice";

export interface IQualifier {
  user: { name: string; user_id: number };
}

export interface IQualifierQueryParams {
  page: number;
}

export interface IQualifierResponse {
  data: IQualifier[];
  last_page: number;
  total: number;
  per_page: number;
}

const callQualifierApiWithTag = appApi.enhanceEndpoints({
  addTagTypes: ["TrackingData"],
});

export const QualifierApiSlice = callQualifierApiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchQualifierData: builder.query<
      IQualifierResponse,
      IQualifierQueryParams
    >({
      query: ({ page }) => ({
        url: `vmap-qualifiers?page=${page}`,
      }),
      transformResponse: (response: { data: IQualifierResponse }) => {
        return response.data
      },
    }),
  }),
});

export const { useFetchQualifierDataQuery } = QualifierApiSlice;
