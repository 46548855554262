import { forwardRef, TextareaHTMLAttributes, useRef } from "react";

export interface InputProps extends TextareaHTMLAttributes<HTMLElement> {
  error?: string;
  additionalClassName?: string;
  overrideClassName?: boolean;
  prefixElement?: JSX.Element;
}

export const TextArea = forwardRef<HTMLTextAreaElement, InputProps>(
  (props, ref) => {
    const { maxLength, defaultValue, placeholder, onChange } = props;
    const refLim = useRef<HTMLTextAreaElement | null>(null);
    return (
      <>
        <div className="relative w-full ">
          <textarea
            defaultValue={defaultValue}
            {...props}
            ref={ref}
            onChange={(e) => {
              if (refLim.current && maxLength) {
                refLim.current.textContent = `char left ${
                  maxLength - e?.target?.value?.length
                }`;
              }
              if (onChange) {
                onChange(e);
              }
            }}
            onFocus={() => {
              if (refLim.current) {
                refLim.current.style.display = "flex";
                // @ts-ignore
                if (maxLength == defaultValue?.length) {
                  refLim.current.textContent = `char left 0 `;
                }
              }
            }}
            onBlurCapture={() => {
              if (refLim.current) {
                refLim.current.style.display = "none";
              }
            }}
            placeholder={placeholder}
            className="focus:shadow-primary-outline mt-5 text-sm leading-5 ease-linear block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none !resize"
          />
          {maxLength && (
            <span
              ref={refLim}
              className="hidden justify-end font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 "
            ></span>
          )}
        </div>
      </>
    );
  }
);
