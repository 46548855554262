import { SyntheticEvent, useState, useEffect, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

import { Modal, Button, Form, DatePicker, useToaster, Input } from "rsuite";
import { RootState } from "../../store/index";
import { toastSuccess, toastError } from "components/toasts/index";
import { setEditTrackingData } from "store/tracking-data";
import { TrackingDataSchema } from "./schema";
import {
  TTrackingPostValues,
  TPutDataRequest,
} from "../../types/tracking-data/index";
import { useUpdateTrackingDataMutation } from "store/tracking-data/trackingApi";
import { useFetchDashboardDataListMutation } from "store/dashboard/DashboardSlice";
import beforeUnload from "hooks/beforeUnload";

const Textarea = forwardRef((props, ref) => (
  <Input {...props} as="textarea" rows={4} />
));

const EditDelegate = () => {
  const toaster = useToaster();
  const dispatch = useDispatch();

  const { filterData } = useSelector(
    (state: RootState) => state?.dashboardSlice
  );

  const [refetchDashboardData] = useFetchDashboardDataListMutation({
    fixedCacheKey: "shared-update-post",
  });

  const [formValues, setFormValues] = useState<TTrackingPostValues | any>();
  const [formError, setFormError] = useState<TTrackingPostValues | any>();
  const [changes, setChanges] = useState<boolean>(false);

  const { isRowEdit, editRow } = useSelector(
    (state: RootState) => state?.trackingDataSlice
  );
  const [updateTrackingData, { isLoading: processing }] =
    useUpdateTrackingDataMutation();

  useEffect(() => {
    setFormValues({
      beginingDate: editRow?.beginningDate && new Date(editRow?.beginningDate),
      endingDate: editRow?.endingDate && new Date(editRow?.endingDate),
      data: editRow?.data,
      comment: editRow?.comment,
    });
  }, [editRow]);

  const handleClose = (confirm: boolean) => {
    if (changes && confirm) {
      beforeUnload(true);
      if (window.confirm("Changes you made may not be saved!")) {
        closeModalBox();
      }
    } else {
      closeModalBox();
    }
  };
  function closeModalBox() {
    setFormValues({
      data: "",
      comment: "",
    });
    setChanges(false);
    beforeUnload(false);
    dispatch(setEditTrackingData(false));
  }
  return (
    <>
      <Modal size={"lg"} open={isRowEdit} onClose={() => handleClose(true)}>
        <Modal.Header>
          <Modal.Title className="mx-auto !text-primary-blue !text-[20px] !font-bold text-left">
            Edit Tracking Data Row
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            layout="inline"
            formError={formError}
            onCheck={setFormError}
            model={TrackingDataSchema}
            formValue={formValues}
            onChange={(formValues: object) => {
              setFormValues(formValues);
              /** check id changes made by user */
              setChanges(true);
              beforeUnload(true);
            }}
            onSubmit={(el, event) => onSubmitHandler(event)}
            className="edit-trackingData-form grid-cols-1 lg:grid-cols-2 gap-2 mt-5"
          >
            <Form.Group controlId="beginingDate">
              <Form.ControlLabel>Beginning Date:</Form.ControlLabel>
              <Form.Control
                oneTap
                label="Beginning Date*"
                accepter={DatePicker}
                name="beginingDate"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYY"
                className="edit-trackingData-input"
              />
            </Form.Group>

            <Form.Group controlId="endingDate">
              <Form.ControlLabel>Ending Date:</Form.ControlLabel>
              <Form.Control
                oneTap
                label="Ending Date*"
                accepter={DatePicker}
                name="endingDate"
                format="MM/dd/yyyy"
                placeholder="MM/DD/YYY"
                className="edit-trackingData-input"
              />
            </Form.Group>

            <Form.Group controlId="data">
              <Form.ControlLabel>Data:</Form.ControlLabel>
              <Form.Control
                oneTap
                label="Data"
                accepter={Input}
                name="data"
                placeholder="Data"
                className="edit-trackingData-input"
              />
            </Form.Group>

            <Form.Group controlId="comment">
              <Form.ControlLabel>Comment:</Form.ControlLabel>
              <Form.Control
                accepter={Textarea}
                name="comment"
                //@ts-ignore
                placeholder="Comment"
                className="edit-trackingData-input"
              />
            </Form.Group>

            <div className="col-span-2  flex gap-5 justify-center md:justify-end items-center flex-col md:flex-row !m-0">
              <button
                type="submit"
                className="text-base font-semibold text-white bg-green-500 py-[6px]  px-7  rounded border border-green-500 hover:text-green-500 hover:bg-transparent transition-all ease-in-out duration-500 "
              >
                {" "}
                Updating Tracking Data
              </button>
              <button
                type="button"
                onClick={() => handleClose(true)}
                className="text-base font-semibold text-white bg-red-500 py-[6px] px-7 rounded border border-red-500 hover:text-red-500 hover:bg-transparent transition-all ease-in-out duration-500 "
              >
                Cancel
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );

  async function onSubmitHandler(event: SyntheticEvent) {
    if (Object.keys(formError)?.length > 0) return false;

    let data: TPutDataRequest = {
      bodyData: {
        activity: editRow?.activity,
        activityId: editRow?.activityId,
        beginningDate: format(new Date(formValues?.beginingDate), "yyyy-MM-dd"),
        endingDate: format(new Date(formValues?.endingDate), "yyyy-MM-dd"),
        comment: formValues?.comment,
        data: formValues?.data,
        trackingId: editRow?.trackingId,
      },
      trackingId: editRow?.trackingId,
    };

    await updateTrackingData(data)
      .unwrap()
      .then((data) => {
        if (data.status) {
          toaster.push(toastSuccess({ message: data.message }));
          handleClose(false);
          setFormValues("");
          setFormError("");
          refetchDashboardData({ bodyData: filterData });
          return true;
        }
      })
      .catch((error) => {
        setFormError({
          beginingDate: error?.data?.errors?.beginingDate?.values()?.next()
            ?.value,
          endingDate: error?.data?.errors?.endingDate?.values()?.next()?.value,
          comment: error?.data?.errors?.comment?.values()?.next()?.value,
          data: error?.data?.errors?.data?.values()?.next()?.value,
        });
        toaster.push(
          toastError({
            message: (
              <ul>
                {error?.data?.errors?.beginingDate ? (
                  <li>
                    <strong>Beginging Date:</strong>{" "}
                    {error?.data?.errors?.beginingDate}
                  </li>
                ) : (
                  ""
                )}
                {error?.data?.errors?.endingDate ? (
                  <li>
                    <strong>Ending Date:</strong>{" "}
                    {error?.data?.errors?.endingDate}
                  </li>
                ) : (
                  ""
                )}
                {error?.data?.errors?.comment ? (
                  <li>
                    <strong>Comment:</strong> {error?.data?.errors?.comment}
                  </li>
                ) : (
                  ""
                )}
                {error?.data?.errors?.data ? (
                  <li>
                    <strong>Data:</strong> {error?.data?.errors?.data}
                  </li>
                ) : (
                  ""
                )}
              </ul>
            ),
          })
        );
      });
  }
};

export default EditDelegate;
