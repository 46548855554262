export const DEFAULT_PAGE: { [key: string]: string } = {
  callMaximizer: "Call Maximizer",
  dashboard: "Dashboard",
  trackSystemData: "Tracking Data",
  vmaproute: "V-MAP",
};

export const WEEK_DAYS: { [key: string]: string } = {
  "0": "Monday",
  "1": "Tuesday",
  "2": "Wednesday",
  "3": "Thursday",
  "4": "Friday",
  "5": "Saturday",
  "6": "Sunday",
};

export const YES_NO: { [key: string]: string } = {
  "1": "YES",
  "0": "NO",
};
export const OPEN_CLOSE: { [key: string]: string } = {
  "1": "OPEN",
  "0": "CLOSE",
};

export const ROUTES: { [key: string]: string } = {
  callMaximizer: "call-maximizer",
  vmaproute: "v-map",
  dashboard: "dashboard",
  trackSystemData: "tracking-data",
};

export const Status = [
  {
    value: 1,
    label: "Active",
  },
  {
    value: 0,
    label: "InActive",
  },
];
